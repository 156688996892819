import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const GroupTagAndDescriptionEmptyStateWrapper = styled(NvFlex)`
  flex-direction: row;
  align-items: center;
  height: 24px;
  gap: 4px;

  &:first-child {
    margin-top: 4px;
  }

  &:last-child {
    margin-bottom: 4px;
  }
`;
