import { NvAddBoxIcon, NvDivider, NvFlex, NvTypography } from '@novaera/core';
import { useNavigate } from '@novaera/route';

import { switchToWorkspace } from '@novaera/utils';
import { WorkspaceItem } from '../../../workspace-item';
import { WorkspaceType } from '../../../workspace-item/types';
import { AddWorkspaceButtonBox, WorkspaceList, WorkspaceMenuItem } from './styled';
import { PopupMenuComponentProps } from './types';

export const WorkspacePopupMenuComponent: React.FC<React.PropsWithChildren<PopupMenuComponentProps>> = ({
  workspaces,
  selectedWorkspace,
}) => {
  const navigate = useNavigate();

  return (
    <NvFlex>
      <WorkspaceList>
        {/* Murat*/}
        {workspaces.map((workspace: WorkspaceType) => (
          <WorkspaceMenuItem
            key={workspace.subdomain}
            value={workspace.subdomain}
            disableRipple
            onClick={() => {
              switchToWorkspace(workspace.subdomain);
            }}
            isSelected={selectedWorkspace?.subdomain === workspace.subdomain}
          >
            <WorkspaceItem workspace={workspace} hideWorkspaceSubdomain />
          </WorkspaceMenuItem>
        ))}
        <NvDivider />
        <AddWorkspaceButtonBox
          onClick={() => {
            navigate('/workspace/create');
          }}
        >
          <NvAddBoxIcon />
          <NvTypography variant="h4">Add new workspace</NvTypography>
        </AddWorkspaceButtonBox>
      </WorkspaceList>
    </NvFlex>
  );
};
