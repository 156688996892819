import { NvBox, NvConditionalRender, NvFlex, NvScrollBox, NvSplitPane, SplitPanelItem } from '@novaera/core';

import { EmptyState } from '../../empty-state';

import React, { forwardRef, memo, Ref } from 'react';
import { useRequests } from './controllers/use-requests';
import { APIRequestDetail } from './detail/api-request';
import { RequestList } from './list';
import { RequestModal } from './request-modal';
import { RequestListWrapper } from './styled';

const OriginalRequests: React.FC<React.PropsWithChildren<{ forwardedRef: Ref<HTMLElement> }>> = ({ forwardedRef }) => {
  const { showList, onOpenModal, selectedRequestId, requestModalParams } = useRequests();

  return (
    <>
      <NvConditionalRender when={() => showList}>
        <NvSplitPane direction="horizontal">
          <SplitPanelItem minSize="300px" initialSize="380px">
            <RequestListWrapper>
              <NvFlex height={1} padding="16px">
                <RequestList openModal={onOpenModal} />
              </NvFlex>
            </RequestListWrapper>
          </SplitPanelItem>
          <SplitPanelItem minSize="500px" className="split-panel-item-position-relative">
            <NvScrollBox marginLeft="-12px" ref={forwardedRef}>
              <NvFlex>{selectedRequestId && <APIRequestDetail key={selectedRequestId} />}</NvFlex>
            </NvScrollBox>
          </SplitPanelItem>
        </NvSplitPane>
      </NvConditionalRender>
      <NvConditionalRender when={() => !showList}>
        <NvBox padding={3}>
          <EmptyState
            onClick={() => onOpenModal({ mode: 'create' })}
            actionText="Add request"
            subText="Add a new request to perform a task or receive information from your tool."
          />
        </NvBox>
      </NvConditionalRender>

      <RequestModal {...requestModalParams} />
    </>
  );
};

const OriginalRequestRef = forwardRef<HTMLElement>((_, ref) => <OriginalRequests forwardedRef={ref} />);

export const Requests = memo(OriginalRequestRef);
