import { Integration, SearchConnection, WorkflowWithState } from '@novaera/actioner-service';

export type WorkflowConnectionSectionProps = {
  label?: React.ReactNode;
  integration?: Integration;
  onChange: (connection?: { connectionId?: string }) => void;
  value?: string;
  workflow: WorkflowWithState;
};

export type ConnectionOption =
  | {
      label: string;
      type: 'connection';
      value: {
        connectionId: string;
        user: SearchConnection['user'];
      };
    }
  | {
      label: string;
      type: 'triggered-user-connection';
      value: {
        connectionId: undefined;
      };
    };

// typescript type guard function that checks if the given value is a ConnectionOption
export function isConnectionOption(value: unknown): value is ConnectionOption {
  if (!value) return false;

  return (
    (value as ConnectionOption).type !== undefined &&
    ((value as ConnectionOption).type === 'connection' ||
      (value as ConnectionOption).type === 'triggered-user-connection')
  );
}
