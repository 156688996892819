import { NvEmoji } from '../emoji';
import { NvFlex } from '../flex';
import { NvTypography } from '../typography';
import { CompletionStateProps } from './types';

export const NvCompletionState: React.FC<React.PropsWithChildren<CompletionStateProps>> = ({
  primaryText,
  secondaryText,
  icon = <NvEmoji symbol="🎉" label="completion" />,
  width = '500px',
  alignItems = 'center',
  textAlign = 'center',
}) => {
  return (
    <NvFlex gap="24px" alignItems={alignItems} width={width}>
      {icon}
      <NvFlex gap="8px" alignItems={alignItems}>
        <NvTypography variant="h2">{primaryText}</NvTypography>
        {secondaryText && (
          <NvTypography variant="body1" align={textAlign}>
            {secondaryText}
          </NvTypography>
        )}
      </NvFlex>
    </NvFlex>
  );
};
