import {
  ProductId,
  WorkspaceStatus,
  useExtendTrialPeriod,
  useGetWorkspaceSubscriptionPlan,
  useGetWorkspaceWithSubdomain,
  useUpdateWorkspaceStatus,
} from '@novaera/actioner-service';
import { SimpleSearchInput } from '@novaera/actioner-ui';
import {
  BasicTableCell,
  BasicTableRow,
  NvBasicTable,
  NvButton,
  NvDialogModal,
  NvEmptyState,
  NvFlex,
  NvSkeleton,
  NvTypography,
  useConfirmDialog,
  useNvDialogModalState,
  useToast,
} from '@novaera/core';
import { assert } from '@novaera/utils';
import { useState } from 'react';
import { WorkspaceSubscriptionExtendModal } from './extend-modal';

export const AdminWorkspaces = ({ productId }: { productId: ProductId }) => {
  const [subdomain, setSubdomain] = useState<string | undefined>();
  const { data: workspace, isInitialLoading: isGettingWorkspace } = useGetWorkspaceWithSubdomain({
    productId,
    subdomain,
  });

  const { data: workspaceSubscription, isInitialLoading: isWorkspaceSubscriptionPlanLoading } =
    useGetWorkspaceSubscriptionPlan({
      workspaceId: workspace?.id,
    });

  const { mutate: updateWorkspaceStatus, isLoading } = useUpdateWorkspaceStatus();

  const { mutate: extendTrialPeriod } = useExtendTrialPeriod();

  const { openConfirm } = useConfirmDialog();

  const { isOpened, onModalCloseClicked, onModalOpenClicked } = useNvDialogModalState();

  const { addToast } = useToast();

  const handleFindWorkspace = (keyword?: string) => {
    setSubdomain(keyword);
  };

  const handelChangeStatus = (id: string) => {
    openConfirm({
      message: 'Are you sure?',
      onConfirm: () => {
        updateWorkspaceStatus({
          productId,
          id,
          status: workspace?.status === WorkspaceStatus.ACTIVE ? WorkspaceStatus.DEACTIVATED : WorkspaceStatus.ACTIVE,
        });
      },
      confirmButtonLabel: workspace?.status === WorkspaceStatus.ACTIVE ? 'Suspend' : 'Activate',
    });
  };

  return (
    <>
      <NvFlex gap="16px" maxWidth="780px">
        <NvFlex>
          <SimpleSearchInput
            helperText="Search workspace with subdomain"
            initialKeyword=""
            onKeywordChanged={handleFindWorkspace}
          />
        </NvFlex>
        {isGettingWorkspace ? (
          <NvBasicTable
            tableBody={
              <BasicTableRow>
                <BasicTableCell>
                  <NvSkeleton />
                </BasicTableCell>
              </BasicTableRow>
            }
          />
        ) : workspace ? (
          <NvBasicTable
            tableHeader={
              <BasicTableRow>
                <BasicTableCell width="250px">Subdomain</BasicTableCell>
                <BasicTableCell width="250px">Name</BasicTableCell>
                <BasicTableCell width="150px">Status</BasicTableCell>
                <BasicTableCell width={'200px'}>Change Status</BasicTableCell>
                <BasicTableCell width={'200px'}>Subscription Plan</BasicTableCell>
                <BasicTableCell></BasicTableCell>
              </BasicTableRow>
            }
            tableBody={
              <BasicTableRow>
                <BasicTableCell>{workspace.subdomain}</BasicTableCell>
                <BasicTableCell>{workspace.displayName}</BasicTableCell>
                <BasicTableCell>{workspace.status}</BasicTableCell>
                <BasicTableCell>
                  <NvButton
                    onClick={() => handelChangeStatus(workspace.id)}
                    size="small"
                    disabled={isLoading}
                    loading={isLoading}
                    color={workspace.status === WorkspaceStatus.ACTIVE ? 'error' : 'success'}
                  >
                    {workspace.status === WorkspaceStatus.ACTIVE ? 'Suspend' : 'Activate'}
                  </NvButton>
                </BasicTableCell>
                {!isWorkspaceSubscriptionPlanLoading && (
                  <>
                    <BasicTableCell>
                      <NvTypography>{workspaceSubscription?.planType}</NvTypography>
                    </BasicTableCell>
                    {workspaceSubscription?.planType === 'trial' && (
                      <BasicTableCell>
                        <NvButton onClick={onModalOpenClicked} size="small">
                          Extend trial
                        </NvButton>
                      </BasicTableCell>
                    )}
                  </>
                )}
                {isWorkspaceSubscriptionPlanLoading && (
                  <BasicTableCell>
                    <NvSkeleton />
                  </BasicTableCell>
                )}
              </BasicTableRow>
            }
          />
        ) : (
          <NvEmptyState primaryText="There is no workspace." />
        )}
      </NvFlex>
      <NvDialogModal<{
        amountInMillis: number;
      }>
        primaryButtonText="Extend"
        modalIcon="edit"
        fullWidth
        maxWidth="sm"
        open={isOpened}
        Header={`Extend ${workspace?.displayName} plan`}
        onCloseButtonClicked={onModalCloseClicked}
        Body={
          !workspace || !workspaceSubscription ? (
            <NvSkeleton />
          ) : (
            <WorkspaceSubscriptionExtendModal workspace={workspace} workspaceSubscription={workspaceSubscription} />
          )
        }
        onFormSubmit={({ amountInMillis }) => {
          assert(!!workspace?.id, new Error('Workspace id is not found'));

          extendTrialPeriod({
            amountInMillis,
            workspaceId: workspace?.id,
          });

          addToast("Workspace's trial period has been extended.", { variant: 'success' });
        }}
      />
    </>
  );
};
