import { APP_PERMISSION, Permissions, USER_PERMISSION } from '@novaera/actioner-service';

export const usePermission = ({
  permissions,
  permission,
  Fallback,
  children,
}: {
  permissions: Permissions;
  permission: APP_PERMISSION | USER_PERMISSION;
  children?: React.ReactNode;
  Fallback?: React.ReactElement;
}) => {
  const permissionGranted = permissions[permission];

  if (!permissionGranted) return Fallback ?? null;

  return children;
};
