import { NvDivider, NvFlex, NvMenuWithItems } from '@novaera/core';
import { Card } from '../card/styled';
import { CardWitHeaderProps } from './types';

export const CardWithHeader = ({
  BodyComponent,
  LeftComponent,
  TitleComponent,
  menuItems,
  className,
}: CardWitHeaderProps) => {
  return (
    <Card className={className}>
      <NvFlex flexDirection={'row'} gap="8px">
        {LeftComponent && <NvFlex justifyContent={'flex-start'}>{LeftComponent}</NvFlex>}
        <NvFlex width={'100%'} gap="8px">
          <NvFlex flexDirection={'row'}>
            <NvFlex flex={'1 1 auto'} minWidth="0">
              {TitleComponent}
            </NvFlex>
            {menuItems && <NvMenuWithItems menuItems={menuItems} />}
          </NvFlex>
          <NvDivider />
          <NvFlex>{BodyComponent}</NvFlex>
        </NvFlex>
      </NvFlex>
    </Card>
  );
};
