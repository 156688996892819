import { ReactNode } from 'react';

export enum EmptyStates {
  INTEGRATION,
  USER_APP,
  WORKFLOWS,
  ACTIONS,
  EVENTS,
  APP_DESCRIPTION,
  DATABASE,
  RECURRING_JOB,
  SCHEDULED_JOB,
  CONNECTION,
  PROFILE_CONNECTION,
  APP_DIRECTORY,
  SLACK_CONNECTION,
  CONFIG,
  DOCUMENTS,
  GROUPS,
  GROUPS_DETAIL,
  ACTIONER_EVENT,
  AI_ASSISTANT,
  CATALOG_RIGHT_PANEL,
}

export type EmptyStateProps = {
  variant: EmptyStates;
  CustomButton?: ReactNode;
  hasEmptyStateBackground?: boolean;
};
