import { Connection, useUpdateConnectionMappingService } from '@novaera/actioner-service';
import {
  Initial,
  NvBox,
  NvButton,
  NvConditionalRender,
  NvCustomCheckIcon,
  NvDivider,
  NvErrorIcon,
  NvFlex,
  NvImage,
  NvSkeleton,
  NvTypography,
  useNvDialogModalState,
} from '@novaera/core';
import { useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import classNames from 'classnames';
import { ConnectModal } from '../../../../../../integrations/components/connect-modal';
import { OAuth2PromiseResultType } from '../../../../../../integrations/components/connect-modal/oauth2-connection/controllers/use-oauth2-connection/types';
import { isOAuth2PromiseResult } from '../../../../../../integrations/components/connect-modal/types';
import { ConnectSlackButton } from '../../../../../../integrations/components/missing-connection/missing-connection-body/item/connect-slack-button';
import { MultipleConnectionSelect } from '../../../../description/app-connections/item/multiple-connection-select';
import { useConnectionState } from '../../../../description/app-connections/item/use-connection-state';
import { SlackWorkspace } from './slack-workspace';
import { ConnectionStepItemCard } from './styled';
import { ConnectionStepItemProps } from './types';

export const ConnectionStepItem: React.FC<React.PropsWithChildren<ConnectionStepItemProps>> = ({ connection }) => {
  const theme = useTheme();
  const { userAppId } = useParams();
  const { isOpened, onModalCloseClicked, onModalOpenClicked } = useNvDialogModalState();
  const { connectionState } = useConnectionState({ connectionMapping: connection });

  const { mutate: updateUserAppSetupConnection, isLoading: isUpdateUserAppSetupConnectionLoading } =
    useUpdateConnectionMappingService();

  const handleFormSubmitFinished = (connection: void | Connection | OAuth2PromiseResultType) => {
    if (!connection) return;

    if (isOAuth2PromiseResult(connection)) {
      if (connection.type === 'success' && connection.schemaId && connection.name) {
        updateUserAppSetupConnection({
          appId: userAppId,
          schemaId: connection.schemaId,
          payload: {
            connectionId: connection.connectionId,
          },
          connectionName: connection.name,
        });
      }
    } else {
      updateUserAppSetupConnection({
        appId: userAppId,
        schemaId: connection.schemaId,
        payload: {
          connectionId: connection.id,
        },
        connectionName: connection.name,
      });
    }
  };

  const hasConnection = Boolean(connection.connected && connection.connectionId);

  return (
    <>
      <ConnectionStepItemCard connected={hasConnection} className={classNames({ ambiguous: connection.ambiguous })}>
        {connection.ambiguous && (
          <NvErrorIcon
            htmlColor={theme.palette.nv_warning[40]}
            sx={{
              position: 'absolute',
              width: '16px',
              height: '16px',
              top: '50%',
              left: '-10px',
              transform: 'translateY(-50%)',
            }}
          />
        )}
        <NvFlex direction="row" alignItems="center" gap="10px">
          <NvImage
            imageShape="square"
            src={connection.logoUrl}
            FallBack={<Initial size="small" value={connection.schemaName} color={theme.palette.nv_neutral[500]} />}
            size="small"
          />
          <NvTypography noWrap variant="h4">
            {connection.schemaName}
          </NvTypography>
        </NvFlex>
        {connectionState.type !== 'no-connection' && (
          <NvDivider orientation="vertical" sx={{ height: '16px', borderColor: theme.palette.nv_neutral_alpha[40] }} />
        )}
        {connectionState.type === 'multiple' && !connection.slackConnection ? (
          <NvBox flex={'0 1 auto'} minWidth={'0'} marginRight="auto">
            <MultipleConnectionSelect connectionMapping={connection} />
          </NvBox>
        ) : connectionState.type === 'only-one' ? (
          <NvTypography variant="h6" noWrap>
            {connectionState.connectedConnection.name}
          </NvTypography>
        ) : null}

        <NvConditionalRender when={Boolean(isUpdateUserAppSetupConnectionLoading)}>
          <NvSkeleton width="120px" height="24px" variant="rectangular" />
        </NvConditionalRender>

        <NvConditionalRender
          when={
            !isUpdateUserAppSetupConnectionLoading && connection.connections.length === 0 && !connection.slackConnection
          }
        >
          <NvButton
            size="small"
            color={'primary'}
            onClick={() => {
              onModalOpenClicked();
            }}
            sx={{ marginLeft: 'auto' }}
          >
            Connect
          </NvButton>
        </NvConditionalRender>

        <NvConditionalRender when={connectionState.connected && !connection.slackConnection}>
          <NvButton
            size="small"
            color={'secondary'}
            onClick={() => {
              onModalOpenClicked();
            }}
            sx={{ marginLeft: 'auto' }}
          >
            Reconnect
          </NvButton>
        </NvConditionalRender>

        <NvConditionalRender
          when={!isUpdateUserAppSetupConnectionLoading && connection.slackConnection && !connection.connected}
        >
          <ConnectSlackButton
            ButtonBase={({ onClick }) => (
              <NvButton size="small" onClick={onClick} sx={{ marginLeft: 'auto' }}>
                Connect
              </NvButton>
            )}
          />
        </NvConditionalRender>
        <NvConditionalRender
          when={!isUpdateUserAppSetupConnectionLoading && connection.slackConnection && connection.connected}
        >
          <SlackWorkspace />
        </NvConditionalRender>
        {connection.connected && (
          <NvFlex direction="row" alignItems="center" gap="4px">
            <NvCustomCheckIcon htmlColor={theme.palette.nv_success[60]} />
            <NvTypography variant="h6" textColor="secondary">
              Connected
            </NvTypography>
          </NvFlex>
        )}
      </ConnectionStepItemCard>
      {connection.schemaId && !connection.slackConnection && (
        <ConnectModal
          onModalCloseClicked={onModalCloseClicked}
          isOpened={isOpened}
          integrationId={connection.integrationId}
          mode="Connect"
          schemaId={connection.schemaId}
          {...(connection.connected
            ? {
                connected: true,
                connectionName: connection.connections.find((c) => c.id === connection.connectionId)?.name,
                connectionId: connection.connectionId,
              }
            : {})}
          onFormSubmitFinished={handleFormSubmitFinished}
          modalTitle={connection.connected ? `Reconnect ${connection.schemaName}` : 'Connect'}
        />
      )}
    </>
  );
};
