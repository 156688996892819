import { NvAddBoxIcon, NvDivider, NvFlex, NvPopoverWithAnchor } from '@novaera/core';
import { AddNewAppInitiatorButton } from '../styled';
import { AddNewAppDropdownMenuItem } from './add-new-app-dropdown-menu-item';
import { AddNewAppButtonProps } from './types';

export const AddNewAppButton: React.FC<React.PropsWithChildren<AddNewAppButtonProps>> = ({
  handleCreate,
  handleInstall,
  popoverProps,
  initiatorButtonProps,
}) => {
  return (
    <NvPopoverWithAnchor
      id="empty-state-add-new-app-buttons"
      initiator={
        <AddNewAppInitiatorButton color="ghost" startIcon={<NvAddBoxIcon />} size="small" {...initiatorButtonProps}>
          Add new app
        </AddNewAppInitiatorButton>
      }
      popoverProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        ...popoverProps,
      }}
    >
      {({ close }) => (
        <NvFlex width="240px" padding="6px">
          <AddNewAppDropdownMenuItem
            onClick={() => {
              handleInstall();
              close();
            }}
            icon={<NvAddBoxIcon />}
            label="Install from our app directory"
            color="primary"
          />

          <NvDivider sx={{ margin: '8px 0' }} />
          <AddNewAppDropdownMenuItem
            onClick={() => {
              handleCreate();
              close();
            }}
            label="Create an app from scratch"
            color="secondary"
          />
        </NvFlex>
      )}
    </NvPopoverWithAnchor>
  );
};
