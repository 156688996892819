import { useDeleteIntegrationAction } from '@novaera/actioner-service';
import { MenuItems, NvDeleteOutlineIcon, useConfirmDialog } from '@novaera/core';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { INTEGRATION_ACTIONS } from '../../../constants';

export const useActionMenus = () => {
  const navigate = useNavigate();
  const { mutate: deleteIntegrationAction, isLoading } = useDeleteIntegrationAction();

  const { openConfirm } = useConfirmDialog();

  const getActionMenus = useCallback(
    (integrationId: string, actionId: string, version: number) => {
      const menuItems: MenuItems[] = [
        {
          name: 'Delete',
          isLoading: isLoading,
          onClick: () => {
            openConfirm({
              message:
                'You are about to delete this action. Deleting the action will affect all workflows depending on it. Are you sure? ',
              onConfirm: () => {
                deleteIntegrationAction(
                  { integrationId, actionId, version },
                  {
                    onSuccess: () => {
                      navigate(INTEGRATION_ACTIONS(integrationId));
                    },
                  }
                );
              },
            });
          },
          icon: <NvDeleteOutlineIcon color="error" fontSize="small" />,
        },
      ];
      return menuItems;
    },
    [isLoading, openConfirm, deleteIntegrationAction, navigate]
  );
  return {
    getActionMenus,
  };
};
