import {
  ChartOutputNodeItem,
  JobNodeExtraData,
  JobRecurrenceEndType,
  KeyValueTableOutputNodeItem,
  MarkdownOutputNodeItem,
  TableOutputNodeItem,
} from '@novaera/actioner-service';
import {
  NodeListItem,
  NvAccessTimeRounded,
  NvAltRoute,
  NvArticleIcon,
  NvBarChartIcon,
  NvBorderAllIcon,
  NvCustomAssistantIcon,
  NvCustomBranchesIcon,
  NvCustomConditionIcon,
  NvCustomFolderAddIcon,
  NvCustomFolderDeleteIcon,
  NvCustomFolderListIcon,
  NvCustomFolderShareIcon,
  NvCustomFolderTransferIcon,
  NvCustomGetConversationIcon,
  NvCustomQA,
  NvCustomWorkflowResolver,
  NvEventBusy,
  NvEventRepeatIcon,
  NvFolderOpenRounded,
  NvFunctionIcon,
  NvHourglassBottomIcon,
  NvKeyValueTableIcon,
  NvMailOutlineIcon,
  NvNotesIcon,
  NvPieChartOutlineIcon,
  NvReceiptLongIcon,
  NvRotateRightIcon,
  NvSensorsIcon,
  NvSmartToyIcon,
  NvTimerIcon,
  NvWorkflowIcon,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { useMemo } from 'react';
import { DEFAULT_RECURRENCE_CONFIGURATION } from '../../../properties-panel/property-panels/job-property-panel-drawer/form-wrapper/constants';
import { OUTPUT_DESCRIPTION_MAP } from '../../../utils/output-description-map';

export const useGetStaticTriggerItems = () => {
  const theme = useTheme();

  const staticTriggerItems: NodeListItem[] = useMemo(() => {
    const recurringJobItem: NodeListItem<JobNodeExtraData> = {
      name: 'Save recurring job',
      description: 'Create or update a recurring job',
      icon: <NvEventRepeatIcon htmlColor={theme.palette.nv_node.job} />,
      type: 'job',
      extraData: {
        operation: {
          type: 'saveRecurring',
          recurrenceConfiguration: DEFAULT_RECURRENCE_CONFIGURATION,
          termination: { type: JobRecurrenceEndType.NEVER },
        },
      },
    };
    const scheduledJobItem: NodeListItem<JobNodeExtraData> = {
      name: 'Save scheduled job',
      description: 'Create or update a scheduled job',
      icon: <NvAccessTimeRounded htmlColor={theme.palette.nv_node.job} />,
      type: 'job',
      extraData: {
        operation: { type: 'saveScheduled' },
      },
    };
    const deleteJobItem: NodeListItem<JobNodeExtraData> = {
      name: 'Delete job',
      description: (
        <>
          Delete a <b>recurring</b> or <b>scheduled</b> job
        </>
      ),
      icon: <NvEventBusy htmlColor={theme.palette.nv_node.job} />,
      type: 'job',
      extraData: {
        operation: { type: 'delete' },
      },
    };
    const barChartItem: NodeListItem<ChartOutputNodeItem> = {
      name: 'Bar chart',
      description: OUTPUT_DESCRIPTION_MAP['chart']['bar'],
      icon: <NvBarChartIcon htmlColor={theme.palette.nv_node.output} />,
      type: 'output',
      extraData: {
        type: 'output',
        outputComponent: { type: 'chart', chart: { type: 'bar' } },
      },
    };

    const pieChartItem: NodeListItem<ChartOutputNodeItem> = {
      name: 'Pie chart',
      description: OUTPUT_DESCRIPTION_MAP['chart']['pie'],
      icon: <NvPieChartOutlineIcon htmlColor={theme.palette.nv_node.output} />,
      type: 'output',
      extraData: {
        type: 'output',
        outputComponent: { type: 'chart', chart: { type: 'pie' } },
      },
    };

    const markdownItem: NodeListItem<MarkdownOutputNodeItem> = {
      name: 'Markdown',
      description: OUTPUT_DESCRIPTION_MAP['markdown'],
      icon: <NvNotesIcon htmlColor={theme.palette.nv_node.output} />,
      type: 'output',
      extraData: {
        type: 'output',
        outputComponent: { type: 'markdown' },
      },
    };

    const tableItem: NodeListItem<TableOutputNodeItem> = {
      name: 'Table',
      description: OUTPUT_DESCRIPTION_MAP['table'],
      icon: <NvBorderAllIcon htmlColor={theme.palette.nv_node.output} />,
      type: 'output',
      extraData: {
        type: 'output',
        outputComponent: { type: 'table' },
      },
    };

    const keyValueTableItem: NodeListItem<KeyValueTableOutputNodeItem> = {
      name: 'Key value table',
      description: OUTPUT_DESCRIPTION_MAP['key-value-table'],
      icon: <NvKeyValueTableIcon htmlColor={theme.palette.nv_node.output} />,
      type: 'output',
      extraData: {
        type: 'output',
        outputComponent: { type: 'key-value-table' },
      },
    };
    return [
      {
        name: 'Actioner event',
        type: 'actionerEventPublisher',
        description: 'Publish an Actioner event',
        icon: <NvSensorsIcon htmlColor={theme.palette.nv_node.actionerEvent} />,
      },
      {
        name: 'Operators',
        description: 'Add workflow operator. ',
        icon: <NvAltRoute htmlColor={theme.palette.nv_node['operator']} />,
        type: 'operators',
        childNodes: [
          {
            name: 'Loop',
            description: 'Define repetitive processes with specified or infinite iterations.',
            icon: <NvRotateRightIcon htmlColor={theme.palette.nv_node['operator']} />,
            type: 'loop',
          },
          {
            name: 'Condition',
            description: 'Create paths for true or false evaluation of a condition.',
            icon: <NvCustomConditionIcon htmlColor={theme.palette.nv_node['operator']} />,
            type: 'workflowCondition',
          },
          {
            name: 'Branch',
            description: 'Split the workflow into different paths.',
            icon: <NvCustomBranchesIcon htmlColor={theme.palette.nv_node['operator']} />,
            type: 'branchJunction',
          },
          {
            name: 'Delay',
            description: 'Add a time delay to the workflow.',
            icon: <NvHourglassBottomIcon htmlColor={theme.palette.nv_node['operator']} />,
            type: 'delay',
          },
        ],
      },
      {
        name: 'Workflows',
        type: 'workflowDispatcher',
        description: (
          <>
            Trigger a <b>manual</b> or <b>automated</b> workflow.
          </>
        ),
        icon: <NvWorkflowIcon htmlColor={theme.palette.nv_node.workflow} />,
      },
      // {
      //   name: 'Generate Link',
      //   type: 'linkGenerator',
      //   description: (
      //     <>
      //       Generate a link for the <b>manual workflow</b> run in a web browser.
      //     </>
      //   ),
      //   icon: <NvLinkIcon htmlColor={theme.palette.nv_node.linkGenerator} />,
      // },
      {
        name: 'AI',
        description: (
          <>
            Use <b>AI power</b> in your workflows.
          </>
        ),
        icon: <NvSmartToyIcon htmlColor={theme.palette.nv_node.ai} />,
        type: 'ai',
        childNodes: [
          {
            name: 'Workflow resolver',
            description: 'Returns a relevant workflow from a given content.',
            icon: <NvCustomWorkflowResolver htmlColor={theme.palette.nv_node.ai} />,
            type: 'workflowResolver',
          },
          {
            name: 'Q&A',
            description: 'Returns a response based on the selected documentations.',
            icon: <NvCustomQA htmlColor={theme.palette.nv_node.ai} />,
            type: 'aiKnowledge',
          },
          {
            name: 'AI assistant',
            description: 'Assist users by running various workflows and answering questions using AI.',
            icon: <NvCustomAssistantIcon htmlColor={theme.palette.nv_node.ai} />,
            type: 'assistant',
          },
          {
            name: 'Get conversation',
            description: 'Returns a relevant conversation from a given identifier.',
            icon: <NvCustomGetConversationIcon htmlColor={theme.palette.nv_node.ai} />,
            type: 'getConversation',
          },
        ],
      },
      {
        name: 'Send email',
        type: 'sendEmail',
        description: 'Send an email',
        icon: <NvMailOutlineIcon htmlColor={theme.palette.nv_node.sendEmail} />,
      },
      {
        name: 'Functions',
        type: 'function',
        description: 'Use Javascript in your workflow.',
        icon: <NvFunctionIcon htmlColor={theme.palette.nv_node.function} />,
      },
      {
        name: 'Files',
        description: <>Manage files with operators like put or transfer.</>,
        icon: <NvFolderOpenRounded htmlColor={theme.palette.nv_node.file} />,
        type: 'file',
        childNodes: [
          {
            name: 'Put file',
            description: 'Create or update a file',
            icon: <NvCustomFolderAddIcon htmlColor={theme.palette.nv_node.file} />,
            type: 'file',
            extraData: {
              nodeOperation: {
                type: 'put',
              },
            },
          },
          {
            name: 'Transfer file',
            description: 'Transfer file to another source',
            icon: <NvCustomFolderTransferIcon htmlColor={theme.palette.nv_node.file} />,
            type: 'file',
            extraData: {
              nodeOperation: {
                type: 'transfer',
              },
            },
          },
          {
            name: 'List files',
            description: 'Returns the list of Actioner files',
            icon: <NvCustomFolderListIcon htmlColor={theme.palette.nv_node.file} />,
            type: 'file',
            extraData: {
              nodeOperation: {
                type: 'list',
              },
            },
          },
          {
            name: 'Update file privacy',
            description: 'Change file sharing options to private or public',
            icon: <NvCustomFolderShareIcon htmlColor={theme.palette.nv_node.file} />,
            type: 'file',
            extraData: {
              nodeOperation: {
                type: 'change-accessibility',
              },
            },
          },
          {
            name: 'Delete file',
            description: 'Delete Actioner file permanently',
            icon: <NvCustomFolderDeleteIcon htmlColor={theme.palette.nv_node.file} />,
            type: 'file',
            extraData: {
              nodeOperation: {
                type: 'delete',
              },
            },
          },
        ],
      },
      {
        name: 'Jobs',
        type: 'job',
        description: (
          <>
            Use <b>recurring</b> or <b>scheduled</b> jobs in your workflow.
          </>
        ),
        icon: <NvTimerIcon htmlColor={theme.palette.nv_node.job} />,
        childNodes: [recurringJobItem, scheduledJobItem, deleteJobItem],
      },
      {
        name: 'Outputs',
        type: 'outputs',
        description: 'Design an output for your workflow.',
        icon: <NvArticleIcon htmlColor={theme.palette.nv_node.output} />,
        childNodes: [markdownItem, tableItem, keyValueTableItem, barChartItem, pieChartItem],
      },
      {
        name: 'Response',
        type: 'response',
        description: 'Design a JSON response for your workflow.',
        icon: <NvReceiptLongIcon htmlColor={theme.palette.nv_node['output']} />,
      },
    ];
  }, [theme.palette.nv_node]);

  return { staticTriggerItems };
};
