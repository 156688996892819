import { NvButton, NvFlex, NvProductLogoWithNameIcon, NvTypography } from '@novaera/core';
import createWorkspaceIcon from '../../../images/create-workspace-icon.png';
import { useCreateWorkspace } from './controllers';
import { CardWrapper, ItemWrapper } from './styled';

export const CreateWorkspace: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { onCreateWorkspaceClicked, isLoading } = useCreateWorkspace();
  return (
    <CardWrapper id="card-wrapper">
      <NvFlex marginBottom={'56px'}>
        <NvProductLogoWithNameIcon />
      </NvFlex>
      <ItemWrapper>
        <NvFlex flexShrink={0}>
          <img alt="empty input" srcSet={createWorkspaceIcon} src={createWorkspaceIcon} />
        </NvFlex>

        <NvFlex direction="column" rowGap="16px">
          <NvFlex>
            <NvTypography variant="h1">Create a new Actioner workspace</NvTypography>
          </NvFlex>
          <NvFlex>
            <NvTypography variant="body1">
              Actioner is a no-code Slack app builder that lets you connect all your work apps through actions.
            </NvTypography>
          </NvFlex>
          <NvFlex>
            <NvButton color="primary" loading={isLoading} size="large" isFitContent onClick={onCreateWorkspaceClicked}>
              Create workspace
            </NvButton>
          </NvFlex>
        </NvFlex>
      </ItemWrapper>
    </CardWrapper>
  );
};
