import { useGetSlackApp, useGetUserApp } from '@novaera/actioner-service';
import { Navigate, Route, Routes, useParams } from '@novaera/route';
import { FC } from 'react';
import { ReactFlowProvider } from 'reactflow';

import { UserAppPageLayout } from '../../components/user-app-page-layout';
import { AIAssistant } from './ai-assistant';
import { Config } from './config';
import { ConfigDetail } from './config/config-detail';
import { USER_APP_LINKS, USER_APP_TABS } from './constants';
import { TableList } from './database';
import { Records } from './database/records';
import { DataModelDetailForm } from './database/tables/table-detail/data-model-detail-form';
import { Description } from './description';
import { DocumentDetail, Documents } from './documents';
import { Events } from './events';
import { EventDetail } from './events/detail';
import { InstallUserAppFlow } from './install-user-app-flow';
import { Jobs } from './jobs';
import { UserAppPermissions } from './permissions';
import { SlackApp } from './slack-app';
import { WorkflowDetail } from './workflow-detail';
import { Workflows } from './workflows';

export const UserAppDetail: FC<React.PropsWithChildren<unknown>> = () => {
  const { userAppId } = useParams();
  const { data: userApp, isInitialLoading } = useGetUserApp(userAppId);
  const { data: slackApp } = useGetSlackApp({ appId: userAppId });

  return (
    <Routes>
      <Route
        element={
          <UserAppPageLayout
            userAppId={userAppId}
            userApp={userApp}
            slackApp={slackApp}
            isInitialLoading={isInitialLoading}
          />
        }
      >
        <Route path={'setup'} element={<InstallUserAppFlow appId={userAppId} />} />
        <Route
          path={USER_APP_TABS.DESCRIPTION}
          element={<Description userApp={userApp} isLoading={isInitialLoading} key={userAppId} />}
        />

        <Route path={USER_APP_TABS.CONFIG}>
          <Route index element={<Config />} />
          <Route path=":configId" element={<ConfigDetail />} />
        </Route>

        <Route path={USER_APP_TABS.WORKFLOWS}>
          <Route index element={<Workflows />} />
          <Route
            path={':workflowId/*'}
            element={
              <ReactFlowProvider>
                <WorkflowDetail />
              </ReactFlowProvider>
            }
          />
        </Route>

        <Route path={USER_APP_TABS.DATABASE}>
          <Route index element={<TableList />} />
          <Route path=":modelId" element={<DataModelDetailForm />} />
        </Route>

        <Route path={`${USER_APP_TABS.JOBS}`} element={<Navigate to="recurring" />} />
        <Route path={`${USER_APP_TABS.JOBS}/recurring`} element={<Jobs />} />
        <Route path={`${USER_APP_TABS.JOBS}/scheduled`} element={<Jobs />} />

        <Route path={USER_APP_TABS.PERMISSIONS} element={<UserAppPermissions />} />

        <Route path={USER_APP_TABS.SLACK_APP} element={<SlackApp appId={userAppId} />} />

        <Route path={USER_APP_TABS.DOCUMENTS}>
          <Route index element={<Documents />} />
          <Route path=":docId" element={<DocumentDetail />} />
        </Route>

        <Route path={USER_APP_LINKS.RECORDS}>
          <Route index element={<Records />} />
          <Route path=":modelId" element={<Records />} />
        </Route>

        <Route path={USER_APP_TABS.EVENTS}>
          <Route index element={<Events />} />
          <Route path=":eventId" element={<EventDetail />} />
        </Route>

        <Route path={USER_APP_TABS.AI_ASSISTANT} element={<AIAssistant />} />

        <Route path="*" element={<Navigate to={'description'} replace={true} />} />
      </Route>
    </Routes>
  );
};
