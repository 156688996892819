import { assert } from '@novaera/utils';
import {
  ActionNodeSummary,
  ActionerEventPublisherNodeSummary,
  BranchJunctionNodeSummary,
  ChartOutputComponentSummary,
  DefaultNodeSummary,
  FileNodeSummary,
  JobNodeSummary,
  KeyValueTableOutputComponentSummary,
  LinkGeneratorNodeSummary,
  MarkdownOutputComponentSummary,
  NodeUnion,
  NodeUnionTypeEnumLike,
  OutputNodeSummary,
  ResponseNodeSummary,
  TableOutputComponentSummary,
  WorkflowDispatcherNodeSummary,
} from '../../../../types';

export const convertNodeToSummary = (node: NodeUnion) => {
  if (node.type === NodeUnionTypeEnumLike.action) {
    const retVal: ActionNodeSummary = {
      actionId: node.actionId,
      alias: node.alias,
      integrationId: node.integrationId,
      name: node.name,
      type: node.type,
      version: node.version,
      connectionId: node.connectionId,
    };
    return retVal;
  } else if (
    node.type === NodeUnionTypeEnumLike.blank ||
    node.type === NodeUnionTypeEnumLike.function ||
    node.type === NodeUnionTypeEnumLike.loop ||
    node.type === NodeUnionTypeEnumLike.delay ||
    node.type === NodeUnionTypeEnumLike.workflowCondition ||
    node.type === NodeUnionTypeEnumLike.workflowResolver ||
    node.type === NodeUnionTypeEnumLike.sendEmail ||
    node.type === NodeUnionTypeEnumLike.aiKnowledge ||
    node.type === NodeUnionTypeEnumLike.assistant ||
    node.type === NodeUnionTypeEnumLike.getConversation
  ) {
    const retVal: DefaultNodeSummary = {
      alias: node.alias,
      name: node.name,
      type: node.type,
    };
    return retVal;
  } else if (node.type === 'actionerEventPublisher') {
    const retVal: ActionerEventPublisherNodeSummary = {
      alias: node.alias,
      name: node.name,
      type: node.type,
      eventId: node.eventId,
    };
    return retVal;
  } else if (node.type === 'response') {
    const retVal: ResponseNodeSummary = {
      alias: node.alias,
      name: node.name,
      type: node.type,
      exampleResponse: node.exampleResponse,
    };
    return retVal;
  } else if (node.type === 'workflowDispatcher') {
    const retVal: WorkflowDispatcherNodeSummary = {
      alias: node.alias,
      name: node.name,
      type: node.type,
      ...(node.dispatchConfiguration
        ? {
            workflowId:
              node.dispatchConfiguration.type === 'static'
                ? node.dispatchConfiguration.workflowId
                : node.dispatchConfiguration.workflowIdTemplate,
          }
        : {}),
    };
    return retVal;
  } else if (node.type === 'branchJunction') {
    const retVal: BranchJunctionNodeSummary = {
      alias: node.alias,
      name: node.name,
      type: node.type,
      branches: node.branches,
    };
    return retVal;
  } else if (node.type === 'file') {
    const retVal: FileNodeSummary = {
      alias: node.alias,
      name: node.name,
      type: node.type,
      nodeOperation: node.nodeOperation,
    };
    return retVal;
  } else if (node.type === 'output') {
    if (node.outputComponent.type === 'chart') {
      const outputComponentSummary: ChartOutputComponentSummary = {
        type: node.outputComponent.type,
        chart: node.outputComponent.chart,
      };
      const retVal: OutputNodeSummary = {
        alias: node.alias,
        name: node.name,
        type: node.type,
        outputComponent: outputComponentSummary,
      };
      return retVal;
    } else if (node.outputComponent.type === 'key-value-table') {
      const outputComponentSummary: KeyValueTableOutputComponentSummary = {
        type: node.outputComponent.type,
      };
      const retVal: OutputNodeSummary = {
        alias: node.alias,
        name: node.name,
        type: node.type,
        outputComponent: outputComponentSummary,
      };
      return retVal;
    } else if (node.outputComponent.type === 'markdown') {
      const outputComponentSummary: MarkdownOutputComponentSummary = {
        type: node.outputComponent.type,
      };
      const retVal: OutputNodeSummary = {
        alias: node.alias,
        name: node.name,
        type: node.type,
        outputComponent: outputComponentSummary,
      };
      return retVal;
    } else if (node.outputComponent.type === 'table') {
      const outputComponentSummary: TableOutputComponentSummary = {
        type: node.outputComponent.type,
      };
      const retVal: OutputNodeSummary = {
        alias: node.alias,
        name: node.name,
        type: node.type,
        outputComponent: outputComponentSummary,
      };
      return retVal;
    } else {
      assert(false, new Error('Unknown output node type'), 'ERROR');
    }
  } else if (node.type === 'job') {
    const retVal: JobNodeSummary = {
      alias: node.alias,
      name: node.name,
      type: node.type,
      operation: { type: node.operation.type },
    };
    return retVal;
  } else if (node.type === 'linkGenerator') {
    const retVal: LinkGeneratorNodeSummary = {
      alias: node.alias,
      name: node.name,
      type: node.type,
      ...(node.formConfiguration
        ? {
            workflowId:
              node.formConfiguration.type === 'static'
                ? node.formConfiguration.workflowId
                : node.formConfiguration.workflowIdTemplate,
          }
        : {}),
    };
    return retVal;
  } else {
    assert(false, new Error('Unknown node type'), 'ERROR');
  }
};
