import {
  AccessType,
  Group,
  GroupUser,
  UseUpdateGroupParams,
  useDeleteGroup,
  useGetGroup,
  useGetUsersInGroup,
  useUpdateGroup,
  useUpdateUsersInGroup,
} from '@novaera/actioner-service';
import {
  EditButton,
  InlineEditWrapper,
  NvArrowBackIcon,
  NvBasicTable,
  NvBox,
  NvButton,
  NvChip,
  NvDeleteOutlineIcon,
  NvDialogModal,
  NvEditIcon,
  NvFlex,
  NvMenuWithItems,
  NvMoreHorizIcon,
  NvPersonIcon,
  NvSearchEmptyState,
  NvSkeleton,
  NvTypography,
  useConfirmDialog,
} from '@novaera/core';
import { useNavigate, useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import { useMemo, useState } from 'react';
import { ROUTES } from '../../../../common/routes';
import { DetailLayoutBody } from '../../../../components/detail-layout/body';
import { EmptyState } from '../../../../components/empty-state';
import { EmptyStates } from '../../../../components/empty-state/types';
import { UserAndGroupImage } from '../../../../components/user-and-group-image';
import { SearchAndButtonBar } from '../../components/search-and-add-button';
import { USER_MANAGEMENT_TAB_PATHS } from '../../constants';
import { GroupsCreateUpdateModalBody } from '../groups-create-update-modal-body';
import { GroupTagAndDescriptionEmptyState } from './group-tag-and-description-empty-state';
import { GroupUserItem } from './group-user-item';
import { SelectUsersModalBody } from './select-user-modal-body';

export const GroupDetail = () => {
  const theme = useTheme();
  const { groupId } = useParams();
  const [isOpened, setIsOpened] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [isOpenedSelectUsersModal, setIsOpenedSelectUsersModal] = useState(false);
  const { data: group, isInitialLoading: isGroupLoading } = useGetGroup({ id: groupId });
  const { mutate: updateGroup, isLoading: isUpdatingGroup } = useUpdateGroup();
  const { data: groupUsers, isInitialLoading: isGroupUsersLoading } = useGetUsersInGroup({ id: groupId });
  const { mutate: addUsersToGroup } = useUpdateUsersInGroup();
  const navigate = useNavigate();
  const { openConfirm } = useConfirmDialog();
  const { mutate: deleteGroup } = useDeleteGroup();

  const usersInGroup = useMemo(
    () =>
      groupUsers?.users.filter(
        (user) =>
          user.email.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
          user.userName.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())
      ) ?? [],
    [groupUsers, keyword]
  );

  const handleRemoveGroup = (group: Group) => {
    openConfirm({
      message: 'Are you sure?',
      onConfirm: () => {
        deleteGroup(
          { id: group.id },
          {
            onSuccess: () => {
              navigate(`${ROUTES.Workspace}${USER_MANAGEMENT_TAB_PATHS.GROUP}`);
            },
          }
        );
      },
    });
  };

  const handleOnSubmit = (values: UseUpdateGroupParams) => {
    updateGroup(values, {
      onSettled: () => {
        setIsOpened(false);
      },
    });
  };

  const handleUserSelect = ({ users }: { users: GroupUser[] }) => {
    addUsersToGroup(
      { id: groupId, users: users.map((user) => user.userId) },
      {
        onSettled: () => {
          setIsOpenedSelectUsersModal(false);
        },
      }
    );
  };

  return (
    <DetailLayoutBody>
      <NvFlex marginTop={'24px'} flexDirection="column" gap="12px">
        <NvBox>
          <NvButton color="secondary" startIcon={<NvArrowBackIcon />} onClick={() => navigate(-1)} size="small">
            Back
          </NvButton>
        </NvBox>

        {group ? (
          <NvFlex gap="4px">
            <NvFlex direction="row" gap="12px" alignItems="flex-start" justifyContent="space-between">
              <InlineEditWrapper className="view-mode" sx={{ width: 'auto' }}>
                <NvFlex flexDirection="row" className="view-wrapper">
                  <NvFlex flex="1 1 auto" minWidth="0">
                    <NvFlex flexDirection="row" gap="12px" alignItems="center" flex="0 1 auto">
                      <UserAndGroupImage src={group.logoUrl} id={group.id} type={AccessType.GROUP} size="large" />
                      <NvTypography variant="h1">{group.name}</NvTypography>
                    </NvFlex>
                  </NvFlex>

                  <EditButton
                    className="edit-button"
                    color="ghost"
                    size="small"
                    onlyIcon
                    onClick={() => {
                      setIsOpened(true);
                    }}
                  >
                    <NvEditIcon />
                  </EditButton>
                </NvFlex>
              </InlineEditWrapper>

              <NvMenuWithItems
                triggerButton={{
                  content: <NvMoreHorizIcon />,
                  props: { onlyIcon: true, size: 'medium', color: 'secondary' },
                }}
                menuItems={[
                  {
                    name: 'Delete',
                    onClick: () => {
                      handleRemoveGroup(group);
                    },
                    icon: (
                      <NvDeleteOutlineIcon
                        htmlColor={theme.palette.nv_error[40]}
                        sx={{ width: '16px', height: '16px' }}
                      />
                    ),
                  },
                ]}
              />
            </NvFlex>
            <InlineEditWrapper className="view-mode">
              <NvFlex flexDirection="row" className="view-wrapper">
                <NvFlex flex="1 1 auto" minWidth="0" gap="8px">
                  {group.description || group.tags.length > 0 ? (
                    <>
                      {group.description ? (
                        <NvTypography variant="body1">{group.description}</NvTypography>
                      ) : (
                        <GroupTagAndDescriptionEmptyState message="No descriptions available for this group yet." />
                      )}

                      {group.tags.length > 0 ? (
                        <NvFlex direction="row" alignItems="center" gap="4px">
                          {group.tags.map((tag, index) => (
                            <NvChip key={`${tag}-${index}`} label={tag} />
                          ))}
                        </NvFlex>
                      ) : (
                        <GroupTagAndDescriptionEmptyState message="No tags available for this group yet." />
                      )}
                    </>
                  ) : (
                    <GroupTagAndDescriptionEmptyState message="No descriptions and tags available for this group yet." />
                  )}
                </NvFlex>

                <EditButton
                  className="edit-button"
                  color="ghost"
                  size="small"
                  onlyIcon
                  onClick={() => {
                    setIsOpened(true);
                  }}
                >
                  <NvEditIcon />
                </EditButton>
              </NvFlex>
            </InlineEditWrapper>
          </NvFlex>
        ) : isGroupLoading ? (
          <NvFlex gap="4px" width="100%">
            <NvFlex flexDirection="row" justifyContent="space-between">
              <NvFlex flexDirection="row" gap="12px" alignItems="center">
                <NvSkeleton variant="rectangular" width="40px" height="40px" />
                <NvSkeleton variant="rectangular" width="200px" height="32px" />
              </NvFlex>
              <NvSkeleton variant="rectangular" width="32px" height="32px" />
            </NvFlex>
            <NvSkeleton variant="rectangular" width="100%" height="48px" />
          </NvFlex>
        ) : null}

        {groupUsers?.users && groupUsers.users.length > 0 ? (
          <>
            <SearchAndButtonBar
              buttonLabel="Select users"
              onButtonClick={() => {
                setIsOpenedSelectUsersModal(true);
              }}
              AddButtonStartIcon={<NvPersonIcon />}
              setSearchUserValue={(keyword) => {
                setKeyword(keyword);
              }}
            />
            <NvBasicTable
              tableBody={
                usersInGroup.length > 0 ? (
                  usersInGroup.map((user) => (
                    <GroupUserItem key={`group_user_${user.userId}`} user={user} groupId={groupId} />
                  ))
                ) : (
                  <NvSearchEmptyState text="No user found. Try different words or clear search bar." />
                )
              }
            />
          </>
        ) : isGroupUsersLoading ? (
          <NvFlex gap="12px">
            <NvSkeleton variant="rectangular" width="100%" height="32px" />
            <NvFlex gap="4px">
              <NvSkeleton variant="rectangular" width="100%" height="48px" />
              <NvSkeleton variant="rectangular" width="100%" height="48px" />
              <NvSkeleton variant="rectangular" width="100%" height="48px" />
            </NvFlex>
          </NvFlex>
        ) : (
          <EmptyState
            variant={EmptyStates.GROUPS_DETAIL}
            CustomButton={
              <NvButton
                color="secondary"
                onClick={() => setIsOpenedSelectUsersModal(true)}
                startIcon={<NvPersonIcon />}
                size="small"
              >
                Select users
              </NvButton>
            }
          />
        )}
      </NvFlex>
      <NvDialogModal<UseUpdateGroupParams>
        formProps={{
          ...(group && {
            initialValues: {
              description: group.description,
              id: group.id,
              name: group.name,
              tags: group.tags,
            },
          }),
        }}
        onFormSubmit={handleOnSubmit}
        maxWidth="sm"
        fullWidth
        open={isOpened}
        onCloseButtonClicked={() => setIsOpened(false)}
        Header="Edit group"
        modalIcon="edit"
        primaryButtonText="Save"
        Body={<GroupsCreateUpdateModalBody logoUrl={group?.logoUrl} />}
        isLoading={isUpdatingGroup}
      />
      <NvDialogModal<{ users: GroupUser[] }>
        onCloseButtonClicked={() => setIsOpenedSelectUsersModal(false)}
        maxWidth="sm"
        fullWidth
        Header={`Add user to ${group?.name} group`}
        open={isOpenedSelectUsersModal}
        Body={<SelectUsersModalBody groupUsers={groupUsers?.users} />}
        onFormSubmit={handleUserSelect}
      />
    </DetailLayoutBody>
  );
};
