import { ConnectionSchema, VariableSchema } from '@novaera/actioner-service';
import {
  NvBox,
  NvDeleteOutlineIcon,
  NvDialogModal,
  NvEditIcon,
  NvTypography,
  useNvDialogModalState,
} from '@novaera/core';
import { DETAIL_FORM_NAME } from '../../../../../../../../components/detail-layout';

import { useForm, useFormState } from 'react-final-form';
import { BodyItem } from '../../../item';

import { useTheme } from '@novaera/theme-provider';
import { RequestVariableFieldEditParams } from '../types';
import { RequestVariableBody } from './variable-body';
import { RequestVariableFieldBody } from './variable-field-body';

export const RequestVariables = (props: {
  onModalOpened: (params: { variableSchema: VariableSchema; rowIndex: number }) => void;
}) => {
  const { values } = useFormState<ConnectionSchema>();

  const { isOpened, onModalCloseClicked, onModalOpenClicked, passedParameters } =
    useNvDialogModalState<RequestVariableFieldEditParams>();

  const { mutators } = useForm<ConnectionSchema>(DETAIL_FORM_NAME);

  const theme = useTheme();

  const getVariableFieldName = (variableSchemaIndex: number) => `variableSchemas.${variableSchemaIndex}.variables`;

  return (
    <>
      {values.variableSchemas.map((variableSchema, index) => {
        return (
          <BodyItem
            key={`variable_schema_${variableSchema.path}_${variableSchema.requestName}`}
            title={
              <NvBox sx={{ marginBottom: '16px' }}>
                <NvTypography variant="h3">{variableSchema.requestName}</NvTypography>
              </NvBox>
            }
            description={
              <RequestVariableBody
                onAddFieldClicked={() => {
                  onModalOpenClicked({
                    variableSchemaIndex: index,
                  });
                }}
                onRemoveFieldClicked={(rowIndex) => {
                  mutators.remove(getVariableFieldName(index), rowIndex);
                }}
                onUpdateFieldClicked={({ fieldVariable, rowIndex }) => {
                  onModalOpenClicked({
                    rowIndex,
                    fieldVariable: fieldVariable,
                    variableSchemaIndex: index,
                  });
                }}
                variableSchema={variableSchema}
              />
            }
            menuItems={[
              {
                name: 'Edit',
                icon: <NvEditIcon sx={{ width: '16px', height: '16px' }} />,
                onClick: () =>
                  props.onModalOpened({
                    variableSchema: variableSchema,
                    rowIndex: index,
                  }),
              },
              {
                name: 'divider',
                isDivider: true,
              },
              {
                name: 'Delete',
                icon: (
                  <NvDeleteOutlineIcon htmlColor={theme.palette.nv_error[40]} sx={{ width: '16px', height: '16px' }} />
                ),
                onClick: () => {
                  mutators.remove('variableSchemas', index);
                },
              },
            ]}
          />
        );
      })}
      <NvDialogModal
        fullWidth
        maxWidth="sm"
        formProps={{ initialValues: passedParameters?.fieldVariable }}
        open={isOpened}
        onCloseButtonClicked={onModalCloseClicked}
        Header={passedParameters?.fieldVariable ? 'Update field' : 'Add field'}
        Body={<RequestVariableFieldBody fieldName="variables" />}
        useArrayMutators
        primaryButtonText={passedParameters?.fieldVariable ? 'Update' : 'Add'}
        onFormSubmit={(values) => {
          if (passedParameters?.variableSchemaIndex !== undefined) {
            const variableFieldName = getVariableFieldName(passedParameters?.variableSchemaIndex);
            if (passedParameters?.fieldVariable) {
              mutators.update(variableFieldName, passedParameters.rowIndex, values);
            } else {
              mutators.push(variableFieldName, values);
            }
          }
          onModalCloseClicked();
        }}
      />
    </>
  );
};
