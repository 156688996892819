import {
  ParameterTypes,
  PartialUpdateQueryOption,
  RecordField,
  RecordPartialUpdateParameterMapping,
  SimpleParameterMapping,
} from '@novaera/actioner-service';
import { useCallback } from 'react';

export const useRecordPartialUpdateAdapter = () => {
  const format = useCallback((value?: RecordPartialUpdateParameterMapping) => {
    const parameterMappings = value?.updates.reduce<
      Partial<Record<string, SimpleParameterMapping<RecordField['value']>>>
    >((acc, { field }) => {
      return field
        ? {
            ...acc,
            [field.id]: {
              type: ParameterTypes.SIMPLE,
              parameterId: field.id,
              value: field.value,
            },
          }
        : acc;
    }, {});

    const types = value?.updates.reduce<Partial<{ types: Record<string, PartialUpdateQueryOption> }>>(
      (acc, { type, field }) => ({
        ...acc,
        types: { ...acc.types, ...(field ? { [field.id]: type } : {}) },
      }),
      {}
    );
    return { ...parameterMappings, ...types };
  }, []);

  const parse = useCallback(
    ({ id }: { id: string }) =>
      (value: {
        types: Record<string, PartialUpdateQueryOption>;
        fields: SimpleParameterMapping[];
      }): RecordPartialUpdateParameterMapping | undefined => {
        if (value) {
          const { types, fields } = value;
          return {
            type: ParameterTypes.RECORD_PARTIAL_UPDATE,
            updates: Object.keys(types).map((key) => {
              const v = fields.find((f) => f.parameterId === key);
              if (v?.value) {
                return { field: { id: v.parameterId, value: v.value }, type: types[v.parameterId] };
              } else {
                return { type: types[key], field: { id: key } };
              }
            }),
            parameterId: id,
          };
        }
        return undefined;
      },
    []
  );

  return { format, parse };
};
