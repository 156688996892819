import React, { ChangeEvent } from 'react';
import { NvButton } from '../button';
import { FileUploadButtonProps } from './types';

export const NvFileUploadButton: React.FC<React.PropsWithChildren<FileUploadButtonProps>> = ({
  onFileSelected,
  ...props
}) => {
  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target && event.target.files && event.target.files?.length > 0) {
      const fileUploaded = event?.target?.files[0];
      onFileSelected?.(fileUploaded);
    }
  };

  return (
    <>
      <NvButton color="secondary" size="small" onClick={handleClick} {...props}>
        {props.text ? props.text : 'Change Image'}
      </NvButton>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
        accept=".png, .svg, .jpg, .jpeg, image/png, image/svg+xml, image/jpeg"
      />
    </>
  );
};
