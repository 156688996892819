import { ActionNode, useGetIntegration, useGetIntegrationAction } from '@novaera/actioner-service';
import { NodeListItem, NvBox, NvForm, NvNodeList, NvPopover, NvSlide, Portal } from '@novaera/core';
import { assert } from '@novaera/utils';
import { noop } from 'lodash';
import { useCallback, useRef, useState } from 'react';
import { PPDrawerItem } from '../../../../../../../../components';
import { DropdownSimpleSearchInput } from '../../../../../../../../components/simple-search-input/styled';
import { FormIdentifierProvider } from '../../../../../../../../providers/form-identifier-provider';
import { useGetTriggerItems } from '../../../../add-new-node-popper/use-get-trigger-items';
import { usePPDrawerItemHelper } from '../../controllers/use-pp-drawer-item';
import { RowItemCard } from '../row-item-card';
import { ActionPanel } from './action-panel';
import { PARENT_PROPERTY_PANEL_DRAWER_ID } from './constants';
import { RowItemContent } from './row-item-content';

export const RowItemActionSelection = ({
  onChange,
  value,
  actionTag,
}: {
  onChange: (actionNode?: ActionNode) => void;
  value?: ActionNode;
  actionTag?: string;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [keyword, setKeyword] = useState<string | undefined>();
  const [selectedIntegrationId, setSelectedIntegrationId] = useState<string | undefined>();

  const open = Boolean(anchorEl);
  const formRef = useRef(null);

  const { style, className, onPanelClose, onPanelOpen } = usePPDrawerItemHelper({ refDom: formRef });

  const { actionNodeListItems, isLoading } = useGetTriggerItems({
    itemTypesToHide: [],
    keyword,
    activeParent: 'action',
  });

  const integrationId = value?.integrationId ?? selectedIntegrationId;

  const { data: integration } = useGetIntegration({
    id: integrationId,
  });
  const { data: integrationAction } = useGetIntegrationAction({
    integrationId: integrationId,
    actionId: value?.actionId,
    version: integration?.latestVersion.number,
  });

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (!value?.integrationId) {
        setAnchorEl(event.currentTarget);
      } else {
        onPanelOpen();
        setSelectedIntegrationId(value.integrationId);
      }
    },
    [onPanelOpen, value?.integrationId]
  );

  const handleClose = useCallback(() => {
    if (!value?.integrationId) {
      setAnchorEl(null);
    } else {
      setSelectedIntegrationId(undefined);
      onPanelClose();
    }
  }, [onPanelClose, value?.integrationId]);

  const handleDeleteActionSource = useCallback(() => {
    onChange(undefined);
    setSelectedIntegrationId(undefined);
    onPanelClose();
  }, [onChange, onPanelClose]);

  return (
    <NvForm<ActionNode>
      initialValues={value}
      onSubmit={noop}
      onChange={({ values }) => {
        onChange(values);
      }}
      keepDirtyOnReinitialize
      formRef={formRef}
    >
      <>
        <RowItemCard
          rowItemLeftContentProps={{ draggable: false }}
          rowItemContent={<RowItemContent integration={integration} integrationAction={integrationAction} />}
          onClick={handleClick}
        />
        <NvPopover
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          slotProps={{
            paper: {
              style: {
                marginTop: -16,
                marginLeft: 8,
              },
            },
          }}
          disableRestoreFocus
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <NvBox width="370px">
            <DropdownSimpleSearchInput
              autoFocus
              inputRef={searchInputRef}
              onKeywordChanged={(keyword) => {
                setKeyword(keyword);
              }}
            />

            <NvSlide
              direction="right"
              in={true}
              mountOnEnter
              unmountOnExit
              appear={false}
              timeout={{ appear: 0, exit: 0, enter: 300 }}
            >
              <NvNodeList
                nodes={actionNodeListItems}
                onItemClick={(e, node) => {
                  assert(
                    Boolean(document && document.getElementById(PARENT_PROPERTY_PANEL_DRAWER_ID)),
                    new Error(
                      `To use row item action selection, you must have a parent property panel drawer with id ${PARENT_PROPERTY_PANEL_DRAWER_ID}`
                    )
                  );

                  const integrationId = (
                    node as NodeListItem<{
                      type: string;
                      integrationId: string;
                      version: number;
                    }>
                  ).extraData?.integrationId;

                  const version = (
                    node as NodeListItem<{
                      type: string;
                      integrationId: string;
                      version: number;
                    }>
                  ).extraData?.version;

                  setSelectedIntegrationId(integrationId);

                  if (integrationId) {
                    onChange({
                      ...((value ?? {}) as ActionNode),
                      integrationId,
                      version: version ?? 1,
                    });
                    onPanelOpen();
                  }
                  handleClose();
                }}
                isLoading={isLoading}
              />
            </NvSlide>
          </NvBox>
        </NvPopover>
        <Portal node={document && document.getElementById(PARENT_PROPERTY_PANEL_DRAWER_ID)}>
          <PPDrawerItem style={style} className={className}>
            {(!integration && selectedIntegrationId) ||
              (integration && integration.id === selectedIntegrationId && (
                <FormIdentifierProvider>
                  <ActionPanel
                    integration={integration}
                    integrationAction={integrationAction}
                    onPanelClose={handleClose}
                    onDelete={handleDeleteActionSource}
                    actionTag={actionTag}
                  />
                </FormIdentifierProvider>
              ))}
          </PPDrawerItem>
        </Portal>
      </>
    </NvForm>
  );
};
