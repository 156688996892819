import { LabelValue } from '@novaera/core';

export const formTriggerDefaultUserBehaviorOptions: LabelValue<boolean>[] = [
  {
    label: 'Fallback user connection',
    value: false,
    description:
      'This workflow will be run using the provided credentials if the runner user does not have any existing connections.',
  },
  {
    label: 'Override user connection',
    value: true,
    description: 'This workflow will be run using the credentials of the current user.',
  },
];
