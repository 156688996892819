import { UserWorkflowExecutionResultResponse } from '@novaera/actioner-service';
import { NvCollapseCard, NvReactJson, NvTypography } from '@novaera/core';
import { JsonViewWrapper } from '../../../../../../../../../components';

export const WorkflowStateResponse = ({ result }: { result?: UserWorkflowExecutionResultResponse | null }) => {
  return (
    <NvCollapseCard
      title={<NvTypography variant="h5">Response</NvTypography>}
      iconSx={{
        width: '16px',
        height: '16px',
      }}
    >
      <JsonViewWrapper>
        <NvReactJson
          src={result ?? {}}
          hideCopyIcon
          style={{ minWidth: '250px', maxHeight: '450px', overflow: 'auto', padding: '2px' }}
          enableClipboard={false}
        />
      </JsonViewWrapper>
    </NvCollapseCard>
  );
};
