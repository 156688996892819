import { HeaderWithTabLayout, NvSettingsIcon } from '@novaera/core';
import { WORKSPACE_SETTINGS_TABS } from './constants';

export const WorkflowSettings = () => {
  return (
    <HeaderWithTabLayout
      title={{
        label: 'Workspace Settings',
        icon: <NvSettingsIcon />,
      }}
      tabs={WORKSPACE_SETTINGS_TABS}
      variant="center-aligned"
    />
  );
};
