import { CatalogEntity } from '@novaera/actioner-service';
import { NvFlex } from '@novaera/core';
import { useState } from 'react';
import { RecordsLayout } from '../../../components/records-layout';
import { RecordsSearch } from '../../../components/records-search';
import { RecordsTable } from '../../../components/records-table';
import { useSelectedCatalogEntity } from '../../controllers/use-selected-catalog-entity';
import { CatalogDetailPanel } from './catalog-detail-panel';
import { RECORD_LIMIT } from './constants';
import { useCatalogEntitySearch } from './controllers/use-catalog-entity-search';

export const CatalogEntitySearch = () => {
  const {
    rows,
    columns,
    page,
    searchKeyword,
    totalHits,
    isLoading,
    isRefetching,
    onSearchChange,
    onSearchEnter,
    onSearchRefresh,
    onSortModelChange,
    onPageChange,
    getCatalogEntity,
  } = useCatalogEntitySearch();

  const { selectedEntityType } = useSelectedCatalogEntity();

  const [selectedCatalogEntity, setSelectedCatalogEntity] = useState<CatalogEntity | undefined>(undefined);

  return (
    selectedEntityType && (
      <NvFlex width={'100%'}>
        <RecordsLayout
          HeaderComponent={
            <RecordsSearch
              minHeight="59px"
              search={searchKeyword}
              onChange={onSearchChange}
              onEnter={onSearchEnter}
              handleRefresh={onSearchRefresh}
            />
          }
          RowDetailPanelComponent={
            <CatalogDetailPanel
              onDetailPanelClose={() => {
                setSelectedCatalogEntity(undefined);
              }}
              entityType={selectedEntityType}
              catalogEntity={selectedCatalogEntity}
            />
          }
          isPanelOpen={selectedCatalogEntity !== undefined}
        >
          <RecordsTable
            rows={rows}
            columns={columns}
            totalHits={totalHits}
            loading={isLoading || isRefetching}
            page={page}
            onPageChange={onPageChange}
            onSortModelChange={onSortModelChange}
            pageSize={RECORD_LIMIT}
            checkboxSelection={false}
            disableSelectionOnClick={false}
            onRowClick={(params) => {
              const catalogEntity = getCatalogEntity(params.row.id);
              setSelectedCatalogEntity(catalogEntity);
            }}
          />
        </RecordsLayout>
      </NvFlex>
    )
  );
};
