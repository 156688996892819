import {
  APP_PERMISSION,
  CloneResponse,
  SearchWorkflow,
  useDeleteWorkflow,
  useGetUserApp,
  useSearchInfiniteWorkflows,
  useUpdateWorkflowEnableState,
  WorkflowPermission,
} from '@novaera/actioner-service';
import {
  NvAddBoxIcon,
  NvButton,
  NvConditionalRender,
  NvContentCopyIcon,
  NvDeleteOutlineIcon,
  NvFlex,
  NvLockIcon,
  NvSearchEmptyState,
  NvToggleOffIcon,
  NvToggleOnIcon,
  NvTooltip,
  NvTypography,
  NvWorkflowIcon,
  useConfirmDialog,
  useDebounce,
  useNvDialogModalState,
} from '@novaera/core';
import { useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import React, { useCallback, useRef, useState } from 'react';
import { SimpleSearchInput } from '../../../components';
import { BaseItem } from '../../../components/base-item';
import { Clone } from '../../../components/clone';
import { CloneFlagger } from '../../../components/clone/flagger';
import { EmptyState } from '../../../components/empty-state';
import { EmptyStates } from '../../../components/empty-state/types';
import { UserAppPermissionBoundary } from '../../user-app-permission-boundary';
import { FreeAppPermissionBoundary } from '../../user-app-permission-boundary/free-app-permission-boundary';
import { useIsAppFree } from '../../user-app-permission-boundary/free-app-permission-boundary/use-free-app-permission-boundary';
import { useWorkflowPermission } from '../../user-app-permission-boundary/use-workflow-permission';
import { USER_APP_WORKFLOW } from '../constants';
import { AddNewWorkflowModal } from './add-new-workflow-modal';
import { WorkflowTriggerIcon } from './workflow-trigger-icon';
import { WorkflowItemsLoading, WorkflowsLoading } from './workflows-loading';

export const Workflows: React.FC<React.PropsWithChildren<unknown>> = () => {
  const theme = useTheme();
  const { userAppId } = useParams();
  const { data: userApp, isLoading: isUserAppLoading } = useGetUserApp(userAppId);
  const { isAppFree } = useIsAppFree({ userAppId });
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const isSearchBarTouched = useRef<boolean>();
  const debouncedSearchKeyword = useDebounce(searchKeyword, 500);
  const [isAddWorkflowModalOpen, setIsWorkflowModalOpen] = useState<boolean>(false);
  const { openConfirm } = useConfirmDialog();
  const { isOpened, onModalCloseClicked, onModalOpenClicked, passedParameters } = useNvDialogModalState<{
    id: string;
    name: string;
  }>();
  const [clonedItem, setClonedItem] = useState<CloneResponse | undefined>();

  const {
    data: getWorkflowsQueries,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useSearchInfiniteWorkflows({
    appIds: [userAppId],
    keyword: debouncedSearchKeyword,
    ...(debouncedSearchKeyword.length === 0 ? { sort: { order: 'asc', field: 'name' } } : {}),
    from: 0,
    includeSearchInvisible: true,
  });

  const getWorkflowsPages = getWorkflowsQueries?.pages.filter((p) => p);
  const hasNoWorkflows =
    !getWorkflowsPages ||
    (getWorkflowsPages && getWorkflowsPages.length === 1 && getWorkflowsPages[0].workflows.length === 0);

  const { mutate: deleteWorkflow } = useDeleteWorkflow();
  const { mutate: updateWorkflowEnableState } = useUpdateWorkflowEnableState();
  const { checkEditPermission } = useWorkflowPermission();

  const workflowMenuItems = useCallback(
    ({ id, name, permission }: { id: string; name: string; permission?: WorkflowPermission }) =>
      [
        {
          name: 'Clone',
          onClick: () => {
            onModalOpenClicked({ id, name });
          },
          icon: <NvContentCopyIcon sx={{ width: '16px', height: '16px' }} />,
          right: checkEditPermission(permission) && !userApp?.free,
        },
        {
          name: 'divider',
          isDivider: true,
          dividerProps: { sx: { marginTop: '4px !important', marginBottom: '4px !important' } },
          right: checkEditPermission(permission) && !userApp?.free,
        },
        {
          name: 'Delete',
          onClick: () => {
            openConfirm({
              title: `Delete ${name ?? 'This workflow'}`,
              message: (
                <NvTypography variant="body1">
                  <b>{name ?? 'This workflow'}</b> will no longer be available for this app. Deleted workflows cannot be
                  recovered.
                </NvTypography>
              ),
              onConfirm: () => {
                deleteWorkflow({ appId: userAppId, workflowId: id });
              },
            });
          },
          icon: <NvDeleteOutlineIcon htmlColor={theme.palette.nv_error[40]} sx={{ width: '16px', height: '16px' }} />,
          right: checkEditPermission(permission) && !userApp?.free,
        },
      ].filter(({ right }) => right),
    [
      checkEditPermission,
      deleteWorkflow,
      onModalOpenClicked,
      openConfirm,
      theme.palette.nv_error,
      userApp?.free,
      userAppId,
    ]
  );

  const handleAddWorkflow = () => {
    setIsWorkflowModalOpen(true);
  };

  return (
    <>
      {(isUserAppLoading || isLoading) && !isSearchBarTouched.current ? (
        <WorkflowsLoading />
      ) : (
        <>
          <NvFlex gap="16px">
            <NvFlex direction="row" gap="8px" alignItems="center">
              <NvWorkflowIcon sx={{ width: '32px', height: '32px', flex: '0 0 auto' }} />
              <NvTypography variant="h1" flex="1 1 auto" minWidth={0}>
                Workflows
              </NvTypography>
            </NvFlex>
            {!isLoading && hasNoWorkflows && debouncedSearchKeyword.length === 0 ? (
              <EmptyState
                variant={EmptyStates.WORKFLOWS}
                CustomButton={
                  <UserAppPermissionBoundary appId={userAppId} permission={APP_PERMISSION.APP_EDIT}>
                    <NvButton
                      startIcon={<NvAddBoxIcon />}
                      color="secondary"
                      onClick={handleAddWorkflow}
                      size="small"
                      sx={{ flex: '0 0 auto' }}
                    >
                      Add workflow
                    </NvButton>
                  </UserAppPermissionBoundary>
                }
              />
            ) : (
              <>
                <NvFlex direction="row" alignItems="center" justifyContent="space-between">
                  <SimpleSearchInput
                    placeholder="Search workflows"
                    onKeywordChanged={(keyword) => {
                      setSearchKeyword(keyword ?? '');
                      isSearchBarTouched.current = true;
                    }}
                  />
                  {!userApp?.free && (
                    <UserAppPermissionBoundary appId={userAppId} permission={APP_PERMISSION.APP_EDIT}>
                      <NvButton startIcon={<NvAddBoxIcon />} size="small" color="ghost" onClick={handleAddWorkflow}>
                        Add workflow
                      </NvButton>
                    </UserAppPermissionBoundary>
                  )}
                </NvFlex>

                {isLoading && isSearchBarTouched.current ? (
                  <WorkflowItemsLoading />
                ) : hasNoWorkflows && debouncedSearchKeyword.length > 0 ? (
                  <NvSearchEmptyState text={'No workflows found. Try different words or clear search bar.'} />
                ) : (
                  <NvFlex gap="8px">
                    {getWorkflowsPages?.map(({ workflows }) =>
                      workflows.map(
                        ({
                          name,
                          description,
                          id,
                          tags,
                          trigger,
                          disabled,
                          permission,
                          creationSource,
                        }: SearchWorkflow) => {
                          const baseItemMenuItems = workflowMenuItems({ id, name, permission });
                          return (
                            <BaseItem
                              key={`workflow-item-${id}`}
                              name={name}
                              tags={tags}
                              icon={<WorkflowTriggerIcon trigger={trigger} />}
                              description={description}
                              to={USER_APP_WORKFLOW({ userAppId, workflowId: id })}
                              {...(baseItemMenuItems.length > 0 ? { menuItems: baseItemMenuItems } : {})}
                              actions={
                                <NvFlex direction="row" alignItems="center" gap="4px">
                                  {creationSource === 'app-directory' && (userApp?.managed || isAppFree) && (
                                    <NvTooltip
                                      title={
                                        isAppFree
                                          ? 'You cannot edit this workflow since it is a free app. To make changes, clone the entire app.'
                                          : 'You cannot edit this workflow since it is part of a managed app. To make changes, unlock the entire app, create a new workflow, or clone.'
                                      }
                                    >
                                      <NvLockIcon sx={{ width: '16px', height: '16px' }} />
                                    </NvTooltip>
                                  )}
                                  <NvConditionalRender when={() => checkEditPermission(permission)}>
                                    <FreeAppPermissionBoundary appId={userAppId}>
                                      <NvButton
                                        size="small"
                                        color="ghost"
                                        startIcon={
                                          disabled ? (
                                            <NvToggleOffIcon htmlColor={theme.palette.nv_error[40]} />
                                          ) : (
                                            <NvToggleOnIcon htmlColor={theme.palette.nv_success[60]} />
                                          )
                                        }
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          e.preventDefault();
                                          updateWorkflowEnableState({
                                            appId: userAppId,
                                            id: id,
                                            enabled: disabled,
                                          });
                                        }}
                                      >
                                        {disabled ? 'Disabled' : 'Enabled'}
                                      </NvButton>
                                    </FreeAppPermissionBoundary>
                                  </NvConditionalRender>
                                </NvFlex>
                              }
                            />
                          );
                        }
                      )
                    )}
                    {hasNextPage && (
                      <NvFlex alignItems="flex-start" padding="16px" flex="0 0 auto">
                        <NvButton
                          size="small"
                          color="secondary"
                          onClick={() => {
                            fetchNextPage();
                          }}
                          loading={isFetchingNextPage}
                          disabled={isFetchingNextPage}
                        >
                          Show more
                        </NvButton>
                      </NvFlex>
                    )}
                  </NvFlex>
                )}
              </>
            )}
          </NvFlex>
          <AddNewWorkflowModal
            isOpen={isAddWorkflowModalOpen}
            onClose={() => {
              setIsWorkflowModalOpen(false);
            }}
          />
        </>
      )}

      {passedParameters?.id && passedParameters.name && (
        <Clone
          isOpened={isOpened}
          appId={userAppId}
          workflowId={passedParameters.id}
          name={passedParameters.name}
          onModalCloseClicked={onModalCloseClicked}
          type="workflows"
          onCloneSuccess={(param) => {
            setClonedItem(param);
          }}
        />
      )}
      {clonedItem && (
        <CloneFlagger
          opened={!!clonedItem}
          clonedItem={clonedItem}
          onFlaggerClosed={() => {
            setClonedItem(undefined);
          }}
          type={'workflows'}
        />
      )}
    </>
  );
};
