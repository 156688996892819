export const LOCAL_STORAGE_ACCESS_KEY = 'access_token';
export const ROUTE_DEFAULTS = {
  SIGN_IN: '/signin',
  SIGN_UP: '/signup',
  SOCIAL_LOG_IN: '/social-login',
  LOG_OUT: '/logout',
  USER_INVITATION: '/user/invitation',
  EMAIL_CHANGE_CONFIRMATION: '/users/confirm-email',
  SLACK_WORKSPACES: '/signup/slack-workspaces',
  REDIRECTING_TO_DASHBOARD: '/redirect/dashboard',
  EARLY_ACCESS: '/early-access',
};

export const LAST_ACTIVE_WORKSPACE = 'LAST_ACTIVE_WORKSPACE';
export const TARGET_URI = 'TARGET_URI';

export const BG_COLORS_OF_INITIAL_COMPONENT = [
  'linear-gradient(135deg, #89f0c2ff 0%, #5fcffaff 100%)',
  'linear-gradient(135deg, #fadf90ff 0%, #ea3772ff 100%)',
  'linear-gradient(135deg, #fadf90ff 0%, #60cf92ff 100%)',
  'linear-gradient(135deg, #dd8af8ff 0%, #ee8034ff 100%)',
  'linear-gradient(135deg, #dd8af8ff 0%, #ea3772ff 100%)',
  'linear-gradient(135deg, #9be8fcff 0%, #0f8bfdff 100%)',
  'linear-gradient(135deg, #f6c545ff 0%, #d661f7ff 100%)',
  'linear-gradient(135deg, #dd8af8ff 0%, #0f8bfdff 100%)',
  'linear-gradient(135deg, #ef8bb7ff 0%, #f6c545ff 100%)',
  'linear-gradient(135deg, #fadf90ff 0%, #5fcffaff 100%)',
];
