import { NvSearchIcon, NvTextField, useDebounce } from '@novaera/core';
import { useEffect, useState } from 'react';
import { SimpleSearchInputProps } from './types';

export const SimpleSearchInput: React.FC<React.PropsWithChildren<SimpleSearchInputProps>> = ({
  onKeywordChanged,
  initialKeyword,
  placeholder,
  color = 'primary',
  ...props
}) => {
  const [keyword, setKeyword] = useState<string>(initialKeyword ?? '');
  const debouncedKeyword = useDebounce(keyword, 500);

  useEffect(() => {
    onKeywordChanged(debouncedKeyword);
  }, [debouncedKeyword, onKeywordChanged]);

  return (
    <NvTextField
      data-testid="simple-search-input"
      placeholder={placeholder ?? 'Search'}
      startIcon={<NvSearchIcon />}
      value={keyword}
      onChange={(e) => {
        setKeyword(e.target.value);
      }}
      color={color}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
        }
      }}
      {...props}
    />
  );
};
