import { Integration, useSearchInfiniteIntegrations } from '@novaera/actioner-service';
import {
  NvAddBoxIcon,
  NvArrowForwardIcon,
  NvBox,
  NvButton,
  NvFlex,
  NvSearchEmptyState,
  NvSkeleton,
  NvTypography,
  useDebounce,
} from '@novaera/core';
import { FC, useRef, useState } from 'react';
import { SimpleSearchInput } from '../../components';
import { EmptyState } from '../../components/empty-state';
import { EmptyStates } from '../../components/empty-state/types';
import { CardListLayout } from '../../components/grid-list-layout';
import { ShowMoreCardWrapper } from '../../user-app/user-apps/styled';
import { CardView } from '../components/card-view';
import { CreateIntegrationModal } from '../create-integration-modal';
import { IntegrationsContainer } from './styled';

export const Integrations: FC<React.PropsWithChildren<unknown>> = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const debouncedKeyword = useDebounce(searchKeyword, 500);
  const isSearchBarTouched = useRef<boolean>();
  const {
    data: searchIntegrationsQueries,
    isInitialLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useSearchInfiniteIntegrations({
    scopeType: 'workspace',
    ...(debouncedKeyword && { value: debouncedKeyword }),
  });
  const searchIntegrationsPages = searchIntegrationsQueries?.pages?.filter((p) => p);
  const hasNoIntegrations =
    !searchIntegrationsPages ||
    (searchIntegrationsPages &&
      searchIntegrationsPages.length === 1 &&
      searchIntegrationsPages[0].integrations.length === 0);
  const integrations = searchIntegrationsPages?.reduce<Integration[]>((acc, cur) => [...acc, ...cur.integrations], []);
  return (
    <>
      <CardListLayout
        CardListWrapperComponent={IntegrationsContainer}
        header={
          <NvBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            {!(hasNoIntegrations && debouncedKeyword.length === 0) && (
              <NvFlex flexDirection="row" gap="8px" alignItems="center">
                <SimpleSearchInput
                  placeholder="Search integrations"
                  onKeywordChanged={(keyword) => {
                    setSearchKeyword(keyword ?? '');
                    isSearchBarTouched.current = true;
                  }}
                />
              </NvFlex>
            )}
            {!(hasNoIntegrations && debouncedKeyword.length === 0) && (
              // Use this component after clone from existing feature is implemented.
              // <AddCustomIntegration
              //   onClickCreateButton={() => setIsOpen(true)}
              //   actionButton={
              //     <NvButton color="ghost" startIcon={<NvAddBoxIcon />}>
              //       Add custom integration
              //     </NvButton>
              //   }
              // />
              <NvButton color="ghost" startIcon={<NvAddBoxIcon />} onClick={() => setIsOpen(true)}>
                Add custom integration
              </NvButton>
            )}
          </NvBox>
        }
        emptyState={
          hasNoIntegrations && debouncedKeyword.length === 0 ? (
            <EmptyState
              CustomButton={
                // Use this component after clone from existing feature is implemented.
                // <AddCustomIntegration
                //   onClickCreateButton={() => setIsOpen(true)}
                //   actionButton={
                //     <NvButton color="secondary" startIcon={<NvAddBoxIcon />} size="small">
                //       Add custom integration
                //     </NvButton>
                //   }
                // />
                <NvButton color="secondary" startIcon={<NvAddBoxIcon />} size="small" onClick={() => setIsOpen(true)}>
                  Add custom integration
                </NvButton>
              }
              variant={EmptyStates.INTEGRATION}
            />
          ) : (
            <NvSearchEmptyState text="No custom integration found. Try different words or clear search bar." />
          )
        }
        CardViewComponent={CardView}
        cardList={integrations}
        isLoading={isInitialLoading && !isSearchBarTouched.current}
        isListLoading={isInitialLoading && isSearchBarTouched.current}
        loadingItem={<NvSkeleton width="100%" height="58px" variant="rectangular" />}
        nextPageIndicator={
          hasNextPage ? (
            isFetchingNextPage ? (
              <NvSkeleton width="100%" height="74px" variant="rectangular" />
            ) : (
              <ShowMoreCardWrapper onClick={() => fetchNextPage()} sx={{ minHeight: '74px' }}>
                <NvTypography variant="h5" flex="1 1 auto" minWidth={0}>
                  Show more
                </NvTypography>
                <NvArrowForwardIcon sx={{ width: '16px', height: '16px' }} />
              </ShowMoreCardWrapper>
            )
          ) : undefined
        }
      />
      <CreateIntegrationModal isOpen={isOpen} onClose={() => setIsOpen((prev) => !prev)} />
    </>
  );
};
