import { useGetUserApp } from '@novaera/actioner-service';
import { usePermission } from '../../utils/hooks';

import { UserAppPermissionBoundaryProps } from './types';

export const UserAppPermissionBoundary: React.FC<React.PropsWithChildren<UserAppPermissionBoundaryProps>> = ({
  appId,
  permission,
  Fallback,
  children,
}) => {
  const { data: app } = useGetUserApp(appId);
  const permissions = app?.permissions ?? {};
  const result = usePermission({
    permissions: permissions,
    permission,
    Fallback,
    children,
  });

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{result}</>;
};
