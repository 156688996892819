export const REASON_ITEMS = [
  'I have another Actioner workspace',
  'I get too much email from Actioner',
  'I have privacy concern',
  "It's too expensive",
] as const;

export const OTHER_REASON_ITEM = 'Other';

export const ALL_REASON_ITEMS = [...REASON_ITEMS, OTHER_REASON_ITEM];
