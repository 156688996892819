import { CodeInput, NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const JsonInput = styled(CodeInput)`
  height: 100%;

  &.NvCodeInput {
    height: 100%;
    .CodeMirror {
      height: 100%;
      background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
      border: 1px solid ${({ theme }) => theme.palette.nv_neutral[20]};

      &-focused {
        border: border: 1px solid transparent;
        box-shadow: none;

        &:hover {
          background-color: ${({ theme }) => theme.palette.nv_neutral[0]} !important;
        }
      }

      &-scroll {
        max-height: initial;
      }

      &:hover {
        background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
      }
    }
  }
`;

export const ConfigDetailWrapper = styled(NvFlex)`
  padding: 0 16px 0 24px;
  flex-direction: row;
  gap: 24px;
  height: 100%;
  flex: 1 1 auto;
  min-height: 0;
  align-items: flex-start;
`;

export const ContainerSidePanel = styled(NvFlex)`
  padding: 24px 0 60px 0;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  width: 400px;
  overflow: auto;
  flex: 0 0 auto;
`;
