import { JobOperationTarget, useGetWorkflow } from '@novaera/actioner-service';
import { NvField, NvWorkflowIcon, isRequired, useField } from '@novaera/core';
import { useParams } from '@novaera/route';
import OnChange from '../../../../../../../../../../action-designer/components/on-change';
import { PropertyPanelSection } from '../../../../../../../../../../components';
import { FormIdentifierProvider } from '../../../../../../../../../../providers/form-identifier-provider';
import { WorkflowSelectComponent } from '../../../../../../common/workflow-select-component';
import { useGetWorkflowContexts } from '../../../../../../controllers/use-get-workflow-contexts';
import { WorkflowInputs } from '../../../../action-node-property-panel-drawer/action-node-properties/workflow-inputs';

export const JobNodeTarget: React.FC = () => {
  const baseFieldName = 'operation.target';
  const { userAppId: appId } = useParams();
  const {
    input: { value: target, onChange: onChangedTarget },
  } = useField<JobOperationTarget>(baseFieldName);
  const { workflowCodeInputContext } = useGetWorkflowContexts();
  const { savedWorkflow } = useGetWorkflow({ appId, workflowId: target.workflowId });

  return (
    <>
      <OnChange name={`${baseFieldName}.workflowId`}>
        {() => {
          onChangedTarget({
            ...target,
            parameters: [],
            formId: undefined,
          });
        }}
      </OnChange>

      <PropertyPanelSection title={'Workflow'} icon={<NvWorkflowIcon />}>
        <NvField
          formControlStyle={{ width: '100%' }}
          name={`${baseFieldName}.workflowId`}
          validators={[isRequired()]}
          component={<WorkflowSelectComponent searchParamsTriggerTypes={['form']} size="small" />}
        />
      </PropertyPanelSection>

      {target.workflowId && savedWorkflow?.trigger?.type === 'form' && (
        <FormIdentifierProvider initialFormId={target.formId}>
          <WorkflowInputs
            workflowId={target.workflowId}
            initialParameterMappings={target.parameters ?? []}
            onChange={({ parameterMappings, formId }) => {
              onChangedTarget({ ...target, parameters: parameterMappings, formId });
            }}
            context={workflowCodeInputContext}
          />
        </FormIdentifierProvider>
      )}
    </>
  );
};
