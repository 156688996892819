import { SearchConnection, useGetUserApp } from '@novaera/actioner-service';
import { NvFlex, NvForm, NvPowerRoundedIcon, NvTypography } from '@novaera/core';
import { noop } from 'lodash';
import { Card } from '../../../../components/card/styled';
import { useGetWorkflowQueryParams } from '../../../../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/controllers/use-get-workflow-query-params';
import { ConnectForm } from '../connect-form';
import { useMissingConnectionController } from '../controllers/use-missing-connection';
import { MissingConnectionBody } from '../missing-connection-body';
import { useMissingConnectionUpdatesController } from '../missing-connection-body/controllers/use-missing-connection-updates';
import { MissingConnectionPageProps } from '../types';

export const MissingConnectionPage = ({
  connectionValidationCheckResult,
  onConnectionsCompleted,
  workflowName,
}: MissingConnectionPageProps) => {
  const { userAppId } = useGetWorkflowQueryParams();
  const { data: userApp } = useGetUserApp(userAppId);
  const { connectionValidationMode } = useMissingConnectionController({
    connectionValidationSchemas: connectionValidationCheckResult?.invalidNodeConnections,
  });
  const { addConnectionMapping } = useMissingConnectionUpdatesController();
  return (
    <NvFlex gap={'32px'}>
      <NvFlex gap={'16px'}>
        <NvFlex flexDirection={'row'} alignItems={'center'} gap={'8px'}>
          <NvPowerRoundedIcon />
          <NvTypography variant="h1">Connect</NvTypography>
        </NvFlex>

        {connectionValidationMode === 'missing' &&
          connectionValidationCheckResult?.invalidNodeConnections?.length === 1 && (
            <>
              <NvFlex>
                <NvTypography variant="body1">
                  You need to connect the tools listed below, as this is essential for the <b>{workflowName}</b>{' '}
                  workflow of <b>{userApp?.name ?? 'this app'}</b>.
                </NvTypography>
              </NvFlex>
              <Card>
                <ConnectForm
                  onFormSubmitCompleted={onConnectionsCompleted}
                  schema={connectionValidationCheckResult?.invalidNodeConnections[0]}
                />
              </Card>
            </>
          )}
        {(connectionValidationMode === 'missing-slack' || connectionValidationMode === 'multiple') &&
          connectionValidationCheckResult?.invalidNodeConnections && (
            <NvForm<{
              connection: SearchConnection;
            }>
              onSubmit={noop}
              onChange={({ values }) => {
                addConnectionMapping({
                  connectionId: values.connection.id,
                  schemaId: values.connection.schemaId,
                  connectionName: values.connection.name,
                });
                onConnectionsCompleted();
              }}
              keepDirtyOnReinitialize
            >
              <MissingConnectionBody
                onFormSubmitCompleted={onConnectionsCompleted}
                connectionValidationCheckResult={connectionValidationCheckResult}
                workflowName={workflowName}
              />
            </NvForm>
          )}
      </NvFlex>
    </NvFlex>
  );
};
