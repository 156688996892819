import { useGetUserAppSetup, useListConnectionMappingService } from '@novaera/actioner-service';
import { NvButton, NvFlex, NvSkeleton, Step, StepTracker } from '@novaera/core';
import { FC, useState } from 'react';
import { CompletedStep } from './completed-step';
import { ConnectionStep } from './connection-step';
import { COMPLETED_STEP_LABEL, CONNECTION_STEP_LABEL, SETUP_WORKFLOW_STEP_LABEL } from './constants';
import { RunSetupWorkflow } from './run-setup-workflow-step';
import { StepBox } from './styled';
import { filterInstallSteps } from './utils';

export const InstallSteps: FC<React.PropsWithChildren<{ appId: string; onCancel: () => void }>> = ({
  appId,
  onCancel,
}) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const { data: userAppSetup, isInitialLoading: isUserAppSetupLoading } = useGetUserAppSetup({ appId });
  const { data: connectionMappingsData, isLoading: isConnectionMappingsLoading } = useListConnectionMappingService({
    appId,
  });

  const connectionMappings = connectionMappingsData ?? [];
  const { setupWorkflows } = userAppSetup ?? {
    setupWorkflows: [],
  };
  const defaultInstallSteps: Step[] = [
    {
      content: (
        <StepBox>
          <ConnectionStep connections={connectionMappings} appId={appId} />
        </StepBox>
      ),
      label: CONNECTION_STEP_LABEL,
      showButtons: true,
      nextButton: ({ nextClick }) => (
        <NvButton onClick={nextClick} disabled={!!connectionMappings?.filter((c) => !c.connected).length}>
          Next
        </NvButton>
      ),
    },
    {
      content: (
        <StepBox>
          <RunSetupWorkflow workflows={setupWorkflows} appId={appId} />
        </StepBox>
      ),
      label: SETUP_WORKFLOW_STEP_LABEL,
      showButtons: true,
      nextButton: ({ nextClick }) => (
        <NvButton onClick={nextClick} disabled={!!setupWorkflows.filter((w) => w.status !== 'successful').length}>
          Next
        </NvButton>
      ),
    },
    {
      content: () => (
        <StepBox>
          <CompletedStep
            appId={appId}
            handleNextClick={() => {
              onCancel();
            }}
          />
        </StepBox>
      ),
      label: COMPLETED_STEP_LABEL,
      showButtons: false,
    },
  ];

  const installSteps = filterInstallSteps({ steps: defaultInstallSteps, connectionMappings, setupWorkflows });

  return !isUserAppSetupLoading && !isConnectionMappingsLoading ? (
    <StepTracker
      steps={installSteps}
      onCancelClick={onCancel}
      initialActiveStep={activeIndex}
      setInitialActiveStep={setActiveIndex}
    />
  ) : (
    <NvFlex width="100%" gap="32px" alignItems="center" maxWidth="1024px" margin="0 auto">
      <NvSkeleton variant="rectangular" height="40px" width="50%" />
      <NvFlex gap="16px" width="100%">
        <NvFlex gap="4px">
          <NvSkeleton variant="rectangular" height="20px" width="100px" />
          <NvSkeleton variant="rectangular" height="16px" width="200px" />
        </NvFlex>

        <NvFlex width="100%" gap="8px">
          <NvSkeleton variant="rectangular" height="56px" />
          <NvSkeleton variant="rectangular" height="56px" />
        </NvFlex>
      </NvFlex>
    </NvFlex>
  );
};
