import { ConnectionSchema } from '@novaera/actioner-service';
import { NvBox, NvKeyValueInput, useFormState } from '@novaera/core';
import { AddButton } from '../../../../../../../../../components/button/add';
import { useCallback } from 'react';
import { ConnectionKeyValueInput } from '../../connection-key-value-input';
import { URL_AND_REQUEST_PARAMETERS_DESCRIPTION } from '../../constants';

export const useUrlAndParameters = () => {
  const { values } = useFormState<ConnectionSchema>();

  const getContext = useCallback(() => {
    return {
      auth: values?.authentication?.authenticationFields?.reduce((previousValue, currentValue) => {
        return { ...previousValue, ...{ [currentValue.key]: '' } };
      }, {}),
    };
  }, [values?.authentication?.authenticationFields]);

  const parameterTabs = [
    {
      label: 'URL Params',
      content: (
        <NvBox paddingTop={1.5}>
          <NvKeyValueInput
            addNewInputLabel="Add URL parameter"
            component={<ConnectionKeyValueInput name="authentication.urlParameters" context={getContext()} />}
            fieldArrayName={'authentication.urlParameters'}
            hasRemoveAction
            keyTitle="KEY"
            valueTitle="VALUE"
            keyFieldName="key"
            valueFieldName="value"
            AddNewFieldButton={<AddButton label="Add URL parameter" />}
          />
        </NvBox>
      ),
    },
    {
      label: 'Headers',
      value: 'headers',
      content: (
        <NvBox paddingTop={1.5}>
          <NvKeyValueInput
            addNewInputLabel="Add header"
            component={<ConnectionKeyValueInput name="authentication.headers" context={getContext()} />}
            fieldArrayName={`authentication.headers`}
            hasRemoveAction
            keyTitle="KEY"
            valueTitle="VALUE"
            keyFieldName="key"
            valueFieldName="value"
            AddNewFieldButton={<AddButton label="Add Header parameter" />}
          />
        </NvBox>
      ),
    },
  ];

  const getDescription = () => {
    return URL_AND_REQUEST_PARAMETERS_DESCRIPTION[values.authentication?.type];
  };

  return {
    parameterTabs,
    description: getDescription(),
  };
};
