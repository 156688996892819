import { useGetActiveUser } from '@novaera/actioner-service';
import {
  NvBox,
  NvConditionalRender,
  NvCustomSpinner,
  NvDivider,
  NvField,
  NvFlex,
  NvTextField,
  NvTypography,
  SectionMessage,
  isRequired,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { getSubdomain } from '@novaera/utils';
import OnChange from '../../../../action-designer/components/on-change';
import { ResponseSummary } from '../../../../components/response-summary';
import { isOAuth2Authentication } from '../../../utils';
import { AutoCompleteConnectionType } from '../../connection-schema-modal/autocomplete-connection-type';
import { FormattedConnectionType } from '../../connection-schema-modal/types';
import { ConnectionInformation } from '../connection-information';
import { useConnectModalBody } from './controllers/use-connect-modal-body';
import { Redirecting } from './styled';
import { ConnectModalBodyProps } from './types';

export const ConnectModalBody = ({
  connectionSchema,
  response,
  isLoading,
  formSubmitResult,
  isConnectionSchemaLoading,
  mode,
  connected,
  setSchemaId,
  showSchemaSelect = false,
}: ConnectModalBodyProps) => {
  const theme = useTheme();
  const subdomain = getSubdomain();

  const { suggestedConnection, isSlackConnection, connectionSchemas, isGlobalConnectionSchemasLoading } =
    useConnectModalBody({
      connectionSchema,
    });
  const { user } = useGetActiveUser({ enabled: isSlackConnection });

  return (
    <NvBox display="flex" flexDirection="column" gap="14px" padding="1px" width={'100%'}>
      {mode !== 'Test' && !connected && !isSlackConnection && (
        <>
          <NvFlex width="254px">
            <NvField
              isRequired
              hasRequiredIndicator
              validators={[isRequired()]}
              showErrorMessageOnlyWhenBlur
              name="name"
              initialValue={suggestedConnection}
              labelText="Connection name"
              direction="label-on-top"
              component={<NvTextField />}
            />
          </NvFlex>

          {showSchemaSelect && (
            <>
              <OnChange name="selectedSchema">
                {(value) => {
                  setSchemaId?.((value as FormattedConnectionType)?.id);
                }}
              </OnChange>
              <NvFlex gap="4px" width="100%">
                <NvField<FormattedConnectionType>
                  hasRequiredIndicator
                  labelText="Connection"
                  direction="label-on-top"
                  labelVariant="h5"
                  name="selectedSchema"
                  isRequired
                  validators={[isRequired('Connection type is required')]}
                  isAutoComplete
                  allowNull
                  showErrorMessageOnlyWhenBlur
                  component={
                    <AutoCompleteConnectionType
                      isLoading={isGlobalConnectionSchemasLoading}
                      options={connectionSchemas}
                      renderInputProps={{ size: 'medium', placeholder: 'Select connection' }}
                    />
                  }
                />
              </NvFlex>
            </>
          )}
          <NvDivider />
        </>
      )}

      {isSlackConnection && (
        <NvTypography>
          {user?.roleId === 'admin' ? (
            <>
              <b>{subdomain}</b> has not connected to any Slack workspaces yet. You need to connect your Slack workspace
              first.
            </>
          ) : (
            <>
              <b>{subdomain}</b> has not connected to any Slack workspaces yet. Contact with your workspace admin to set
              up a Slack connection for this workspace.
            </>
          )}
        </NvTypography>
      )}

      <NvConditionalRender when={() => connectionSchema !== undefined && !isSlackConnection}>
        <ConnectionInformation connectionSchema={connectionSchema} isLoading={isConnectionSchemaLoading} />
      </NvConditionalRender>

      {response && (
        <NvFlex direction="row" justifyContent="space-between">
          <NvTypography variant="h4">Response</NvTypography>
          <ResponseSummary
            response={{
              statusCode: response.statusCode,
              durationInMillis: response.durationInMillis,
              contentLength: response.contentLength,
            }}
          ></ResponseSummary>
        </NvFlex>
      )}

      <NvConditionalRender when={() => !response}>
        {connectionSchema?.authentication?.authenticationFields?.map((authenticationField) => (
          <NvField
            key={authenticationField.key}
            name={authenticationField.key}
            labelText={authenticationField.label}
            direction="label-on-top"
            hasRequiredIndicator={authenticationField.required}
            {...(authenticationField.required && { validators: [isRequired()] })}
            defaultValue={authenticationField.defaultValue}
            labelVariant="h5"
            hint={authenticationField.helpText}
            showErrorMessageOnlyWhenBlur
            component={
              <NvTextField
                size="medium"
                placeholder={authenticationField.placeholder}
                {...(authenticationField.inputType === 'password' && { type: 'password' })}
                data-private
              />
            }
          />
        ))}
      </NvConditionalRender>

      <NvConditionalRender
        when={() =>
          !isLoading && isOAuth2Authentication(connectionSchema?.authentication) && formSubmitResult === undefined
        }
      >
        <SectionMessage
          variant="info"
          message={`You will be directed to ${connectionSchema?.name} to authorize Actioner. After successful authorization, you’ll be returning to this page.`}
        />
      </NvConditionalRender>

      <NvConditionalRender
        when={() =>
          !isLoading && isOAuth2Authentication(connectionSchema?.authentication) && formSubmitResult !== undefined
        }
      >
        <SectionMessage
          variant={formSubmitResult?.type === 'success' ? 'info' : 'error'}
          message={formSubmitResult?.message ?? 'A problem occurred while showing message'}
        />
      </NvConditionalRender>

      <NvConditionalRender
        when={() =>
          isLoading && isOAuth2Authentication(connectionSchema?.authentication) && formSubmitResult === undefined
        }
      >
        <Redirecting>
          <NvCustomSpinner
            sx={{
              color: theme.palette.nv_neutral_alpha[300],
            }}
          />
          <NvTypography variant="h5" textColor="subtle">
            Redirecting
          </NvTypography>
        </Redirecting>
      </NvConditionalRender>

      {response && <SectionMessage message={response?.message} variant="error" />}
    </NvBox>
  );
};
