import { useGetUserAppSetup } from '@novaera/actioner-service';
import {
  FieldArray,
  isRequired,
  NvAddBoxIcon,
  NvBox,
  NvButton,
  NvFlex,
  NvSkeleton,
  NvTypography,
  StepField,
  useFormState,
} from '@novaera/core';
import { FC, useMemo } from 'react';
import { DragDropList } from '../../../../components/drag-drop-list';
import { WorkflowSearchField } from '../../../../components/workflow-search-field';
import { WorkflowSearchOption } from '../../../../components/workflow-search-field/types';

import { PublishAppFormData, PublishAppMode, PublishNewVersionFormData } from '../types';

export const SetupWorkflowsStep: FC<React.PropsWithChildren<{ mode: PublishAppMode }>> = ({ mode }) => {
  const {
    values: { appDetails },
  } = useFormState<PublishAppFormData | PublishNewVersionFormData>();

  const { data: userAppSetup, isLoading: isUserAppSetupLoading } = useGetUserAppSetup({ appId: appDetails.appId });

  const initialSetupWorkflows: WorkflowSearchOption[] = useMemo(
    () =>
      userAppSetup && mode === PublishAppMode.Update && userAppSetup.setupWorkflows.length > 0
        ? userAppSetup.setupWorkflows.map(({ id, name }) => ({ id, name }))
        : [],
    [userAppSetup, mode]
  );

  return (
    <>
      <NvTypography marginBottom="8px" variant="h2">
        Setup workflows
      </NvTypography>

      <StepField
        help={{
          title: 'Setup workflows',
          description: (
            <NvFlex gap="16px">
              <NvTypography variant="body2">
                If your app requires some configuration steps, you can design <b>Setup workflow</b> for users who will
                install your app.
              </NvTypography>
              <NvTypography variant="body2">
                Check out for example setup workflows <a>here.</a>
              </NvTypography>
            </NvFlex>
          ),
        }}
      >
        <NvTypography>
          If you have any setup workflow in your app, select them from the list. Those workflow will be available to the
          users during the setup process.
        </NvTypography>
        {isUserAppSetupLoading ? (
          <NvFlex gap="16px" marginTop="16px">
            <NvSkeleton variant="rectangular" width="100%" height="32px" />
            <NvSkeleton variant="rectangular" width="100%" height="32px" />
            <NvSkeleton variant="rectangular" width="100%" height="32px" />
          </NvFlex>
        ) : (
          <FieldArray<WorkflowSearchOption> name="appDetails.setupWorkflows" initialValue={initialSetupWorkflows}>
            {({ fields }) => (
              <NvBox marginTop="16px">
                {fields.value?.length > 0 && (
                  <DragDropList
                    droppableId="setupWorkflows.droppableId"
                    listItems={fields}
                    onDragEnd={(sourceIndex, destination) => {
                      fields.move(sourceIndex, destination);
                    }}
                    onItemRemoved={fields.remove}
                    itemNode={(field, fieldName) => (
                      <WorkflowSearchField
                        appId={appDetails.appId}
                        fieldName={fieldName}
                        fieldProps={{ validators: [isRequired()] }}
                        searchWorkflowsParams={{ triggerTypes: ['form'], sort: { order: 'asc', field: 'name' } }}
                      />
                    )}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    getDraggableId={(item: any, index: number) => `draggable-workflow-item-${index}`}
                    getItemValue={(index) => fields.value[index]}
                  />
                )}

                <NvButton
                  startIcon={<NvAddBoxIcon />}
                  size="small"
                  color="secondary"
                  onClick={() => {
                    fields.push(null);
                  }}
                  sx={{ marginTop: '4px' }}
                >
                  Add workflow
                </NvButton>
              </NvBox>
            )}
          </FieldArray>
        )}
      </StepField>
    </>
  );
};
