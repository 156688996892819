import { BodyItem } from '../../item';
import { AddAuthenticationFieldModal } from './add-authentication-field-modal';
import { AddFieldButton } from './add-field-button';
import { AuthenticationFieldItems } from './authentication-field-items';
import { IntegrationConnectionAuthenticationFieldProvider } from './provider';

export const AuthenticationFields = () => {
  return (
    <BodyItem
      title="Authentication fields"
      description="Authentication fields are used to request information from users while connected. Add API key and any additional fields your app requires for authentication. Actioner does not provide any default fields."
    >
      <IntegrationConnectionAuthenticationFieldProvider>
        <AuthenticationFieldItems />
        <AddAuthenticationFieldModal />
        <AddFieldButton />
      </IntegrationConnectionAuthenticationFieldProvider>
    </BodyItem>
  );
};
