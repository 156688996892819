import { NvSlackIcon } from '@novaera/core';
import React, { useMemo } from 'react';

import { useGetSlackAuth } from './controllers/use-get-slack-auth';
import { AddToSlackWrapper } from './styled';

export const AddToSlack: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { slackAuthUrl } = useGetSlackAuth({
    isAnonymous: true,
  });

  const hasSlackAuthUrl = useMemo(() => Boolean(slackAuthUrl), [slackAuthUrl]);

  return (
    <AddToSlackWrapper
      size="large"
      color="ghost"
      startIcon={<NvSlackIcon />}
      href={slackAuthUrl}
      disabled={!hasSlackAuthUrl}
    >
      Add to Slack
    </AddToSlackWrapper>
  );
};
