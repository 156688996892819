export enum USER_PERMISSION {
  WORKSPACE_UPDATE_METADATA = 'workspace:update-metadata',
  WORKSPACE_CONNECT_SLACK = 'workspace:connect-slack',
  USER_INVITE = 'user:invite',
  USER_UNINVITE = 'user:uninvite',
  USER_CHANGE = 'user:change-role',
  CATALOG_EDIT = 'catalog:edit',
}

export enum APP_PERMISSION {
  APP_EDIT = 'app:edit',
}

export type Permissions = Partial<Record<APP_PERMISSION | USER_PERMISSION, boolean>>;
