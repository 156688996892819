import {
  useGetWorkflow,
  useResetWorkflowSourceOptions,
  WorkflowReferenceElement,
  WorkflowWithState,
} from '@novaera/actioner-service';
import { assert } from '@novaera/utils';
import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { InputFormValues } from '../../../../../../action-designer/providers/input-values';
import { useGetWorkflowQueryParams } from '../../controllers/use-get-workflow-query-params';
import { OnExecutionFinishedCallback, WorkflowOutputRefRunImmediatelyParams } from './types';

type DispatchReferenceOutputItem = (workflowReference: WorkflowReferenceElement) => void;

const WorkflowReferenceOutputProviderContext = createContext<
  | {
      workflow?: WorkflowWithState;
      dispatchReferenceOutputItem: DispatchReferenceOutputItem;
      onBackClicked: (currentFormOrder: number) => void;
      updateFormValues: (param: { values: InputFormValues }) => void;
      resetWorkflowReferenceOutputs: () => void;
      onExecutionRunFinished: OnExecutionFinishedCallback;
      isLoading?: boolean;
    }
  | undefined
>(undefined);

export const WorkflowReferenceOutputProvider = ({
  children,
}: {
  children: (params: {
    workflow?: WorkflowWithState;
    initialFormValues?: InputFormValues;
    currentPage: number;
    workflowOutputRefParams?: WorkflowOutputRefRunImmediatelyParams;
    executeRef?: React.Ref<(values: InputFormValues) => Promise<void>>;
    isLoading?: boolean;
  }) => ReactNode;
}) => {
  const { workflowId: initialWorkflowId, userAppId } = useGetWorkflowQueryParams();
  const { removeWorkflowSourceOptionsCacheForApp } = useResetWorkflowSourceOptions();
  const [formValues, setFormValues] = useState<Record<string, InputFormValues | undefined>>();

  const [workflowOutputConfig, setWorkflowOutputConfig] = useState<
    {
      page: number;
      workflowId: string;
      workflowOutputRefParams?: WorkflowOutputRefRunImmediatelyParams;
    }[]
  >(() => {
    return [
      {
        page: 0,
        workflowId: initialWorkflowId,
      },
    ];
  });

  const workflowId = useMemo(() => {
    return workflowOutputConfig[workflowOutputConfig.length - 1]?.workflowId ?? initialWorkflowId;
  }, [workflowOutputConfig, initialWorkflowId]);

  const { workflow, isLoading } = useGetWorkflow({ workflowId, appId: userAppId });

  const handleOnDispatchReferenceOutputItem: DispatchReferenceOutputItem = (
    workflowReference: WorkflowReferenceElement
  ) => {
    assert(workflowReference.type === 'static', new Error('Workflow reference type should be static.'), 'ERROR');

    const formValue: Record<string, InputFormValues> = {
      [workflowReference.workflowId]: {},
    };
    workflowReference.parameterMappings?.forEach((parameterMapping) => {
      formValue[workflowReference.workflowId] = {
        ...formValue[workflowReference.workflowId],
        [parameterMapping.parameterId]: parameterMapping,
      };
    });
    setFormValues(formValue);
    setWorkflowOutputConfig((preWorkflowOutputConfig) => [
      ...preWorkflowOutputConfig,
      {
        page: preWorkflowOutputConfig.length,
        workflowId: workflowReference.workflowId,
        workflowOutputConfig: {
          workflowId: workflowReference.workflowId,
          runImmediately: workflowReference.executeInPlace ?? false,
          formValues: formValue[workflowReference.workflowId],
        },
      },
    ]);

    removeWorkflowSourceOptionsCacheForApp({ appId: userAppId });
  };

  const getCurrentPage = useCallback(() => {
    return workflowOutputConfig.length;
  }, [workflowOutputConfig.length]);

  const handleBackClicked = useCallback(() => {
    workflowOutputConfig.pop();
    const result = workflowOutputConfig[workflowOutputConfig.length - 1];
    if (result) {
      setWorkflowOutputConfig([...workflowOutputConfig]);
    }

    if (workflowOutputConfig.length >= 2) {
      // set workflowOutputConfig with the last element removed
      setWorkflowOutputConfig(workflowOutputConfig.slice(0, -1));
    }
  }, [workflowOutputConfig]);

  const handleUpdateFormValues = useCallback(
    ({ values }: { values: InputFormValues }) => {
      setFormValues({ [workflowId]: values });
    },
    [workflowId]
  );

  const handleCleanWorkflowReferenceOutputs = useCallback(() => {
    setFormValues({});
    setWorkflowOutputConfig([]);
  }, []);

  const handleExecutionFinished: OnExecutionFinishedCallback = useCallback(({ executionIdentifier, workflowId }) => {
    if (!executionIdentifier) {
      return;
    }
    setWorkflowOutputConfig((preWorkflowOutputConfig) => {
      return preWorkflowOutputConfig.map((config, index) => {
        if (index === preWorkflowOutputConfig.length - 1) {
          return {
            ...config,
            workflowOutputRefParams: {
              workflowId: config.workflowOutputRefParams?.workflowId as string,
              runImmediately: config.workflowOutputRefParams?.runImmediately ?? false,
              formValues: config.workflowOutputRefParams?.formValues,
              identifier: executionIdentifier,
            },
          };
        } else {
          return config;
        }
      });
    });
  }, []);

  return (
    <WorkflowReferenceOutputProviderContext.Provider
      value={{
        workflow,
        dispatchReferenceOutputItem: handleOnDispatchReferenceOutputItem,
        onBackClicked: handleBackClicked,
        updateFormValues: handleUpdateFormValues,
        resetWorkflowReferenceOutputs: handleCleanWorkflowReferenceOutputs,
        onExecutionRunFinished: handleExecutionFinished,
        isLoading,
      }}
    >
      {children({
        workflow,
        initialFormValues: formValues?.[workflowId],
        currentPage: getCurrentPage(),
        workflowOutputRefParams: workflowOutputConfig[workflowOutputConfig.length - 1]?.workflowOutputRefParams,
        isLoading,
      })}
    </WorkflowReferenceOutputProviderContext.Provider>
  );
};

export const useWorkflowReferenceOutputProvider = () => {
  const context = useContext(WorkflowReferenceOutputProviderContext);
  assert(
    !!context,
    new Error(`useWorkflowReferenceOutputProvider should be used within WorkflowReferenceOutputProvider`),
    'ERROR'
  );

  return context;
};
