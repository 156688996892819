import { ChartOutput } from '../chart-output';
import { KeyValueTableOutput } from '../key-value-table-output';
import { MarkdownOutput } from '../markdown-output';
import { TableOutput } from '../table-output';

export const TAB_CONTENT = {
  table: TableOutput,
  'key-value-table': KeyValueTableOutput,
  markdown: MarkdownOutput,
  chart: {
    bar: ChartOutput,
    pie: ChartOutput,
  },
};
