import {
  GetWorkflowSourceOptionsResponse,
  InputParameter,
  useGetWorkflowSourceOptions,
} from '@novaera/actioner-service';
import { UseQueryResult } from '@tanstack/react-query';
import { PropsWithChildren, useMemo } from 'react';
import { useFormState } from 'react-final-form';
import { InputFormValues } from '../../../../../../../../action-designer/providers/input-values';
import { useSearchAsYouTypeValuesContext } from '../../../../../../../../action-designer/providers/search-as-you-type-values';
import { useFormParameterProvider } from '../../../../providers/form-parameter-provider';
import { FormTriggerWorkflowRun } from './body';
import { useRunWorkflowFormBody } from './controllers/use-run-workflow-body';

export const RunWorkflowFormTriggerBody: React.FC<
  PropsWithChildren<{
    workflowId: string;
    workflowInputParameters: InputParameter[];
    showHintText?: boolean;
  }>
> = ({ workflowId, workflowInputParameters, showHintText }) => {
  const { isDraft, userAppId } = useRunWorkflowFormBody({ workflowId });

  const { enabledInputParameterIdToFetchOptions, formId } = useFormParameterProvider();

  const { searchAsYouTypeValues } = useSearchAsYouTypeValuesContext();

  const { values } = useFormState<InputFormValues>();

  const workflowSourceOptions = useMemo(() => {
    return {
      requestParams: {
        appId: userAppId,
        workflowId,
        formId,
        payload: {
          draft: isDraft,
          inputParameterIds: enabledInputParameterIdToFetchOptions,
          context: {},
          inputParameters: Object.values(values),
          searchAsYouTypeValues,
        },
      },
      options: { keepPreviousData: true, staleTime: Infinity },
      enabledInputParameters: enabledInputParameterIdToFetchOptions ?? [],
      allInputParameterIdsWithOrder: workflowInputParameters.map((inputParameter) => inputParameter.id),
      calculateInputParameters: () => Object.values(values),
    };
  }, [
    userAppId,
    workflowId,
    formId,
    isDraft,
    enabledInputParameterIdToFetchOptions,
    values,
    searchAsYouTypeValues,
    workflowInputParameters,
  ]);

  const results = useGetWorkflowSourceOptions(workflowSourceOptions);

  const extendedResults = useMemo(() => {
    if (!enabledInputParameterIdToFetchOptions || enabledInputParameterIdToFetchOptions?.length === 0 || !results) {
      return;
    }

    const result: (UseQueryResult<GetWorkflowSourceOptionsResponse, unknown> & { inputParameterId: string })[] =
      results?.map((result, index) => {
        return {
          ...result,
          inputParameterId: enabledInputParameterIdToFetchOptions[index],
        };
      });
    return result;
  }, [enabledInputParameterIdToFetchOptions, results]);

  return (
    <FormTriggerWorkflowRun
      inputParameters={workflowInputParameters}
      showHintText={showHintText}
      results={extendedResults}
    />
  );
};
