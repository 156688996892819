import {
  Initial,
  Markdown,
  NvArrowBackIcon,
  NvButton,
  NvChip,
  NvCloudUploadIcon,
  NvDivider,
  NvDownloadIcon,
  NvFlex,
  NvFocusState,
  NvImage,
  NvLink,
  NvMenuWithItems,
  NvMoreHorizIcon,
  NvSkeleton,
  NvStarIcon,
  NvTypography,
} from '@novaera/core';
import { Link } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import { assert } from '@novaera/utils';
import classnames from 'classnames';
import { isNull, isUndefined } from 'lodash';
import { ROUTES } from '../../common/routes';
import { Contributor } from '../app-directory-list/app-directory-card/contributors/contributor';
import { PublishAppStepTracer } from '../app-directory-list/publish-your-app';
import { SelectedAppIdProvider } from '../app-directory-list/publish-your-app/providers/selected-app-id';
import { PublishAppMode } from '../app-directory-list/publish-your-app/types';
import { COMING_SOON_APP_TAG } from '../constants';
import { AppProperties } from './app-properties';
import { AppVersionHistoryModal } from './app-version-history-modal';
import { useAppDirectoryDetail } from './controller/use-app-directory-detail';
import { FeaturedSection } from './featured-section';
import {
  AnonymousStarBox,
  AppDirectoryDetailBody,
  AppDirectoryDetailBodyContainer,
  AppDirectoryDetailBodyInner,
  AppDirectoryDetailBodyLeft,
  AppDirectoryDetailBodyRight,
  AppDirectoryDetailCarousel,
  AppDirectoryDetailHeader,
  AppDirectoryDetailHeaderInner,
  AppDirectoryDetailHeaderWrapper,
  AppDirectoryDetailTabs,
  AppTitle,
  AppVersion,
  ComingSoonWrapper,
  InstallWrapper,
} from './styled';
import { VersionPublishedTime } from './version-published-time';

export const AppDirectoryDetail: React.FC<
  React.PropsWithChildren<{
    isAnonymous: boolean;
    appDirectoryContainerRef: React.MutableRefObject<HTMLDivElement | null>;
  }>
> = ({ isAnonymous, appDirectoryContainerRef }) => {
  const {
    activeAppSchema,
    isActiveAppSchemaLoading,
    onVersionClick,
    isVersionHistoryModalOpen,
    onVersionHistoryModalClose,
    onToggleSchemaStar,
    isUpdateSchemaStarLoading,
    onInstallClick,
    isOwner,
    setIsFocusStateOpen,
    isFocusStateOpen,
    appLogoInitialColor,
    onDeleteActionPack,
    isInstallAppLoading,
    handleSignInToInstall,
    activeAppSchemaSupportLinks,
    activeAppSchemaUseCasesLinks,
    carouselItems,
    appDirectoryDetailTabs,
    handleActiveTabChange,
    appDirectoryDetailHeaderRef,
    workflowsSectionRef,
    isMobile,
    focusIndex,
  } = useAppDirectoryDetail({ isAnonymous, appDirectoryContainerRef });

  const { palette } = useTheme();

  assert(
    isUndefined(activeAppSchema) || isNull(activeAppSchema) || Number(activeAppSchema?.numberOfStars) >= 0,
    new Error(
      `For App ${activeAppSchema?.name} number of star has a negative value: ${activeAppSchema?.numberOfStars}`
    ),
    'ERROR'
  );

  return (
    <NvFlex width="100%" height="auto">
      <AppDirectoryDetailHeaderWrapper isPublic={isAnonymous}>
        <AppDirectoryDetailHeader ref={appDirectoryDetailHeaderRef}>
          {activeAppSchema && <FeaturedSection appSchema={activeAppSchema} />}
          <NvFlex width="100%">
            <AppDirectoryDetailHeaderInner>
              {!isActiveAppSchemaLoading && activeAppSchema ? (
                <>
                  {isMobile && (
                    <NvButton
                      component={Link}
                      to={ROUTES.AppDirectory}
                      startIcon={<NvArrowBackIcon />}
                      color="secondary"
                      size="small"
                    >
                      Back
                    </NvButton>
                  )}
                  <AppTitle>
                    {!isMobile && (
                      <NvFlex direction="row" alignItems="center" gap="4px" padding="4px 0" flex="0 0 auto">
                        <NvButton
                          component={Link}
                          to={ROUTES.AppDirectory}
                          startIcon={<NvArrowBackIcon />}
                          color="ghost"
                          size="small"
                        >
                          Back
                        </NvButton>
                        <NvDivider orientation="vertical" sx={{ height: '12px' }} />
                      </NvFlex>
                    )}

                    <NvImage
                      imageShape="square"
                      size="medium"
                      src={activeAppSchema.logoUrl}
                      FallBack={<Initial size="medium" color={appLogoInitialColor} value={activeAppSchema.name} />}
                    />
                    <NvFlex gap="6px">
                      <NvFlex direction="row" alignItems="center" gap="12px">
                        <NvTypography variant="h1">{activeAppSchema.name}</NvTypography>
                      </NvFlex>
                    </NvFlex>
                  </AppTitle>

                  <NvFlex direction={isMobile ? 'row-reverse' : 'row'} gap="8px" alignItems="center" flex="0 0 auto">
                    <NvTypography variant="h5">V {activeAppSchema.version}</NvTypography>
                    <NvDivider orientation="vertical" sx={{ height: '8px' }} />
                    {!isAnonymous ? (
                      <NvButton
                        color={activeAppSchema.starred ? 'info' : 'secondary'}
                        startIcon={<NvStarIcon />}
                        size="medium"
                        onlyIcon={Number(activeAppSchema.numberOfStars) === 0}
                        onClick={() => onToggleSchemaStar(activeAppSchema.starred)}
                        loading={isUpdateSchemaStarLoading}
                      >
                        {Number(activeAppSchema.numberOfStars) <= 0 ? <NvStarIcon /> : activeAppSchema.numberOfStars}
                      </NvButton>
                    ) : (
                      <AnonymousStarBox>
                        <NvStarIcon htmlColor={palette.nv_new[60]} />
                        <NvTypography variant="h4" textColor="secondary">
                          {activeAppSchema.numberOfStars}
                        </NvTypography>
                      </AnonymousStarBox>
                    )}
                    <InstallWrapper
                      className={classnames({
                        'UG-app-install-button': true,
                      })}
                    >
                      {!isAnonymous ? (
                        activeAppSchema.tags.find((t) => t.label === COMING_SOON_APP_TAG[0].label) ? (
                          <ComingSoonWrapper>
                            <NvTypography variant="h4" textColor="subtle">
                              Coming soon
                            </NvTypography>
                          </ComingSoonWrapper>
                        ) : (
                          <NvButton
                            startIcon={<NvDownloadIcon />}
                            size="medium"
                            loading={isInstallAppLoading}
                            onClick={onInstallClick}
                            data-testid="app-directory-install"
                          >
                            Install
                          </NvButton>
                        )
                      ) : (
                        <NvButton
                          size="medium"
                          onClick={handleSignInToInstall}
                          data-testid="app-directory-sign-in-to-install"
                        >
                          Sign in to install
                        </NvButton>
                      )}
                    </InstallWrapper>
                  </NvFlex>
                </>
              ) : (
                <>
                  {isMobile && <NvSkeleton height="24px" width="70px" variant="rectangular" />}
                  <NvFlex direction="row" gap="12px" alignItems="center" flex="1 1 auto" minWidth={0}>
                    {!isMobile && (
                      <NvFlex direction="row" alignItems="center" gap="4px" flex="0 0 auto">
                        <NvSkeleton height="24px" width="70px" variant="rectangular" />
                        <NvDivider orientation="vertical" sx={{ height: '12px' }} />
                      </NvFlex>
                    )}
                    <NvSkeleton height="32px" width="32px" variant="rectangular" />
                    <NvSkeleton height="24px" width="90px" variant="rectangular" />
                  </NvFlex>
                  <NvFlex direction={isMobile ? 'row-reverse' : 'row'} gap="8px" alignItems="center" flex="0 0 auto">
                    <NvSkeleton height="16px" width="40px" variant="rectangular" />
                    <NvDivider orientation="vertical" sx={{ height: '8px' }} />
                    <NvSkeleton height="32px" width="40px" variant="rectangular" />
                    <NvSkeleton height="32px" width="120px" variant="rectangular" />
                  </NvFlex>
                </>
              )}
            </AppDirectoryDetailHeaderInner>
            {!isMobile && (
              <AppDirectoryDetailTabs
                tabs={appDirectoryDetailTabs}
                focusIndex={focusIndex}
                onActiveTabChanged={handleActiveTabChange}
              />
            )}
          </NvFlex>
        </AppDirectoryDetailHeader>
      </AppDirectoryDetailHeaderWrapper>
      <AppDirectoryDetailBodyContainer className={`app-directory-detail-body ${!isAnonymous ? 'app' : 'public'}`}>
        {!isActiveAppSchemaLoading && activeAppSchema ? (
          <AppDirectoryDetailBody>
            <NvFlex width="100%" maxWidth="1090px" margin="0 auto">
              <AppDirectoryDetailBodyInner>
                <AppDirectoryDetailBodyLeft>
                  {carouselItems && carouselItems.length > 0 && (
                    <AppDirectoryDetailCarousel title={activeAppSchema.name} items={carouselItems} />
                  )}
                  {activeAppSchema.description && (
                    <NvFlex spacing="8px" maxWidth="600px">
                      <Markdown>{activeAppSchema.description}</Markdown>
                    </NvFlex>
                  )}

                  <AppProperties ref={workflowsSectionRef} items={activeAppSchema.workflows ?? []} title="Workflows" />
                </AppDirectoryDetailBodyLeft>
                <AppDirectoryDetailBodyRight isPublic={isAnonymous}>
                  {isOwner && (
                    <NvFlex direction="row" spacing="12px">
                      <NvButton
                        color="secondary"
                        fullWidth
                        onClick={() => {
                          setIsFocusStateOpen(true);
                        }}
                      >
                        Publish new version
                      </NvButton>
                      <NvMenuWithItems
                        triggerButton={{
                          content: <NvMoreHorizIcon />,
                          props: { onlyIcon: true, size: 'medium', color: 'secondary' },
                        }}
                        menuItems={[
                          {
                            name: 'Delete app from app directory',
                            onClick: () => {
                              onDeleteActionPack(activeAppSchema.id);
                            },
                          },
                        ]}
                      />
                    </NvFlex>
                  )}

                  {activeAppSchema.contributors.length > 0 && (
                    <NvFlex spacing="8px">
                      <NvTypography variant="h4">Contributors</NvTypography>
                      {activeAppSchema.contributors.map((contributor, index) => (
                        <Contributor key={`${contributor.id}-${index}`} contributor={contributor} />
                      ))}
                    </NvFlex>
                  )}
                  {activeAppSchema.categories && activeAppSchema.categories.length > 0 && (
                    <NvFlex spacing="8px" alignItems="flex-start">
                      <NvTypography variant="h4">Categories</NvTypography>
                      <NvFlex direction="row" gap="4px" flexWrap="wrap">
                        {activeAppSchema.categories.map((category, index) => (
                          <NvChip key={`app-schema-category-${index}`} label={category} compact />
                        ))}
                      </NvFlex>
                    </NvFlex>
                  )}
                  {activeAppSchemaSupportLinks && activeAppSchemaSupportLinks.length > 0 && (
                    <NvFlex spacing="8px" alignItems="flex-start">
                      <NvTypography variant="h4">Learn more & Support</NvTypography>
                      {activeAppSchemaSupportLinks.map(({ url, displayText }, index) => (
                        <NvLink key={`activeAppSchemaSupportLinks-${index}`} href={url} target="_blank">
                          {displayText}
                        </NvLink>
                      ))}
                    </NvFlex>
                  )}
                  {activeAppSchemaUseCasesLinks && activeAppSchemaUseCasesLinks.length > 0 && (
                    <NvFlex spacing="8px" alignItems="flex-start">
                      <NvTypography variant="h4">Use cases</NvTypography>
                      {activeAppSchemaUseCasesLinks.map(({ url, displayText }, index) => (
                        <NvLink key={`activeAppSchemaUseCasesLinks-${index}`} href={url} target="_blank">
                          {displayText}
                        </NvLink>
                      ))}
                    </NvFlex>
                  )}
                  <NvDivider sx={{ marginTop: '-16px', marginBottom: '-16px' }} />
                  <>
                    <AppVersion>
                      <NvFlex direction="row" width="100%">
                        <NvTypography variant="h4" flex="1 1 auto">
                          v{activeAppSchema.version}
                        </NvTypography>
                        <VersionPublishedTime publishedAt={activeAppSchema.publishedAt} />
                      </NvFlex>
                      {activeAppSchema.changeLog && (
                        <>
                          <NvTypography variant="body2" textColor="subtle">
                            {activeAppSchema.changeLog}
                          </NvTypography>
                          <NvButton sx={{ marginTop: '4px' }} color="secondary" size="small" onClick={onVersionClick}>
                            Version history
                          </NvButton>
                        </>
                      )}
                    </AppVersion>
                    <AppVersionHistoryModal
                      isOpen={isVersionHistoryModalOpen}
                      onClose={onVersionHistoryModalClose}
                      schemaId={activeAppSchema.id}
                    />
                  </>
                </AppDirectoryDetailBodyRight>
              </AppDirectoryDetailBodyInner>

              <NvFocusState
                open={isFocusStateOpen}
                headerLogo={<NvCloudUploadIcon fontSize="large" />}
                title="Update your app"
                onClose={() => {
                  setIsFocusStateOpen(false);
                }}
              >
                <SelectedAppIdProvider>
                  <PublishAppStepTracer
                    mode={PublishAppMode.Update}
                    onCancel={() => {
                      setIsFocusStateOpen(false);
                    }}
                  />
                </SelectedAppIdProvider>
              </NvFocusState>
            </NvFlex>
          </AppDirectoryDetailBody>
        ) : (
          <AppDirectoryDetailBody>
            <NvFlex width="100%" maxWidth="1090px" margin="0 auto">
              <AppDirectoryDetailBodyInner>
                <AppDirectoryDetailBodyLeft>
                  <NvFlex width="100%">
                    <NvFlex>
                      <NvSkeleton height="40px" width="100%" />
                      <NvSkeleton height="40px" width="100%" />
                    </NvFlex>
                    <NvFlex>
                      <NvSkeleton height="40px" width="40%" />
                      <NvSkeleton height="40px" width="80%" />
                    </NvFlex>
                  </NvFlex>
                </AppDirectoryDetailBodyLeft>
                <AppDirectoryDetailBodyRight>
                  <NvFlex width="70%">
                    <NvFlex>
                      <NvSkeleton height="40px" />
                    </NvFlex>
                    <NvFlex>
                      <NvSkeleton height="40px" width="60%" />
                      <NvSkeleton height="40px" width="80%" />
                    </NvFlex>
                  </NvFlex>
                </AppDirectoryDetailBodyRight>
              </AppDirectoryDetailBodyInner>
            </NvFlex>
          </AppDirectoryDetailBody>
        )}
      </AppDirectoryDetailBodyContainer>
    </NvFlex>
  );
};
