import { FileNodeSummary, NodeUnionTypeEnumLike, isFileNodeSummary, useGetWorkflow } from '@novaera/actioner-service';
import { useParams } from '@novaera/route';
import { assert } from '@novaera/utils';
import { useCallback, useMemo } from 'react';
import { useReactFlow } from 'reactflow';
import { FormIdentifierProvider } from '../../../../../../providers/form-identifier-provider';
import { usePropertyPanelContext } from '../provider';
import { ActionNodePropertyPanelDrawer } from './action-node-property-panel-drawer';
import { ActionerChangeAccessibilityFileNodePropertyPanelDrawer } from './actioner-change-accessibility-file-property-panel-drawer';
import { ActionerDeleteFileNodePropertyPanelDrawer } from './actioner-delete-file-property-panel-drawer';
import { ActionerEventNodePropertyPanelDrawer } from './actioner-event-node-property-panel-drawer';
import { ActionerEventTriggerPropertyPanelDrawer } from './actioner-event-trigger-property-panel-drawer';
import { ActionerListFileNodePropertyPanelDrawer } from './actioner-list-file-property-panel-drawer';
import { ActionerPutFileNodePropertyPanelDrawer } from './actioner-put-file-property-panel-drawer';
import { ActionerTransferFileNodePropertyPanelDrawer } from './actioner-transfer-file-property-panel-drawer';
import { AssistantPropertyPanelDrawer } from './assistant-property-panel-drawer';
import { BranchOperatorPropertyPanelDrawer } from './branch-property-panel-drawer';
import { DelayOperatorPropertyPanelDrawer } from './delay-property-panel-drawer';
import { EmailTriggerPropertyPanelDrawer } from './email-trigger-property-panel-drawer';
import { FormTriggerPropertyPanelDrawer } from './form-trigger-property-panel-drawer';
import { FunctionPropertyPanelDrawer } from './function-property-panel-drawer';
import { GetConversationPropertyPanelDrawer } from './get-conversation-property-panel-drawer';
import { IntegrationTriggerPropertyPanelDrawer } from './integration-trigger-property-panel-drawer';
import { JobPropertyPanelDrawer } from './job-property-panel-drawer';
import { LinkGeneratorPropertyPanelDrawer } from './link-generator';
import { LoopOperatorPropertyPanelDrawer } from './loop-operator-panel-drawer';
import { OutputPropertyPanelDrawer } from './output-property-panel-drawer';
import { QAPropertyPanelDrawer } from './qa-property-panel-drawer';
import { ResponsePropertyPanelDrawer } from './response-property-panel-drawer';
import { SendEmailPropertyPanelDrawer } from './send-email-property-panel-drawer';
import { WebhookTriggerPropertyPanelDrawer } from './webhook-trigger-property-panel-drawer';
import { WorkflowConditionPropertyPanelDrawer } from './workflow-condition-panel-drawer';
import { WorkflowDispatcherPropertyPanelDrawer } from './workflow-dispatcher-property-panel-drawer';
import { WorkflowResolverPropertyPanelDrawer } from './workflow-resolver-property-panel-drawer';

export const PropertyPanels: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { userAppId, workflowId } = useParams();
  const { workflow, savedWorkflow, isLoading } = useGetWorkflow({ appId: userAppId, workflowId });
  const { setNodes } = useReactFlow();
  const { emptySelectedNode, selectedNode } = usePropertyPanelContext();
  const handleClose = useCallback(() => {
    emptySelectedNode();
    setNodes((nodes) => nodes.map((n) => ({ ...n, selected: false })));
  }, [emptySelectedNode, setNodes]);

  const drawerType = useMemo(() => {
    if (!workflow) {
      return;
    }
    if (!selectedNode) {
      return;
    }

    if (
      (selectedNode.type === NodeUnionTypeEnumLike.integrationWebhook ||
        selectedNode.type === NodeUnionTypeEnumLike.integrationApp ||
        selectedNode.type === NodeUnionTypeEnumLike.genericWebhook ||
        selectedNode.type === NodeUnionTypeEnumLike.email ||
        selectedNode.type === NodeUnionTypeEnumLike.form) &&
      !workflow.trigger
      // if type of node is trigger but trigger of workflow is not defined.
    ) {
      return;
    }

    if (
      selectedNode.type === NodeUnionTypeEnumLike.integrationWebhook ||
      selectedNode.type === NodeUnionTypeEnumLike.integrationApp
    ) {
      return 'integration-trigger-panel';
    }

    if (selectedNode.type === NodeUnionTypeEnumLike.genericWebhook) {
      return 'webhook-trigger-panel';
    }

    if (selectedNode.type === NodeUnionTypeEnumLike.email) {
      return 'email-trigger-panel';
    }

    if (selectedNode.type === NodeUnionTypeEnumLike.actionerEvent) {
      return 'actioner-event-trigger-panel';
    }

    if (selectedNode.type === NodeUnionTypeEnumLike.actionerEventPublisher) {
      return 'actioner-event-node-panel';
    }

    if (selectedNode.type === NodeUnionTypeEnumLike.form) {
      return 'form-panel';
    }

    if (selectedNode.type === NodeUnionTypeEnumLike.workflowCondition) {
      return 'workflow-condition';
    }

    if (selectedNode.type === NodeUnionTypeEnumLike.response) {
      return 'response-panel';
    }

    if (selectedNode.type === NodeUnionTypeEnumLike.loop) {
      return 'loop-panel';
    }

    if (selectedNode.type === NodeUnionTypeEnumLike.branchJunction) {
      return 'branch-junction-panel';
    }

    if (selectedNode.type === NodeUnionTypeEnumLike.action) {
      return 'action-panel';
    }

    if (selectedNode.type === NodeUnionTypeEnumLike.function) {
      return 'function-panel';
    }

    if (selectedNode.type === NodeUnionTypeEnumLike.delay) {
      return 'delay-panel';
    }

    if (selectedNode.type === 'output') {
      return 'output';
    }

    if (selectedNode.type === NodeUnionTypeEnumLike.workflowDispatcher) {
      return 'workflow-dispatcher';
    }

    if (selectedNode.type === NodeUnionTypeEnumLike.workflowResolver) {
      return 'workflow-resolver';
    }

    if (selectedNode.type === NodeUnionTypeEnumLike.aiKnowledge) {
      return 'QA';
    }

    if (selectedNode.type === NodeUnionTypeEnumLike.assistant) {
      return 'assistant';
    }

    if (selectedNode.type === NodeUnionTypeEnumLike.sendEmail) {
      return 'send-email';
    }
    if (selectedNode.type === NodeUnionTypeEnumLike.file) {
      assert(
        !!workflow?.nodeSummaries[selectedNode.alias] && isFileNodeSummary(workflow?.nodeSummaries[selectedNode.alias]),
        new Error('node summary should not be undefined'),
        'ERROR'
      );
      const nodeSummary = workflow?.nodeSummaries[selectedNode.alias] as FileNodeSummary;

      assert(
        !!nodeSummary.nodeOperation,
        new Error('for file node, nodeOperation property must be set in node summary'),
        'ERROR'
      );

      if (nodeSummary.nodeOperation.type === 'put') {
        return 'put-file';
      } else if (nodeSummary.nodeOperation.type === 'change-accessibility') {
        return 'change-accessibility-file';
      } else if (nodeSummary.nodeOperation.type === 'delete') {
        return 'delete-file';
      } else if (nodeSummary.nodeOperation.type === 'list') {
        return 'list-file';
      } else if (nodeSummary.nodeOperation.type === 'transfer') {
        return 'transfer-file';
      }
    }

    if (selectedNode.type === NodeUnionTypeEnumLike.job) {
      return 'job';
    }

    if (selectedNode.type === NodeUnionTypeEnumLike.linkGenerator) {
      return 'link-generator';
    }

    if (selectedNode.type === NodeUnionTypeEnumLike.getConversation) {
      return 'get-conversation';
    }

    return;
  }, [selectedNode, workflow]);

  return isLoading ? (
    <>loading</>
  ) : (
    <>
      {workflow && drawerType === 'integration-trigger-panel' && (
        <IntegrationTriggerPropertyPanelDrawer
          workflow={workflow}
          savedWorkflow={savedWorkflow}
          handleClose={handleClose}
        />
      )}
      {workflow && drawerType === 'webhook-trigger-panel' && (
        <WebhookTriggerPropertyPanelDrawer
          workflow={workflow}
          savedWorkflow={savedWorkflow}
          handleClose={handleClose}
        />
      )}
      {workflow && drawerType === 'email-trigger-panel' && (
        <EmailTriggerPropertyPanelDrawer workflow={workflow} handleClose={handleClose} key={selectedNode?.alias} />
      )}
      {workflow && drawerType === 'actioner-event-trigger-panel' && (
        <ActionerEventTriggerPropertyPanelDrawer
          workflow={workflow}
          handleClose={handleClose}
          key={selectedNode?.alias}
        />
      )}
      {workflow && drawerType === 'actioner-event-node-panel' && (
        <ActionerEventNodePropertyPanelDrawer handleClose={handleClose} key={selectedNode?.alias} />
      )}
      {drawerType === 'workflow-condition' && (
        <WorkflowConditionPropertyPanelDrawer handleClose={handleClose} key={selectedNode?.alias} />
      )}
      {drawerType === 'loop-panel' && (
        <LoopOperatorPropertyPanelDrawer handleClose={handleClose} key={selectedNode?.alias} />
      )}
      {drawerType === 'branch-junction-panel' && workflow && (
        <BranchOperatorPropertyPanelDrawer workflow={workflow} handleClose={handleClose} key={selectedNode?.alias} />
      )}
      {drawerType === 'action-panel' && workflow && (
        <FormIdentifierProvider>
          <ActionNodePropertyPanelDrawer workflow={workflow} handleClose={handleClose} key={selectedNode?.alias} />
        </FormIdentifierProvider>
      )}
      {workflow && workflow.trigger?.type === 'form' && drawerType === 'form-panel' && (
        <FormTriggerPropertyPanelDrawer handleClose={handleClose} key={selectedNode?.alias} workflow={workflow} />
      )}
      {drawerType === 'function-panel' && (
        <FunctionPropertyPanelDrawer handleClose={handleClose} key={selectedNode?.alias} />
      )}
      {drawerType === 'delay-panel' && (
        <DelayOperatorPropertyPanelDrawer handleClose={handleClose} key={selectedNode?.alias} />
      )}
      {drawerType === 'response-panel' && (
        <ResponsePropertyPanelDrawer handleClose={handleClose} key={selectedNode?.alias} />
      )}
      {drawerType === 'output' && <OutputPropertyPanelDrawer handleClose={handleClose} key={selectedNode?.alias} />}
      {drawerType === 'job' && <JobPropertyPanelDrawer handleClose={handleClose} key={selectedNode?.alias} />}
      {drawerType === 'workflow-dispatcher' && (
        <WorkflowDispatcherPropertyPanelDrawer handleClose={handleClose} key={selectedNode?.alias} />
      )}
      {drawerType === 'send-email' && (
        <SendEmailPropertyPanelDrawer handleClose={handleClose} key={selectedNode?.alias} />
      )}
      {drawerType === 'workflow-resolver' && (
        <WorkflowResolverPropertyPanelDrawer handleClose={handleClose} key={selectedNode?.alias} />
      )}
      {drawerType === 'QA' && <QAPropertyPanelDrawer handleClose={handleClose} key={selectedNode?.alias} />}

      {drawerType === 'assistant' && (
        <AssistantPropertyPanelDrawer handleClose={handleClose} key={selectedNode?.alias} />
      )}

      {drawerType === 'get-conversation' && (
        <GetConversationPropertyPanelDrawer handleClose={handleClose} key={selectedNode?.alias} />
      )}

      {drawerType === 'put-file' && (
        <ActionerPutFileNodePropertyPanelDrawer handleClose={handleClose} key={selectedNode?.alias} />
      )}

      {drawerType === 'list-file' && (
        <ActionerListFileNodePropertyPanelDrawer handleClose={handleClose} key={selectedNode?.alias} />
      )}
      {drawerType === 'delete-file' && (
        <ActionerDeleteFileNodePropertyPanelDrawer handleClose={handleClose} key={selectedNode?.alias} />
      )}
      {drawerType === 'transfer-file' && (
        <ActionerTransferFileNodePropertyPanelDrawer handleClose={handleClose} key={selectedNode?.alias} />
      )}
      {drawerType === 'change-accessibility-file' && (
        <ActionerChangeAccessibilityFileNodePropertyPanelDrawer handleClose={handleClose} key={selectedNode?.alias} />
      )}

      {drawerType === 'link-generator' && (
        <LinkGeneratorPropertyPanelDrawer handleClose={handleClose} key={selectedNode?.alias} />
      )}
    </>
  );
};
