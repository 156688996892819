import { NvDivider } from '@novaera/core';
import { FormIdentifierProvider } from '../../../../../providers/form-identifier-provider';
import { WorkflowReferenceOutputProvider } from '../providers/workflow-reference-output-provider';
import { IntegrationUpdate } from './integration-update';
import { StickyPanel, WorkflowStickyPanelContainer } from './styled';
import { WorkflowPermissions } from './workflow-permissions';
import { WorkflowRun } from './workflow-run';

export const WorkflowStickyPanel = ({
  isSaveProgress,
  isManaged,
}: {
  isSaveProgress: boolean;
  isManaged?: boolean;
}) => {
  return (
    <StickyPanel className="react-flow__panel">
      {!isManaged && <IntegrationUpdate />}
      <WorkflowStickyPanelContainer>
        <WorkflowPermissions />
        <NvDivider orientation="vertical" sx={{ height: '12px' }} />
        <FormIdentifierProvider>
          <WorkflowReferenceOutputProvider>
            {({ workflow, currentPage, workflowOutputRefParams, initialFormValues }) => {
              return (
                <WorkflowRun
                  currentPage={currentPage}
                  workflow={workflow}
                  workflowOutputRefParams={workflowOutputRefParams}
                  initialFormValues={initialFormValues}
                  isSaveProgress={isSaveProgress}
                />
              );
            }}
          </WorkflowReferenceOutputProvider>
        </FormIdentifierProvider>
      </WorkflowStickyPanelContainer>
    </StickyPanel>
  );
};
