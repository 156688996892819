import {
  AppDetailSetupWorkflow,
  AppDocument,
  AppSchemaPreview,
  PublishAppSchemaParams,
  UpdateAppSchemaParams,
} from '@novaera/actioner-service';

export type PublishAppStepTracerProps = {
  mode: PublishAppMode;
  onCancel: (activeStep?: number) => void;
  schemaPreview?: AppSchemaPreview;
  schemaId?: string;
};

export enum PublishAppMode {
  Publish,
  Update,
}

export type OptionalPublishAppFormData = Partial<Omit<PublishAppSchemaParams, 'appDetails'>> & {
  appDetails: Partial<
    Omit<PublishAppSchemaParams['appDetails'], 'documents'> & {
      documents: { support: AppDocument[]; useCases: AppDocument[] };
      logoUrl: string;
    }
  >;
};

export type PublishAppFormData = Omit<PublishAppSchemaParams, 'appDetails'> & {
  appDetails: Omit<PublishAppSchemaParams['appDetails'], 'documents' | 'setupWorkflows'> & {
    setupWorkflows?: AppDetailSetupWorkflow[];
    documents: { support: AppDocument[]; useCases: AppDocument[] };
    logoUrl: string;
  };
};

export type OptionalPublishNewVersionFormData = Partial<Omit<UpdateAppSchemaParams, 'appDetails'>> & {
  appDetails: Partial<
    Omit<UpdateAppSchemaParams['appDetails'], 'documents'> & {
      documents: { support: AppDocument[]; useCases: AppDocument[] };
      logoUrl: string;
    }
  >;
};

export type PublishNewVersionFormData = Omit<UpdateAppSchemaParams, 'appDetails'> & {
  appDetails: Omit<UpdateAppSchemaParams['appDetails'], 'documents' | 'setupWorkflows'> & {
    setupWorkflows?: AppDetailSetupWorkflow[];
    documents: { support: AppDocument[]; useCases: AppDocument[] };
    logoUrl: string;
  };
};
