import { useLocation } from '@novaera/route';
import { setAccessToken } from '@novaera/service';
import { assert } from '@novaera/utils';
import { useEffect, useState } from 'react';
import { SlackAuthAnonymousAccessWorkspaceSelectionResponse } from '../../../../services/slack/use-slack-auth-access/types';
import { useSlackCompleteWorkspaceSelection } from '../../../../services/slack/use-slack-complete-workspace-selection';
import {
  SlackCreateWorkspaceCompleteWorkspaceSelection,
  SlackJoinCompleteWorkspaceSelection,
} from '../../../../services/slack/use-slack-complete-workspace-selection/types';
import { useCreateUserSession } from '../../../../services/use-create-user-session';
import { redirectToWorkspace } from '../../../sign-in/workspace-panel/list/utils';

export const useJoinWorkspace = () => {
  const location = useLocation();

  const [slackResponse, setSlackResponse] = useState<SlackAuthAnonymousAccessWorkspaceSelectionResponse>();

  const { mutate: completeWorkspaceSelection, isLoading } = useSlackCompleteWorkspaceSelection();
  const { mutate: createUserSession } = useCreateUserSession();

  useEffect(() => {
    setSlackResponse(location.state as SlackAuthAnonymousAccessWorkspaceSelectionResponse);
  }, [location.state]);

  const handleJoin = (params?: { workspaceName?: string; workspaceId?: string }) => {
    return new Promise<void>((resolve) => {
      assert(Boolean(slackResponse?.stateKey), new Error('Slack response state key is not defined'), 'ERROR');
      const { workspaceName, workspaceId } = params || {};

      if (workspaceId) {
        const params: SlackJoinCompleteWorkspaceSelection = {
          type: 'select-existing-workspace',
          stateKey: slackResponse?.stateKey ?? '',
          workspaceId,
        };
        completeWorkspaceSelection(params, {
          onSuccess: (result) => {
            assert(!!workspaceName, new Error("Workspace is expected but it's empty!"));
            createUserSession(
              {
                productId: 'actioner',
                jwt: result.jwt,
              },
              {
                onSuccess: (result) => {
                  setAccessToken(result.accessToken);
                  redirectToWorkspace(workspaceName);
                  resolve();
                },
              }
            );
          },
        });
      } else {
        const params: SlackCreateWorkspaceCompleteWorkspaceSelection = {
          type: 'create-new-workspace',
          stateKey: slackResponse?.stateKey ?? '',
        };
        completeWorkspaceSelection(params, {
          onSuccess: (onSuccessResult) => {
            createUserSession(
              {
                productId: 'actioner',
                jwt: onSuccessResult.jwt,
              },
              {
                onSuccess: (result) => {
                  setAccessToken(result.accessToken);
                  redirectToWorkspace(onSuccessResult.subdomain);
                  resolve();
                },
              }
            );
          },
        });
      }
    });
  };

  return {
    joinWorkspace: handleJoin,
    isLoading,
  };
};
