import { NvAxios, useQuery } from '@novaera/core';
import { CATALOG_RELATIONSHIP_ROOT_PATH } from '../constants';
import { QUERY_KEY_CATALOG_RELATIONSHIP_TYPES } from '../keys';
import { CatalogRelationship } from '../types';
import { SearchCatalogRelationshipParams } from './types';

export type SearchCatalogRelationshipResult = {
  relationshipDefinitions: CatalogRelationship[];
};

const searchCatalogRelationship = async (params: SearchCatalogRelationshipParams) => {
  const result = await NvAxios.get<SearchCatalogRelationshipResult>(
    `${CATALOG_RELATIONSHIP_ROOT_PATH}?expandTarget=true`,
    { params }
  );
  return result?.data;
};

export const useSearchCatalogRelationship = (params: SearchCatalogRelationshipParams) => {
  return useQuery<SearchCatalogRelationshipResult>(
    QUERY_KEY_CATALOG_RELATIONSHIP_TYPES.list(params.firstEntityTypeId),
    () => searchCatalogRelationship(params)
  );
};
