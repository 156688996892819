import { useQueryParams } from '@novaera/route';
import { switchToWorkspace } from '@novaera/utils';
import { useEffect, useMemo } from 'react';
import { STATE_DELIMITER } from '../../common/constants';

import { isSlackAuthAccessAnonymous, useSlackAuthAccess } from '@novaera/identity-provider';
import { getSlackRedirectUri } from '../../common/utils';
import { SlackRedirectQueryParams } from './types';

const useParseParamsForSlackAuthAccess = (params?: SlackRedirectQueryParams) => {
  const authParams = useMemo(() => {
    if (!params) {
      return;
    }

    const state = params.state;
    const code = params.code;
    const isRedirectedFromSlack = params.redirected === 'true';
    const [subdomain, triggerId, _isAnonymous] = (state ?? '').split(STATE_DELIMITER);
    const isSignUp = _isAnonymous === 'true';
    const redirectUri = getSlackRedirectUri();

    return {
      code,
      isRedirectedFromSlack,
      subdomain,
      triggerId,
      isSignUp,
      redirectUri,
      state,
    };
  }, [params]);

  return authParams;
};

export const useSetSlackToken = () => {
  const { getSearchParams } = useQueryParams();
  const params = getSearchParams<SlackRedirectQueryParams>();
  const authParams = useParseParamsForSlackAuthAccess(params);

  const { data } = useSlackAuthAccess(
    {
      code: authParams?.code ?? '',
      isAnonymous: authParams?.isSignUp ?? false,
      redirectUri: authParams?.redirectUri ?? '',
      triggerId: authParams?.triggerId ?? '',
    },
    {
      enabled: Boolean(authParams?.isRedirectedFromSlack || authParams?.isSignUp),
    }
  );

  useEffect(() => {
    if (authParams && !authParams?.isRedirectedFromSlack && !authParams?.isSignUp) {
      const { code, state, subdomain } = authParams;
      switchToWorkspace(subdomain, `/workspace/slack/access?code=${code}&state=${state}&redirected=true`);
    }
  }, [authParams, authParams?.isRedirectedFromSlack, authParams?.isSignUp]);

  useEffect(() => {
    if (params?.error && params.state) {
      const { error, state } = params;
      const [subdomain] = state.split(STATE_DELIMITER);
      switchToWorkspace(subdomain, `/workspace/slack?error=${error}`);
    }
  }, [params, params?.error, params?.state]);

  const isRedirectingToSlack = useMemo(
    () => params?.state?.split(STATE_DELIMITER)[2] === 'true' || (data && isSlackAuthAccessAnonymous(data)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  return {
    isRedirectingToSlack,
  };
};
