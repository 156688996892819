import { NvCustomEmptyIcon, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { GroupTagAndDescriptionEmptyStateWrapper } from './styled';

export const GroupTagAndDescriptionEmptyState: React.FC<{ message: string }> = ({ message }) => {
  const theme = useTheme();
  return (
    <GroupTagAndDescriptionEmptyStateWrapper>
      <NvCustomEmptyIcon sx={{ width: '16px', height: '16px' }} htmlColor={theme.palette.nv_neutral[300]} />
      <NvTypography variant="h6" textColor="subtle">
        {message}
      </NvTypography>
    </GroupTagAndDescriptionEmptyStateWrapper>
  );
};
