import { CodeInput, Context, NvField } from '@novaera/core';

export const ConnectionKeyValueInput: React.FC<React.PropsWithChildren<{ name?: string; context: Context }>> = ({
  name,
  context,
}) => {
  return (
    <>
      <NvField name={`${name}.key`} component={<CodeInput context={context} expectedType="string" />} />
      <NvField name={`${name}.value`} component={<CodeInput context={context} expectedType="string" />} />
    </>
  );
};
