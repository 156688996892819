import { NvCssBaseline } from '@novaera/core';
import { Outlet, useLocation } from '@novaera/route';
import classnames from 'classnames';
import { useEffect } from 'react';
import { AppWrapper, AppWrapperContent } from './style';

export const AppLayout: React.FC<
  React.PropsWithChildren<{
    hasNoMenu?: boolean;
  }>
> = ({ hasNoMenu }) => {
  const location = useLocation();
  useEffect(() => {
    if (window.userGuiding && window.userGuiding.finishPreview) {
      window.userGuiding?.finishPreview();
    }
  }, [location]);

  return (
    <>
      <NvCssBaseline />
      <AppWrapper>
        <AppWrapperContent
          className={classnames({
            'has-no-menu': hasNoMenu,
          })}
        >
          <Outlet />
        </AppWrapperContent>
      </AppWrapper>
    </>
  );
};
