import { NeutralChipColor, NvBox, NvChip, NvFlex, NvMenuWithItems, NvTypography } from '@novaera/core';
import classnames from 'classnames';
import React, { useState } from 'react';
import { BaseItemCard, BaseItemTag } from './styled';
import { BaseItemProps } from './types';

export const BaseItem: React.FC<React.PropsWithChildren<BaseItemProps>> = ({
  icon,
  name,
  description,
  tags,
  isDraft,
  menuItems,
  actions,
  to,
  disabled = false,
  className,
}) => {
  const [isBaseIteActionsMenuOpen, setIsBaseItemActionsMenuOpen] = useState<boolean>(false);

  return (
    <BaseItemCard
      to={to}
      className={`${className} ${classnames({
        'is-base-item-actions-menu-open': isBaseIteActionsMenuOpen,
        'is-base-item-disabled': disabled,
      })}`}
    >
      <NvFlex direction="row" alignItems="center" justifyContent="space-between" gap="8px">
        <NvFlex direction="row" gap="8px" alignItems="center" flex="1 1 auto" minWidth={0}>
          {icon}
          {typeof name === 'string' ? (
            <NvTypography variant="h4" noWrap>
              {name}
            </NvTypography>
          ) : (
            name
          )}
          {isDraft && <NvChip label={'draft'} compact color={NeutralChipColor.DARK_ALPHA} sx={{ flex: '0 0 auto' }} />}
        </NvFlex>
        {actions}
        {((Array.isArray(menuItems) && menuItems.length > 0) || (!Array.isArray(menuItems) && menuItems)) && (
          <NvBox className="base-item-actions" flex="0 0 auto">
            <NvMenuWithItems
              menuItems={menuItems}
              onOpened={() => {
                setIsBaseItemActionsMenuOpen(true);
              }}
              onClosed={() => {
                setTimeout(() => {
                  setIsBaseItemActionsMenuOpen(false);
                }, 300);
              }}
            />
          </NvBox>
        )}
      </NvFlex>

      {description && description.length > 0 && (
        <NvTypography variant="body3" textColor="secondary" marginRight="24px">
          {description}
        </NvTypography>
      )}
      {tags && tags.length > 0 && (
        <NvFlex
          direction="row"
          gap="4px"
          alignItems="center"
          flexWrap="wrap"
          marginRight="24px"
          className="base-item-tags-wrapper"
        >
          {tags.map((tag, index) => (
            <BaseItemTag key={`base-item-tag-${tag}-${index}`}>
              <NvTypography variant="body2" textColor="secondary" noWrap>
                {tag}
              </NvTypography>
            </BaseItemTag>
          ))}
        </NvFlex>
      )}
    </BaseItemCard>
  );
};
