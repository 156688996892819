import {
  FileNodeSummary,
  JobNodeSummary,
  LinkGeneratorNodeSummary,
  NodeSummary,
  NodeUnionTypeEnumLike,
  WorkflowDispatcherNodeSummary,
} from './types';

export const isWorkflowDispatcherNodeSummary = (node: NodeSummary): node is WorkflowDispatcherNodeSummary => {
  return node.type === NodeUnionTypeEnumLike.workflowDispatcher;
};

export const isJobNodeSummary = (node: NodeSummary): node is JobNodeSummary => {
  return node.type === NodeUnionTypeEnumLike.job;
};

export const isFileNodeSummary = (node: NodeSummary): node is FileNodeSummary => {
  return node.type === NodeUnionTypeEnumLike.file;
};

export const isLinkGeneratorNodeSummary = (node: NodeSummary): node is LinkGeneratorNodeSummary => {
  return node.type === NodeUnionTypeEnumLike.linkGenerator;
};
