import { PageViewTracker } from '@novaera/core';
import { useMatch } from '@novaera/route';
import { useMemo } from 'react';
import { ROUTES } from '../../common/routes';

export const AppDirectoryPageViewTracker: React.FC<React.PropsWithChildren<{ isAuthenticated: boolean }>> = ({
  isAuthenticated,
}) => {
  const appDirectoryDetailPageMatch = useMatch(`${ROUTES.AppDirectory}/:name/:storeSchemaId`);
  const details = useMemo(
    () =>
      appDirectoryDetailPageMatch
        ? {
            appSchemaId: appDirectoryDetailPageMatch.params.storeSchemaId,
            appSchemaName: appDirectoryDetailPageMatch.params.name,
          }
        : {},
    [appDirectoryDetailPageMatch]
  );
  const eventName = useMemo(() => (isAuthenticated ? 'Actioner App Page' : 'Actioner Public Page'), [isAuthenticated]);
  return <PageViewTracker eventName={eventName} pageDetails={details} />;
};
