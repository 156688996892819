import { Breakpoint } from '@mui/material';
import { EmptyInterface, ModalIcons, NvButton, NvConditionalRender, NvDialogModal, NvForm } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import arrayMutators from 'final-form-arrays';
import { Header } from '../header';
import { DetailLayoutBody } from './body';
import { DETAIL_FORM_NAME, HEADER_ICONS, getDefaultActions } from './constant';
import { useDetailLayoutModalController } from './controllers/use-detail-layout-modal';
import { LayoutBodyWrapper } from './styled';
import { TrackFormState } from './track-form-state';
import { DetailLayoutFormProps, DetailLayoutProps } from './types';
import { isPredefinedDetailLayoutHeaderIcon } from './utils';

export const DetailLayout = <LayoutFormValue extends EmptyInterface, ModalFormValue extends EmptyInterface>({
  children,
  headerProps,
  formProps,
  useArrayMutators,
  editModalProps,
  onFormSubmit,
  onActionButtonClicked,
  onFormStateChanged,
  isLoading: isPageLoading = false,
  LoadingSkeleton,
}: DetailLayoutProps<LayoutFormValue, ModalFormValue>) => {
  const { isOpened, onClose, onOpenClick } = useDetailLayoutModalController();
  const theme = useTheme();
  const { actions, icon, enableEdit, onDiscard, ...otherHeaderProps } = headerProps;
  const { title, isLoading, modalBody, useLayoutForm, onSubmit, ...otherEditModalFormProps } = editModalProps;
  const { formProps: editModalFormProps, ...nvDialogProps } = otherEditModalFormProps;
  const headerIcon = isPredefinedDetailLayoutHeaderIcon(icon) ? HEADER_ICONS[icon] : icon;

  return (
    <>
      <NvConditionalRender when={() => isPageLoading && LoadingSkeleton !== undefined}>
        <LayoutBodyWrapper>
          <Header name={''} isLoading={true} icon={headerIcon} actions={[]} />
          <DetailLayoutBody>{LoadingSkeleton}</DetailLayoutBody>
        </LayoutBodyWrapper>
      </NvConditionalRender>
      <NvConditionalRender when={() => !isPageLoading}>
        {
          <NvForm<LayoutFormValue>
            {...(useArrayMutators && {
              mutators: {
                ...arrayMutators,
              },
            })}
            {...formProps}
            onSubmit={async (values, formApi) => {
              await onFormSubmit(values, formApi);
              if (isOpened) {
                onClose();
              }
            }}
            name={DETAIL_FORM_NAME}
          >
            {({ form, values, submitting }) => {
              const baseDialogModalProps = {
                Header: title,
                modalIcon: 'add' as ModalIcons,
                open: isOpened,
                onCloseButtonClicked: onClose,
                isLoading: isLoading,
                fullWidth: true,
                Body: modalBody,
                maxWidth: 'sm' as Breakpoint,
                ...nvDialogProps,
              };

              return (
                <TrackFormState form={form} onFormStateChanged={onFormStateChanged}>
                  <LayoutBodyWrapper>
                    <Header
                      actions={actions ?? getDefaultActions(theme, values, onActionButtonClicked)}
                      icon={headerIcon}
                      {...(enableEdit && {
                        onEditNameClick: onOpenClick,
                      })}
                      buttonActions={
                        <>
                          <NvButton
                            size="small"
                            color="secondary"
                            loading={otherHeaderProps.isLoading}
                            disabled={submitting}
                            onClick={async () => {
                              if (onDiscard) {
                                await onDiscard?.(form);
                              } else {
                                form.reset();
                              }
                            }}
                          >
                            Discard changes
                          </NvButton>
                          <NvButton
                            size="small"
                            color="primary"
                            loading={otherHeaderProps.isLoading}
                            disabled={submitting}
                            onClick={async () => {
                              await form.submit();
                            }}
                          >
                            Apply
                          </NvButton>
                        </>
                      }
                      {...otherHeaderProps}
                    />
                    <DetailLayoutBody>{children}</DetailLayoutBody>

                    <NvConditionalRender when={() => !!useLayoutForm}>
                      {
                        <NvDialogModal<LayoutFormValue>
                          {...baseDialogModalProps}
                          onFormSubmit={(value) => {
                            onFormSubmit(
                              {
                                ...values,
                                ...value,
                              },
                              form
                            );
                            onClose();
                          }}
                          {...(editModalFormProps && {
                            formProps: editModalFormProps as DetailLayoutFormProps<LayoutFormValue>,
                          })}
                          form={form}
                        />
                      }
                    </NvConditionalRender>
                    <NvConditionalRender when={() => !useLayoutForm}>
                      {
                        <NvDialogModal<ModalFormValue>
                          {...baseDialogModalProps}
                          onFormSubmit={(value) => {
                            onSubmit(value);
                            onClose();
                          }}
                          {...(editModalFormProps && {
                            formProps: editModalFormProps,
                          })}
                          form={undefined}
                        />
                      }
                    </NvConditionalRender>
                  </LayoutBodyWrapper>
                </TrackFormState>
              );
            }}
          </NvForm>
        }
      </NvConditionalRender>
    </>
  );
};

export * from './constant';
