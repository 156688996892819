import { VariableSchema } from '@novaera/actioner-service';
import { NvFlex, NvTypography } from '@novaera/core';
import { AddButton } from '../../../../../../../../../components/button/add';
import { RequestVariableBodyFieldsList } from './field-list';
import { BodyWrapper } from './styled';
import { FieldVariableActionProps } from './types';

const BodyItem = (props: { label: string; description: string }) => (
  <NvFlex flexDirection={'row'} gap="6px">
    <NvTypography variant="h5">{props.label}:</NvTypography>
    <NvTypography variant="h6">{props.description}</NvTypography>
  </NvFlex>
);

export const RequestVariableBody = (
  props: {
    variableSchema: VariableSchema;
    onAddFieldClicked: () => void;
  } & FieldVariableActionProps
) => {
  return (
    <NvFlex gap={'16px'}>
      <BodyWrapper gap="8px">
        <BodyItem label="Path" description={props.variableSchema.path} />
        <BodyItem label="Http method" description={props.variableSchema.httpMethod} />
      </BodyWrapper>
      <RequestVariableBodyFieldsList
        onUpdateFieldClicked={props.onUpdateFieldClicked}
        onRemoveFieldClicked={props.onRemoveFieldClicked}
        variables={props.variableSchema.variables}
      />
      <AddButton label="Add field" onClick={props.onAddFieldClicked} />
    </NvFlex>
  );
};
