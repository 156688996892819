import {
  NvButton,
  NvChip,
  NvCloseIcon,
  NvDivider,
  NvDrawer,
  NvFilterListIcon,
  NvFlex,
  NvSearchIcon,
  NvSkeleton,
  NvStoreIllustration,
  NvTextField,
  NvTypography,
} from '@novaera/core';
import { Fragment } from 'react';
import { EmptyState } from '../../components/empty-state';
import { EmptyStates } from '../../components/empty-state/types';
import { COMING_SOON_APP_TAG } from '../constants';
import { AppDirectoryCard } from './app-directory-card';
import { AppDirectoryCardLoadingSkeleton } from './app-directory-card/loading-skeleton';
import { AppDirectoryMenu } from './app-directory-menu';
import { useAppDirectoryList } from './controller/use-app-directory-list';
import {
  AppDirectoryBody,
  AppDirectoryBodyLeft,
  AppDirectoryBodyRight,
  AppDirectoryGrid,
  AppDirectoryListContainer,
  AppDirectoryMobileSearch,
  ApplyFilterButtonBox,
  FilterDrawerHeader,
} from './styled';

export const AppDirectoryList: React.FC<React.PropsWithChildren<{ isAnonymous: boolean }>> = ({ isAnonymous }) => {
  const {
    schemasPages,
    isSchemasLoading,
    hasNoAppSchemas,
    fetchNextSchemasPage,
    hasNextSchemasPage,
    isFetchingNextSchemasPage,
    searchValue,
    searchQueries,
    selectedItem,
    onSearchValueChange,
    onAppDirectoryMenuItemSelect,
    onAppDirectoryMenuItemDeselect,
    onSearchQueryDelete,
    searchMetadata,
    isAppDirectoryFilterMenuOpen,
    setIsAppDirectoryFilterMenuOpen,
    searchTextFieldRef,
    isSearchMetadataLoading,
  } = useAppDirectoryList({ isAnonymous });

  return (
    <>
      <NvFlex width="100%" height="auto">
        <AppDirectoryListContainer className={`app-directory-body ${!isAnonymous ? 'app' : 'public'}`}>
          <AppDirectoryBody>
            <AppDirectoryBodyLeft isPublic={isAnonymous}>
              <NvFlex gap="8px">
                <NvStoreIllustration />
                <NvTypography variant="h1">App directory</NvTypography>
                <NvTextField
                  startIcon={<NvSearchIcon />}
                  inputRef={searchTextFieldRef}
                  fullWidth
                  placeholder="Search apps"
                  size="small"
                  value={searchValue}
                  onChange={onSearchValueChange}
                />
                <NvDivider sx={{ marginTop: '8px', marginBottom: '8px' }} />
                <AppDirectoryMenu
                  selectedItem={selectedItem}
                  onSelect={onAppDirectoryMenuItemSelect}
                  onDeselect={onAppDirectoryMenuItemDeselect}
                  categories={searchMetadata?.availableCategories}
                  isLoading={isSearchMetadataLoading}
                />
              </NvFlex>
            </AppDirectoryBodyLeft>
            <AppDirectoryBodyRight>
              <AppDirectoryMobileSearch isPublic={isAnonymous}>
                <NvTypography variant="h1">App directory</NvTypography>
                <NvFlex direction="row" alignItems="center" gap="16px">
                  <NvButton color="secondary" onClick={() => setIsAppDirectoryFilterMenuOpen(true)} onlyIcon>
                    <NvFilterListIcon />
                  </NvButton>
                  <NvDivider orientation="vertical" sx={{ height: '8px' }} />
                  <NvTextField
                    startIcon={<NvSearchIcon />}
                    inputRef={searchTextFieldRef}
                    fullWidth
                    placeholder="Search apps"
                    value={searchValue}
                    onChange={onSearchValueChange}
                  />
                </NvFlex>
              </AppDirectoryMobileSearch>
              {isSchemasLoading ? (
                <NvFlex gap="24px" key={`loading-app-directory-list`}>
                  <NvFlex>
                    <NvSkeleton width="10%" height="40px" />
                  </NvFlex>
                  <AppDirectoryGrid>
                    {[1, 2, 3, 4].map((index) => (
                      <AppDirectoryCardLoadingSkeleton key={`loading_${index}`} />
                    ))}
                  </AppDirectoryGrid>
                </NvFlex>
              ) : (
                <Fragment key={`app-directory-fragment`}>
                  <NvFlex gap="24px">
                    {searchQueries.length > 0 && (
                      <NvFlex spacing="8px">
                        <NvTypography variant="h2">Search results</NvTypography>
                        {
                          <NvFlex direction="row" spacing="4px">
                            {searchQueries.map((query, index) => (
                              <NvChip
                                key={`${query.value}-${index}`}
                                label={query.displayValue ?? query.value}
                                compact
                                onDelete={() => onSearchQueryDelete(query)}
                              />
                            ))}
                          </NvFlex>
                        }
                      </NvFlex>
                    )}
                    {searchQueries.length > 0 && hasNoAppSchemas ? (
                      <EmptyState variant={EmptyStates.APP_DIRECTORY} />
                    ) : (
                      <AppDirectoryGrid>
                        {schemasPages?.map(({ result }) =>
                          result.schemas.map((schema) => (
                            <AppDirectoryCard
                              key={`${schema.id}`}
                              title={schema.name}
                              description={schema.shortDescription}
                              tags={
                                schema.tags.find((t) => t.label === COMING_SOON_APP_TAG[0].label)
                                  ? COMING_SOON_APP_TAG
                                  : schema.tags
                              }
                              star={schema.numberOfStars}
                              contributors={schema.contributors}
                              logoUrl={schema.logoUrl}
                              id={schema.id}
                              version={schema.version}
                              isManagedApp={schema.managed}
                              free={schema.free}
                            />
                          ))
                        )}
                      </AppDirectoryGrid>
                    )}
                    {hasNextSchemasPage && (
                      <NvFlex alignItems="flex-start">
                        <NvButton
                          color="secondary"
                          onClick={() => fetchNextSchemasPage()}
                          loading={isFetchingNextSchemasPage}
                          disabled={isFetchingNextSchemasPage}
                        >
                          Show more
                        </NvButton>
                      </NvFlex>
                    )}
                  </NvFlex>
                </Fragment>
              )}
            </AppDirectoryBodyRight>
          </AppDirectoryBody>
        </AppDirectoryListContainer>
      </NvFlex>

      <NvDrawer open={isAppDirectoryFilterMenuOpen} fullWidth borderRadius="0">
        <NvFlex height="100%" overflow="hidden">
          <NvFlex flex="1 1 auto" minHeight={0}>
            <FilterDrawerHeader>
              <NvFlex direction="row" flex="1 1 auto" alignItems="center" gap="8px">
                <NvFilterListIcon />
                <NvTypography variant="h2">Filter</NvTypography>
              </NvFlex>

              <NvButton onlyIcon color="secondary" onClick={() => setIsAppDirectoryFilterMenuOpen(false)}>
                <NvCloseIcon />
              </NvButton>
            </FilterDrawerHeader>

            <NvFlex padding="16px" overflow="auto">
              <AppDirectoryMenu
                selectedItem={selectedItem}
                onSelect={onAppDirectoryMenuItemSelect}
                onDeselect={onAppDirectoryMenuItemDeselect}
                categories={searchMetadata?.availableCategories}
                fullWidth
                isLoading={isSearchMetadataLoading}
              />
            </NvFlex>
          </NvFlex>
          <ApplyFilterButtonBox>
            <NvButton onClick={() => setIsAppDirectoryFilterMenuOpen(false)}>Apply filter</NvButton>
          </ApplyFilterButtonBox>
        </NvFlex>
      </NvDrawer>
    </>
  );
};
