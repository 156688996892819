import { AppSchemasPayload, AppSchemeChangeSize } from '@novaera/actioner-service';

export const changeSizeCalculator = (type: AppSchemeChangeSize, version: string) => {
  const versionUnits = version.split('.');
  switch (type) {
    case AppSchemeChangeSize.PATCH:
      return `${versionUnits[0]}.${versionUnits[1]}.${Number(versionUnits[2]) + 1}`;

    case AppSchemeChangeSize.MINOR:
      return `${versionUnits[0]}.${Number(versionUnits[1]) + 1}.0`;

    case AppSchemeChangeSize.MAJOR:
      return `${Number(versionUnits[0]) + 1}.0.0`;

    default:
      return version;
  }
};

export const getSearchSchemeBody = (
  updatedSearchQuery: AppSchemasPayload,
  initialSearchQuery: AppSchemasPayload
): AppSchemasPayload => {
  return updatedSearchQuery.queries.length > 0
    ? {
        ...updatedSearchQuery,
        sortParams: null,
      }
    : initialSearchQuery;
};
