import { NvBox, NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const FreeAppWrapper = styled(NvBox)`
  position: sticky !important; // to override position relative from tab component
  top: 24px;
  width: 100%;
  height: 0px;

  @media screen and (max-width: 1280px) {
    display: none;
  }
`;

export const FreeAppBox = styled(NvFlex)`
  width: 136px;
  position: absolute;
  left: calc(100% + 24px);
  top: 0px;
  gap: 0px;
  align-items: center;
`;

export const FreeAppHeader = styled(NvFlex)`
  flex-direction: row;
  background-color: ${({ theme }) => theme.palette.nv_neutral[1000]};
  gap: 8px;
  padding: 4px 8px;
  border-radius: 6px 6px 0px 0px;
`;

export const FreeAppBody = styled(NvFlex)`
  width: 100%;
  ${({ theme }) => theme.elevations.e100};
  padding: 32px 16px 20px 16px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
`;

export const StyledImage = styled('img')`
  position: absolute;
  top: -24px;
  filter: blur(52px);
  opacity: 0.3;
`;
