import { NvAxios, useQuery } from '@novaera/core';
import { isUndefined } from 'lodash';
import { APP_DIRECTORY_ROOT_PATH } from '../constants';
import { QUERY_KEYS_APP_DIRECTORY } from '../keys';
import { GetAppSchemaPreviewParams, GetAppSchemaPreviewResponse } from './types';

const getAppSchemaPreview = async ({ schemaId }: GetAppSchemaPreviewParams) => {
  const result = await NvAxios.get<GetAppSchemaPreviewResponse>(
    `${APP_DIRECTORY_ROOT_PATH}/schemas/${schemaId}/preview`
  );
  return result?.data;
};

export const useGetAppSchemaPreview = (params: GetAppSchemaPreviewParams) =>
  useQuery<GetAppSchemaPreviewResponse>(
    QUERY_KEYS_APP_DIRECTORY.previewDetail(params.schemaId),
    () => getAppSchemaPreview(params),
    !isUndefined(params.enabled) ? { enabled: params.enabled } : {}
  );
