import {
  InputParameter,
  RunConfiguration,
  UIComponentType,
  UIComponentTypes,
  useDeleteInputParameter,
  useUpdateInputParameter,
  useUpdateIntegrationActionRunConfiguration,
} from '@novaera/actioner-service';
import { NvForm, NvMenuWithItems, NvMoreHorizIcon } from '@novaera/core';
import { FormState } from 'final-form';
import arrayMutators from 'final-form-arrays';
import { noop } from 'lodash';
import { FC, useCallback, useMemo } from 'react';
import {
  ConfiguredEntity,
  InputParameterEntity,
  PropertyConfiguration,
  useDynamicInputContext,
  useIntegrationActionOptionsContext,
} from '../../../../components';
import { useActionDesignerFormApiContext } from '../../../providers/form-api-provider';
import { useInputsContext } from '../../../providers/input-provider';
import { ACTION_EXECUTION_BUTTON_ID } from '../constants';
import { IntegrationActionInputParameterPropertiesProps } from './types';

export const IntegrationActionInputParameterProperties: FC<
  React.PropsWithChildren<IntegrationActionInputParameterPropertiesProps>
> = ({ integrationAction: { id: actionId, integrationId, inputParameters, runConfiguration }, integrationVersion }) => {
  const { invalidate } = useIntegrationActionOptionsContext();
  const { invalidate: invalidateDynamicInput } = useDynamicInputContext();
  const { mutate: updateInputParameter } = useUpdateInputParameter();
  const { mutate: updateRunConfiguration } = useUpdateIntegrationActionRunConfiguration();
  const { mutate: deleteInputParameter } = useDeleteInputParameter();
  const { selectedInputParameterId, setSelectedInputParameterId } = useInputsContext();
  const selectedInputParameter = useMemo(() => {
    return inputParameters?.find((i) => i.id === selectedInputParameterId);
  }, [inputParameters, selectedInputParameterId]);
  const { inputPropertiesFormApiRef } = useActionDesignerFormApiContext();
  const configuredEntity: ConfiguredEntity | undefined = useMemo(() => {
    if (selectedInputParameterId === ACTION_EXECUTION_BUTTON_ID) {
      return { type: 'run-configuration', runConfiguration: runConfiguration ?? { label: 'Run', runSteps: [] } };
    } else if (selectedInputParameterId && selectedInputParameter) {
      const retVal: InputParameterEntity = {
        type: 'integration-action-input-parameter',
        inputParameter: selectedInputParameter,
      };
      return retVal;
    }
    return;
  }, [runConfiguration, selectedInputParameter, selectedInputParameterId]);

  const handlePropertiesChanges = useCallback(
    (
      values: ConfiguredEntity,
      dirtyFields: {
        [key: string]: boolean;
      }
    ) => {
      if (values.type === 'run-configuration') {
        updateRunConfiguration({ integrationId, actionId, version: integrationVersion, ...values.runConfiguration });
      } else {
        updateInputParameter(
          { integrationId, actionId, version: integrationVersion, ...values.inputParameter },
          {
            onSuccess: () => {
              if (selectedInputParameterId) {
                if (
                  dirtyFields['uiComponent.dataSource.optionsProducers'] ||
                  dirtyFields['uiComponent.dataSource.options']
                ) {
                  invalidate(selectedInputParameterId);
                }
                if (values.inputParameter.uiComponent.type === UIComponentType.DYNAMIC_INPUT) {
                  invalidateDynamicInput(selectedInputParameterId);
                }
              }
            },
          }
        );
      }
    },
    [
      actionId,
      integrationId,
      integrationVersion,
      invalidate,
      invalidateDynamicInput,
      selectedInputParameterId,
      updateInputParameter,
      updateRunConfiguration,
    ]
  );

  const handleRemoveInputItem = () => {
    selectedInputParameterId &&
      deleteInputParameter(
        { integrationId, actionId, version: integrationVersion, id: selectedInputParameterId },
        {
          onSuccess: () => {
            setSelectedInputParameterId();
          },
        }
      );
  };
  const handleInputParameterCloned = () => {
    console.log('clone input');
  };

  const initialPropertyConfigurationFormValues = useMemo(() => {
    if (configuredEntity) {
      if (configuredEntity.type === 'integration-action-input-parameter') {
        return configuredEntity.inputParameter;
      } else if (configuredEntity?.type === 'run-configuration') {
        return configuredEntity.runConfiguration;
      } else {
        return;
      }
    } else {
      return;
    }
  }, [configuredEntity]);

  const handlePropertyConfigurationFormChange = useCallback<
    (
      params: FormState<
        InputParameter<UIComponentTypes> | RunConfiguration,
        Partial<InputParameter<UIComponentTypes> | RunConfiguration>
      >
    ) => void
  >(
    ({ values, dirtyFields }) => {
      if (configuredEntity && configuredEntity.type === 'integration-action-input-parameter') {
        const configuredEntity1: ConfiguredEntity = {
          type: configuredEntity?.type,
          // todo Alper instead of this an assertion might be good. but I am not sure if input parameter can be distinguished from run configuration somehow and it is logical.
          inputParameter: values as InputParameter,
        };
        handlePropertiesChanges(configuredEntity1, dirtyFields);
      } else if (configuredEntity && configuredEntity.type === 'run-configuration') {
        // todo Alper instead of this an assertion might be good. but I am not sure if input parameter can be distinguished from run configuration somehow and it is logical.
        const runConfigurationValues = values as RunConfiguration;
        const newRunConfigurationValues: RunConfiguration = {
          label: runConfigurationValues.label,
          runSteps: runConfigurationValues.runSteps ?? [],
        };

        const configuredEntity1: ConfiguredEntity = {
          type: configuredEntity?.type,
          runConfiguration: newRunConfigurationValues,
        };
        handlePropertiesChanges(configuredEntity1, dirtyFields);
      }
    },
    [configuredEntity, handlePropertiesChanges]
  );

  return (
    <NvForm<InputParameter | RunConfiguration>
      myRef={inputPropertiesFormApiRef}
      key={selectedInputParameterId}
      onSubmit={noop}
      initialValues={initialPropertyConfigurationFormValues}
      onChange={handlePropertyConfigurationFormChange}
      mutators={{ ...arrayMutators }}
      keepDirtyOnReinitialize
      autoSaveProps={{ autoSaveType: 'debounce', debounceDelay: 500 }}
    >
      <PropertyConfiguration
        type="integration-action"
        configuredEntity={configuredEntity}
        headerActions={
          <NvMenuWithItems
            triggerButton={{
              content: <NvMoreHorizIcon />,
              props: { onlyIcon: true, size: 'small', color: 'secondary' },
            }}
            menuItems={[
              {
                name: 'Delete',
                onClick: handleRemoveInputItem,
              },
              {
                name: 'Clone',
                onClick: handleInputParameterCloned,
              },
            ]}
          />
        }
        integrationVersion={integrationVersion}
      />
    </NvForm>
  );
};
