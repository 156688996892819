import { NvFlex } from '@novaera/core';
import React from 'react';

import { SearchAndButtonBar } from '../components/search-and-add-button';
import { UserList } from '../components/user-list';
import { MemoizedNewMemberInvitation } from './new-member-invitation';
import { useUserManagement } from './use-user-management';

export const UserTab: React.FC<React.PropsWithChildren<unknown>> = () => {
  const {
    users,
    availableRoles,
    onRemoveClicked,
    onInviteNewMemberClicked,
    isNewMemberOpened,
    onInviteNewMemberClosed,
    onResentInvitationClick,
    invitedUsers,
    setSearchUserValue,
    onUpdateUserRoleChange,
    inProgressDeletingUser,
    inProgressUpdatingUser,
  } = useUserManagement();

  return (
    <>
      <NvFlex gap="16px">
        <SearchAndButtonBar
          buttonLabel="Invite users"
          onButtonClick={onInviteNewMemberClicked}
          setSearchUserValue={setSearchUserValue}
        />
        <UserList
          inProgressDeletingUser={inProgressDeletingUser}
          inProgressUpdatingUser={inProgressUpdatingUser}
          invitedUsers={invitedUsers}
          onRemoveClicked={onRemoveClicked}
          onResentInvitationClick={onResentInvitationClick}
          onUpdateUserRoleChange={onUpdateUserRoleChange}
          availableRoles={availableRoles}
          users={users}
        />
        {/* Postponed beyond MVP.
          <NvFlex direction="row" gap="8px" alignItems="center">
            <NvCheckbox />
            <NvTypography variant="h4">Only admin’s can invite new members to the workspace</NvTypography>
          </NvFlex> */}
      </NvFlex>
      <MemoizedNewMemberInvitation isOpened={isNewMemberOpened} onClosed={onInviteNewMemberClosed} />
    </>
  );
};
