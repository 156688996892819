import { isAxiosError, NvAxios, useAxiosErrorHandler, useMutation, useToast } from '@novaera/core';
import { SLACK_APP_ROOT_PATH } from '../constants';
import { ExportSlackAppParams, ExportSlackAppResponse } from './types';

const exportSlackApp = async ({ appId, exportSlackAppRequestParams }: ExportSlackAppParams) => {
  const result = await NvAxios.post<ExportSlackAppResponse>(
    `${SLACK_APP_ROOT_PATH(appId)}/export`,
    exportSlackAppRequestParams
  );
  return result?.data;
};

export const useExportSlackApp = () => {
  const { axiosErrorHandler } = useAxiosErrorHandler();
  const { addToast } = useToast();
  return useMutation(exportSlackApp, {
    onError: (error) => {
      if (isAxiosError(error)) {
        axiosErrorHandler(error);
      } else if (error instanceof Error) {
        addToast(`The Slack app cannot be exported, reason: ${error.message}`, { variant: 'error' });
      } else {
        addToast(`The Slack app cannot be exported, reason: ${JSON.stringify(error)}`, {
          variant: 'error',
        });
      }
    },
  });
};
