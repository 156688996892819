import { NvFlex } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const FooterWrapper = styled(NvFlex)`
  /* flex-direction: row; */
  align-items: center;
  width: 100%;
  margin-top: 40px;
  justify-content: center;
  margin-bottom: 60px;
  row-gap: 16px;
  padding: 0 40px;
`;
