import { Markdown, NvDeleteOutlineIcon, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { ConnectionSchemaLogo } from '../../../../components/connection-schema-logo';
import { DetailLayoutBody } from '../../../../components/detail-layout/body';
import { LayoutBodyWrapper } from '../../../../components/detail-layout/styled';
import { useGlobalSchemaDetailController } from './controllers/use-global-schema-detail';
import { StyledCardWithHeader } from './styled';
import { GlobalSchemaDetailTitle } from './title';
import { GlobalSchemaDetailProps } from './types';

export const GlobalSchemaDetail = ({ connectionSchema }: GlobalSchemaDetailProps) => {
  const { onDeleteSchemaClicked, isLoading } = useGlobalSchemaDetailController({ connectionSchema });
  const theme = useTheme();

  return (
    <LayoutBodyWrapper>
      <DetailLayoutBody>
        <StyledCardWithHeader
          LeftComponent={<ConnectionSchemaLogo connectionSchema={connectionSchema} size="medium" />}
          TitleComponent={<GlobalSchemaDetailTitle connectionSchema={connectionSchema} />}
          BodyComponent={
            connectionSchema?.description?.type === 'markdown' ? (
              <Markdown>{connectionSchema?.description?.value}</Markdown>
            ) : (
              <NvTypography variant="body2">{connectionSchema?.description?.value}</NvTypography>
            )
          }
          menuItems={[
            {
              name: 'Delete',
              isLoading,
              onClick: onDeleteSchemaClicked,
              icon: (
                <NvDeleteOutlineIcon htmlColor={theme.palette.nv_error[40]} sx={{ width: '16px', height: '16px' }} />
              ),
            },
          ]}
        />
      </DetailLayoutBody>
    </LayoutBodyWrapper>
  );
};
