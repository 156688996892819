import {
  DataSourceType,
  useGetIntegration,
  useGetIntegrationAction,
  useUpdateHttpRequestConfigurations,
} from '@novaera/actioner-service';
import { FormProps, NvForm } from '@novaera/core';
import { useParams } from '@novaera/route';
import { assert } from '@novaera/utils';
import arrayMutators from 'final-form-arrays';
import { isUndefined, noop } from 'lodash';
import { useCallback } from 'react';
import { useIntegrationActionOptionsContext } from '../../../components/options/provider';
import { componentsNeedsOptions } from '../../../components/ui-components/utils';
import { useActionDesignerFormApiContext } from '../../providers/form-api-provider';
import { useRequestContext } from '../../providers/request-provider';
import { Requests } from './requests';
import { RequestsFormValues } from './types';

export const RequestTab = () => {
  const { actionId, integrationId } = useParams();
  const { mutate } = useUpdateHttpRequestConfigurations();
  const { data: integration } = useGetIntegration({ id: integrationId });
  const { data: integrationAction } = useGetIntegrationAction({
    actionId,
    integrationId,
    version: integration?.latestVersion.number,
  });
  const { invalidate } = useIntegrationActionOptionsContext();
  const { selectedRequestId } = useRequestContext();
  const { requestsFormApiRef } = useActionDesignerFormApiContext();
  const handleOnChange = useCallback<Required<FormProps<RequestsFormValues>>['onChange']>(
    ({ values: { httpRequestConfigurations } }) => {
      const version = integration?.latestVersion?.number;
      assert(!isUndefined(version), new Error('Integration is expected to be defined'), 'ERROR');

      mutate({
        actionId,
        integrationId,
        version,
        payload: { httpRequestConfigurations: httpRequestConfigurations ?? [] },
      });

      const inputParameterIds = integrationAction?.inputParameters?.reduce<string[]>((prev, current) => {
        const uiComponent = current.uiComponent;
        if (
          componentsNeedsOptions(uiComponent) &&
          'dataSource' in uiComponent &&
          uiComponent.dataSource.type === DataSourceType.DYNAMIC
        ) {
          const isRequestIncludedInOptionProducer = uiComponent.dataSource.optionsProducers.some(
            (optionProducer) =>
              optionProducer.type === 'request' && optionProducer.requestDefinitionId === selectedRequestId
          );
          if (isRequestIncludedInOptionProducer) {
            return [...prev, current.id];
          } else {
            return prev;
          }
        } else {
          return prev;
        }
      }, []);

      inputParameterIds?.forEach((inputParameterId) => {
        invalidate(inputParameterId);
      });
    },
    [
      actionId,
      integration?.latestVersion?.number,
      integrationAction?.inputParameters,
      integrationId,
      invalidate,
      mutate,
      selectedRequestId,
    ]
  );

  return (
    <NvForm<RequestsFormValues>
      myRef={requestsFormApiRef}
      onSubmit={noop}
      initialValues={{ httpRequestConfigurations: integrationAction?.httpRequestConfigurations }}
      onChange={handleOnChange}
      mutators={{ ...arrayMutators }}
      keepDirtyOnReinitialize
      autoSaveProps={{ autoSaveType: 'debounce', debounceDelay: 500 }}
    >
      <Requests />
    </NvForm>
  );
};
