import { TabProps } from '@mui/material';
import { NvGroupIcon, NvPersonIcon } from '@novaera/core';
import { ROUTES } from '../../common/routes';
import { Groups } from './groups';
import { UserTab } from './users';

const WORKSPACE_SETTINGS_LINK = ROUTES.Workspace;

export const USER_MANAGEMENT_TAB_PATHS = {
  USER: '/user-management/users',
  GROUP: '/user-management/groups',
};

export const USER_MANAGEMENT_TABS = [
  {
    label: 'Users',
    icon: <NvPersonIcon fontSize="small" />,
    iconPosition: 'start' as TabProps['iconPosition'],
    content: <UserTab />,

    path: `${WORKSPACE_SETTINGS_LINK}${USER_MANAGEMENT_TAB_PATHS.USER}`,
  },
  {
    label: 'Groups',
    icon: <NvGroupIcon fontSize="small" />,
    iconPosition: 'start' as TabProps['iconPosition'],
    content: <Groups />,
    path: `${WORKSPACE_SETTINGS_LINK}${USER_MANAGEMENT_TAB_PATHS.GROUP}`,
  },
];
