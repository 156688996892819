import { ActionNode, FileNode, useUpdateNode } from '@novaera/actioner-service';
import {
  CodeInput,
  NvButton,
  NvCloseIcon,
  NvCondition,
  NvConditionalRender,
  NvCustomShareIcon,
  NvDynamicField,
  NvField,
  NvFingerPrintIcon,
  NvFlex,
  NvForm,
  NvSelect,
  NvShortTextIcon,
  NvTypography,
  isRequired,
} from '@novaera/core';
import { assert } from '@novaera/utils';
import { cloneDeep, noop } from 'lodash';
import { FC, useCallback } from 'react';
import {
  NodeType,
  PropertyPanelHeader,
  PropertyPanelListHeader,
  PropertyPanelSection,
  PropertyPanelSimpleSection,
} from '../../../../../../../../components';
import { OutputItem } from '../../../../../../../../components/output-item';
import { useWorkflowNodeUtilities } from '../../../../../../../../components/property-panel/utils';
import { useWorkflowPermission } from '../../../../../../../user-app-permission-boundary/use-workflow-permission';
import { useGetWorkflowContexts } from '../../../../controllers/use-get-workflow-contexts';
import { useGetWorkflowQueryParams } from '../../../../controllers/use-get-workflow-query-params';
import { userAppGraph } from '../../../../graph-utils/user-app-graph';
import { useNovaeraFlow } from '../../../../use-novaera-flow';
import { ErrorHandlingStrategyPanel } from '../../common/error-handling-strategy-panel';
import { RowItemActionSelection } from '../../common/row-item-action-selection';
import { PUT_FILE_EXAMPLE_PAYLOAD } from './constants';
import { NodePropertiesProps } from './types';

type AccessType = {
  type: 'boolean';
  value?: boolean;
};

type DynamicAccessType = {
  type: 'boolean';
  codeTemplate?: string;
};

export const NodeProperties: FC<NodePropertiesProps> = ({ onCloseClicked, node }) => {
  assert(
    !!node,
    new Error('Since there is a selected node there should be respective node in the workflow object'),
    'ERROR'
  );

  const { userAppId, workflowId } = useGetWorkflowQueryParams();

  const { updateNode } = useNovaeraFlow(userAppGraph);
  const { workflowCodeInputContext } = useGetWorkflowContexts();

  const { hasEditPermission } = useWorkflowPermission();

  const { mutate: updateNodeDetail } = useUpdateNode();

  const { getWorkflowNodeIcon } = useWorkflowNodeUtilities();

  const handleSave = useCallback(
    (newNode: FileNode) => {
      if (!hasEditPermission) {
        return;
      }
      updateNodeDetail({ appId: userAppId, workflowId, nodeAlias: node.alias, payload: newNode });
    },
    [hasEditPermission, updateNodeDetail, userAppId, workflowId, node.alias]
  );

  const handleSaveName = useCallback(
    (newName: string) => {
      if (!hasEditPermission) {
        return;
      }
      updateNodeDetail(
        {
          appId: userAppId,
          workflowId,
          nodeAlias: node.alias,
          payload: { ...node, name: newName },
        },
        {
          onSuccess: () => {
            const graphNode = userAppGraph.node(node.alias);
            const newGraphNode = cloneDeep(graphNode);
            newGraphNode.name = newName;
            updateNode({ newNode: newGraphNode });
          },
        }
      );
    },
    [node, hasEditPermission, updateNode, updateNodeDetail, userAppId, workflowId]
  );

  return (
    <NvFlex width="100%">
      <NvConditionalRender when={hasEditPermission}>
        <PropertyPanelHeader
          icon={getWorkflowNodeIcon({ type: NodeType.PUT_FILE })}
          title={node?.name}
          type={node.nodeOperation.type as NodeType}
          onTitleChange={async (title) => {
            if (title) {
              handleSaveName(title);
            }
          }}
          validateTitle={(title) => (title && title.length > 0 ? undefined : 'This field is required')}
          actions={
            <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
              <NvCloseIcon />
            </NvButton>
          }
        />
      </NvConditionalRender>
      <NvConditionalRender when={!hasEditPermission}>
        <PropertyPanelHeader
          title={node?.name}
          type={NodeType.FILE}
          actions={
            <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
              <NvCloseIcon />
            </NvButton>
          }
        />
      </NvConditionalRender>
      <NvForm
        onSubmit={noop}
        onChange={({ values }) => {
          handleSave(values);
        }}
        autoSaveProps={{ autoSaveType: 'debounce', debounceDelay: 500 }}
        initialValues={node}
      >
        <NvConditionalRender when={!hasEditPermission}>
          <PropertyPanelHeader
            title={node?.name}
            type={NodeType.FILE}
            actions={
              <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
                <NvCloseIcon />
              </NvButton>
            }
          />
        </NvConditionalRender>

        <PropertyPanelSection title="Key" icon={<NvFingerPrintIcon />}>
          <NvField
            formControlStyle={{
              width: '100%',
            }}
            isRequired
            showErrorMessageOnlyWhenBlur
            hasRequiredIndicator
            validators={[isRequired()]}
            name={'nodeOperation.key'}
            format={(value) => {
              return value?.value;
            }}
            parse={(value) => {
              return {
                type: 'string',
                value,
              };
            }}
            component={<CodeInput context={workflowCodeInputContext} placeholder="{{ ... }}" />}
          />
        </PropertyPanelSection>

        <PropertyPanelSection title="Name" icon={<NvShortTextIcon />}>
          <NvField
            formControlStyle={{
              width: '100%',
            }}
            format={(value) => {
              return value?.value;
            }}
            parse={(value) => {
              return {
                type: 'string',
                value,
              };
            }}
            name={'nodeOperation.name'}
            component={<CodeInput context={workflowCodeInputContext} placeholder="{{ ... }}" />}
          />
        </PropertyPanelSection>

        <PropertyPanelSection title="Sharing option" icon={<NvCustomShareIcon />}>
          <NvDynamicField<AccessType, DynamicAccessType, string | boolean, AccessType | DynamicAccessType>
            name={'nodeOperation.publicAccess'}
            format={(value, isDynamic) => {
              if (isDynamic) {
                return (value as DynamicAccessType)?.codeTemplate;
              } else {
                return (value as AccessType)?.value;
              }
            }}
            parse={(value, isDynamic) => {
              if (isDynamic) {
                return {
                  type: 'boolean',
                  codeTemplate: value,
                };
              } else {
                return {
                  type: 'boolean',
                  value,
                };
              }
            }}
            defaultValue={{
              type: 'boolean',
              value: false,
            }}
            component={
              <NvSelect
                options={[
                  {
                    label: 'Public',
                    value: true,
                  },
                  {
                    label: 'Private',
                    value: false,
                  },
                ]}
              ></NvSelect>
            }
            context={workflowCodeInputContext}
          />
        </PropertyPanelSection>

        <PropertyPanelSection title="Source">
          <NvFlex flexDirection={'column'} gap={'8px'}>
            <NvTypography>{`Enter a public URL or use an
            action
           to determine the file's source.`}</NvTypography>
            <NvField
              name={'nodeOperation.source.type'}
              defaultValue={'url'}
              component={
                <NvSelect
                  options={[
                    {
                      label: 'Action',
                      value: 'action',
                    },
                    {
                      label: 'URL',
                      value: 'url',
                    },
                  ]}
                />
              }
            />
            <NvCondition when={'nodeOperation.source.type'} is={'action'}>
              <NvField<ActionNode | undefined>
                name={'nodeOperation.source.action'}
                component={({ onChange, value }) => {
                  return (
                    <RowItemActionSelection
                      onChange={(node) => {
                        onChange(node);
                      }}
                      value={value}
                      actionTag="download"
                    />
                  );
                }}
              />
            </NvCondition>
            <NvCondition when={'nodeOperation.source.type'} is={'url'}>
              <NvField
                formControlStyle={{
                  width: '100%',
                }}
                format={(value) => {
                  return value?.value ?? '';
                }}
                parse={(value) => {
                  return {
                    type: 'string',
                    value,
                  };
                }}
                isRequired
                showErrorMessageOnlyWhenBlur
                hasRequiredIndicator
                name={'nodeOperation.source.url'}
                component={<CodeInput context={workflowCodeInputContext} placeholder="{{ ... }}" />}
              />
            </NvCondition>
          </NvFlex>
        </PropertyPanelSection>

        <PropertyPanelListHeader title="Response" tooltip="The response displayed below is solely a sample." />
        <PropertyPanelSimpleSection>
          <OutputItem content={PUT_FILE_EXAMPLE_PAYLOAD} isTestResultFailed={false} />
        </PropertyPanelSimpleSection>

        <NvField name="errorHandlingStrategy" component={<ErrorHandlingStrategyPanel />} />
      </NvForm>
    </NvFlex>
  );
};
