import {
  useGetActionerEvent,
  useGetIntegration,
  useGetIntegrationAction,
  useGetWorkflowExecutionResult,
  useGetWorkflowNodeExecution,
} from '@novaera/actioner-service';
import {
  NvAccessTimeRounded,
  NvArrowForwardIcon,
  NvBox,
  NvButton,
  NvCollapse,
  NvDivider,
  NvFlex,
  NvReactJson,
  NvRouterLink,
  NvSensorsIcon,
  NvSkeleton,
  NvTypography,
} from '@novaera/core';
import { useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import { differenceInMilliseconds, format } from 'date-fns';
import { useMemo } from 'react';
import { LogoPlaceholder, PropertyPanelSection } from '../../../../../components';
import { USER_APP_EVENT_DETAIL } from '../../../constants';
import {
  isActionerEventDetails,
  isConditionExecutionDetails,
  isDispatchedWorkflowExecutionDetails,
  isQANodeExecutionDetails,
} from '../utils';
import { ConditionResults } from './condition-results';
import { WorkflowHistoryNodeExecutionHeader } from './header';
import { WorkflowHistoryNodeExecutionIcon } from './icon';
import { RunWorkflowLink } from './run-workflow-link';
import { ExecutionSource } from './source';
import { SourceCard } from './source/styled';
import { WorkflowHistoryNodeExecutionStatusIcon } from './status-icon';
import { WorkflowHistoryNodeExecutionStatusLabel } from './status-label';
import {
  ResponseHeader,
  WorkflowHistoryNodeExecutionCard,
  WorkflowHistoryNodeExecutionCardHeader,
  WorkflowHistoryNodeExecutionResultCard,
} from './styled';
import { WorkflowHistoryNodeExecutionProps } from './types';

export const WorkflowHistoryNodeExecution: React.FC<React.PropsWithChildren<WorkflowHistoryNodeExecutionProps>> = ({
  nodeExecution,
  handleItemClick,
  selectedNodeExecutionObjectKeys,
}) => {
  const theme = useTheme();
  const { userAppId, workflowId, workflowExecutionId } = useParams();

  const isDetailOpen = useMemo(
    () => !!selectedNodeExecutionObjectKeys.includes(nodeExecution.objectKey),
    [nodeExecution.objectKey, selectedNodeExecutionObjectKeys]
  );

  const isPassiveMode = useMemo(
    () =>
      !!(
        selectedNodeExecutionObjectKeys &&
        selectedNodeExecutionObjectKeys.length > 0 &&
        !selectedNodeExecutionObjectKeys.includes(nodeExecution.objectKey)
      ),
    [nodeExecution.objectKey, selectedNodeExecutionObjectKeys]
  );

  const { data: nodeExecutionDetail, isInitialLoading: isNodeExecutionDetailLoading } = useGetWorkflowNodeExecution({
    appId: userAppId,
    workflowId,
    workflowExecutionId,
    nodeExecutionKey: isDetailOpen ? nodeExecution.objectKey : null,
  });

  const { data: workflowExecutionResult, isInitialLoading: isWorkflowExecutionResultLoading } =
    useGetWorkflowExecutionResult({
      userAppId,
      workflowId,
      executionId: workflowExecutionId,
    });

  const { integrationId, actionId, version } = useMemo(() => {
    let integrationId;
    let actionId;
    let version;
    const node = workflowExecutionResult?.nodeSummaries?.[nodeExecution.alias];

    if (node?.type === 'action') {
      integrationId = node.integrationId;
      actionId = node.actionId;
      version = node.version;
    }

    return { integrationId, actionId, version };
  }, [nodeExecution.alias, workflowExecutionResult?.nodeSummaries]);

  const { data: integrationDetail, isInitialLoading: isIntegrationDetailLoading } = useGetIntegration({
    id: integrationId,
  });
  const { data: integrationActionDetail, isInitialLoading: isIntegrationActionDetailLoading } = useGetIntegrationAction(
    { integrationId, actionId, version }
  );

  const { data: actionerEvent, isLoading: isActionerEventLoading } = useGetActionerEvent({
    appId: userAppId,
    id:
      nodeExecutionDetail && isActionerEventDetails(nodeExecutionDetail.executionDetails)
        ? nodeExecutionDetail?.executionDetails?.eventId
        : undefined,
  });

  return (
    <WorkflowHistoryNodeExecutionCard isPassiveMode={isPassiveMode}>
      <WorkflowHistoryNodeExecutionCardHeader onClick={handleItemClick}>
        <NvFlex direction="row" alignItems="center" gap="8px" flex="1 1 auto" minWidth={0}>
          {isIntegrationDetailLoading || isWorkflowExecutionResultLoading ? (
            <NvSkeleton variant="rectangular" width="24px" height="24px" />
          ) : integrationDetail ? (
            <LogoPlaceholder src={integrationDetail.logoUrl} initialText={integrationDetail.name} size="small" />
          ) : (
            <WorkflowHistoryNodeExecutionIcon type={nodeExecution.type} subtype={nodeExecution.subtype} />
          )}

          <NvFlex>
            {isIntegrationActionDetailLoading || isWorkflowExecutionResultLoading ? (
              <NvSkeleton variant="rectangular" height="8px" />
            ) : integrationActionDetail ? (
              <NvFlex direction="row" alignItems="center" gap="2px">
                <WorkflowHistoryNodeExecutionIcon
                  type={nodeExecution.type}
                  subtype={nodeExecution.subtype}
                  width={12}
                  height={12}
                />
                <NvTypography variant="h8" color={theme.palette.nv_node.action}>
                  {integrationActionDetail.name}
                </NvTypography>
              </NvFlex>
            ) : (
              <WorkflowHistoryNodeExecutionHeader type={nodeExecution.type} subtype={nodeExecution.subtype} />
            )}
            <NvTypography variant="h4">{nodeExecution.name}</NvTypography>

            <NvTypography variant="c3" textColor="secondary">
              {`{{${
                nodeExecution.type === 'form'
                  ? 'input'
                  : nodeExecution.type === 'integrationWebhook' ||
                    nodeExecution.type === 'integrationApp' ||
                    nodeExecution.type === 'email' ||
                    nodeExecution.type === 'actionerEvent' ||
                    nodeExecution.type === 'genericWebhook'
                  ? 'event'
                  : nodeExecution.alias
              }}}`}
            </NvTypography>
          </NvFlex>
        </NvFlex>
        <NvFlex alignItems="flex-end" flex="0 0 auto">
          <NvFlex direction="row" alignItems="center" gap="8px">
            <WorkflowHistoryNodeExecutionStatusIcon status={nodeExecution.status} />
            <NvDivider orientation="vertical" borderColor={theme.palette.nv_neutral_alpha[20]} sx={{ height: '8px' }} />
            <NvTypography variant="h7" textColor="subtle">
              #{nodeExecution.sequenceNo}
            </NvTypography>
          </NvFlex>
          <NvFlex direction="row" gap="3px" alignItems="center">
            <NvAccessTimeRounded sx={{ width: '16px', height: '16px' }} />
            <NvTypography variant="body2">
              <b>{format(new Date(nodeExecution.startedAt), 'pp')}</b>
            </NvTypography>
          </NvFlex>
        </NvFlex>
      </WorkflowHistoryNodeExecutionCardHeader>
      <NvCollapse in={isDetailOpen} mountOnEnter unmountOnExit>
        {isNodeExecutionDetailLoading && (
          <NvFlex>
            <WorkflowHistoryNodeExecutionResultCard>
              <NvSkeleton variant="rectangular" height="18px" width="60%" />
            </WorkflowHistoryNodeExecutionResultCard>
            <ResponseHeader title="Response" />
            <NvBox padding="16px">
              <NvSkeleton variant="rectangular" height="120px" width="100%" />
            </NvBox>
          </NvFlex>
        )}
        {nodeExecutionDetail && (
          <NvFlex>
            <WorkflowHistoryNodeExecutionResultCard>
              <NvFlex direction="row" alignItems="center" gap="4px">
                <NvTypography variant="body2" textColor="secondary">
                  Status:
                </NvTypography>

                <NvFlex direction="row" alignItems="center">
                  <WorkflowHistoryNodeExecutionStatusIcon status={nodeExecution.status} />
                  <WorkflowHistoryNodeExecutionStatusLabel status={nodeExecution.status} />
                </NvFlex>
              </NvFlex>
              <NvFlex direction="row" alignItems="center" gap="4px">
                <NvTypography variant="body2" textColor="secondary">
                  Duration:
                </NvTypography>

                <NvTypography variant="body2" textColor="secondary">
                  <b>
                    {differenceInMilliseconds(
                      new Date(nodeExecutionDetail.completedAt),
                      new Date(nodeExecutionDetail.startedAt)
                    )}
                    ms
                  </b>
                </NvTypography>
              </NvFlex>
            </WorkflowHistoryNodeExecutionResultCard>
            {nodeExecution.type === 'workflowDispatcher' &&
              nodeExecutionDetail?.executionDetails &&
              isDispatchedWorkflowExecutionDetails(nodeExecutionDetail.executionDetails) &&
              nodeExecutionDetail.executionDetails.dispatchedWorkflow && (
                <RunWorkflowLink dispatchedWorkflow={nodeExecutionDetail.executionDetails.dispatchedWorkflow} />
              )}

            {nodeExecutionDetail?.executionDetails &&
              isConditionExecutionDetails(nodeExecutionDetail.executionDetails) &&
              nodeExecutionDetail.executionDetails.ruleEvaluationResults && (
                <ConditionResults executionDetails={nodeExecutionDetail.executionDetails} />
              )}

            {nodeExecutionDetail?.executionDetails && isActionerEventDetails(nodeExecutionDetail.executionDetails) && (
              <PropertyPanelSection title="Published to">
                <SourceCard>
                  <NvSensorsIcon />
                  <NvTypography variant="h5">Event</NvTypography>
                  <NvDivider
                    orientation="vertical"
                    sx={{ height: '8px' }}
                    borderColor={theme.palette.nv_neutral_alpha[30]}
                  />
                  {isActionerEventLoading ? (
                    <NvSkeleton variant="rectangular" width={'100px'} />
                  ) : (
                    <NvTypography variant="h6" textColor="secondary">
                      {actionerEvent?.name}
                    </NvTypography>
                  )}
                  <NvFlex flex="1 1 auto" minWidth={0} direction="row-reverse">
                    <NvRouterLink to={USER_APP_EVENT_DETAIL(userAppId, '1')}>
                      <NvButton color="secondary" onlyIcon size="small">
                        <NvArrowForwardIcon />
                      </NvButton>
                    </NvRouterLink>
                  </NvFlex>
                </SourceCard>
              </PropertyPanelSection>
            )}

            <ResponseHeader title="Inputs" />
            <NvReactJson src={nodeExecutionDetail.input} collapsed={1} />
            {isQANodeExecutionDetails(nodeExecutionDetail.executionDetails) && (
              <>
                <ResponseHeader title="Execution details" />
                <NvReactJson src={nodeExecutionDetail.executionDetails} collapsed={1} />
              </>
            )}
            <ResponseHeader title="Response" />
            <NvReactJson src={nodeExecutionDetail.response} collapsed={1} />
            {(nodeExecution.type === 'form' ||
              nodeExecution.type === 'genericWebhook' ||
              nodeExecution.type === 'integrationApp' ||
              nodeExecution.type === 'email' ||
              nodeExecution.type === 'actionerEvent' ||
              nodeExecution.type === 'integrationWebhook') &&
              workflowExecutionResult?.executionSourceData && (
                <ExecutionSource executionSourceData={workflowExecutionResult.executionSourceData} />
              )}
          </NvFlex>
        )}
      </NvCollapse>
    </WorkflowHistoryNodeExecutionCard>
  );
};
