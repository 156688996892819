import { NvBox, NvFlex, NvScrollBox, NvSplitPane, SplitPanelItem, useFormState } from '@novaera/core';
import { EmptyState } from '../../empty-state';
import { FunctionsFormValues } from '../types';
import { useFunctions } from './controller/use-functions';
import { FunctionDetail } from './function-detail';
import { FunctionList } from './function-list';
import { FunctionListWrapper } from './styled';

export const Functions: React.FC<React.PropsWithChildren<unknown>> = () => {
  const {
    values: { functions },
  } = useFormState<FunctionsFormValues>();
  const { onAddNewFunction } = useFunctions();
  const hasFunctions = Boolean(functions?.length);

  return hasFunctions ? (
    <NvSplitPane direction="horizontal">
      <SplitPanelItem minSize="300px" initialSize="420px">
        <FunctionListWrapper>
          <NvFlex height={1} padding="16px">
            <FunctionList />
          </NvFlex>
        </FunctionListWrapper>
      </SplitPanelItem>
      <SplitPanelItem minSize="500px">
        <NvScrollBox marginLeft="-12px">
          <NvFlex height={1} width={1}>
            <FunctionDetail />
          </NvFlex>
        </NvScrollBox>
      </SplitPanelItem>
    </NvSplitPane>
  ) : (
    <NvBox padding={3}>
      <EmptyState
        onClick={onAddNewFunction}
        actionText="Add function"
        subText="Add a new function to write blocks of code in JavaScript."
      />
    </NvBox>
  );
};
