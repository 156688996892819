import { NvFlex, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { Line, Separator } from '../../sign-in/welcome/styled';
import { WorkspaceListItem } from '../../sign-in/workspace-panel/list/item';
import { useSlackWorkspace } from './controllers/use-slack-workspace';
import { NvCardWrapper } from './styled';

export const Workspaces: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { joinedWorkspaces, notJoinedWorkspaces, onWorkspaceItemClicked, loadingList } = useSlackWorkspace();

  const { palette } = useTheme();

  return (
    <NvFlex alignItems={'center'} rowGap="24px">
      {(joinedWorkspaces?.length > 0 || notJoinedWorkspaces?.length > 0) && (
        <NvFlex width={'100%'} maxWidth="466px" padding="0 40px">
          <Separator>
            <Line marginRight={'10px'} color={palette.nv_neutral_alpha[40]} />
            <NvFlex>
              <NvTypography textColor="secondary">or</NvTypography>
            </NvFlex>
            <Line color={palette.nv_neutral_alpha[40]} />
          </Separator>
        </NvFlex>
      )}
      <NvFlex>
        <NvFlex rowGap={'32px'} padding="0 40px">
          {joinedWorkspaces?.length > 0 && (
            <NvFlex>
              <NvCardWrapper>
                <NvFlex>
                  <NvFlex marginBottom={'8px'}>
                    <NvTypography variant="h2">Open a workspace</NvTypography>
                  </NvFlex>
                  <NvFlex marginBottom={'24px'}>
                    <NvTypography variant="body1">Choose a workspace to get back to running actions.</NvTypography>
                  </NvFlex>
                  <NvFlex rowGap={'16px'}>
                    {joinedWorkspaces.map((workspace) => (
                      <WorkspaceListItem
                        displayName={workspace.workspaceName}
                        subdomain=""
                        onClick={() => {
                          onWorkspaceItemClicked(workspace.workspaceId, workspace.workspaceSubdomain);
                        }}
                      />
                    ))}
                  </NvFlex>
                </NvFlex>
              </NvCardWrapper>
            </NvFlex>
          )}
          {notJoinedWorkspaces?.length > 0 && (
            <NvFlex>
              <NvCardWrapper>
                <NvFlex>
                  <NvFlex marginBottom={'8px'}>
                    <NvTypography variant="h2">Join a workspace</NvTypography>
                  </NvFlex>
                  <NvFlex marginBottom={'24px'}>
                    <NvTypography variant="body1">
                      Your Slack workspace has already connected with some Actioner workspaces. Select one of them below
                      to join your colleagues.
                    </NvTypography>
                  </NvFlex>
                  <NvFlex rowGap={'16px'}>
                    {notJoinedWorkspaces.map((workspace) => (
                      <WorkspaceListItem
                        isLoading={loadingList?.find((w) => w === workspace.workspaceId) !== undefined ?? false}
                        displayName={workspace.workspaceName}
                        subdomain=""
                        onClick={() => {
                          onWorkspaceItemClicked(workspace.workspaceId, workspace.workspaceSubdomain);
                        }}
                        IndicatorComponent={
                          <NvTypography variant="h4" color={palette.nv_main[40]}>
                            Join
                          </NvTypography>
                        }
                      />
                    ))}
                  </NvFlex>
                </NvFlex>
              </NvCardWrapper>
            </NvFlex>
          )}
        </NvFlex>
      </NvFlex>
    </NvFlex>
  );
};
