import { CatalogEntityType, useListCatalogEntityTypes } from '@novaera/actioner-service';
import { useCallback, useMemo, useState } from 'react';
import { useCatalogContext } from '../../provider/use-catalog-provider';
import { SideMenuItemType } from '../../types';
import { useCatalogUtil } from '../use-catalog-util';
import { useSelectedCatalogEntity } from '../use-selected-catalog-entity';

export const useCatalogSideMenuController = () => {
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const { setSelectedEntityTypeId } = useCatalogContext();

  const { setSelectedDetailItem } = useSelectedCatalogEntity();

  const { data: listItems, isLoading } = useListCatalogEntityTypes({
    queryParams: { nameFilter: searchKeyword },
  });

  const { navigateToCatalogEntityType } = useCatalogUtil();

  const filteredListItems = useMemo(
    () => listItems?.pages.reduce<CatalogEntityType[]>((acc, page) => [...acc, ...(page.entityTypes ?? [])], []) ?? [],
    [listItems]
  );

  const handleSearchKeywordChanged = useCallback((keyword?: string) => {
    setSearchKeyword(keyword ?? '');
  }, []);

  const handleEntityTypeSelected = useCallback(
    (item: SideMenuItemType) => {
      navigateToCatalogEntityType(item.id);
      setSelectedEntityTypeId(item.id);

      if (item.parameters && item.parameters?.length > 0) {
        setSelectedDetailItem({
          index: 0,
          selectedItemDetail: item.parameters[0],
        });
      }
    },
    [navigateToCatalogEntityType, setSelectedDetailItem, setSelectedEntityTypeId]
  );

  const handleEntityTypeDeleted = useCallback(
    (item: SideMenuItemType, itemIndex: number) => {
      if (filteredListItems && filteredListItems.length > 0) {
        const nextItemIndex = itemIndex > 0 ? itemIndex - 1 : 0;
        navigateToCatalogEntityType(filteredListItems[nextItemIndex].id);
      }
    },
    [filteredListItems, navigateToCatalogEntityType]
  );

  return {
    onSearchKeywordChanged: handleSearchKeywordChanged,
    onItemSelected: handleEntityTypeSelected,
    items: filteredListItems,
    isCatalogEntitiesLoading: isLoading,
    onEntityTypeDeleted: handleEntityTypeDeleted,
  };
};
