import {
  ParameterMappings,
  UIComponentType,
  useGetWorkflow,
  useUpdateNode,
  WorkflowDispatchConfiguration,
  WorkFlowDispatcherNode,
} from '@novaera/actioner-service';
import {
  CodeInput,
  NvButton,
  NvCloseIcon,
  NvDeleteOutlineIcon,
  NvField,
  NvFlex,
  NvForm,
  NvMenuWithItems,
  NvMoreHorizIcon,
  NvSelect,
  NvTypography,
  NvWorkflowIcon,
} from '@novaera/core';
import { useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import { cloneDeep, noop } from 'lodash';
import { FC, useCallback, useMemo, useState } from 'react';
import {
  NodeType,
  PropertyPanelHeader,
  PropertyPanelListHeader,
  PropertyPanelSection,
  PropertyPanelSimpleSection,
} from '../../../../../../../../components';
import { FormIdentifierProvider } from '../../../../../../../../providers/form-identifier-provider';
import { useWorkflowPermission } from '../../../../../../../user-app-permission-boundary/use-workflow-permission';
import { WorkflowSelect } from '../../../../components/workflow-select';
import { useGetWorkflowContexts } from '../../../../controllers/use-get-workflow-contexts';
import { userAppGraph } from '../../../../graph-utils/user-app-graph';
import { useNovaeraFlow } from '../../../../use-novaera-flow';
import { usePropertyPanelContext } from '../../../provider';
import { WorkflowInputs } from '../../action-node-property-panel-drawer/action-node-properties/workflow-inputs';
import { ErrorHandlingStrategyPanel } from '../../common/error-handling-strategy-panel';
import { ErrorHandlingStrategyOptions } from '../../common/error-handling-strategy-panel/constants';
import { WorkflowDispatcherPropertiesProps } from './types';

export const WorkflowDispatcherProperties: FC<WorkflowDispatcherPropertiesProps> = ({
  workflowDispatcherNode,
  onCloseClicked,
}) => {
  const theme = useTheme();
  const { userAppId, workflowId } = useParams();
  const { deleteNodeAndUpdateGraph } = usePropertyPanelContext();
  const { hasEditPermission } = useWorkflowPermission();
  const { mutate: updateNodeDetail } = useUpdateNode();
  const { updateNode } = useNovaeraFlow(userAppGraph);
  const { workflowCodeInputContext } = useGetWorkflowContexts();

  const [formId, setFormId] = useState<string | undefined>();

  const initialWorkflowDispatcherNode = useMemo<WorkFlowDispatcherNode>(
    () =>
      workflowDispatcherNode.dispatchConfiguration
        ? workflowDispatcherNode
        : {
            ...workflowDispatcherNode,
            dispatchConfiguration: {
              type: 'static',
              appId: userAppId,
              workflowId: '',
              blocking: true,
              parameterMappings: [],
            },
          },
    [userAppId, workflowDispatcherNode]
  );

  const { savedWorkflow } = useGetWorkflow({
    appId: userAppId,
    workflowId:
      workflowDispatcherNode.dispatchConfiguration?.type === 'static'
        ? workflowDispatcherNode.dispatchConfiguration.workflowId
        : undefined,
  });

  const hasDynamicInputParameters = useMemo(
    () =>
      savedWorkflow?.trigger?.type === 'form' &&
      savedWorkflow.trigger.inputParameters.find(
        (inputParameter) => inputParameter.uiComponent.type === UIComponentType.DYNAMIC_INPUT
      ),
    [savedWorkflow?.trigger]
  );

  const handleSaveName = useCallback(
    (newName: string) => {
      updateNodeDetail(
        {
          appId: userAppId,
          workflowId,
          nodeAlias: workflowDispatcherNode.alias,
          payload: { ...workflowDispatcherNode, name: newName },
        },
        {
          onSuccess: () => {
            const graphNode = userAppGraph.node(workflowDispatcherNode.alias);
            const newGraphNode = cloneDeep(graphNode);
            newGraphNode.name = newName;
            updateNode({ newNode: newGraphNode });
          },
        }
      );
    },
    [workflowDispatcherNode, updateNode, updateNodeDetail, userAppId, workflowId]
  );

  const handleOnChange = useCallback(
    (values: WorkFlowDispatcherNode) => {
      const { dispatchConfiguration, errorHandlingStrategy, ...rest } = values;
      if (dispatchConfiguration) {
        const { dispatchConfiguration: prevDispatchConfiguration } = workflowDispatcherNode;
        const hasChangedWorkflowId =
          prevDispatchConfiguration?.type === 'static' &&
          dispatchConfiguration?.type === 'static' &&
          prevDispatchConfiguration.workflowId !== dispatchConfiguration.workflowId;

        const dispatchConfigurationPayload: WorkflowDispatchConfiguration =
          dispatchConfiguration?.type === 'dynamic'
            ? {
                type: 'dynamic',
                appIdTemplate: dispatchConfiguration.appIdTemplate,
                workflowIdTemplate: dispatchConfiguration.workflowIdTemplate,
                inputTemplate: dispatchConfiguration.inputTemplate,
                blocking: dispatchConfiguration.blocking,
              }
            : {
                type: 'static',
                appId: userAppId,
                workflowId: dispatchConfiguration.workflowId,
                blocking: dispatchConfiguration.blocking,
                ...(savedWorkflow?.trigger?.type === 'form'
                  ? {
                      parameterMappings: hasChangedWorkflowId ? [] : dispatchConfiguration.parameterMappings,
                      ...(hasDynamicInputParameters && { formId }),
                    }
                  : { inputTemplate: dispatchConfiguration.inputTemplate }),
              };
        updateNodeDetail({
          appId: userAppId,
          workflowId,
          nodeAlias: workflowDispatcherNode.alias,
          payload: {
            ...rest,
            dispatchConfiguration: dispatchConfigurationPayload,
            ...(dispatchConfigurationPayload.blocking && { errorHandlingStrategy }),
          },
        });
      }
    },
    [
      formId,
      hasDynamicInputParameters,
      savedWorkflow?.trigger?.type,
      updateNodeDetail,
      userAppId,
      workflowDispatcherNode,
      workflowId,
    ]
  );

  return (
    <NvFlex width="100%">
      <PropertyPanelHeader
        hasEditRight={hasEditPermission}
        icon={<NvWorkflowIcon />}
        title={workflowDispatcherNode.name}
        type={NodeType.WORKFLOW_DISPATCHER}
        onTitleChange={async (title) => {
          if (title) {
            handleSaveName(title);
          }
        }}
        validateTitle={(title) => (title && title.length > 0 ? undefined : 'This field is required')}
        actions={
          <>
            <NvMenuWithItems
              triggerButton={{
                content: <NvMoreHorizIcon />,
                props: { onlyIcon: true, size: 'small', color: 'secondary' },
              }}
              menuItems={[
                {
                  name: 'Delete',
                  icon: (
                    <NvDeleteOutlineIcon
                      htmlColor={theme.palette.nv_error[40]}
                      sx={{ width: '16px', height: '16px' }}
                    />
                  ),
                  onClick: () => {
                    deleteNodeAndUpdateGraph({ nodeId: workflowDispatcherNode.alias });
                  },
                },
              ]}
            />

            <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
              <NvCloseIcon />
            </NvButton>
          </>
        }
      />
      <NvForm<WorkFlowDispatcherNode>
        onSubmit={noop}
        onChange={({ values }) => {
          handleOnChange(values);
        }}
        initialValues={initialWorkflowDispatcherNode}
        keepDirtyOnReinitialize
        autoSaveProps={{ autoSaveType: 'debounce', debounceDelay: 500 }}
      >
        <PropertyPanelSimpleSection>
          <WorkflowSelect
            context={workflowCodeInputContext}
            isDynamic={workflowDispatcherNode.dispatchConfiguration?.type === 'dynamic'}
            typeFieldName="dispatchConfiguration.type"
            dynamicFieldAppIdFieldName="dispatchConfiguration.appIdTemplate"
            dynamicFieldWorkflowIdFieldName="dispatchConfiguration.workflowIdTemplate"
            staticFieldWorkflowIdFieldName="dispatchConfiguration.workflowId"
          />
        </PropertyPanelSimpleSection>
        {workflowDispatcherNode.dispatchConfiguration?.type === 'static' &&
          workflowDispatcherNode.dispatchConfiguration?.workflowId &&
          savedWorkflow?.trigger?.type === 'form' && (
            <FormIdentifierProvider initialFormId={workflowDispatcherNode.dispatchConfiguration.formId}>
              <NvField<ParameterMappings>
                formControlStyle={{ width: '100%' }}
                name="dispatchConfiguration.parameterMappings"
                component={({ value, onChange }) => {
                  return (
                    <WorkflowInputs
                      workflowId={savedWorkflow.id}
                      initialParameterMappings={value || []}
                      onChange={({ parameterMappings, formId }) => {
                        setFormId(formId);
                        onChange(parameterMappings);
                      }}
                      context={workflowCodeInputContext}
                    />
                  );
                }}
              />
            </FormIdentifierProvider>
          )}
        {((workflowDispatcherNode.dispatchConfiguration?.type === 'static' &&
          workflowDispatcherNode.dispatchConfiguration?.workflowId &&
          savedWorkflow?.trigger?.type === 'genericWebhook') ||
          workflowDispatcherNode.dispatchConfiguration?.type === 'dynamic') && (
          <>
            <PropertyPanelListHeader
              title={savedWorkflow?.trigger?.type === 'genericWebhook' ? 'Workflow event' : 'Workflow input template'}
              tooltip={
                savedWorkflow?.trigger?.type === 'genericWebhook'
                  ? 'You need to provide the payload to be used as the workflow trigger payload during runtime.'
                  : 'You need to provide workflow input values in JSON format.'
              }
            />
            <PropertyPanelSimpleSection>
              <NvField
                formControlStyle={{ width: '100%' }}
                name="dispatchConfiguration.inputTemplate"
                hint={
                  <NvTypography variant="body3" textColor="ghost">
                    Supports only JSON format.
                  </NvTypography>
                }
                hideLabel
                component={<CodeInput context={workflowCodeInputContext} growingHeight initialHeight="238px" />}
              />
            </PropertyPanelSimpleSection>
          </>
        )}
        {workflowDispatcherNode.dispatchConfiguration && (
          <PropertyPanelSection
            collapsible
            title="Process type"
            tooltip="For running other nodes without waiting for the workflow result, select async option. For waiting and running other nodes after completion, choose sync option."
          >
            <NvField
              formControlStyle={{ width: '200px' }}
              name="dispatchConfiguration.blocking"
              component={
                <NvSelect<boolean>
                  renderValue={(value) => {
                    if (value) {
                      return (
                        <NvTypography variant="body2" component="div">
                          Synchronously{' '}
                          <NvTypography variant="body2" component="span" textColor="ghost">
                            (default)
                          </NvTypography>
                        </NvTypography>
                      );
                    } else {
                      return <NvTypography variant="body2">Asynchronously</NvTypography>;
                    }
                  }}
                  options={[
                    { label: 'Synchronously', value: true },
                    { label: 'Asynchronously', value: false },
                  ]}
                />
              }
            />
          </PropertyPanelSection>
        )}

        {workflowDispatcherNode.dispatchConfiguration?.blocking && (
          <NvField
            name="errorHandlingStrategy"
            defaultValue={ErrorHandlingStrategyOptions[0]}
            component={<ErrorHandlingStrategyPanel />}
          />
        )}
      </NvForm>
    </NvFlex>
  );
};
