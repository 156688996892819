import { NvAddButtonWithLabel, NvDeleteOutlineIcon, NvFlex } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';

import { Item } from '../../../request-tab/requests/list/item';
import { useFunctionList } from './controller/use-function-list';

export const FunctionList: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { functions, selectedFunctionId, onHandleDeleteClick, onHandleItemClick, onAddNewFunction } = useFunctionList();
  const theme = useTheme();
  return (
    <NvFlex paddingBottom={10}>
      <NvAddButtonWithLabel onClick={onAddNewFunction} variant="small" label="Add function" />

      <NvFlex height={1} spacing={0.75} mt={1.5}>
        {functions?.map((currentFunction) => {
          const { name, id } = currentFunction;

          return (
            <Item
              isSelected={selectedFunctionId === id}
              label={name}
              onItemClick={() => {
                if (id) {
                  onHandleItemClick(id);
                }
              }}
              actionButtons={[
                {
                  name: 'Delete',
                  onClick: () => {
                    onHandleDeleteClick(id);
                  },
                  icon: (
                    <NvDeleteOutlineIcon
                      htmlColor={theme.palette.nv_error[40]}
                      sx={{ width: '16px', height: '16px' }}
                    />
                  ),
                },
              ]}
              key={`function_list_item_${id}`}
              variant="Function"
            />
          );
        })}
      </NvFlex>
    </NvFlex>
  );
};
