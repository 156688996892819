import {
  NodeSummaries,
  useBatchGetIntegration,
  useGetWorkflow,
  useUpdateWorkflowGraph,
  Workflow,
} from '@novaera/actioner-service';
import {
  isAxiosError,
  NvBox,
  NvConditionalRender,
  NvCustomManagedAppsIcon,
  NvDivider,
  NvFlex,
  NvLink,
  NvSkeleton,
  NvTypography,
  useToast,
} from '@novaera/core';
import { useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import { assert } from '@novaera/utils';
import _ from 'lodash';
import { FC, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { useReactFlow } from 'reactflow';
import { useWorkflowPermission } from '../../../user-app-permission-boundary/use-workflow-permission';
import { APP_WORKFLOW_EDGE_TYPES } from './components/user-app-workflow-edge-types';
import { APP_WORKFLOW_NODE_TYPES } from './components/user-app-workflow-node-types';
import { AppWorkflowAllNodeType } from './components/user-app-workflow-node-types/types';
import { userAppGraph } from './graph-utils/user-app-graph';
import { WorkflowDragAndDropProvider } from './providers/workflow-drag-and-drop-provider';
import { convertBackendToUi } from './service/get-nodes';
import { StyledWorkflowCanvas } from './styled';
import { WorkflowCanvasProps } from './types';
import { useNovaeraFlow } from './use-novaera-flow';
import { useWorkflowCanvas } from './use-workflow-canvas';
import { isEditable } from './utils/is-draggable';
import { moveNode } from './utils/nodes-drag-and-drop/utils/move-node';
import { WorkflowPermissionInformationBox } from './workflow-permission-information-box/styled';
import { WorkflowStickyPanel } from './workflow-sticky-panel';

export const WorkflowCanvas: FC<PropsWithChildren<WorkflowCanvasProps>> = ({ isSaveProgress }) => {
  const { userAppId, workflowId } = useParams();
  const { mutate } = useBatchGetIntegration();
  const [initialNodeSummaries, setInitialNodeSummaries] = useState<NodeSummaries>();
  const { workflow, isLoading: isGettingWorkflow } = useGetWorkflow({ appId: userAppId, workflowId });
  const [initialWorkflow, setInitialWorkflow] = useState<Workflow>();
  const { hasEditPermission, isManagedApp } = useWorkflowPermission();
  const theme = useTheme();
  const { getNode } = useReactFlow();
  const { updateWholeGraph } = useNovaeraFlow<AppWorkflowAllNodeType>(userAppGraph);
  const { mutate: updateWorkflowGraph } = useUpdateWorkflowGraph();
  const { addToast } = useToast();

  useEffect(() => {
    if (workflow?.nodeSummaries && !initialNodeSummaries) {
      setInitialNodeSummaries(workflow?.nodeSummaries);
    }
  }, [initialNodeSummaries, workflow?.nodeSummaries]);

  useEffect(() => {
    if (initialNodeSummaries) {
      const integrationIds = Object.values(initialNodeSummaries)
        .filter((n) => n.type === 'action' && n.integrationId)
        .map((n) => {
          assert(n.type === 'action', new Error('Filtered nodes should be in action type'), 'ERROR');
          return n.integrationId;
        });
      const uniqueIntegrationIds = _.uniq(integrationIds);
      if (uniqueIntegrationIds.length > 0) {
        mutate(uniqueIntegrationIds);
      }
    }
  }, [mutate, initialNodeSummaries]);

  useEffect(() => {
    if (workflow && !initialWorkflow) {
      setInitialWorkflow(workflow);
    }
  }, [workflow, initialWorkflow]);

  const { novaeraEdges, novaeraNodes } = useMemo(() => {
    return convertBackendToUi({ workflow: initialWorkflow });
  }, [initialWorkflow]);
  const { handleSelectionChange } = useWorkflowCanvas();

  const handleNodeDropStopped = useCallback(
    ({
      nodeId,
      itemOnDropped,
    }: {
      nodeId: string;
      itemOnDropped:
        | {
            sourceId: string;
            targetId: string;
          }
        | string;
    }) => {
      const node = getNode(nodeId);

      const graph = workflow?.graph;
      const originalData = _.cloneDeepWith(workflow);

      if (graph && node) {
        const { updatedNodes, vertices } = moveNode({
          vertices: [...graph.vertices],
          nodeId,
          itemOnDropped,
          graph,
        });

        // make optimistic update for better ui experience
        updateWholeGraph({
          newWorkflow: {
            ...workflow,
            graph: {
              ...workflow.graph,
              vertices,
            },
            nodeSummaries: {
              ...workflow.nodeSummaries,
              ...Object.fromEntries(updatedNodes.map((node) => [node.alias, node])),
            },
          },
        });

        updateWorkflowGraph(
          {
            appId: userAppId,
            workflowId,
            payload: {
              graph: { vertices },
              updates: { nodes: Object.values(updatedNodes) },
            },
          },
          {
            onError: (error) => {
              addToast(isAxiosError(error) ? error.message : 'Something went wrong.', {
                variant: 'error',
              });

              // get back to previous state
              if (!originalData) {
                return;
              }
              updateWholeGraph({
                newWorkflow: originalData,
              });
            },
          }
        );
      }
    },
    [addToast, getNode, updateWholeGraph, updateWorkflowGraph, userAppId, workflow, workflowId]
  );

  return isGettingWorkflow ? (
    <NvBox width="100%" height="100%" gap="8px" display="flex" alignItems="center" justifyContent="center">
      <NvSkeleton variant="circular" width="10px" height="10px" animation="pulse"></NvSkeleton>
      <NvSkeleton variant="circular" width="10px" height="10px" animation="pulse"></NvSkeleton>
      <NvSkeleton variant="circular" width="10px" height="10px" animation="pulse"></NvSkeleton>
      <NvSkeleton variant="circular" width="10px" height="10px" animation="pulse"></NvSkeleton>
      <NvSkeleton variant="circular" width="10px" height="10px" animation="pulse"></NvSkeleton>
    </NvBox>
  ) : novaeraNodes.length > 0 ? (
    <>
      <WorkflowDragAndDropProvider
        draggable={isEditable({
          workflowCreationSource: workflow?.creationSource,
          isAppManaged: !!isManagedApp,
          hasEditPermission: hasEditPermission,
        })}
        onDropFinished={(draggedNode, droppedTarget) => {
          handleNodeDropStopped({
            nodeId: draggedNode.id,
            itemOnDropped: droppedTarget,
          });
        }}
      >
        <StyledWorkflowCanvas
          defaultNodes={novaeraNodes}
          defaultEdges={novaeraEdges}
          nodeTypes={APP_WORKFLOW_NODE_TYPES}
          edgeTypes={APP_WORKFLOW_EDGE_TYPES}
          onSelectionchange={handleSelectionChange}
        />
      </WorkflowDragAndDropProvider>
      {<WorkflowStickyPanel isSaveProgress={isSaveProgress} isManaged={isManagedApp} />}
      <NvConditionalRender when={!hasEditPermission || Boolean(isManagedApp)}>
        <NvConditionalRender when={Boolean(isManagedApp) && !(workflow?.creationSource !== 'app-directory')}>
          <WorkflowPermissionInformationBox>
            <NvCustomManagedAppsIcon htmlColor={theme.palette.nv_neutral[0]} />
            <NvTypography variant="h4" color={theme.palette.nv_neutral[0]}>
              Managed app
            </NvTypography>
            <NvDivider
              orientation="vertical"
              sx={{
                color: theme.palette.nv_neutral[600],
                height: '10px',
              }}
            />
            <NvTypography variant="body2" color={theme.palette.nv_neutral[60]}>
              This app is managed by <b>Actioner</b>
            </NvTypography>
            <NvDivider
              orientation="vertical"
              color={theme.palette.nv_neutral[600]}
              sx={{
                height: '7.5px',
              }}
            />
            <NvTypography variant="body3" noWrap color={theme.palette.nv_neutral[0]}>
              <NvLink linkVariant="inherit" href="https://actioner.com/help/managed-apps" target="_blank">
                Learn more.
              </NvLink>
            </NvTypography>
          </WorkflowPermissionInformationBox>
        </NvConditionalRender>
        <NvConditionalRender
          when={!hasEditPermission && !(Boolean(isManagedApp) && !(workflow?.creationSource !== 'app-directory'))}
        >
          <WorkflowPermissionInformationBox>
            <NvTypography variant="h5" color={theme.palette.nv_neutral[0]}>
              As a workflow runner, you cannot change any configurations on this workflow.
            </NvTypography>
          </WorkflowPermissionInformationBox>
        </NvConditionalRender>
      </NvConditionalRender>
    </>
  ) : (
    <NvFlex flex="1 1 auto" alignItems="center" justifyContent="center">
      <NvTypography variant="h1">There is no node to show.</NvTypography>
    </NvFlex>
  );
};
