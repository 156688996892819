import { Integration, useSearchConnectionsService } from '@novaera/actioner-service';
import {
  NvAddBoxIcon,
  NvButton,
  NvFlex,
  NvSkeleton,
  NvSlackIcon,
  NvSlackIconNotColored,
  NvTypography,
} from '@novaera/core';
import { useParams } from '@novaera/route';
import { PropertyPanelSection } from '../../../../../../../components';
import { ConnectSlackButton } from '../../../../../../../integrations/components/missing-connection/missing-connection-body/item/connect-slack-button';
import { SlackWorkspaceSectionCard } from './styled';

export const SlackWorkspaceSection: React.FC<{ integration: Integration }> = ({ integration }) => {
  const { userAppId } = useParams();
  const { data: connectionsResult, isLoading: isConnectionsLoading } = useSearchConnectionsService({
    payload: {
      appId: userAppId,
      schemaId: integration?.connectionSchemaId,
    },
  });
  const slackConnection = connectionsResult?.connections[0];

  return (
    <PropertyPanelSection title="Slack workspace" icon={<NvSlackIconNotColored />}>
      {isConnectionsLoading ? (
        <NvSkeleton height="32px" width="100%" variant="rectangular" />
      ) : slackConnection ? (
        <SlackWorkspaceSectionCard>
          <NvFlex direction="row" alignItems="center" gap="4px" flex="1 1 auto" minWidth={0}>
            <NvSlackIcon />
            <NvTypography variant="h5" flex="1 1 auto" minWidth={0} noWrap>
              {slackConnection.name.split('#')[0]}
            </NvTypography>
          </NvFlex>
        </SlackWorkspaceSectionCard>
      ) : (
        <NvFlex gap="8px" alignItems="flex-start">
          <NvTypography variant="body2" textColor="subtle">
            You don’t have any connected <b>Slack workspace.</b>
          </NvTypography>
          <ConnectSlackButton
            ButtonBase={({ onClick }) => (
              <NvButton size="small" color="secondary" startIcon={<NvAddBoxIcon />} onClick={onClick}>
                Connect Slack workspace
              </NvButton>
            )}
          />
        </NvFlex>
      )}
    </PropertyPanelSection>
  );
};
