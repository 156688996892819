import { NvAxios, useQuery, useToast } from '@novaera/core';
import { GetUserPermissionsResponse } from './types';

const getUserPermissions = async () => {
  const result = await NvAxios.get<GetUserPermissionsResponse>('/v1/access');
  return result.data;
};

export const useGetUserPermissions = () => {
  const { addToast } = useToast();
  return useQuery(['getUserPermissions'], getUserPermissions, {
    onError: ({ response }: { response: { data: string } }) => {
      addToast(`User permissions cannot be fetched. ${response.data}`, { variant: 'error' });
    },
  });
};
