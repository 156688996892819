import { Integration } from '@novaera/actioner-service';
import {
  NvActionFilledIcon,
  NvBox,
  NvNotesIcon,
  NvPowerRoundedIcon,
  NvTrackChangesIcon,
  NvTypography,
} from '@novaera/core';
import { useLocation, useParams } from '@novaera/route';
import { FC, memo } from 'react';
import { SideMenu } from '../../../components/side-menu';
import { SideMenuDividerItem } from '../../../components/side-menu/divider';
import { SideMenuItemFrameMain, SideMenuTitleFrame } from '../../../components/side-menu/styled';
import { TitleLoading } from '../../../components/side-menu/title-loading';
import { INTEGRATION_ACTIONS, INTEGRATION_CONNECTION, INTEGRATION_EVENTS, INTEGRATION_SUMMARY } from '../../constants';
import { CollapsibleMenu } from './collapsible-menu';
import { IntegrationSelect } from './integration-select';
import { SideMenuActions } from './side-menu-actions';
import { SideMenuEventDispatchers } from './side-menu-event-dispatchers';

const IntegrationSideMenuOriginal: FC<
  React.PropsWithChildren<{
    integration?: Integration;
    isLoading: boolean;
    onClickActionCreateButton: () => void;
    onClickEventRuleCreateButton: () => void;
  }>
> = ({ integration, isLoading, onClickActionCreateButton, onClickEventRuleCreateButton }) => {
  const { integrationId } = useParams();
  const { pathname } = useLocation();

  return (
    <SideMenu>
      {isLoading ? (
        <TitleLoading />
      ) : (
        <SideMenuTitleFrame hasNoBorderBottom>
          {integration && <IntegrationSelect integration={integration} />}
        </SideMenuTitleFrame>
      )}

      <SideMenuItemFrameMain to="" selected={pathname === INTEGRATION_SUMMARY(integrationId)}>
        <NvBox display="flex" alignItems="center" gap="8px">
          <NvNotesIcon />
          <NvTypography variant="h4">Summary</NvTypography>
        </NvBox>
      </SideMenuItemFrameMain>
      <SideMenuDividerItem />

      <SideMenuItemFrameMain to="connection" selected={pathname === INTEGRATION_CONNECTION(integrationId)}>
        <NvBox display="flex" alignItems="center" gap="8px">
          <NvPowerRoundedIcon />
          <NvTypography variant="h4">Connection schema</NvTypography>
        </NvBox>
      </SideMenuItemFrameMain>

      <SideMenuDividerItem />

      <CollapsibleMenu
        name="Triggers"
        to="triggers"
        startIcon={<NvTrackChangesIcon />}
        onClickAddButton={onClickEventRuleCreateButton}
        selected={pathname === INTEGRATION_EVENTS(integrationId)}
      >
        <SideMenuEventDispatchers />
      </CollapsibleMenu>

      <SideMenuDividerItem />

      <CollapsibleMenu
        name="Actions"
        to="actions"
        startIcon={<NvActionFilledIcon />}
        onClickAddButton={onClickActionCreateButton}
        selected={pathname === INTEGRATION_ACTIONS(integrationId)}
      >
        <SideMenuActions />
      </CollapsibleMenu>
    </SideMenu>
  );
};

export const IntegrationSideMenu = memo(IntegrationSideMenuOriginal);
