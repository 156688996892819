import { APP_PERMISSION, SlackApp, UserApp } from '@novaera/actioner-service';
import {
  NvArticleIcon,
  NvBox,
  NvChevronRightIcon,
  NvCollapse,
  NvDNSIcon,
  NvFlex,
  NvHomeIcon,
  NvManageAccountsIcon,
  NvSensorsIcon,
  NvSlackIconNotColored,
  NvSmartToyIcon,
  NvTimerIcon,
  NvTuneIcon,
  NvTypography,
  NvWorkflowIcon,
} from '@novaera/core';
import { useParams } from '@novaera/route';
import { usePathCondition } from '@novaera/utils';
import classNames from 'classnames';
import { FC, PropsWithChildren, memo, useState } from 'react';
import { SideMenu } from '../../../components/side-menu';
import { SideMenuItemFrameMain, SideMenuTitleFrame } from '../../../components/side-menu/styled';
import { UserAppPermissionBoundary } from '../../user-app-permission-boundary';
import {
  USER_APP_AI_ASSISTANT,
  USER_APP_CONFIG,
  USER_APP_DATABASE,
  USER_APP_DESCRIPTION,
  USER_APP_DOCUMENTS,
  USER_APP_EVENTS,
  USER_APP_JOBS_RECURRING,
  USER_APP_JOBS_SCHEDULED,
  USER_APP_PERMISSIONS,
  USER_APP_SLACK_APP,
  USER_APP_TABS,
  USER_APP_WORKFLOWS,
} from '../constants';
import { ChevronIcon, CollapseItemWrapper, ConfigMenuLine, ConfigMenuWrapper } from './styled';
import { UserAppSelect } from './user-app-select';
import { UserAppSideMenuLoading } from './user-app-side-menu-loading';

const UserAppSideMenuOriginal: FC<
  PropsWithChildren<{ userApp?: UserApp; isLoading: boolean; slackApp?: SlackApp }>
> = ({ userApp, isLoading, slackApp }) => {
  const [selectedMenu, setSelectedMenu] = useState<string | undefined>();
  const { userAppId } = useParams();

  usePathCondition([
    {
      do: () => {
        setSelectedMenu(USER_APP_TABS.DESCRIPTION);
      },
      when: (location) => USER_APP_DESCRIPTION(userAppId) === location.pathname,
    },
    {
      do: () => {
        setSelectedMenu(USER_APP_TABS.CONFIG);
      },
      when: (location) => USER_APP_CONFIG(userAppId) === location.pathname,
    },
    {
      do: () => {
        setSelectedMenu(USER_APP_TABS.WORKFLOWS);
      },
      when: (location) => USER_APP_WORKFLOWS(userAppId) === location.pathname,
    },
    {
      do: () => {
        setSelectedMenu(USER_APP_TABS.DATABASE);
      },
      when: (location) => USER_APP_DATABASE(userAppId) === location.pathname,
    },
    {
      do: () => {
        setSelectedMenu(USER_APP_TABS.JOBS);
      },
      when: (location) =>
        USER_APP_JOBS_RECURRING(userAppId) === location.pathname ||
        USER_APP_JOBS_SCHEDULED(userAppId) === location.pathname,
    },
    {
      do: () => {
        setSelectedMenu(USER_APP_TABS.SLACK_APP);
      },
      when: (location) => USER_APP_SLACK_APP(userAppId) === location.pathname,
    },
    {
      do: () => {
        setSelectedMenu(USER_APP_TABS.PERMISSIONS);
      },
      when: (location) => USER_APP_PERMISSIONS(userAppId) === location.pathname,
    },
    {
      do: () => {
        setSelectedMenu(USER_APP_TABS.DOCUMENTS);
      },
      when: (location) => USER_APP_DOCUMENTS(userAppId) === location.pathname,
    },
    {
      do: () => {
        setSelectedMenu(USER_APP_TABS.EVENTS);
      },
      when: (location) => USER_APP_EVENTS(userAppId) === location.pathname,
    },
    {
      do: () => {
        setSelectedMenu(USER_APP_TABS.AI_ASSISTANT);
      },
      when: (location) => USER_APP_AI_ASSISTANT(userAppId) === location.pathname,
    },
  ]);

  const [isSettingsOpen, setIsSettingsOpen] = useState(!userApp?.managed);

  return isLoading ? (
    <UserAppSideMenuLoading />
  ) : (
    <SideMenu>
      <SideMenuTitleFrame hasNoBorderBottom>{userApp && <UserAppSelect userApp={userApp} />}</SideMenuTitleFrame>
      <SideMenuItemFrameMain to="description" selected={selectedMenu === USER_APP_TABS.DESCRIPTION}>
        <NvBox display="flex" alignItems="center" gap="8px">
          <NvHomeIcon />
          <NvTypography variant="h4">Home</NvTypography>
        </NvBox>
      </SideMenuItemFrameMain>
      <UserAppPermissionBoundary appId={userAppId} permission={APP_PERMISSION.APP_EDIT}>
        <SideMenuItemFrameMain to={USER_APP_TABS.DOCUMENTS} selected={selectedMenu === USER_APP_TABS.DOCUMENTS}>
          <NvBox display="flex" alignItems="center" gap="8px">
            <NvArticleIcon />
            <NvTypography variant="h4">Documentation</NvTypography>
          </NvBox>
        </SideMenuItemFrameMain>
      </UserAppPermissionBoundary>
      <NvFlex gap={'8px'}>
        <CollapseItemWrapper
          onClick={() => {
            setIsSettingsOpen(!isSettingsOpen);
          }}
        >
          <NvBox display="flex" alignItems="center" gap="8px">
            <ChevronIcon className={classNames({ opened: isSettingsOpen })}>
              <NvChevronRightIcon />
            </ChevronIcon>

            <NvTypography variant="h4">Settings</NvTypography>
          </NvBox>
        </CollapseItemWrapper>
        <NvCollapse in={isSettingsOpen}>
          <NvFlex flexDirection={'row'}>
            <ConfigMenuLine />

            <ConfigMenuWrapper>
              <UserAppPermissionBoundary appId={userAppId} permission={APP_PERMISSION.APP_EDIT}>
                <NvFlex>
                  <SideMenuItemFrameMain to="config" selected={selectedMenu === USER_APP_TABS.CONFIG}>
                    <NvBox display="flex" alignItems="center" gap="8px">
                      <NvTuneIcon />
                      <NvTypography variant="h4">Configs</NvTypography>
                    </NvBox>
                  </SideMenuItemFrameMain>
                </NvFlex>
              </UserAppPermissionBoundary>

              <NvFlex>
                <SideMenuItemFrameMain to="workflows" selected={selectedMenu === USER_APP_TABS.WORKFLOWS}>
                  <NvBox display="flex" alignItems="center" gap="8px">
                    <NvWorkflowIcon />
                    <NvTypography variant="h4">Workflows</NvTypography>
                  </NvBox>
                </SideMenuItemFrameMain>
              </NvFlex>

              <UserAppPermissionBoundary appId={userAppId} permission={APP_PERMISSION.APP_EDIT}>
                <NvFlex>
                  <SideMenuItemFrameMain to="ai-assistant" selected={selectedMenu === USER_APP_TABS.AI_ASSISTANT}>
                    <NvBox display="flex" alignItems="center" gap="8px">
                      <NvSmartToyIcon />
                      <NvTypography variant="h4">AI assistant</NvTypography>
                    </NvBox>
                  </SideMenuItemFrameMain>
                </NvFlex>
              </UserAppPermissionBoundary>

              <NvFlex>
                <SideMenuItemFrameMain to="events" selected={selectedMenu === USER_APP_TABS.EVENTS}>
                  <NvBox display="flex" alignItems="center" gap="8px">
                    <NvSensorsIcon />
                    <NvTypography variant="h4">Events</NvTypography>
                  </NvBox>
                </SideMenuItemFrameMain>
              </NvFlex>

              <UserAppPermissionBoundary appId={userAppId} permission={APP_PERMISSION.APP_EDIT}>
                <NvFlex>
                  <SideMenuItemFrameMain to="tables" selected={selectedMenu === USER_APP_TABS.DATABASE}>
                    <NvBox display="flex" alignItems="center" gap="8px" width="180px">
                      <NvDNSIcon />
                      <NvTypography variant="h4">Tables</NvTypography>
                    </NvBox>
                  </SideMenuItemFrameMain>
                </NvFlex>
              </UserAppPermissionBoundary>

              <NvFlex>
                <SideMenuItemFrameMain to="jobs" selected={selectedMenu === USER_APP_TABS.JOBS}>
                  <NvBox display="flex" alignItems="center" gap="8px">
                    <NvTimerIcon />
                    <NvTypography variant="h4">Jobs</NvTypography>
                  </NvBox>
                </SideMenuItemFrameMain>
              </NvFlex>

              <UserAppPermissionBoundary appId={userAppId} permission={APP_PERMISSION.APP_EDIT}>
                {slackApp && (
                  <NvFlex>
                    <SideMenuItemFrameMain to="slack-app" selected={selectedMenu === USER_APP_TABS.SLACK_APP}>
                      <NvBox display="flex" alignItems="center" gap="8px">
                        <NvSlackIconNotColored />
                        <NvTypography variant="h4">Standalone Slack app</NvTypography>
                      </NvBox>
                    </SideMenuItemFrameMain>
                  </NvFlex>
                )}
                <NvFlex>
                  <SideMenuItemFrameMain to="permissions" selected={selectedMenu === USER_APP_TABS.PERMISSIONS}>
                    <NvBox display="flex" alignItems="center" gap="8px">
                      <NvManageAccountsIcon />
                      <NvTypography variant="h4">Permissions</NvTypography>
                    </NvBox>
                  </SideMenuItemFrameMain>
                </NvFlex>
              </UserAppPermissionBoundary>
            </ConfigMenuWrapper>
          </NvFlex>
        </NvCollapse>
      </NvFlex>
    </SideMenu>
  );
};

export const UserAppSideMenu = memo(UserAppSideMenuOriginal);
