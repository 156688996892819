import { USER_PERMISSION } from '@novaera/actioner-service';
import { NvAddBoxIcon, NvBox, NvButton, NvFlex } from '@novaera/core';
import { SimpleSearchInput } from '../../../../components';
import { UserPermissionBoundary } from '../../../../user-permission-boundary';

export const SearchAndButtonBar = ({
  setSearchUserValue,
  onButtonClick,
  buttonLabel,
  AddButtonStartIcon = <NvAddBoxIcon />,
}: {
  AddButtonStartIcon?: JSX.Element;
  setSearchUserValue: (keyword: string) => void;
  onButtonClick: () => void;
  buttonLabel: string;
}) => {
  return (
    <NvFlex direction="row" alignItems="center" gap="8px">
      <NvBox flex="1 1 auto" minWidth={0}>
        <SimpleSearchInput
          onKeywordChanged={(keyword) => {
            setSearchUserValue(keyword || '');
          }}
        />
      </NvBox>
      <UserPermissionBoundary permission={USER_PERMISSION.USER_INVITE}>
        <NvButton
          color="ghost"
          size="small"
          startIcon={AddButtonStartIcon}
          onClick={onButtonClick}
          sx={{ flex: '0 0 auto' }}
        >
          {buttonLabel}
        </NvButton>
      </UserPermissionBoundary>
    </NvFlex>
  );
};
