import {
  Integration,
  IntegrationAction,
  IntegrationType,
  ParameterMappings,
  useGetWorkflow,
} from '@novaera/actioner-service';
import {
  NvActionFilledIcon,
  NvButton,
  NvChip,
  NvCloseIcon,
  NvDeleteOutlineIcon,
  NvField,
  NvFlex,
  NvImage,
  NvMenuWithItems,
  NvTypography,
  useField,
  useForm,
} from '@novaera/core';

import { useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import { useMemo } from 'react';
import {
  ActionParameterMapper,
  ActionSearchField,
  PropertyPanelHeader,
  PropertyPanelListHeader,
  PropertyPanelSection,
  PropertyPanelSimpleSection,
  TestActionProvider,
  TestActionResult,
  UpdateVersionButton,
} from '../../../../../../../../../components';
import { IntegrationInitialLogo } from '../../../../../../../../../integrations/components/integration-initial-logo';
import { useFormIdentifierContext } from '../../../../../../../../../providers/form-identifier-provider';
import { useWorkflowPermission } from '../../../../../../../../user-app-permission-boundary/use-workflow-permission';
import { SlackWorkspaceOrWorkflowConnectionSection } from '../../../../../components/slack-workspace-or-workflow-connection-section';
import { useGetWorkflowContexts } from '../../../../../controllers/use-get-workflow-contexts';
import { IntegrationBox } from '../../../integration-trigger-property-panel-drawer/integration-trigger-properties/styled';

export const ActionPanel = ({
  integration,
  integrationAction,
  onPanelClose,
  onDelete,
  actionTag,
}: {
  integration?: Integration;
  integrationAction?: IntegrationAction;
  onPanelClose: () => void;
  onDelete: (integration?: Integration, integrationAction?: IntegrationAction) => void;
  actionTag?: string;
}) => {
  const theme = useTheme();

  const { formId } = useFormIdentifierContext();
  const { userAppId, workflowId } = useParams();
  const { change } = useForm();

  const { workflowCodeInputContext } = useGetWorkflowContexts();

  const { hasEditPermission, isManagedApp } = useWorkflowPermission();

  const fieldNames = {
    actionId: 'actionId',
    connectionId: 'connectionId',
    parameterMappings: 'parameterMappings',
    formId: 'formId',
    integrationId: 'integrationId',
    version: 'version',
  } as const;

  const { input: connectionInput } = useField(fieldNames.connectionId);

  return (
    <NvFlex width="100%" height="100%">
      <>
        <PropertyPanelHeader
          title="Source action"
          actions={
            <>
              <NvMenuWithItems
                menuItems={[
                  {
                    name: 'Delete',
                    icon: (
                      <NvDeleteOutlineIcon
                        htmlColor={theme.palette.nv_error[40]}
                        sx={{ width: '16px', height: '16px' }}
                      />
                    ),
                    onClick: () => {
                      onDelete(integration, integrationAction);
                    },
                  },
                ]}
              />
              <NvButton onlyIcon size="small" color="secondary" onClick={onPanelClose}>
                <NvCloseIcon />
              </NvButton>
            </>
          }
        />
        <PropertyPanelSection icon={<NvActionFilledIcon />} title="Action">
          <NvFlex gap="8px">
            <IntegrationBox>
              <NvImage
                FallBack={<IntegrationInitialLogo name={integration?.name ?? ''} size="small" />}
                src={integration?.logoUrl}
                imageShape="square"
                size="smaller"
                className="integration-image"
              />
              <NvTypography variant="h5" textColor="main" flex="1 1 auto" noWrap>
                {integration?.name}
              </NvTypography>
              {hasEditPermission &&
                !isManagedApp &&
                integration &&
                integrationAction &&
                integration?.latestVersion.number !== integrationAction?.version.number && (
                  <UpdateVersionButton
                    onClick={() => {
                      change(fieldNames.version, integration?.latestVersion.number);
                    }}
                  />
                )}
              {integration?.scope.type === 'workspace' ? <NvChip label="custom" compact /> : undefined}
              {integrationAction && (
                <NvTypography variant="body2" textColor="subtle">
                  v{integrationAction?.version.number}.0.0
                </NvTypography>
              )}
            </IntegrationBox>
            {integration && (
              <ActionSearchField
                integrationId={integration.id}
                version={integration.latestVersion.number}
                fieldName={'actionId'}
                textFieldOverrides={{ size: 'small' }}
                tag={actionTag}
              />
            )}
          </NvFlex>
        </PropertyPanelSection>
        <TestActionProvider>
          {/* Open it after test with sample context feature will be implemented */}
          {/* <TestActionButton
                  testActionParams={{
                    formId,
                    integrationId: optionProducer.integrationId,
                    actionId: optionProducer.actionId,
                    inputParameters: initialParameterMappings,
                    draft: integrationAction?.state === ActionState.DRAFT,
                    ...(optionProducer.connectionId ? { connectionId: optionProducer.connectionId } : {}),
                    versionNumber: integrationAction?.version.number ?? 1,
                  }}
                /> */}

          <NvFlex width="100%">
            <NvField<ParameterMappings>
              name={fieldNames.parameterMappings}
              defaultValue={[]}
              format={(value) => value}
              parse={(value) => value}
              component={({ value, onChange }) =>
                integration?.type === IntegrationType.DATA_MODEL ? (
                  <div>Not supported!</div>
                ) : (
                  <>
                    <PropertyPanelListHeader
                      title="Action inputs"
                      tooltip="When a user attempts to select a value for this field, the options will be populated based on the response of the action with the given inputs below."
                    />
                    <PropertyPanelSimpleSection>
                      {integration && (
                        <ActionParameterMapper
                          appId={userAppId}
                          workflowId={workflowId}
                          type="in-workflow"
                          actionId={integrationAction?.id}
                          integrationId={integration?.id}
                          // do not send connectionId if it is Slack
                          {...(integration?.type !== 'slack' ? { connectionId: connectionInput.value } : {})}
                          versionNumber={integrationAction?.version.number ?? 1}
                          initialParameterMappings={value}
                          onParameterMappingsChanged={({ inputParametersValues, shouldAddFormId }) => {
                            onChange(inputParametersValues);
                            change(fieldNames.formId, shouldAddFormId ? formId : undefined);
                          }}
                          context={workflowCodeInputContext}
                        />
                      )}
                    </PropertyPanelSimpleSection>
                  </>
                )
              }
            />
          </NvFlex>

          <TestActionResult
            sampleResponse={integrationAction?.responseConfiguration?.exampleResponse}
            tooltip="The response displayed below is solely a sample."
          />
        </TestActionProvider>
      </>
    </NvFlex>
  );
};
