import {
  FormExecutionContext,
  InputParameterValues,
  SimpleParameterMapping,
  Trigger,
  useExecuteWorkflowService,
  useGetWorkflowExecutionResultForPolling,
  UserWorkflowExecutionResponse,
  UserWorkflowExecutionResultResponse,
  UseWorkflowExecutionProps,
  useWorkflowServiceUtils,
} from '@novaera/actioner-service';
import { isAxiosError, useToast } from '@novaera/core';
import { noop } from '@novaera/utils';

import { useCallback, useState } from 'react';
import { InputFormValues } from '../../../../../../../../action-designer/providers/input-values';
import { RealTimeTaskEngine } from '../../../../../../../../real-time/real-time-task-engine';
import { REAL_TIME_TASKS } from '../../../../../../../../real-time/real-time-tasks-object';
import { useGetWorkflowQueryParams } from '../../../../controllers/use-get-workflow-query-params';
import {
  OnExecutionFinishedCallback,
  WorkflowOutputRefRunImmediatelyParams,
} from '../../../../providers/workflow-reference-output-provider/types';

export const useExecuteWorkflow = ({
  workflowId,
  workflowTriggerType,
  isDraft,
  formId,
  onExecutionRunFinished,
  workflowOutputRefParams,
}: {
  workflowId: string;
  workflowTriggerType?: Trigger['type'];
  isDraft: boolean;
  formId?: string;
  onExecutionRunFinished?: OnExecutionFinishedCallback;
  workflowOutputRefParams?: WorkflowOutputRefRunImmediatelyParams;
}) => {
  const { userAppId } = useGetWorkflowQueryParams();
  const {
    mutate: executeWorkflow,
    isLoading: isExecutionServiceLoading,
    data: executeWorkflowData,
    reset: resetExecutionWorkflowService,
  } = useExecuteWorkflowService();
  const {
    mutateAsync: getWorkflowExecutionResult,
    isLoading: isGetWorkflowLoading,
    reset,
  } = useGetWorkflowExecutionResultForPolling();

  const { addToast } = useToast();

  const { getExecutionResultFromCache } = useWorkflowServiceUtils();

  const [isLoading, setIsLoading] = useState(false);

  const executeWorkflowSuccess = useCallback(
    (result: UserWorkflowExecutionResponse) => {
      if (result) {
        const { executionIdentifier: executionId } = result;
        const newTask = new RealTimeTaskEngine<
          UserWorkflowExecutionResultResponse,
          {
            id: string;
            properties: Record<string, unknown>;
            type: 'workflow-execution';
            userId: string;
            workflowId: string;
          }
        >({
          id: executionId,
          pollingCallback: async () => {
            const result = await getWorkflowExecutionResult({ executionId, userAppId, workflowId }, { onError: noop });

            if (result.status === 'in_progress') {
              throw new Error('in_progress');
            }

            return result;
          },
          resultAction: async ({ resultContext: result, source }) => {
            if (source === 'socket') {
              const result = await getWorkflowExecutionResult(
                { executionId, userAppId, workflowId },
                { onError: noop }
              );
              onExecutionRunFinished?.({ executionIdentifier: executionId, workflowId, result });
            } else {
              onExecutionRunFinished?.({ executionIdentifier: executionId, workflowId, result });
            }

            setIsLoading(false);
          },
        });

        newTask.executePolling();
      }
    },
    [getWorkflowExecutionResult, onExecutionRunFinished, userAppId, workflowId]
  );

  const handleExecuteWorkflow: (values: InputFormValues) => void = useCallback(
    (values: InputFormValues) => {
      setIsLoading(true);

      let triggerContext: UseWorkflowExecutionProps['triggerContext'];

      if (workflowTriggerType === 'form') {
        const parameterValues: SimpleParameterMapping<InputParameterValues>[] = [];
        Object.keys(values).forEach((key) => {
          parameterValues.push(values[key] as SimpleParameterMapping<InputParameterValues>);
        });

        const formTriggerContext: FormExecutionContext = {
          type: 'form',
          parameterValues,
          formId,
        };

        triggerContext = formTriggerContext;
      } else {
        if (!workflowTriggerType) {
          return;
        }

        triggerContext = {
          type: workflowTriggerType,
          eventContext: {
            event: typeof values.event === 'string' ? JSON.parse(values.event) : values.event,
          },
        };
      }

      if (triggerContext) {
        executeWorkflow(
          {
            userAppId,
            workflowId,
            triggerContext,
            isDraft,
          },
          {
            onSuccess: executeWorkflowSuccess,
            onError: (error) => {
              addToast(isAxiosError(error) ? error.response?.data : 'An error occurred!', { variant: 'error' });
              setIsLoading(false);
            },
          }
        );
      }
    },
    [addToast, executeWorkflow, executeWorkflowSuccess, formId, isDraft, userAppId, workflowId, workflowTriggerType]
  );

  const executionIdentifierToFetch = workflowOutputRefParams?.identifier ?? executeWorkflowData?.executionIdentifier;
  const result = executionIdentifierToFetch
    ? getExecutionResultFromCache({
        executionId: executionIdentifierToFetch,
        workflowId,
      })
    : undefined;

  const handleCleanResult = useCallback(() => {
    setIsLoading(false);
    if (executeWorkflowData?.executionIdentifier) {
      delete REAL_TIME_TASKS[executeWorkflowData?.executionIdentifier];
    }
    resetExecutionWorkflowService();
    reset();
  }, [executeWorkflowData?.executionIdentifier, reset, resetExecutionWorkflowService]);

  return {
    onExecuteWorkflow: handleExecuteWorkflow,
    isLoading: isExecutionServiceLoading || isLoading || isGetWorkflowLoading || result?.status === 'in_progress',
    result: result,
    cleanResult: handleCleanResult,
    executionIdentifier: executionIdentifierToFetch,
  };
};
