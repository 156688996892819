import {
  InputParameter,
  InputParameters,
  ParameterMapping,
  ParameterMappings,
  UIComponentType,
  useGetIntegrationAction,
} from '@novaera/actioner-service';
import { NvDynamicFormIcon, NvFlex, NvSkeleton, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { FC, PropsWithChildren, useCallback, useMemo } from 'react';
import { InputValuesProvider } from '../../../action-designer/providers/input-values';
import { InputOptionsWrapper } from '../../../action-designer/providers/inputs-options-wrapper';
import { SearchAsYouTypeValuesProvider } from '../../../action-designer/providers/search-as-you-type-values';
import { DynamicInputWrapper, useDynamicInputContext } from '../../dynamic-input';
import { GetDynamicInputResponseFunction } from '../../dynamic-input/providers/action-dynamic-input-provider/types';
import { WaitForProvider, useWaitForContext } from '../../dynamic-input/providers/wait-for-provider';
import { ActionPropertyMapperProps } from '../types';
import { useCalculateShouldAddFormId } from '../utils';
import { ActionParameterMapperForm } from './action-parameter-mapper-form';

export const ActionParameterMapper: FC<ActionPropertyMapperProps> = ({
  actionId,
  integrationId,
  versionNumber,
  onParameterMappingsChanged,
  initialParameterMappings,
  filterInputParameters,
  hideInputParameterLabel,
  context,
  replaceParameterMappingsForOptions,
  isForceHideSwitch,
  ...rest
}) => {
  const theme = useTheme();
  const { data: action, isInitialLoading: isActionLoading } = useGetIntegrationAction({
    actionId,
    integrationId,
    includeDraft: false,
    version: versionNumber,
  });

  const inputParameters = useMemo(
    () =>
      (filterInputParameters ? action?.inputParameters?.filter(filterInputParameters) : action?.inputParameters) ?? [],
    [action?.inputParameters, filterInputParameters]
  );
  const initialInputValues = useMemo(() => {
    const retVal = initialParameterMappings?.reduce((prev, current) => {
      const parameterId = current.parameterId;
      return { ...prev, [parameterId]: current };
    }, {});
    return retVal;
  }, [initialParameterMappings]);

  const conditionFunction = useCallback<
    (params: { conditionVariable: unknown; prevConditionVariable: unknown }) => boolean
  >(({ conditionVariable, prevConditionVariable }) => {
    return !!(prevConditionVariable && !conditionVariable);
  }, []);

  return !isActionLoading ? (
    actionId && inputParameters && inputParameters.length > 0 ? (
      <InputValuesProvider initialInputFormValues={initialInputValues}>
        <SearchAsYouTypeValuesProvider>
          <DynamicInputWrapper
            actionId={actionId}
            integrationId={integrationId}
            versionNumber={versionNumber}
            storeDynamicFormParametersPermanently
            {...rest}
          >
            {({ isBusy, getDynamicInputParameters }) => (
              <InputOptionsWrapper
                initialInputValues={initialInputValues}
                integrationId={integrationId}
                actionId={actionId}
                replaceParameterMappingsForOptions={replaceParameterMappingsForOptions}
                {...rest}
              >
                <WaitForProvider
                  conditionVariable={isBusy}
                  argsChanged={getDynamicInputParameters}
                  conditionFunction={conditionFunction}
                >
                  <ActionParameterMapperInner
                    inputParameters={inputParameters}
                    onParameterMappingsChanged={onParameterMappingsChanged}
                    initialParameterMappings={initialParameterMappings}
                    hideInputParameterLabel={hideInputParameterLabel}
                    context={context}
                    isForceHideSwitch={isForceHideSwitch}
                  />
                </WaitForProvider>
              </InputOptionsWrapper>
            )}
          </DynamicInputWrapper>
        </SearchAsYouTypeValuesProvider>
      </InputValuesProvider>
    ) : (
      <NvFlex direction="row" alignItems="center" gap="8px" height="32px">
        <NvDynamicFormIcon sx={{ width: '16px', height: '16px' }} htmlColor={theme.palette.nv_neutral[60]} />
        <NvTypography variant="body2" textColor="ghost">
          This action doesn't take any inputs.
        </NvTypography>
      </NvFlex>
    )
  ) : (
    <NvFlex gap="16px">
      <NvSkeleton variant="rectangular" height="32px" />
      <NvSkeleton variant="rectangular" height="32px" />
      <NvSkeleton variant="rectangular" height="32px" />
    </NvFlex>
  );
};

export const ActionParameterMapperInner: React.FC<
  PropsWithChildren<
    Pick<
      ActionPropertyMapperProps,
      | 'onParameterMappingsChanged'
      | 'initialParameterMappings'
      | 'hideInputParameterLabel'
      | 'context'
      | 'isForceHideSwitch'
    > & { inputParameters: InputParameters }
  >
> = ({
  inputParameters,
  onParameterMappingsChanged,
  initialParameterMappings,
  hideInputParameterLabel,
  context,
  isForceHideSwitch,
}) => {
  const { dynamicInputParameters, handleGetDynamicInputComponentState, getDynamicInputParameters } =
    useDynamicInputContext();
  const { wrapMethodForWaitUntil } = useWaitForContext<ParameterMapping[], typeof getDynamicInputParameters>();

  const { calculateShouldAddFormId } = useCalculateShouldAddFormId({
    handleGetDynamicInputComponentState,
  });

  const handleParameterChanged = useCallback(
    (values: ParameterMappings, getDynamicInputParameters: GetDynamicInputResponseFunction) => {
      const activeInputParameterIds = [...inputParameters, ...dynamicInputParameters].map(({ id }) => id);

      return onParameterMappingsChanged({
        inputParametersValues: values,
        activeInputParameterIds,
        shouldAddFormId: calculateShouldAddFormId(values, inputParameters, getDynamicInputParameters),
      });
    },
    [calculateShouldAddFormId, dynamicInputParameters, inputParameters, onParameterMappingsChanged]
  );

  const wrappedHandleParameterChanged = wrapMethodForWaitUntil(({ argsChanged, argsNotChanged }) =>
    handleParameterChanged(argsNotChanged, argsChanged)
  );

  const handleScriptedChange = useCallback(
    ({ inputParameter, scripted }: { inputParameter: InputParameter; scripted: boolean }) => {
      if (inputParameter.uiComponent.type === UIComponentType.DYNAMIC_INPUT) {
        if (scripted) {
          // clear old values of dynamic input parameter mappings if all block converts into scripted mode.
          const dynamicInputParameterIds = dynamicInputParameters.map(({ id }) => id);

          const filteredParameterMappings =
            initialParameterMappings?.filter((p) => !dynamicInputParameterIds.includes(p.parameterId)) ?? [];

          wrappedHandleParameterChanged(filteredParameterMappings);
        }
      }
    },
    [dynamicInputParameters, initialParameterMappings, wrappedHandleParameterChanged]
  );

  return (
    <ActionParameterMapperForm
      inputParameters={inputParameters}
      onParameterMappingsChanged={wrappedHandleParameterChanged}
      initialParameterMappings={initialParameterMappings}
      hideInputParameterLabel={hideInputParameterLabel}
      context={context}
      onScriptedChange={handleScriptedChange}
      isForceHideSwitch={isForceHideSwitch}
    />
  );
};
