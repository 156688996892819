import { useGetSubscriptionStatus, USER_PERMISSION } from '@novaera/actioner-service';
import {
  ColoredChipColor,
  NvActionerIcon,
  NvChip,
  NvCustomCardGiftIcon,
  NvCustomStoreIcon,
  NvCustomUpgradeIcon,
  NvDivider,
  NvManageAccountsIcon,
  NvNavigationActionerText,
  NvPackageIcon,
  NvSettingsIcon,
  NvTypography,
  Portal,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import classNames from 'classnames';
import { formatDistanceToNowStrict } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { ROUTES } from '../../common/routes';
import { UserPermissionBoundary } from '../../user-permission-boundary';
import { PORTAL_ID_FOR_NAVIGATION_POPOVER } from './constants';
import { useNavigationController } from './controllers/use-navigation-controller';
import { MenuItem } from './menu-item';
import { StyledTypography, UpgradeLabelWrapper } from './menu-item/styled';
import { NavigationContextProvider, useNavigationContext } from './nav-context';
import { ProfileMenuItem } from './profile-menu-item';
import {
  MenuHomeWrapper,
  MenuItemsWrapper,
  NavigationBottomWrapper,
  NavigationItemWrapper,
  NavigationLogoBox,
  NavigationMenuWrapper,
  NavigationWorkspaceWrapper,
  StyledCustomUpgradeIcon,
} from './styled';
import { WorkspaceMenu } from './workspace-menu';

const Navigation: React.FC = () => {
  const { palette } = useTheme();
  const [isNavigationOpened, setIsNavigationOpened] = useState<boolean>();
  const { state } = useNavigationContext();
  useNavigationController();

  const isPopupMenuOpened = useMemo(() => Boolean(state.openedPopoverId), [state]);
  const { data: subscription } = useGetSubscriptionStatus();

  useEffect(() => {
    window.onkeyup = function (event) {
      setIsNavigationOpened(false);
    };
  }, []);

  return (
    <Portal>
      <div id={PORTAL_ID_FOR_NAVIGATION_POPOVER}></div>
      <NavigationMenuWrapper
        className={classNames({
          'popup-menu-opened': isPopupMenuOpened,
          closed: !isNavigationOpened,
        })}
        onMouseEnter={() => {
          setIsNavigationOpened(true);
        }}
        onMouseLeave={() => {
          setIsNavigationOpened(true);
        }}
      >
        <NavigationItemWrapper to="/">
          <NavigationLogoBox>
            <NvActionerIcon sx={{ width: '40px', height: '40px' }} />
          </NavigationLogoBox>
          <NvNavigationActionerText
            sx={{
              width: '70px',
              height: 'auto',
            }}
            className="hidden-item logo-text-wrapper"
          />
        </NavigationItemWrapper>
        <NavigationWorkspaceWrapper>
          <WorkspaceMenu />
        </NavigationWorkspaceWrapper>
        <MenuItemsWrapper>
          <MenuItem
            type="link"
            key="action_pack_menu"
            id="action_pack_menu"
            icon={<NvPackageIcon />}
            labelProps={{ type: 'typography', labelText: 'My apps' }}
            link={ROUTES.UserApps}
          />
          {/* <MenuItem
            type="link"
            key="catalog_menu"
            id="catalog_menu"
            icon={<NvCustomCatalogIcon />}
            labelProps={{ type: 'typography', labelText: 'Catalog' }}
            link={ROUTES.Catalog}
          /> */}
          <MenuItem
            type="link"
            key="action_store_menu"
            id="action_store_menu"
            icon={<NvCustomStoreIcon data-testid="action-store-menu-button" />}
            labelProps={{ type: 'typography', labelText: 'App directory' }}
            link={ROUTES.AppDirectory}
          />
        </MenuItemsWrapper>

        <NavigationBottomWrapper>
          <MenuHomeWrapper>
            <MenuItem
              type="link"
              key="users_menu_item"
              id="users_menu_item"
              icon={<NvManageAccountsIcon data-testid="users-menu-button" />}
              labelProps={{ type: 'typography', labelText: 'User management' }}
              link="/workspace/user-management"
            />
            <UserPermissionBoundary permission={USER_PERMISSION.WORKSPACE_UPDATE_METADATA}>
              <MenuItem
                type="link"
                key="workspace_settings_menu_item"
                id="workspace_settings_menu_item"
                icon={<NvSettingsIcon data-testid="workspace-settings-menu-button" />}
                labelProps={{ type: 'typography', labelText: 'Workspace settings' }}
                link={[
                  '/workspace/info',
                  '/workspace/billing-and-plans',
                  '/workspace/slack',
                  '/workspace/integrations',
                  '/integrations',
                ]}
              />
              {subscription?.planType === 'free' && (
                <>
                  <NvDivider orientation="horizontal" sx={{ height: '1px', opacity: 0.1, margin: '4px 0px' }} />
                  <MenuItem
                    type="link"
                    key="upgrade-menu-item"
                    id="upgrade-menu-item"
                    icon={
                      <>
                        <NvCustomUpgradeIcon
                          data-testid="upgrade-menu-button"
                          sx={{ width: '32px', height: '32px' }}
                          htmlColor={palette.nv_accent[40]}
                        />
                        <StyledCustomUpgradeIcon />
                      </>
                    }
                    labelProps={{
                      type: 'component',
                      labelComponent: (
                        <UpgradeLabelWrapper>
                          <StyledTypography noWrap variant={'h4'} className="hidden-item menu-item-label">
                            Free plan
                          </StyledTypography>
                          <NvChip label="UPGRADE" compact color={ColoredChipColor.ACCENT} className="hidden-item" />
                        </UpgradeLabelWrapper>
                      ),
                      id: 'free-plan',
                    }}
                    link={`/workspace/billing-and-plans`}
                    forceNeverActive
                  />
                  <NvDivider orientation="horizontal" sx={{ height: '1px', opacity: 0.1, margin: '4px 0px' }} />
                </>
              )}
              {subscription?.planType === 'trial' && (
                <>
                  <NvDivider orientation="horizontal" sx={{ height: '1px', opacity: 0.1, margin: '4px 0px' }} />
                  <MenuItem
                    type="link"
                    id="trial-menu-item"
                    icon={
                      <>
                        <NvCustomCardGiftIcon
                          data-testid="trial-menu-button"
                          sx={{ width: '32px', height: '32px' }}
                          htmlColor={palette.nv_accent[40]}
                        />
                        <StyledCustomUpgradeIcon />
                      </>
                    }
                    labelProps={{
                      type: 'component',
                      labelComponent: (
                        <UpgradeLabelWrapper>
                          <NvChip
                            label={<strong>Trial plan</strong>}
                            color={ColoredChipColor.ACCENT}
                            className="hidden-item"
                            sx={{
                              textTransform: 'none',
                            }}
                          />
                          {subscription.periodEnd && (
                            <NvTypography
                              whiteSpace={'nowrap'}
                              noWrap
                              variant={'h4'}
                              className="hidden-item menu-item-label"
                            >
                              {formatDistanceToNowStrict(new Date(subscription.periodEnd))} left
                            </NvTypography>
                          )}
                        </UpgradeLabelWrapper>
                      ),
                      id: 'free-plan',
                    }}
                    link={`/workspace/billing-and-plans`}
                    forceNeverActive
                  />
                  <NvDivider orientation="horizontal" sx={{ height: '1px', opacity: 0.1, margin: '4px 0px' }} />
                </>
              )}
            </UserPermissionBoundary>
          </MenuHomeWrapper>
          <ProfileMenuItem />
        </NavigationBottomWrapper>
      </NavigationMenuWrapper>
    </Portal>
  );
};

export const NavigationMenu: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <NavigationContextProvider>
      <Navigation />
      {children}
    </NavigationContextProvider>
  );
};
