import { styled } from '@novaera/theme-provider';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { NvBox } from '../box';
import { NvButton } from '../button';

export const CustomCarousel = styled(Carousel)<{ altText?: string }>`
  width: 100%;

  .carousel-slider {
    padding-bottom: 24px;
    overflow: visible;
    position: relative;

    .slider-wrapper {
      ${({ theme }) => theme.elevations.e100};
      border-radius: 12px;
    }

    &::after {
      content: ${({ altText }) => (altText ? `"${altText}"` : '')};
      display: block;
      position: absolute;
      bottom: 0;
      left: 32px;
      width: calc(100% - 64px);
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.02em;
      color: ${({ theme }) => theme.palette.nv_neutral[600]};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .thumbs-wrapper {
    position: relative;
    margin: 0;
    padding: 0;
    margin-left: -16px;

    &::after,
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 16px;
      z-index: 1;
      pointer-events: none;
    }

    &::after {
      background: linear-gradient(to left, rgba(251, 251, 252, 1) 0, rgba(251, 251, 252, 0) 100%);
      right: 0;
    }

    &::before {
      background: linear-gradient(to right, rgba(251, 251, 252, 1) 0, rgba(251, 251, 252, 0) 100%);
      left: 0;
    }

    button {
      display: none;
    }

    .thumbs {
      padding: 0px 16px;
      margin: 16px 0;
      border-radius: 6px;

      .thumb {
        width: 130px;
        height: 72px;
        margin-right: 8px;
        padding: 0;
        ${({ theme }) => theme.elevations.e100};
        border: 1px solid transparent;
        border-radius: 6px;

        &.selected {
          border: 1px solid ${({ theme }) => theme.palette.nv_main[40]};
          ${({ theme }) => theme.mixins.focus};
        }
      }
    }
  }
`;

export const CustomCarouselInBackdrop = styled(CustomCarousel)`
  height: 100%;

  .carousel-slider {
    height: 100%;
    padding: 0 40px;

    .slider-wrapper {
      height: 100%;
      border-radius: 0;

      ul.slider {
        height: 100%;

        li.slide {
          display: flex;
          height: 100%;
          align-items: center;
          justify-content: center;

          img.horizontal {
            width: 100%;
            height: auto;
          }

          img.vertical {
            width: auto;
            height: 100%;
          }
        }
      }
    }
  }

  .thumbs-wrapper {
    display: none;
  }
`;

export const CarouselDirectionButton = styled(NvButton)`
  position: absolute;
  z-index: 2;
  top: calc(50% - 24px);
  cursor: pointer;
  backdrop-filter: invert(15%);
`;

export const CarouselDirectionButtonLeft = styled(CarouselDirectionButton)`
  left: 4px;
`;

export const CarouselDirectionButtonRight = styled(CarouselDirectionButton)`
  right: 4px;
`;

export const CarouselDirectionButtonInBackdrop = styled(CarouselDirectionButton)`
  top: calc(50% - 26px);

  &.MuiButtonBase-root {
    &.NvButton-onlyIcon {
      &.MuiButton-sizeMedium {
        height: 52px;
      }
    }

    & .MuiButton-iconSizeMedium {
      width: 24px;
      height: 24px;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    &.MuiButton-containedPrimary {
      color: ${({ theme }) => theme.palette.nv_neutral[1000]};
      background-color: ${({ theme }) => theme.palette.nv_neutral[30]};

      &.MuiLoadingButton-loading {
        background-color: ${({ theme }) => theme.palette.nv_neutral[30]};
      }

      &.Mui-disabled {
        background-color: ${({ theme }) => theme.palette.nv_neutral[10]};
      }

      & .MuiLoadingButton-loadingIndicator {
        color: ${({ theme }) => theme.palette.nv_neutral[1000]};
      }

      &.Mui-focusVisible,
      &:hover {
        background-color: ${({ theme }) => theme.palette.nv_neutral[40]};
      }

      &:active {
        background-color: ${({ theme }) => theme.palette.nv_neutral[50]};
      }
    }
  }
`;

export const CarouselDirectionButtonLeftInBackdrop = styled(CarouselDirectionButtonInBackdrop)`
  left: 0;
`;

export const CarouselDirectionButtonRightInBackdrop = styled(CarouselDirectionButtonInBackdrop)`
  right: 0;
`;

export const CarouselBackdropHeader = styled(NvBox)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 52px;
  flex: 0 0 auto;
  padding: 0 16px;
  color: ${({ theme }) => theme.palette.nv_neutral[0]};
  background-color: ${({ theme }) => theme.palette.nv_neutral[1000]};
`;

export const CarouselBackdropBody = styled(NvBox)`
  flex: 0 0 auto;
  height: calc(100vh - 104px);
  overflow: hidden;
  padding: 16px;
`;

export const CarouselBackdropFooter = styled(NvBox)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  height: 52px;
  padding: 0 16px;
  color: ${({ theme }) => theme.palette.nv_neutral[0]};
  background-color: ${({ theme }) => theme.palette.nv_neutral[1000]};
`;
