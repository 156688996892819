import {
  ActionNode,
  ActionerEventNode,
  AssistantNode,
  DelayNode,
  FileNodeExtraData,
  FunctionNode,
  GetConversationNode,
  JobNodeExtraData,
  LinkGeneratorNode,
  LoopNode,
  OutputNodeItem,
  QANode,
  ResponseNode,
  SendEmailNode,
  WorkFlowDispatcherNode,
  WorkflowResolverNode,
  useGetWorkflow,
  useUpdateWorkflowGraph,
} from '@novaera/actioner-service';
import {
  NodeListGroupItem,
  NodeListItem,
  NovaeraEdge,
  NovaeraNode,
  NvArrowBackIcon,
  NvBox,
  NvButton,
  NvNodeList,
  NvPopover,
  NvSlide,
} from '@novaera/core';
import { useParams } from '@novaera/route';
import { assert } from '@novaera/utils';
import { upperFirst } from 'lodash';
import { PopupState, bindPopover, usePopupState } from 'material-ui-popup-state/hooks';
import { FC, createContext, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { DropdownSimpleSearchInput } from '../../../../../../components/simple-search-input/styled';
import { AppWorkflowAllNodeType } from '../../components/user-app-workflow-node-types/types';
import { userAppGraph } from '../../graph-utils/user-app-graph';
import { usePropertyPanelContext } from '../../properties-panel/provider';
import { useNovaeraFlow } from '../../use-novaera-flow';
import { createComponentMethodMap } from '../../use-novaera-flow/create-node-parts';
import { ActionComponentFactory } from '../../use-novaera-flow/create-node-parts/create-action';
import { ActionerEventComponentFactory } from '../../use-novaera-flow/create-node-parts/create-actioner-event';
import { AssistantComponentFactory } from '../../use-novaera-flow/create-node-parts/create-assistant';
import { DelayComponentFactory } from '../../use-novaera-flow/create-node-parts/create-delay';
import { FilesComponentFactory } from '../../use-novaera-flow/create-node-parts/create-files';
import { FunctionComponentFactory } from '../../use-novaera-flow/create-node-parts/create-function';
import { GetConversationComponentFactory } from '../../use-novaera-flow/create-node-parts/create-get-conversation';
import { JobComponentFactory } from '../../use-novaera-flow/create-node-parts/create-job';
import { LinkGeneratorComponentFactory } from '../../use-novaera-flow/create-node-parts/create-link-generator';
import { LoopComponentFactory } from '../../use-novaera-flow/create-node-parts/create-loop';
import { OutputComponentFactory } from '../../use-novaera-flow/create-node-parts/create-output';
import { ResponseComponentFactory } from '../../use-novaera-flow/create-node-parts/create-response';
import { SendEmailComponentFactory } from '../../use-novaera-flow/create-node-parts/create-send-email';
import { WorkflowDispatcherComponentFactory } from '../../use-novaera-flow/create-node-parts/create-workflow-dispatcher';
import { QAComponentFactory } from '../../use-novaera-flow/create-node-parts/qa';
import { WorkflowResolverComponentFactory } from '../../use-novaera-flow/create-node-parts/workflow-resolver';
import { isUserAppWorkflowType } from '../../utils';
import { insertNodeToWorkflow } from '../insert-node-to-workflow';
import { useGetTriggerItems } from '../use-get-trigger-items';

export type ButtonContext = EdgeAddButtonContext | NodeAddButtonContext;

type EdgeAddButtonContext = {
  type: 'Edge';
  sourceId: NovaeraEdge['sourceId'];
  targetId: NovaeraEdge['targetId'];
};

type NodeAddButtonContext = {
  type: 'Node';
  nodeId: NovaeraNode['alias'];
};

const AddNewNodeContext = createContext<
  | {
      popupState: PopupState;
      setButtonContext: (buttonContext: ButtonContext) => void;
    }
  | undefined
>(undefined);

export const AddNewNodeProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'demoPopover',
  });
  const [buttonContext, setButtonContext] = useState<ButtonContext>();

  const { insertNode } = useNovaeraFlow<AppWorkflowAllNodeType>(userAppGraph);

  const { userAppId, workflowId } = useParams();

  const { workflow } = useGetWorkflow({ appId: userAppId, workflowId });

  const { mutate } = useUpdateWorkflowGraph();

  const [activeParent, setActiveParent] = useState<string | undefined>(undefined);

  const [keyword, setKeyword] = useState<string | undefined>('');

  const { triggerItems, filteredTriggerItems, isLoading, searchModeActive } = useGetTriggerItems({
    activeParent,
    keyword,
    itemTypesToHide: workflow?.trigger?.type !== 'form' ? ['outputs', 'output'] : [],
  });

  const activeParentItem = useMemo(() => {
    return filteredTriggerItems.find((t) => t.type === activeParent);
  }, [activeParent, filteredTriggerItems]);

  const { fillSelectedNode } = usePropertyPanelContext();

  const handleItemClick = useCallback(
    (_event: React.MouseEvent<HTMLDivElement, MouseEvent>, item: NodeListItem) => {
      searchInputRef.current?.focus();

      if (item.childNodes) {
        setActiveParent(item.type);
        return;
      }
      assert(!!item?.type, new Error(`Item type should be defined`), 'ERROR');

      if (buttonContext && item.type) {
        assert(
          isUserAppWorkflowType(item.type),
          new Error(`item type: ${item.type} should in app workflow types`),
          'ERROR'
        );

        const factory = createComponentMethodMap[item.type]({
          graph: userAppGraph,
        });

        let workflowComponent;
        if (factory instanceof ActionComponentFactory) {
          workflowComponent = factory.componentForWorkflow(item.extraData as ActionNode);
        } else if (factory instanceof LoopComponentFactory) {
          workflowComponent = factory.componentForWorkflow(item.extraData as LoopNode);
        } else if (factory instanceof FunctionComponentFactory) {
          workflowComponent = factory.componentForWorkflow(item.extraData as FunctionNode);
        } else if (factory instanceof ResponseComponentFactory) {
          workflowComponent = factory.componentForWorkflow(item.extraData as ResponseNode);
        } else if (factory instanceof OutputComponentFactory) {
          workflowComponent = factory.componentForWorkflow(item.extraData as OutputNodeItem);
        } else if (factory instanceof DelayComponentFactory) {
          workflowComponent = factory.componentForWorkflow(item.extraData as DelayNode);
        } else if (factory instanceof WorkflowDispatcherComponentFactory) {
          workflowComponent = factory.componentForWorkflow(item.extraData as WorkFlowDispatcherNode);
        } else if (factory instanceof WorkflowResolverComponentFactory) {
          workflowComponent = factory.componentForWorkflow(item.extraData as WorkflowResolverNode);
        } else if (factory instanceof QAComponentFactory) {
          workflowComponent = factory.componentForWorkflow(item.extraData as QANode);
        } else if (factory instanceof SendEmailComponentFactory) {
          workflowComponent = factory.componentForWorkflow(item.extraData as SendEmailNode);
        } else if (factory instanceof ActionerEventComponentFactory) {
          workflowComponent = factory.componentForWorkflow(item.extraData as ActionerEventNode);
        } else if (factory instanceof JobComponentFactory) {
          workflowComponent = factory.componentForWorkflow(item.extraData as JobNodeExtraData);
        } else if (factory instanceof FilesComponentFactory) {
          workflowComponent = factory.componentForWorkflow(item.extraData as FileNodeExtraData);
        } else if (factory instanceof AssistantComponentFactory) {
          workflowComponent = factory.componentForWorkflow(item.extraData as AssistantNode);
        } else if (factory instanceof GetConversationComponentFactory) {
          workflowComponent = factory.componentForWorkflow(item.extraData as GetConversationNode);
        } else if (factory instanceof LinkGeneratorComponentFactory) {
          workflowComponent = factory.componentForWorkflow(item.extraData as LinkGeneratorNode);
        } else {
          workflowComponent = factory.componentForWorkflow();
        }

        if (workflow) {
          const newWorkflow = insertNodeToWorkflow({
            item,
            workflow,
            workflowComponent,
            ...buttonContext,
          });

          const { graph } = newWorkflow;

          assert(!!graph, new Error(`graph should be defined`), 'ERROR');

          const newAlias = workflowComponent.root.alias;

          mutate(
            {
              appId: userAppId,
              workflowId,
              payload: { graph, updates: { nodes: Object.values(workflowComponent.nodeSummaries) } },
            },
            {
              onSuccess: () => {
                insertNode({ newWorkflow });
                const node = userAppGraph.node(newAlias);
                fillSelectedNode(node);
              },
            }
          );
        }
        setActiveParent(undefined);
        popupState.close();
      }
    },
    [buttonContext, fillSelectedNode, insertNode, mutate, popupState, userAppId, workflow, workflowId]
  );

  const value = { popupState, setButtonContext };
  const PaperProps = {
    style: {
      marginTop: -16,
      marginLeft: 8,
    },
  };

  const searchInputRef = useRef<HTMLInputElement>(null);

  const popoverProps = useMemo(() => {
    const { onClose } = bindPopover(popupState);
    const props = Object.assign({}, bindPopover(popupState), {
      onClose: () => {
        setActiveParent(undefined);
        onClose();
      },
    });
    return props;
  }, [popupState]);

  return (
    <AddNewNodeContext.Provider value={value}>
      {children}
      <NvPopover
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={PaperProps}
        {...popoverProps}
        disableRestoreFocus
      >
        <NvBox width="370px">
          <DropdownSimpleSearchInput
            autoFocus
            inputRef={searchInputRef}
            onKeywordChanged={(keyword) => {
              setKeyword(keyword);
            }}
          />

          {searchModeActive ? (
            <NvNodeList
              nodes={filteredTriggerItems}
              onItemClick={handleItemClick}
              groupBy="group"
              RenderGroupItem={({ groupKey }) => {
                const parentNode = triggerItems.find((item) => item.type === groupKey);
                return (
                  <NodeListGroupItem name={parentNode?.name ?? upperFirst(groupKey)} icon={parentNode?.icon ?? ''} />
                );
              }}
              isLoading={isLoading}
            />
          ) : (
            <>
              <NvSlide
                direction="right"
                in={activeParent === undefined}
                mountOnEnter
                unmountOnExit
                appear={false}
                timeout={{ appear: 0, exit: 0, enter: 300 }}
              >
                <NvNodeList nodes={filteredTriggerItems} onItemClick={handleItemClick} isLoading={isLoading} />
              </NvSlide>

              <NvSlide
                direction="left"
                in={Boolean(activeParentItem)}
                mountOnEnter
                unmountOnExit
                timeout={{ appear: 0, exit: 0, enter: 300 }}
              >
                <NvNodeList
                  nodes={activeParentItem?.childNodes ?? []}
                  header={
                    activeParentItem && (
                      <NodeListGroupItem
                        name={activeParentItem.name}
                        icon={activeParentItem.icon}
                        leftSlot={
                          <NvButton
                            color="ghost"
                            size="small"
                            onlyIcon
                            onClick={() => {
                              setActiveParent(undefined);
                              searchInputRef.current?.focus();
                            }}
                          >
                            <NvArrowBackIcon />
                          </NvButton>
                        }
                      />
                    )
                  }
                  onItemClick={handleItemClick}
                  isLoading={isLoading}
                />
              </NvSlide>
            </>
          )}
        </NvBox>
      </NvPopover>
    </AddNewNodeContext.Provider>
  );
};

export const useAddNewNodeContext = () => {
  const context = useContext(AddNewNodeContext);
  assert(!!context, new Error(`useAddNewNodeContext should be used within AddNewNodeProvider`), 'ERROR');

  return context;
};
