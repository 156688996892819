import { NextStateParams } from '../../common/types';
import { SIGN_IN_STATE } from './types';

export const getNextState = (params: NextStateParams): SIGN_IN_STATE => {
  if (!params.isInitial && !params.isInWorkspaceList && !params.redirectToDashboard) {
    return SIGN_IN_STATE.IN_CODE;
  } else if (!params.isInitial && params.isInWorkspaceList) {
    return SIGN_IN_STATE.CHOOSE_WORKSPACE;
  } else if (!params.isInitial && params.redirectToDashboard) {
    return SIGN_IN_STATE.REDIRECT_TO_DASHBOARD;
  } else {
    return SIGN_IN_STATE.INITIAL;
  }
};

export const getProviderButtonContent = (provider: string, fromSignUp: boolean) =>
  fromSignUp ? `Sign up with ${provider}` : `Sign in with ${provider}`;
