import { HTTPRequestConfiguration } from '@novaera/actioner-service';
import { FieldArray, NvAddButtonWithLabel, NvFlex } from '@novaera/core';
import { useRequestList } from './controller/use-request-list';
import { Item } from './item';
import { OpenModalParams } from './types';

export const RequestList: React.FC<
  React.PropsWithChildren<{
    openModal: (params: OpenModalParams) => void;
  }>
> = ({ openModal }) => {
  const { httpRequestConfigurations, selectedRequestId, handleItemClick, actionButtons } = useRequestList();

  return (
    <NvFlex paddingBottom={10}>
      <NvAddButtonWithLabel
        onClick={() => {
          return openModal({ mode: 'create' });
        }}
        variant="small"
        label="Add request"
      />

      <NvFlex height={1} spacing={0.75} mt={1.5}>
        <FieldArray<HTTPRequestConfiguration> name="httpRequestConfigurations">
          {() =>
            httpRequestConfigurations?.map((request) => {
              const { id, name } = request;

              const hasError = false;
              return (
                <Item
                  isSelected={selectedRequestId === id}
                  label={name}
                  variant="API"
                  onItemClick={() => {
                    handleItemClick(id);
                  }}
                  onEditClick={() => openModal({ mode: 'update', id })}
                  actionButtons={actionButtons(id)}
                  key={`request_list_item_${id}`}
                  hasError={hasError}
                />
              );
            })
          }
          {}
        </FieldArray>
      </NvFlex>
    </NvFlex>
  );
};
