import { CloneResponse } from '@novaera/actioner-service';
import {
  NvAddBoxIcon,
  NvButton,
  NvDivider,
  NvDNSIcon,
  NvFlex,
  NvReceiptLongIcon,
  NvSearchEmptyState,
  NvTypography,
  useNvDialogModalState,
} from '@novaera/core';
import { useNavigate, useParams } from '@novaera/route';
import { matchSorter } from 'match-sorter';
import { FC, useMemo, useState } from 'react';
import { SimpleSearchInput } from '../../../../../components';
import { Clone } from '../../../../../components/clone';
import { CloneFlagger } from '../../../../../components/clone/flagger';
import { EmptyState } from '../../../../../components/empty-state';
import { EmptyStates } from '../../../../../components/empty-state/types';
import { FreeAppPermissionBoundary } from '../../../../user-app-permission-boundary/free-app-permission-boundary';
import { USER_APP_RECORDS } from '../../../constants';
import { CreateTableModal } from '../create-table-modal';
import { TableListItem } from '../table-list-item';
import { TableListLoading } from './table-list-loading';
import { useTableList } from './use-table-list';

export const TableList: FC<React.PropsWithChildren<unknown>> = () => {
  const { userAppId } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const navigate = useNavigate();
  const { userAppId: appId } = useParams();
  const { models, isLoading } = useTableList({ appId });
  const filteredModels = useMemo(
    () => (models ? matchSorter(models, searchKeyword, { keys: ['name'] }) : []),
    [models, searchKeyword]
  );
  const handleAddTable = () => {
    setIsOpen(true);
  };
  const { isOpened, onModalCloseClicked, onModalOpenClicked, passedParameters } = useNvDialogModalState<{
    id: string;
    name: string;
  }>();
  const [clonedItem, setClonedItem] = useState<CloneResponse | undefined>();

  return (
    <>
      {isLoading ? (
        <TableListLoading />
      ) : (
        <NvFlex gap="16px">
          <NvFlex direction="row" gap="8px" alignItems="center">
            <NvDNSIcon sx={{ width: '32px', height: '32px', flex: '0 0 auto' }} />
            <NvTypography variant="h1" flex="1 1 auto" minWidth={0}>
              Tables
            </NvTypography>
          </NvFlex>
          {models?.length === 0 ? (
            <EmptyState
              variant={EmptyStates.DATABASE}
              CustomButton={
                <FreeAppPermissionBoundary appId={userAppId}>
                  <NvButton
                    startIcon={<NvAddBoxIcon />}
                    color="secondary"
                    onClick={handleAddTable}
                    size="small"
                    sx={{ flex: '0 0 auto' }}
                  >
                    Add table
                  </NvButton>
                </FreeAppPermissionBoundary>
              }
            />
          ) : (
            <>
              <NvFlex direction="row" alignItems="center" justifyContent="space-between">
                <SimpleSearchInput
                  onKeywordChanged={(keyword) => {
                    setSearchKeyword(keyword ?? '');
                  }}
                  placeholder="Search tables"
                />

                <NvFlex direction="row" alignItems="center" gap="8px">
                  <FreeAppPermissionBoundary appId={userAppId}>
                    <NvButton startIcon={<NvAddBoxIcon />} size="small" color="ghost" onClick={handleAddTable}>
                      Add table
                    </NvButton>
                  </FreeAppPermissionBoundary>
                  <NvDivider orientation="vertical" sx={{ height: '12px' }} />
                  <NvButton
                    startIcon={<NvReceiptLongIcon />}
                    size="small"
                    color="ghost"
                    onClick={() => {
                      navigate(USER_APP_RECORDS(appId));
                    }}
                  >
                    Records
                  </NvButton>
                </NvFlex>
              </NvFlex>
              {filteredModels.length === 0 && searchKeyword.length > 0 ? (
                <NvSearchEmptyState text={'No tables found. Try different words or clear search bar.'} />
              ) : (
                <NvFlex gap="8px">
                  {filteredModels.map((dataModel) => (
                    <TableListItem
                      onCloneItemClicked={onModalOpenClicked}
                      key={`data_model_${dataModel.id}`}
                      dataModel={dataModel}
                    />
                  ))}
                </NvFlex>
              )}
            </>
          )}
        </NvFlex>
      )}

      {isOpen && <CreateTableModal isOpen={isOpen} handleClose={() => setIsOpen(false)} />}

      {passedParameters?.id && passedParameters.name && (
        <Clone
          isOpened={isOpened}
          appId={appId}
          modelId={passedParameters.id}
          name={passedParameters.name}
          onModalCloseClicked={onModalCloseClicked}
          type="data-models"
          onCloneSuccess={(param) => {
            setClonedItem(param);
          }}
        />
      )}
      {clonedItem && (
        <CloneFlagger
          opened={!!clonedItem}
          clonedItem={clonedItem}
          onFlaggerClosed={() => {
            setClonedItem(undefined);
          }}
          type={'data-models'}
        />
      )}
    </>
  );
};
