import { useGetUserPermissions } from '@novaera/actioner-service';
import { usePermission } from '../utils/hooks/use-permission';
import { UserPermissionBoundaryProps } from './types';

export const UserPermissionBoundary: React.FC<React.PropsWithChildren<UserPermissionBoundaryProps>> = ({
  permission,
  Fallback,
  children,
}) => {
  const { data } = useGetUserPermissions();

  const result = usePermission({ permissions: data?.permissions ?? {}, permission, Fallback, children });

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{result}</>;
};
