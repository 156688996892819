import { NvFlex, NvPersonIcon, NvSelect, NvSkeleton, NvSwitch, NvTypography } from '@novaera/core';
import { assert } from '@novaera/utils';
import { FC } from 'react';
import { PropertyPanelSection } from '../../../../../../../../../components';
import { DefaultPrincipal } from '../../../common/default-principal-property-panel-section/default-principal';
import { useDefaultUserBehavior } from '../../../common/default-principal-property-panel-section/default-principal/controllers/use-default-user-behavior';
import { useEmptyDefaultPrinciple } from '../../../common/default-principal-property-panel-section/default-principal/controllers/use-empty-default-principal';
import { useSwitchToMe } from '../../../common/default-principal-property-panel-section/default-principal/controllers/use-switch-to-me';
import { formTriggerDefaultUserBehaviorOptions } from './constants';
import { FormTriggerDefaultUserSectionProps } from './types';

export const FormTriggerDefaultUserSection: FC<FormTriggerDefaultUserSectionProps> = ({
  defaultStarterUserId,
  useDefaultUserAsPrimeUser,
}) => {
  const { handleSwitchToMe, isSaveLoading } = useSwitchToMe();
  const { emptyDefaultPrinciple } = useEmptyDefaultPrinciple();
  const { handleUpdateDefaultUserBehavior } = useDefaultUserBehavior();

  return (
    <PropertyPanelSection
      title="Advanced connection settings"
      icon={<NvPersonIcon />}
      actions={
        <NvSwitch
          checked={!!defaultStarterUserId}
          variant="compact"
          onChange={(_, checked) => {
            if (checked) {
              handleSwitchToMe();
            } else {
              emptyDefaultPrinciple();
            }
          }}
        />
      }
    >
      {!isSaveLoading ? (
        defaultStarterUserId && (
          <NvFlex gap="8px">
            <NvSelect<boolean>
              value={useDefaultUserAsPrimeUser}
              onChange={(e) => {
                assert(
                  typeof e.target.value === 'boolean',
                  new Error('[FormTriggerDefaultUserSection - value of useDefaultUserAsPrimeUser should be boolean.]'),
                  'ERROR'
                );
                handleUpdateDefaultUserBehavior(e.target.value);
              }}
              options={formTriggerDefaultUserBehaviorOptions}
              renderValue={(v) => (
                <NvTypography variant="body2">
                  {formTriggerDefaultUserBehaviorOptions.find((o) => o.value === v)?.label}
                </NvTypography>
              )}
              sx={{ width: '200px' }}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
              }}
            />
            <DefaultPrincipal defaultStarterUserId={defaultStarterUserId} />
          </NvFlex>
        )
      ) : (
        <NvFlex gap="8px">
          <NvSkeleton variant="rectangular" height="32px" width="200px" />
          <NvSkeleton variant="rectangular" height="40px" width="100%" />
        </NvFlex>
      )}
    </PropertyPanelSection>
  );
};
