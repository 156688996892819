import { InputParameter, WorkflowState } from '@novaera/actioner-service';
import { useParams } from '@novaera/route';
import { useMemo, useState } from 'react';
import { InputsProvider } from '../../../../../../../action-designer/providers/input-provider';
import { SearchAsYouTypeValuesProvider } from '../../../../../../../action-designer/providers/search-as-you-type-values';
import { WorkflowDynamicInputProvider } from '../../../../../../../components/dynamic-input/providers/workflow-dynamic-input-provider';
import {
  PPDrawerItem,
  PropertyPaneWrapper,
} from '../../../../../../../components/property-panel/property-panel-drawer/styled';
import {
  componentsAlwaysShowingOptions,
  componentsNeedsOptions,
} from '../../../../../../../components/ui-components/utils';
import { FormIdentifierProvider } from '../../../../../../../providers/form-identifier-provider';
import { UserAppOptionsProvider } from '../../../components/options/provider';
import { usePropertyPanelContext } from '../../provider';
import { helperFnDrawerItem, usePPDrawerWrapper } from '../controllers/use-pp-drawer-item';
import { FormTriggerProperties } from './form-trigger-properties';
import { FormTriggerDetailAndSource } from './form-trigger-properties/form-trigger-detail-and-source';
import { FormTriggerPropertyPanelDrawerProps } from './types';

export const FormTriggerPropertyPanelDrawer: React.FC<React.PropsWithChildren<FormTriggerPropertyPanelDrawerProps>> = ({
  handleClose,
  workflow,
}) => {
  const { userAppId, workflowId } = useParams();

  const [dataSourceIndex, setDataSourceIndex] = useState<number | null>(null);

  const inputParameters = useMemo(
    () => (workflow?.trigger && workflow?.trigger.type === 'form' && workflow.trigger.inputParameters) || [],
    [workflow?.trigger]
  );

  const { inputParameterIds, inputParameterIdsShowingOptions } = useMemo(
    () => ({
      inputParameterIds: inputParameters
        .filter((inputParameter?: InputParameter) =>
          inputParameter ? componentsNeedsOptions(inputParameter.uiComponent) : false
        )
        .map((ip: InputParameter) => ip.id),
      inputParameterIdsShowingOptions: inputParameters.reduce<string[]>((prev, current) => {
        if (componentsAlwaysShowingOptions(current.uiComponent)) {
          return [...prev, current.id];
        } else {
          return prev;
        }
      }, []),
    }),
    [inputParameters]
  );

  const { isPanelOpen: isFirstPanelOpen, emptySelectedNode } = usePropertyPanelContext();
  const [thirdPanelOpen, setThirdPanelOpen] = useState(false);

  const { wrapperProps } = usePPDrawerWrapper({
    wrapper: { open: isFirstPanelOpen },
  });

  return (
    <PropertyPaneWrapper {...wrapperProps}>
      <SearchAsYouTypeValuesProvider>
        {({ searchAsYouTypeValues }) => (
          <InputsProvider>
            {({
              fillSelectedInputParameterIdWithDelay,
              isPanelOpen: secondPanelOpen,
              emptySelectedInputParameterIdWithDelay,
              selectedInputParameterId,
            }) => {
              const {
                panelProps: [first, second, third],
                panelCloseFunctions: [onFirstClose, onSecondClose, onThirdClose],
              } = helperFnDrawerItem({
                subPanels: [
                  {
                    open: isFirstPanelOpen,
                    closeFn: () => {
                      emptySelectedNode();
                      handleClose();
                    },
                  },
                  {
                    open: isFirstPanelOpen && secondPanelOpen,
                    closeFn: () => {
                      emptySelectedInputParameterIdWithDelay();
                    },
                  },
                  {
                    open: isFirstPanelOpen && secondPanelOpen && thirdPanelOpen,
                    closeFn: () => {
                      setThirdPanelOpen(false);
                    },
                  },
                ],
              });
              return (
                <FormIdentifierProvider>
                  {({ formId }) => (
                    <WorkflowDynamicInputProvider
                      appId={userAppId}
                      workflowId={workflowId}
                      draft={workflow.state === WorkflowState.DRAFT}
                      formId={formId}
                      initialInputParameterIdsShowingOptions={[]}
                      inputParameterIds={[]}
                      allInputParameterIdsWithOrder={[]}
                      storeDynamicFormParametersPermanently
                      inputParameters={inputParameters}
                    >
                      <UserAppOptionsProvider
                        formId={formId}
                        appId={userAppId}
                        workflowId={workflowId}
                        draft={workflow?.state === WorkflowState.DRAFT}
                        inputParameterIds={inputParameterIds}
                        initialInputParameterIdsShowingOptions={inputParameterIdsShowingOptions}
                        searchAsYouTypeValues={searchAsYouTypeValues}
                        allInputParameterIdsWithOrder={inputParameters.map((ip) => ip.id)}
                      >
                        <PPDrawerItem {...first}>
                          <FormTriggerProperties
                            onCloseClicked={onFirstClose}
                            onSelectedInputParameterChanged={({ selectedInputParameterId }) => {
                              fillSelectedInputParameterIdWithDelay(selectedInputParameterId);
                              setThirdPanelOpen(false);
                              setDataSourceIndex(null);
                            }}
                          />
                        </PPDrawerItem>
                        {selectedInputParameterId && (
                          <FormTriggerDetailAndSource
                            workflow={workflow}
                            secondPanelProps={second}
                            onSecondClose={onSecondClose}
                            thirdPanelProps={third}
                            onThirdClose={onThirdClose}
                            dataSourceIndex={dataSourceIndex}
                            setDataSourceIndex={setDataSourceIndex}
                            setThirdPanelOpen={setThirdPanelOpen}
                          />
                        )}
                      </UserAppOptionsProvider>
                    </WorkflowDynamicInputProvider>
                  )}
                </FormIdentifierProvider>
              );
            }}
          </InputsProvider>
        )}
      </SearchAsYouTypeValuesProvider>
    </PropertyPaneWrapper>
  );
};
