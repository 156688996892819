import { useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { NvBackdrop } from '../../backdrop';
import { NvBox } from '../../box';
import { NvFlex } from '../../flex';
import { NvChevronLeftIcon, NvChevronRightIcon, NvCloseIcon } from '../../icons';
import { NvTypography } from '../../typography';
import { CarouselItemCard } from '../carousel-item';
import {
  CarouselBackdropBody,
  CarouselBackdropFooter,
  CarouselBackdropHeader,
  CarouselDirectionButtonLeftInBackdrop,
  CarouselDirectionButtonRightInBackdrop,
  CustomCarouselInBackdrop,
} from '../styled';
import { CarouselBackdropProps } from './types';

export const CarouselBackdrop: React.FC<React.PropsWithChildren<CarouselBackdropProps>> = ({
  isOpen,
  title,
  items,
  initialSelectedItem,
  handleBackdropClose,
}) => {
  const [selectedItem, setSelectedItem] = useState<number>(initialSelectedItem);
  useHotkeys('esc', () => handleBackdropClose());

  return (
    <NvBackdrop open={isOpen}>
      <NvFlex width="100%" height="100vh" justifyContent="space-between">
        <CarouselBackdropHeader>
          <NvBox flex="1 1 auto" minWidth={0}>
            {title && (
              <NvTypography variant="h1" noWrap>
                {title}
              </NvTypography>
            )}
          </NvBox>

          <NvCloseIcon onClick={handleBackdropClose} />
        </CarouselBackdropHeader>
        <CarouselBackdropBody>
          <CustomCarouselInBackdrop
            key="carousel-on-modal"
            useKeyboardArrows
            autoFocus
            infiniteLoop
            selectedItem={selectedItem}
            onChange={(index) => {
              setSelectedItem(index);
            }}
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
            renderArrowNext={(onClick, hasNext, label) => (
              <CarouselDirectionButtonRightInBackdrop onlyIcon onClick={onClick} title={label}>
                <NvChevronRightIcon />
              </CarouselDirectionButtonRightInBackdrop>
            )}
            renderArrowPrev={(onClick, hasNext, label) => (
              <CarouselDirectionButtonLeftInBackdrop onlyIcon onClick={onClick} title={label}>
                <NvChevronLeftIcon />
              </CarouselDirectionButtonLeftInBackdrop>
            )}
          >
            {items.map((item, index) => (
              <CarouselItemCard
                key={`carousel-on-modal-item-card-${index}`}
                {...item}
                isActive={selectedItem === index}
              />
            ))}
          </CustomCarouselInBackdrop>
        </CarouselBackdropBody>

        <CarouselBackdropFooter>
          <NvTypography variant="body1" textAlign="center" noWrap>
            {items[selectedItem].displayText}
          </NvTypography>
        </CarouselBackdropFooter>
      </NvFlex>
    </NvBackdrop>
  );
};
