import { SlackWorkspaceSection } from './slack-workspace-section';
import { WorkflowConnectionSection } from './workflow-connection-section';
import { WorkflowConnectionSectionProps } from './workflow-connection-section/types';

export const SlackWorkspaceOrWorkflowConnectionSection = ({
  label,
  integration,
  onChange,
  value,
  workflow,
}: WorkflowConnectionSectionProps) => {
  return integration?.type === 'slack' ? (
    <SlackWorkspaceSection integration={integration} />
  ) : (
    <WorkflowConnectionSection
      label={label}
      integration={integration}
      onChange={onChange}
      value={value}
      workflow={workflow}
    />
  );
};
