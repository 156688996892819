import { FileNodeAction, JobOperation, NodeUnionType } from '../types';

export enum WorkflowHistoryTimeWindow {
  LAST_HOUR = 'last-hour',
  LAST_DAY = 'last-day',
  LAST_WEEK = 'last-week',
  LAST_MONTH = 'last-month',
}

export enum WorkflowHistoryStatus {
  IN_PROGRESS = 'in_progress',
  SUCCESSFUL = 'successful',
  DISCARDED = 'discarded',
  FAILED = 'failed',
}

export interface WorkflowHistory {
  executionId: string;
  startedAt: string;
  status: WorkflowHistoryStatus;
}

export interface NodeExecution {
  executionId: string;
  objectKey: string;
  alias: string;
  name: string;
  header: string;
  type: NodeUnionType;
  status: NodeExecutionStatus;
  sequenceNo: number;
  startedAt: string;
  subtype?: JobOperation['type'] | FileNodeAction['type'];
}

export enum NodeExecutionSortOrder {
  EARLIEST_FIRST = 'earliest-first',
  LATEST_FIRST = 'latest-first',
}

export enum NodeExecutionStatus {
  IN_PROGRESS = 'in_progress',
  SUCCESSFUL = 'successful',
  DISCARDED = 'discarded',
  FAILED = 'failed',
  NOT_EXECUTED = 'not_executed',
}
