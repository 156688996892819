export default {
  color: {
    Default: {
      light: {
        nv_neutral: {
          0: {
            value: 'rgba(255, 255, 255, 1)',
          },
          5: {
            value: 'rgba(251, 251, 252, 1)',
          },
          10: {
            value: 'rgba(247, 248, 249, 1)',
          },
          20: {
            value: 'rgba(243, 243, 245, 1)',
          },
          30: {
            value: 'rgba(233, 234, 237, 1)',
          },
          40: {
            value: 'rgba(221, 223, 227, 1)',
          },
          50: {
            value: 'rgba(207, 209, 216, 1)',
          },
          60: {
            value: 'rgba(192, 196, 204, 1)',
          },
          70: {
            value: 'rgba(177, 182, 192, 1)',
          },
          80: {
            value: 'rgba(158, 164, 177, 1)',
          },
          90: {
            value: 'rgba(149, 155, 169, 1)',
          },
          100: {
            value: 'rgba(134, 141, 156, 1)',
          },
          200: {
            value: 'rgba(124, 132, 149, 1)',
          },
          300: {
            value: 'rgba(110, 118, 137, 1)',
          },
          400: {
            value: 'rgba(96, 105, 126, 1)',
          },
          500: {
            value: 'rgba(83, 93, 116, 1)',
          },
          600: {
            value: 'rgba(69, 80, 104, 1)',
          },
          700: {
            value: 'rgba(57, 68, 94, 1)',
          },
          800: {
            value: 'rgba(40, 52, 81, 1)',
          },
          900: {
            value: 'rgba(25, 39, 69, 1)',
          },
          1000: {
            value: 'rgba(13, 27, 59, 1)',
          },
        },
        nv_neutral_alpha: {
          900: {
            value: 'rgba(13, 27, 59, 0.95)',
          },
          800: {
            value: 'rgba(13, 27, 59, 0.89)',
          },
          700: {
            value: 'rgba(13, 27, 59, 0.82)',
          },
          600: {
            value: 'rgba(13, 27, 59, 0.77)',
          },
          500: {
            value: 'rgba(13, 27, 59, 0.71)',
          },
          400: {
            value: 'rgba(13, 27, 59, 0.66)',
          },
          300: {
            value: 'rgba(13, 27, 59, 0.6)',
          },
          200: {
            value: 'rgba(13, 27, 59, 0.54)',
          },
          100: {
            value: 'rgba(13, 27, 59, 0.5)',
          },
          90: {
            value: 'rgba(13, 27, 59, 0.44)',
          },
          80: {
            value: 'rgba(13, 27, 59, 0.4)',
          },
          70: {
            value: 'rgba(13, 27, 59, 0.32)',
          },
          60: {
            value: 'rgba(13, 27, 59, 0.26)',
          },
          50: {
            value: 'rgba(13, 27, 59, 0.2)',
          },
          40: {
            value: 'rgba(13, 27, 59, 0.14)',
          },
          30: {
            value: 'rgba(13, 27, 59, 0.09)',
          },
          20: {
            value: 'rgba(13, 27, 59, 0.05)',
          },
          10: {
            value: 'rgba(13, 27, 59, 0.03)',
          },
        },
        nv_main: {
          60: {
            value: 'rgba(52, 92, 177, 1)',
          },
          50: {
            value: 'rgba(70, 109, 191, 1)',
          },
          40: {
            value: 'rgba(84, 126, 212, 1)',
          },
          30: {
            value: 'rgba(157, 187, 250, 1)',
          },
          20: {
            value: 'rgba(235, 241, 255, 1)',
          },
          10: {
            value: 'rgba(249, 251, 255, 1)',
          },
        },
        nv_accent: {
          60: {
            value: 'rgba(202, 57, 246, 1)',
          },
          50: {
            value: 'rgba(214, 97, 247, 1)',
          },
          40: {
            value: 'rgba(253, 141, 255, 1)',
          },
          30: {
            value: 'rgba(240, 215, 252, 1)',
          },
          20: {
            value: 'rgba(248, 235, 253, 1)',
          },
          10: {
            value: 'rgba(252, 246, 254, 1)',
          },
        },
        nv_error: {
          60: {
            value: 'rgba(216, 50, 14, 1)',
          },
          50: {
            value: 'rgba(229, 62, 26, 1)',
          },
          40: {
            value: 'rgba(246, 76, 38, 1)',
          },
          30: {
            value: 'rgba(255, 140, 115, 1)',
          },
          20: {
            value: 'rgba(255, 140, 115, 1)',
          },
          10: {
            value: 'rgba(255, 248, 247, 1)',
          },
        },
        nv_warning: {
          60: {
            value: 'rgba(244, 129, 15, 1)',
          },
          50: {
            value: 'rgba(255, 139, 23, 1)',
          },
          40: {
            value: 'rgba(255, 157, 60, 1)',
          },
          30: {
            value: 'rgba(255, 187, 121, 1)',
          },
          20: {
            value: 'rgba(255, 232, 207, 1)',
          },
          10: {
            value: 'rgba(255, 247, 239, 1)',
          },
        },
        nv_success: {
          60: {
            value: 'rgba(105, 188, 143, 1)',
          },
          50: {
            value: 'rgba(124, 204, 161, 1)',
          },
          40: {
            value: 'rgba(140, 223, 178, 1)',
          },
          30: {
            value: 'rgba(153, 239, 192, 1)',
          },
          20: {
            value: 'rgba(207, 255, 229, 1)',
          },
          10: {
            value: 'rgba(244, 255, 249, 1)',
          },
        },
        nv_new: {
          60: {
            value: 'rgba(255, 204, 63, 1)',
          },
          50: {
            value: 'rgba(255, 215, 104, 1)',
          },
          40: {
            value: 'rgba(255, 225, 143, 1)',
          },
          30: {
            value: 'rgba(255, 235, 181, 1)',
          },
          20: {
            value: 'rgba(254, 243, 215, 1)',
          },
          10: {
            value: 'rgba(255, 252, 244, 1)',
          },
        },
        nv_code: {
          brown: {
            value: 'rgba(115, 60, 29, 1)',
          },
          purple: {
            value: 'rgba(77, 7, 191, 1)',
          },
          green: {
            value: 'rgba(60, 132, 92, 1)',
          },
          blue: {
            value: 'rgba(35, 138, 234, 1)',
          },
          orange: {
            value: 'rgba(238, 128, 52, 1)',
          },
        },
        nv_node: {
          output: {
            value: 'rgba(115, 60, 29, 1)',
          },
          function: {
            value: 'rgba(77, 7, 191, 1)',
          },
          trigger: {
            value: 'rgba(60, 132, 92, 1)',
          },
          operator: {
            value: 'rgba(35, 138, 234, 1)',
          },
          action: {
            value: 'rgba(238, 128, 52, 1)',
          },
          workflow: {
            value: 'rgba(205, 23, 132, 1)',
          },
          emitter: {
            value: 'rgba(23, 151, 151, 1)',
          },
          response: {
            value: 'rgba(115, 60, 29, 1)',
          },
          ai: {
            value: 'rgba(214, 97, 247, 1)',
          },
          sendEmail: {
            value: 'rgba(190, 74, 49, 1)',
          },
          actionerEvent: {
            value: 'rgba(23, 151, 151, 1)',
          },
          job: {
            value: 'rgba(121, 78, 164, 1)',
          },
          file: {
            value: 'rgba(78, 118, 164, 1)',
          },
          linkGenerator: {
            value: 'rgba(205, 23, 132, 1)',
          },
        },
      },
    },
  },
};
