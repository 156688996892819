import { NvButton, NvFlex, NvTypography } from '@novaera/core';
import { useLocation, useNavigate } from '@novaera/route';
import React, { useMemo, useState } from 'react';
import { SIGN_IN_STATE } from '../../controllers/use-sign-in/types';
import { AddToSlack } from '../add-to-slack';
import { NvProductLogoWithName } from '../product-icon/styled';
import { SignIn } from '../sign-in';
import { CustomDivider } from '../sign-in/styled';
import { SignUpAgreement } from '../sign-up-agreement';

export const SignUp: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [isSignUpWithoutSlackClicked, setIsSignUpWithoutSlackClicked] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isFromSignIn = useMemo(() => Boolean((location.state as any)?.username), [location]);

  const handleWithoutSlackClick = () => {
    if (isFromSignIn) {
      // we have user's email, so just send user to code validation page
      navigate('/user/verification', {
        state: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          username: (location.state as any)?.username,
          signInState: SIGN_IN_STATE.IN_CODE,
        },
      });
    } else {
      setIsSignUpWithoutSlackClicked(true);
    }
  };

  return !isSignUpWithoutSlackClicked ? (
    <NvFlex direction="column">
      <NvFlex>
        <NvProductLogoWithName />
      </NvFlex>
      <NvFlex marginTop={'40px'}>
        <NvTypography variant="h1">
          {isFromSignIn ? "It seems like you don't have an Actioner account yet" : 'Get started with Actioner'}
        </NvTypography>
      </NvFlex>
      <NvFlex marginTop={'8px'}>
        <NvTypography>
          {isFromSignIn
            ? "No worries! It'll only take a few clicks to create a new Actioner account. Let's start!"
            : "Actioner is a no-code Slack app builder that lets you connect all your work apps through actions. Let's start!"}
        </NvTypography>
      </NvFlex>
      <NvFlex marginTop={'32px'}>
        <AddToSlack />
      </NvFlex>
      <NvFlex marginTop={'32px'}>
        <CustomDivider />
      </NvFlex>
      <NvFlex marginTop={'24px'}>
        <NvFlex rowGap={'16px'}>
          <NvFlex>
            <NvTypography variant="h4">Not using Slack?</NvTypography>
          </NvFlex>
          <NvFlex>
            <NvTypography variant="body2" sx={{ maxWidth: '400px' }}>
              If you are not using Slack, you can get started with{' '}
              {isFromSignIn ? 'your email address' : 'Google or just your email address'}.
            </NvTypography>
          </NvFlex>
          <NvFlex>
            <NvButton color="secondary" size="medium" onClick={handleWithoutSlackClick} isFitContent>
              Continue without Slack
            </NvButton>
          </NvFlex>
          <NvFlex marginTop={'8px'} marginBottom={'8px'}>
            <CustomDivider />
          </NvFlex>
          <SignUpAgreement />
        </NvFlex>
      </NvFlex>
    </NvFlex>
  ) : (
    <SignIn fromSignUp />
  );
};
