import { NvButton } from '@novaera/core';
import { styled } from '@novaera/theme-provider';

export const AddToSlackWrapper = styled(NvButton)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  padding: 0 8px;
  border-color: transparent;
  background-color: ${({ theme }) => theme.palette.nv_neutral[0]};
  border: 1px solid ${({ theme }) => theme.palette.nv_main[30]};
  border-radius: 12px;
  box-shadow: 0px 2px 6px rgba(10, 45, 98, 0.06);
  max-width: 240px;
  width: 240px;

  transition: background-color 0.4s ease, border-color 0.5s ease, box-shadow 0.6s ease;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.palette.nv_neutral[10]};
    border: 1px solid ${({ theme }) => theme.palette.nv_main[40]};
  }
`;

export const SvgWrapper = styled('svg')`
  height: 20px;
  width: 20px;
  margin-right: 12px;
`;
