import { NvCssBaseline, NvFlex } from '@novaera/core';
import { Outlet, useLocation } from '@novaera/route';
import { useEffect } from 'react';
import { MenuBar } from '../menu-bar';
import { LayoutContent, LayoutContentInner } from './styled';

export const LayoutTopMenu: React.FC<React.PropsWithChildren<unknown>> = () => {
  const location = useLocation();
  useEffect(() => {
    if (window.userGuiding && window.userGuiding.finishPreview) {
      window.userGuiding?.finishPreview();
    }
  }, [location]);

  return (
    <>
      <NvCssBaseline />
      <NvFlex height="100vh" width="100%">
        <MenuBar />
        <LayoutContent>
          <LayoutContentInner>
            <Outlet />
          </LayoutContentInner>
        </LayoutContent>
      </NvFlex>
    </>
  );
};
