import { NvAddBoxIcon, NvButton, NvConditionalRender } from '@novaera/core';
import { EmptyState } from '../../../components/empty-state';
import { EmptyStates } from '../../../components/empty-state/types';
import { AddNewConnectionModal } from './add-new-connection-modal';
import { useAddNewConnectionModelController } from './controllers/use-add-new-connection-model';
import { useGetConnectionController } from './controllers/use-get-connection';
import { IntegrationConnectionDetail } from './detail';
import { GlobalSchemaDetail } from './global-schema-detail';

export const Connection = () => {
  const { onChangeAddNewConnectionModelState, isNewIntegrationModelOpened } = useAddNewConnectionModelController();

  const { hasConnection, connection, isGetConnectionLoading, isGlobalSchema } = useGetConnectionController();

  return (
    <>
      <NvConditionalRender when={() => !isGetConnectionLoading && !hasConnection}>
        <EmptyState
          variant={EmptyStates.CONNECTION}
          CustomButton={
            <NvButton
              startIcon={<NvAddBoxIcon />}
              color="secondary"
              size="small"
              onClick={() => onChangeAddNewConnectionModelState(true)}
            >
              Add new connection schema
            </NvButton>
          }
        />
      </NvConditionalRender>
      <NvConditionalRender when={() => isNewIntegrationModelOpened}>
        <AddNewConnectionModal
          isOpen={isNewIntegrationModelOpened}
          onClose={() => onChangeAddNewConnectionModelState(false)}
        />
      </NvConditionalRender>
      <NvConditionalRender when={() => !!connection || isGetConnectionLoading}>
        {isGlobalSchema && <GlobalSchemaDetail connectionSchema={connection} />}
        {!isGlobalSchema && (
          <IntegrationConnectionDetail isConnectionLoading={isGetConnectionLoading} connection={connection} />
        )}
      </NvConditionalRender>
    </>
  );
};
