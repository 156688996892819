import { useGetSlackWorkspace, useGetUserApp, useUpdateUserAppUserPreferences } from '@novaera/actioner-service';
import { NvCompletionState, NvEmoji, NvFlex, NvLink } from '@novaera/core';
import { useEffect } from 'react';
import { InstallStepsCompletedStepProps } from './types';

export const CompletedStep: React.FC<React.PropsWithChildren<InstallStepsCompletedStepProps>> = ({
  appId,
  handleNextClick,
}) => {
  const { data: app } = useGetUserApp(appId);
  const { data: slackWorkspace } = useGetSlackWorkspace();
  const ACTIONER_PROD_SLACK_APP_ID = 'A02TCGS98DS';
  const { mutate: updateUserAppUserPreferences } = useUpdateUserAppUserPreferences();

  useEffect(() => {
    updateUserAppUserPreferences({ appId, setupDismissed: true });
  }, [appId, updateUserAppUserPreferences]);

  return (
    <NvFlex gap="32px" width="100%" alignItems="center">
      <NvCompletionState
        primaryText={'You are all set!'}
        secondaryText={
          <>
            <strong>{app?.name ?? 'This app'}</strong> is now ready to use! You can update the list of users who can
            access this app from the <strong>Permissions</strong> section.
          </>
        }
        width="520px"
        icon={<NvEmoji symbol="🎉" label="completion-state" />}
      />
      <NvFlex gap="8px" alignItems="center">
        <NvLink onClick={handleNextClick}>Go to Actioner Web for Further Settings and Adjustments</NvLink>
        {slackWorkspace && (
          <NvLink
            onClick={() => {
              window.location.href = `${slackWorkspace.slackTeamLink}&id=${ACTIONER_PROD_SLACK_APP_ID}&tab=home`;
            }}
          >
            Go to App Home in Slack to Begin Using <strong>{app?.name ?? 'this app'}</strong>
          </NvLink>
        )}
      </NvFlex>
    </NvFlex>
  );
};
