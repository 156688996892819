import { useFetchProfile, useGetSlackWorkspace, useGetUserPermissions } from '@novaera/actioner-service';
import { isAnalyticsEnabled, isLogRocketEnabled, isUserGuidingEnabled } from '../../utils';
import { useAnalyticsIdentifier } from '../use-analytics-identifier';
import { useLogRocketIdentifier } from '../use-log-rocket-identifier';
import { useUserGuidingIdentifier } from '../use-user-guiding-identifier';

export const UserPermissionProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { data, isFetched: permissionsIsFetched } = useGetUserPermissions();
  const { data: user, isFetched: userIsFetched } = useFetchProfile();
  const { data: slackWorkspace, isFetched: slackWorkspaceIsFetched } = useGetSlackWorkspace();

  useUserGuidingIdentifier({
    enabled: isUserGuidingEnabled && permissionsIsFetched && userIsFetched && slackWorkspaceIsFetched,
    user: user,
    permissions: data?.permissions,
    slackWorkspace,
  });

  useLogRocketIdentifier({
    enabled: isLogRocketEnabled && permissionsIsFetched && userIsFetched,
    user: user,
    permissions: data?.permissions,
  });

  useAnalyticsIdentifier({
    enabled: isAnalyticsEnabled && userIsFetched,
    user: user,
  });

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
