import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { ConnectionMapping } from '../../connection-mapping';
import { QUERY_KEYS_CONNECTION_MAPPINGS } from '../../connection-mapping/keys';
import { CONNECTION_ROOT_PATH } from '../constants';
import { QUERY_KEYS_CONNECTION } from '../keys';
import { Connection } from '../types';
import { UserConnectionsResponse } from '../use-get-user-connections/types';
import { DeleteConnectionParams } from './types';

const deleteConnection = async (params: DeleteConnectionParams) => {
  const result = await NvAxios.delete<Connection>(`${CONNECTION_ROOT_PATH}/${params.connectionId}`);
  return result?.data;
};

export const useDeleteConnectionService = () => {
  const cache = useQueryClient();

  return useMutation(deleteConnection, {
    onSuccess(data, params) {
      cache.setQueryData<UserConnectionsResponse>(QUERY_KEYS_CONNECTION.list(), (old?: UserConnectionsResponse) => {
        return {
          userConnections:
            old?.userConnections
              .map((userConnection) => {
                if (userConnection.schemaId !== data.schemaId) {
                  return userConnection;
                }
                return {
                  ...userConnection,
                  connections: userConnection.connections.filter((connection) => connection.id !== data.id),
                };
              })
              .filter((userConnection) => userConnection.connections.length > 0) ?? [],
        };
      });

      cache.setQueriesData(QUERY_KEYS_CONNECTION_MAPPINGS.all, (old?: ConnectionMapping[]) => {
        return old?.map((item) => {
          if (item.slackConnection) {
            return item;
          }

          const newConnections = item.connections.filter((connection) => connection.id !== params.connectionId);

          const partialNewItem = {
            connections: newConnections,
            integrationId: item.integrationId,
            schemaId: item.schemaId,
            schemaName: item.schemaName,
            logoUrl: item.logoUrl,
            slackConnection: item.slackConnection,
            ambiguous: false,
          };

          let newItem: ConnectionMapping;

          if (newConnections?.length === 1) {
            newItem = {
              connected: true,
              connectionId: newConnections[0].id,
              ...partialNewItem,
            };
          } else {
            newItem = {
              connected: false,
              ...partialNewItem,
            };
          }
          return item.connected && item.connectionId === params.connectionId ? newItem : item;
        });
      });
    },
  });
};
