import { PartialUpdateQueryOption } from '@novaera/actioner-service';
import { SchemaType } from '@novaera/ah-common';
import { NvButton, NvDeleteOutlineIcon, NvField, NvFlex, NvSelect, NvTypography, useField } from '@novaera/core';
import { useRecordParametersContext } from '../../../../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/properties-panel/property-panels/action-node-property-panel-drawer/action-node-properties/records-parameter-mapping/provider';
import { ParameterMapperItem } from '../../../parameter-mapper/parameter-mapper-form/parameter-mapper-item';
import { PropertyPanelSection } from '../../../property-panel';
import { QueryOptions } from '../constants';
import { useRecordFieldController } from './controllers/use-record-field-controller';
import { RecordFieldProps } from './types';

export const RecordField: React.FC<React.PropsWithChildren<RecordFieldProps>> = ({ field, onDelete, context }) => {
  const { recordParameters } = useRecordParametersContext();
  const { queryOptions } = useRecordFieldController(field);
  const inputParameter = recordParameters.find(({ id }) => id === field.id);
  const {
    input: { value: operationType },
  } = useField<PartialUpdateQueryOption>(`types.${inputParameter?.id}`);

  return (
    <PropertyPanelSection
      title={field.name}
      actions={
        <NvButton
          onlyIcon
          size="small"
          color="ghost"
          onClick={() => {
            onDelete(field);
          }}
        >
          <NvDeleteOutlineIcon />
        </NvButton>
      }
    >
      {inputParameter && (
        <NvFlex gap="8px">
          <NvFlex width="50%">
            <NvField
              name={`types.${inputParameter.id}`}
              allowNull
              format={(value) => value}
              parse={(value) => value}
              component={({ value, onChange }) => (
                <NvSelect
                  value={value}
                  onChange={onChange}
                  renderValue={(option) => QueryOptions.find((queryOption) => queryOption.value === option)?.label}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                  }}
                  defaultValue={queryOptions[0].value}
                  options={queryOptions}
                />
              )}
            />
          </NvFlex>
          {(operationType !== PartialUpdateQueryOption.REMOVE || inputParameter.schema?.type === SchemaType.map) && (
            <NvFlex>
              <ParameterMapperItem context={context} inputParameter={inputParameter} hideLabel />
              {operationType === PartialUpdateQueryOption.REMOVE && inputParameter.schema?.type === SchemaType.map && (
                <NvTypography marginTop="4px" marginLeft={'2px'} variant="body3">
                  The value to extract from the 'map' in the specified field is the value that will be removed from the
                  'map' object using a specific key.
                </NvTypography>
              )}
            </NvFlex>
          )}
        </NvFlex>
      )}
    </PropertyPanelSection>
  );
};
