import {
  NvActionIllustration,
  NvCalendarIllustration,
  NvClockIllustration,
  NvConnectionIllustration,
  NvDatabaseIllustration,
  NvEventRuleIllustration,
  NvFlex,
  NvGeneralIllustration,
  NvIntegrationIllustration,
  NvPenIllustration,
  NvTypography,
  NvWorkflowIllustration,
} from '@novaera/core';
import { ReactNode } from 'react';
import { EmptyStates } from './types';

export const EMPTY_STATE: Record<EmptyStates, { logo: ReactNode; content: React.ReactElement | string }> = {
  [EmptyStates.INTEGRATION]: {
    logo: <NvIntegrationIllustration />,
    content: (
      <>
        With integrations, you can define specific triggers and actions that enable communication with your various
        tools through a connection schema. By adding an integration, you can define these triggers and actions, allowing
        you to incorporate them into your workflows.
      </>
    ),
  },
  [EmptyStates.USER_APP]: {
    logo: <NvGeneralIllustration />,
    content: (
      <NvFlex gap="8px">
        <NvTypography variant="h2">You have no apps yet</NvTypography>
        An Actioner app is a collection of automations that seamlessly integrate your tools and workflows into Slack.
      </NvFlex>
    ),
  },
  [EmptyStates.WORKFLOWS]: {
    logo: <NvWorkflowIllustration />,
    content: (
      <>
        Workflows empower you to manage and automate your recurring tasks. By creating a workflow, you can set up your
        sequence of events to run automatically.
      </>
    ),
  },
  [EmptyStates.ACTIONS]: {
    logo: <NvActionIllustration />,
    content: (
      <>
        Actions serve as the primary building block of integrations and enable the sending of HTTPS requests to your
        tools to execute simple CRUD operations. Actions take inputs, utilize a connection schema to send a request to
        your tool, and ultimately generate a response.
      </>
    ),
  },
  [EmptyStates.EVENTS]: {
    logo: <NvEventRuleIllustration />,
    content: (
      <>
        A trigger is a set of conditions and actions defined to process events received through webhook subscriptions.
        Triggers are used to filter the raw event data, enrich the context of the event, and transform the event into
        meaningful integration triggers for the workflows.
      </>
    ),
  },
  [EmptyStates.APP_DESCRIPTION]: {
    logo: <NvPenIllustration />,
    content: (
      <>
        There are no descriptions available for this app yet. Add a brief overview of app functionalities, potential use
        cases, and features.
      </>
    ),
  },
  [EmptyStates.DATABASE]: {
    logo: <NvDatabaseIllustration />,
    content: <>Add a table to define a data structure and create records based on its configuration.</>,
  },
  [EmptyStates.RECURRING_JOB]: {
    logo: <NvCalendarIllustration />,
    content: (
      <>
        Recurring jobs let you automate repetitive tasks on a regular basis.Create a recurring job to run a workflow at
        a scheduled interval.
      </>
    ),
  },
  [EmptyStates.SCHEDULED_JOB]: {
    logo: <NvClockIllustration />,
    content: (
      <>
        Scheduled jobs let you run actions or workflows at a specific time automatically. Create a scheduled job to run
        a workflow at a designated time.
      </>
    ),
  },
  [EmptyStates.CONNECTION]: {
    logo: <NvConnectionIllustration />,
    content: (
      <>
        A connection schema is a set of configurations that allow you to interact with your external tools by defining
        authorization parameters.
      </>
    ),
  },
  [EmptyStates.PROFILE_CONNECTION]: {
    logo: <NvConnectionIllustration />,
    content: <>Add connections for seamless interaction with your external tools.</>,
  },
  [EmptyStates.APP_DIRECTORY]: {
    logo: <NvGeneralIllustration />,
    content: <>No results found for your search. Try adjusting your search criteria.</>,
  },
  [EmptyStates.CONFIG]: {
    logo: <NvGeneralIllustration />,
    content: (
      <>
        App configurations allow you to manage and control your app by specifying variables in a JSON file. By creating
        a configuration policy, you can effectively store configuration information for your app and utilize this data
        within your workflows.
      </>
    ),
  },
  [EmptyStates.SLACK_CONNECTION]: {
    logo: <NvConnectionIllustration />,
    content: (
      <>
        Actioner enables you to do all the work in Slack in a consistent and unified form. No effort is required to use
        workflows as Slack-native apps.
      </>
    ),
  },
  [EmptyStates.DOCUMENTS]: {
    logo: <NvPenIllustration />,
    content: (
      <>
        Documents enable seamless creation and storage information related to your business. Using documents with
        workflows enhances productivity and allows easy access to essential knowledge.
      </>
    ),
  },
  [EmptyStates.GROUPS]: {
    logo: <NvPenIllustration />,
    content: (
      <>
        Groups help you to categorize users who have similar roles, team connections, or responsibilities, simplifying
        the management of workflow permissions within these defined categories.
      </>
    ),
  },
  [EmptyStates.GROUPS_DETAIL]: {
    logo: <NvPenIllustration />,
    content: <>Start adding users to group.</>,
  },
  [EmptyStates.ACTIONER_EVENT]: {
    logo: <NvEventRuleIllustration />,
    content: (
      <>
        Actioner events allow you to structure events, enabling workflows to listen and subscribe to these events, as
        well as to publish new events to them.
      </>
    ),
  },
  [EmptyStates.AI_ASSISTANT]: {
    logo: <NvGeneralIllustration />,
    content: (
      <>
        Create an AI assistant for your app to facilitate interaction through conversational exchanges in dedicated
        Slack channels.
      </>
    ),
  },
  [EmptyStates.CATALOG_RIGHT_PANEL]: {
    logo: <NvDatabaseIllustration />,
    content: <>Choose Property or Relationship to view settings.</>,
  },
};
