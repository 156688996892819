import { NvAddBoxIcon, NvBox, NvButton, NvChevronRightIcon, NvCollapse, NvTypography } from '@novaera/core';
import classNames from 'classnames';
import { cloneElement, FC, useState } from 'react';
import { SideMenuItemFrameMain } from '../../../../components/side-menu/styled';
import { ChevronButton } from './styled';

export const CollapsibleMenu: FC<
  React.PropsWithChildren<{
    name: string;
    to: string;
    startIcon: JSX.Element;
    onClickAddButton: () => void;
    selected?: boolean;
  }>
> = ({ name, startIcon, onClickAddButton, children, to, selected }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <NvBox>
      <SideMenuItemFrameMain to={to} selected={selected}>
        <ChevronButton
          onClick={(e) => {
            setIsOpen((prev) => !prev);
            e.preventDefault();
          }}
          className={classNames({ isOpen })}
        >
          <NvChevronRightIcon className="opener" />
        </ChevronButton>
        <NvBox display="flex" gap="8px" alignItems="center" flex="1 1 auto">
          {cloneElement(startIcon, { sx: { width: '20px', height: '20px' } })}

          <NvTypography variant="h4">{name}</NvTypography>
        </NvBox>
        <NvButton
          onlyIcon
          color="ghost"
          size="small"
          onClick={(e) => {
            onClickAddButton();
            e.preventDefault();
          }}
        >
          <NvAddBoxIcon />
        </NvButton>
      </SideMenuItemFrameMain>
      <NvCollapse in={isOpen} timeout={300} unmountOnExit>
        {children}
      </NvCollapse>
    </NvBox>
  );
};
