import { NvAxios, useMutation } from '@novaera/core';
import { SendVerificationProps } from './types';

const sendVerification = async (props: SendVerificationProps) => {
  const result = await NvAxios.post<{ redirectUri?: string }>('/v1/users/signup/initiate', {
    ...props,
  });
  return result.data;
};

export const useSendVerification = () => {
  return useMutation((props: SendVerificationProps) => sendVerification(props), {
    onSuccess: ({ redirectUri }) => {
      if (redirectUri) {
        window.location.href = redirectUri;
      }
    },
  });
};
