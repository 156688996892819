import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { NvBox, NvButton, NvCloseIcon, NvDragIndicatorIcon, NvFlex, NvTooltip } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { isEmpty, isUndefined } from 'lodash';
import { DraggableItem, DraggablePlaceholder, DraggablePlaceholderBorderedBox } from './styled';
import { DragDropListProps } from './types';
import { useDragDropList } from './use-drag-drop-list';

export const DragDropList = <T,>({
  listItems,
  onDragEnd,
  itemNode,
  onItemRemoved,
  getDraggableId,
  getItemValue,
  droppableId,
}: DragDropListProps<T>) => {
  const theme = useTheme();

  const { handleDragEnd, handleDragStart, handleDragUpdate, placeholderProps } = useDragDropList({ onDragEnd });

  return (
    <DragDropContext
      onDragStart={handleDragStart}
      onDragUpdate={handleDragUpdate}
      onDragEnd={(event) => handleDragEnd(event)}
    >
      {!isUndefined(listItems.length) && listItems.length > 0 && (
        <Droppable droppableId={droppableId}>
          {(provided, snapshot) => {
            return (
              <NvFlex
                {...provided.droppableProps}
                ref={provided.innerRef}
                position="relative"
                marginLeft="-4px"
                width="calc(100% + 8px)"
              >
                {listItems.map((fieldName, index) => {
                  const field = getItemValue(index);
                  const draggableId = getDraggableId(field, index);
                  const isDragDisabled = Boolean(listItems.length && listItems.length < 2);

                  return (
                    <Draggable
                      key={`${draggableId}_${fieldName}_${index}`}
                      draggableId={draggableId}
                      index={index}
                      isDragDisabled={isDragDisabled}
                    >
                      {(provided, snapshot) => (
                        <DraggableItem
                          isDragging={snapshot.isDragging}
                          key={`${fieldName}`}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <NvBox
                            minWidth="20px"
                            height="20px"
                            display={isDragDisabled ? 'none' : 'flex'}
                            alignItems="center"
                            justifyContent="center"
                            marginTop="6px"
                          >
                            <NvDragIndicatorIcon fontSize="small" htmlColor={theme.palette.nv_neutral_alpha[400]} />
                          </NvBox>
                          {typeof itemNode === 'function' ? itemNode(field, fieldName) : itemNode}
                          <NvBox marginTop="4px">
                            <NvTooltip title="Remove" placement="top">
                              <NvButton onlyIcon color="secondary" size="small" onClick={() => onItemRemoved(index)}>
                                <NvCloseIcon></NvCloseIcon>
                              </NvButton>
                            </NvTooltip>
                          </NvBox>
                        </DraggableItem>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
                {!isEmpty(placeholderProps) && snapshot.isDraggingOver && (
                  <DraggablePlaceholder
                    style={{
                      top: placeholderProps.clientY,
                      left: placeholderProps.clientX + 4,
                      height: placeholderProps.clientHeight,
                      width: placeholderProps.clientWidth - 4,
                    }}
                  >
                    <DraggablePlaceholderBorderedBox />
                  </DraggablePlaceholder>
                )}
              </NvFlex>
            );
          }}
        </Droppable>
      )}
    </DragDropContext>
  );
};
