import { DropResult } from '@hello-pangea/dnd';
import {
  StaticWorkflowReferenceElement,
  useGetWorkflowsDetail,
  WorkflowReferenceElement,
  WorkflowReferencesBlock,
} from '@novaera/actioner-service';
import { useParams } from '@novaera/route';
import { uniq } from 'lodash';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { ACTION_NODE_PROPERTIES_WRAPPER_ID } from '../../../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/properties-panel/property-panels/action-node-property-panel-drawer/constants';
import { useSlackBlocksContext } from '../../ui-components/slack-blocks-component/provider';

export const useWorkflowReferenceItems = ({
  block,
  onChange,
  index,
}: {
  block: WorkflowReferencesBlock;
  onChange: (block: WorkflowReferencesBlock, index: number) => void;
  index: number;
}) => {
  const { setSelectedElementId, selectedElementId, panelCloseFn, panelProps, setPanelOpen } = useSlackBlocksContext();
  const makeOnDragEndFunction = useCallback(
    (result: DropResult) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      const source = block.elements[result.source.index];
      block.elements.splice(result.source.index, 1);
      block.elements.splice(result.destination.index, 0, source);

      onChange(block, index);
    },
    [block, onChange, index]
  );

  const { userAppId } = useParams();

  const ids = useMemo(
    () =>
      uniq(
        block?.elements
          ?.filter((r): r is StaticWorkflowReferenceElement => r.type === 'static')
          .map((e) => e.workflowId)
      ),
    [block?.elements]
  );

  const workflowsDetailsMap = useGetWorkflowsDetail({
    appId: userAppId,
    workflowIds: ids,
  });

  const handleElementChange = useCallback(
    (element: WorkflowReferenceElement) => {
      onChange(
        {
          ...block,
          elements: block.elements.map((e) => (e.id === element.id ? element : e)),
        },
        index
      );
    },
    [block, index, onChange]
  );

  const handleDeleteClick = useCallback(
    (id: string) => {
      onChange(
        {
          ...block,
          elements: block.elements.filter((e) => e.id !== id),
        },
        index
      );
    },
    [onChange, block, index]
  );

  const wrapperElementRef = useRef<HTMLElement | null>(null);

  const element = block.elements.find((e) => e.id === selectedElementId);
  useEffect(() => {
    const wrapperElement = document.getElementById(ACTION_NODE_PROPERTIES_WRAPPER_ID);
    wrapperElementRef.current = wrapperElement;
  }, []);
  return {
    element,
    handleDeleteClick,
    handleElementChange,
    makeOnDragEndFunction,
    workflowsDetailsMap,
    setSelectedElementId,
    setPanelOpen,
    selectedElementId,
    wrapperElementRef,
    panelProps,
    panelCloseFn,
  };
};
