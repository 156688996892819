import { FormTrigger, WorkflowWithState, useGetWorkflow, useSaveToDraftWorkflow } from '@novaera/actioner-service';
import { useCallback } from 'react';
import { useGetWorkflowQueryParams } from '../../../../../../../controllers/use-get-workflow-query-params';

export const useDefaultUserBehavior = () => {
  const { mutate: saveToDraft, isLoading: isSaveLoading } = useSaveToDraftWorkflow();
  const { userAppId, workflowId } = useGetWorkflowQueryParams();
  const { workflow } = useGetWorkflow({ appId: userAppId, workflowId });

  const handleUpdateDefaultUserBehavior = useCallback(
    (useDefaultUserAsPrimeUser: boolean) => {
      const trigger = workflow?.trigger;
      if (trigger && trigger.type === 'form') {
        const newTrigger: FormTrigger = {
          ...trigger,
          useDefaultUserAsPrimeUser,
        };
        const newWorkflow: WorkflowWithState = {
          ...workflow,
          trigger: newTrigger,
        };
        saveToDraft(newWorkflow);
      }
    },
    [saveToDraft, workflow]
  );

  return { handleUpdateDefaultUserBehavior, isSaveLoading };
};
