import { APP_LOADING_ANIMATION_DATA } from '@novaera/ah-common';
import { NvBox, NvFlex, NvLottie, NvTypography, Portal } from '@novaera/core';
import { AppLoadingWrapper } from './styled';

export const LoadingPage: React.FC<
  React.PropsWithChildren<{
    message?: string;
  }>
> = ({ message }) => {
  const animationOptions = {
    loop: true,
    autoplay: true,
    animationData: APP_LOADING_ANIMATION_DATA,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <Portal node={document.getElementById('root')}>
      <NvBox>
        <AppLoadingWrapper>
          <NvFlex alignItems="center" justifyContent="center">
            {message && (
              <NvFlex marginBottom="110px">
                <NvTypography variant="h1">Redirecting to Slack</NvTypography>
              </NvFlex>
            )}
            <NvFlex>
              <NvLottie options={animationOptions} width="150px" height="150px" speed={0} />
            </NvFlex>
          </NvFlex>
        </AppLoadingWrapper>
      </NvBox>
    </Portal>
  );
};
