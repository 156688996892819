import {
  CheckboxComponent,
  CheckboxGroupComponent,
  DatePickerComponent,
  DateTimePickerComponent,
  DynamicInputComponent,
  HeaderComponent,
  InputParameter,
  InputParameters,
  JobTarget,
  JobTargetUIComponent,
  LinkComponent,
  MultiSelectComponent,
  OptionErrorContext,
  PartialUpdateQueryOption,
  PlainTextComponent,
  RadioButtonGroupComponent,
  RecordAttributesUIComponent,
  RecordPartialUpdateUIComponent,
  SimpleParameterMapping,
  SingleSelectComponent,
  SlackBlocks,
  TextInputComponent,
  TimePickerComponent,
  UIComponentType,
} from '@novaera/actioner-service';
import { Option, SchemaType } from '@novaera/ah-common';
import { Context, FieldComponentCommonProps, FieldProps } from '@novaera/core';
import { Dispatch, SetStateAction } from 'react';
import { FieldInputProps } from 'react-final-form';
import { InputFormValues } from '../../../action-designer/providers/input-values';
import { GetDynamicInputResponseFunction } from '../../dynamic-input/providers/action-dynamic-input-provider/types';
import { DependencyInputComponentStateReturnValue } from '../../dynamic-input/providers/controller/use-check-dependency-value/types';
import { DynamicInputComponentGetState } from '../../dynamic-input/providers/workflow-dynamic-input-provider/types';
import { GetOptionsResponseFunction } from '../../options/provider/types';
import { useCheckboxFieldAdapter } from '../checkbox-component/checkbox-field-adapter';
import { useMultiOptionFieldAdapter } from '../common/adapter/use-multi-option-field-adapter';
import { useSingleOptionFieldAdapter } from '../common/adapter/use-single-option-field-adapter';
import { useDatePickerFieldAdapter } from '../date-picker-component/date-picker-field-adapter';
import { useDateTimePickerFieldAdapter } from '../date-time-picker-component/date-time-picker-field-adapter';
import { useDisplayValueFieldAdapter } from '../display-value-component/display-value-field-adapter';
import { useJobTargetAdapter } from '../job-target-component/controllers/use-job-target-adapter';
import { useRecordAttributesAdapter } from '../record-attributes-component/record-attributes-adapter';
import { useRecordPartialUpdateAdapter } from '../record-partial-update-component/record-partial-update-adapter';
import { useSlackBlocksAdapter } from '../slack-blocks-component/controllers/use-slack-blocks-adapter';
import { useTextFieldFieldAdapter } from '../text-field-component/use-text-field-field-adapter';
import { useTimePickerFieldAdapter } from '../time-picker-component/time-picker-field-adapter';
import { useCodeInputFieldAdapter } from '../use-code-input-field-adapter';

export type UseUIComponentType = {
  inputParameter: InputParameter;
  isScripted?: boolean;
  isDisplayValueConfigurable?: boolean;
  extractValue?: boolean;
  hidden?: boolean;
  dynamicInputNestedComponentProps?: DynamicInputNestedComponentProps;
  getOptionsResponse?: GetOptionsResponseFunction;
  getDynamicInputResponse?: GetDynamicInputResponseFunction;
  setInputParameterIdsShowingOptions?: Dispatch<SetStateAction<string[]>>;
  onSearchAsYouTypeValueUpdate?: (params: { value: string; inputParameterId: string }) => void;
  getDynamicInputComponentState?: DynamicInputComponentGetState;
  forceValueUsage?: boolean;
  onInputValueChange?: (param: { inputParameterId: string; value: unknown }) => void;
};

export type BaseUIComponentProps = Omit<FieldProps, 'component' | 'format' | 'parse'> & { key: string };
export type GetTextFieldComponentProps = BaseUIComponentProps & {
  parse: ReturnType<ReturnType<typeof useTextFieldFieldAdapter>['parse']>;
  format: ReturnType<typeof useTextFieldFieldAdapter>['format'];
};

export type GetSingleSelectComponentProps = BaseUIComponentProps & {
  parse: ReturnType<ReturnType<typeof useSingleOptionFieldAdapter>['parse']>;
  format: ReturnType<ReturnType<typeof useSingleOptionFieldAdapter>['format']>;
};

export type GetMultiSelectComponentProps = BaseUIComponentProps & {
  parse: ReturnType<ReturnType<typeof useMultiOptionFieldAdapter>['parse']>;
  format: ReturnType<ReturnType<typeof useMultiOptionFieldAdapter>['format']>;
};

export type GetCheckboxComponentProps = BaseUIComponentProps & {
  parse: ReturnType<ReturnType<typeof useCheckboxFieldAdapter>['parse']>;
  format: ReturnType<typeof useCheckboxFieldAdapter>['format'];
};

export type GetDatePickerComponentProps = BaseUIComponentProps & {
  parse: ReturnType<ReturnType<typeof useDatePickerFieldAdapter>['parse']>;
  format: ReturnType<typeof useDatePickerFieldAdapter>['format'];
};

export type GetTimePickerComponentProps = BaseUIComponentProps & {
  parse: ReturnType<ReturnType<typeof useTimePickerFieldAdapter>['parse']>;
  format: ReturnType<typeof useTimePickerFieldAdapter>['format'];
};

export type GetDateTimePickerComponentProps = BaseUIComponentProps & {
  parse: ReturnType<ReturnType<typeof useDateTimePickerFieldAdapter>['parse']>;
  format: ReturnType<typeof useDateTimePickerFieldAdapter>['format'];
};

export type GetCodeInputComponentProps = BaseUIComponentProps & {
  parse: ReturnType<ReturnType<typeof useCodeInputFieldAdapter>['parse']>;
  format: ReturnType<typeof useCodeInputFieldAdapter>['format'];
};

export type SlackBlockComponentProps = BaseUIComponentProps & {
  parse: ReturnType<ReturnType<typeof useSlackBlocksAdapter>['parse']>;
  format: ReturnType<typeof useSlackBlocksAdapter>['format'];
};

export type RecordAttributesComponentProps = BaseUIComponentProps & {
  parse: ReturnType<ReturnType<typeof useRecordAttributesAdapter>['parse']>;
  format: ReturnType<typeof useRecordAttributesAdapter>['format'];
};

export type RecordPartialUpdateComponentProps = BaseUIComponentProps & {
  parse: ReturnType<ReturnType<typeof useRecordPartialUpdateAdapter>['parse']>;
  format: ReturnType<typeof useRecordPartialUpdateAdapter>['format'];
};

export type JobTargetComponentProps = BaseUIComponentProps & {
  parse: ReturnType<ReturnType<typeof useJobTargetAdapter>['parse']>;
  format: ReturnType<typeof useJobTargetAdapter>['format'];
};

export type DisplayValueComponentProps = BaseUIComponentProps & {
  parse: ReturnType<ReturnType<typeof useDisplayValueFieldAdapter>['parse']>;
  format: ReturnType<typeof useDisplayValueFieldAdapter>['format'];
};

export type GetUIComponentProps =
  | GetTextFieldComponentProps
  | GetSingleSelectComponentProps
  | GetMultiSelectComponentProps
  | GetCheckboxComponentProps
  | GetDatePickerComponentProps
  | GetTimePickerComponentProps
  | GetDateTimePickerComponentProps
  | GetCodeInputComponentProps
  | SlackBlockComponentProps
  | RecordAttributesComponentProps
  | RecordPartialUpdateComponentProps
  | JobTargetComponentProps
  | DisplayValueComponentProps;

export type FieldInputType<V = unknown> = FieldInputProps<V, HTMLElement> &
  Pick<FieldComponentCommonProps<V>, 'onChange'> & { error?: unknown };

export type GetUIComponentType<V = unknown> = {
  inputParameter?: InputParameter;
  inputProps?: FieldInputType<V>;
  options?: Option[];
  otherProps?: Record<string, unknown>;
  isScripted?: boolean;
  isDisplayValueConfigurable?: boolean;
  context?: Context;
  onSearchAsYouTypeValueUpdate?: (params: { value: string; inputParameterId: string }) => void;
};

export type InputTypeComponentParams = {
  inputParameter: InputParameter<TextInputComponent>;
  inputProps: FieldInputType<string>;
  otherProps?: Record<string, unknown>;
};

export type CodeInputComponentParams = {
  inputParameter: InputParameter<TextInputComponent>;
  inputProps: {
    value: string;
    onBlur: (event: unknown) => void;
    onFocus: (event: unknown) => void;
    name: string;
    onChange: (event: unknown) => void;
  };
  context?: Context;
  otherProps?: Record<string, unknown>;
};

export type SelectTypeComponentParams = {
  inputParameter: InputParameter<SingleSelectComponent>;
  inputProps: FieldInputType<Option>;
  options?: Option[];
  errorContext?: OptionErrorContext;
  isLoading?: boolean;
  onSearchAsYouTypeValueUpdate?: (params: { value: string; inputParameterId: string }) => void;
  otherProps?: Record<string, unknown>;
  dynamicInputComponentState?: DependencyInputComponentStateReturnValue;
};

export type MultiSelectTypeComponentParams = {
  inputParameter: InputParameter<MultiSelectComponent>;
  inputProps: FieldInputType<Option[]>;
  options?: Option[];
  errorContext?: OptionErrorContext;
  isLoading?: boolean;
  onSearchAsYouTypeValueUpdate?: (params: { value: string; inputParameterId: string }) => void;
  otherProps?: Record<string, unknown>;
  dynamicInputComponentState?: DependencyInputComponentStateReturnValue;
};

export type CheckBoxComponentParams = {
  inputParameter: InputParameter<CheckboxComponent>;
  inputProps: FieldInputType<boolean>;
  otherProps?: Record<string, unknown>;
};

export type CheckBoxGroupComponentParams = {
  inputParameter: InputParameter<CheckboxGroupComponent>;
  inputProps: FieldInputType<Option[]>;
  options?: Option[];
  errorContext?: OptionErrorContext;
  isLoading?: boolean;
  otherProps?: Record<string, unknown>;
  dynamicInputComponentState?: DependencyInputComponentStateReturnValue;
};

export type DatePickerComponentParams = {
  inputParameter: InputParameter<DatePickerComponent>;
  inputProps: FieldInputType<string>;
  otherProps?: Record<string, unknown>;
};

export type DisplayValueComponentParams = {
  inputParameter: InputParameter<
    SingleSelectComponent | MultiSelectComponent | CheckboxGroupComponent | RadioButtonGroupComponent
  >;
  inputProps: {
    value: { value: string; displayValue: string };
    onBlur: (event: unknown) => void;
    onFocus: (event: unknown) => void;
    name: string;
    onChange: (event: unknown) => void;
  };
  context?: Context;
  otherProps?: Record<string, unknown>;
};

export const isInputForHeaderComponent = (params: GetUIComponentType): params is HeaderComponentParams => {
  return params.inputParameter?.uiComponent.type === UIComponentType.HEADER;
};

export const isInputForLinkComponent = (params: GetUIComponentType): params is LinkComponentParams => {
  return params.inputParameter?.uiComponent.type === UIComponentType.LINK;
};

export const isInputForPlainTextComponent = (params: GetUIComponentType): params is PlainTextComponentParams => {
  return params.inputParameter?.uiComponent.type === UIComponentType.PLAIN_TEXT;
};

type HeaderComponentParams = {
  inputParameter: InputParameter<HeaderComponent>;
};

type LinkComponentParams = {
  inputParameter: InputParameter<LinkComponent>;
};

type PlainTextComponentParams = {
  inputParameter: InputParameter<PlainTextComponent>;
};

export type RadioButtonComponentParams = {
  inputParameter: InputParameter<RadioButtonGroupComponent>;
  inputProps: FieldInputType<Option>;
  options?: Option[];
  errorContext?: OptionErrorContext;
  isLoading?: boolean;
  otherProps?: Record<string, unknown>;
  dynamicInputComponentState?: DependencyInputComponentStateReturnValue;
};

export type DynamicInputNestedComponentProps = {
  labelVariant: 'h5' | 'h6';
  isParameterMapping?: boolean;
};

export type DynamicInputComponentParams = {
  inputParameter: InputParameter<DynamicInputComponent>;
  inputProps: FieldInputType<SimpleParameterMapping[]>;
  dynamicInputParameters?: InputParameters;
  errorContext?: Record<string, unknown>;
  context?: Context;
  isLoading?: boolean;
  otherProps?: Record<string, unknown>;
  dynamicInputNestedComponentProps?: DynamicInputNestedComponentProps;
  dependencyState?: DependencyInputComponentStateReturnValue;
} & Pick<
  UseUIComponentType,
  | 'getDynamicInputResponse'
  | 'getOptionsResponse'
  | 'onSearchAsYouTypeValueUpdate'
  | 'setInputParameterIdsShowingOptions'
  | 'getDynamicInputComponentState'
>;

export type TimePickerComponentParams = {
  inputParameter: InputParameter<TimePickerComponent>;
  inputProps: FieldInputType<string>;
  otherProps?: Record<string, unknown>;
};

export type DateTimePickerComponentParams = {
  inputParameter: InputParameter<DateTimePickerComponent>;
  inputProps: FieldInputType<string>;
  otherProps?: Record<string, unknown>;
};

export const isCodeInputComponent = (params: GetUIComponentType): params is CodeInputComponentParams => {
  return (
    params.inputParameter?.uiComponent.type === UIComponentType.TEXT_FIELD &&
    !params.isScripted &&
    params.inputParameter.schema?.type === SchemaType.map
  );
};

export const isInputForTextComponent = (params: GetUIComponentType): params is InputTypeComponentParams => {
  return params.inputParameter?.uiComponent.type === UIComponentType.TEXT_FIELD;
};

export const isInputCodeInput = (params: GetUIComponentType): params is CodeInputComponentParams => {
  return !!params.isScripted;
};

export const isInputForSelectComponent = (params: GetUIComponentType): params is SelectTypeComponentParams => {
  return params.inputParameter?.uiComponent.type === UIComponentType.SINGLE_SELECT;
};

export const isDynamicInputComponent = (params: GetUIComponentType): params is DynamicInputComponentParams => {
  return params.inputParameter?.uiComponent.type === UIComponentType.DYNAMIC_INPUT;
};

export const isInputForMultiSelectComponent = (
  params: GetUIComponentType
): params is MultiSelectTypeComponentParams => {
  return params.inputParameter?.uiComponent.type === UIComponentType.MULTI_SELECT;
};

export const isInputForCheckboxGroupComponent = (
  params: GetUIComponentType
): params is CheckBoxGroupComponentParams => {
  return params.inputParameter?.uiComponent.type === UIComponentType.CHECK_BOX_GROUP;
};

export const isInputForRadioButtonComponent = (params: GetUIComponentType): params is RadioButtonComponentParams => {
  return params.inputParameter?.uiComponent.type === UIComponentType.RADIO_BUTTON_GROUP;
};

export const isInputForCheckboxComponent = (params: GetUIComponentType): params is CheckBoxComponentParams => {
  return params.inputParameter?.uiComponent.type === UIComponentType.CHECK_BOX;
};

export const isInputForDatePickerComponent = (params: GetUIComponentType): params is DatePickerComponentParams => {
  return params.inputParameter?.uiComponent.type === UIComponentType.DATE_PICKER;
};

export const isInputForTimePickerComponent = (params: GetUIComponentType): params is TimePickerComponentParams => {
  return params.inputParameter?.uiComponent.type === UIComponentType.TIME_PICKER;
};

export const isInputForDateTimePickerComponent = (
  params: GetUIComponentType
): params is DateTimePickerComponentParams => {
  return params.inputParameter?.uiComponent.type === UIComponentType.DATE_TIME_PICKER;
};

export type BlockListComponentParams = {
  inputProps: FieldInputType<SlackBlocks>;
  context?: Context;
};

export const isInputForSlackBlockComponent = (params: GetUIComponentType): params is BlockListComponentParams => {
  return params.inputParameter?.uiComponent.type === UIComponentType.BLOCK_LIST;
};

export type RecordAttributesUIComponentParams = {
  inputParameter: InputParameter<RecordAttributesUIComponent>;
  inputProps: FieldInputType<SimpleParameterMapping[]>;
  context: Context;
};

export const isInputForRecordAttributesComponent = (
  params: GetUIComponentType
): params is RecordAttributesUIComponentParams => {
  return params.inputParameter?.uiComponent.type === UIComponentType.RECORD_ATTRIBUTES;
};

export type RecordUpdateAttributesUIComponentParams = {
  inputParameter: InputParameter<RecordAttributesUIComponent>;
  inputProps: FieldInputType<SimpleParameterMapping[]>;
  context: Context;
};

export const isInputForRecordUpdateAttributesComponent = (
  params: GetUIComponentType
): params is RecordUpdateAttributesUIComponentParams => {
  return params.inputParameter?.uiComponent.type === UIComponentType.RECORD_UPDATE_ATTRIBUTES;
};

export type RecordPartialUpdateUIComponentParams = {
  inputParameter: InputParameter<RecordPartialUpdateUIComponent>;
  inputProps: FieldInputType<InputFormValues & { types?: Record<string, PartialUpdateQueryOption> }>;
  context: Context;
};

export const isInputForRecordPartialUpdateUIComponent = (
  params: GetUIComponentType
): params is RecordPartialUpdateUIComponentParams => {
  return params.inputParameter?.uiComponent.type === UIComponentType.RECORD_PARTIAL_UPDATE;
};

export type JobTargetParameterMappingParams = {
  inputParameter: InputParameter<JobTargetUIComponent>;
  inputProps: FieldInputType<JobTarget>;
  context?: Context;
};

export const isJobTargetParameterMapping = (params: GetUIComponentType): params is JobTargetParameterMappingParams => {
  return params.inputParameter?.uiComponent.type === UIComponentType.JOB_TARGET;
};

export const isDisplayValueComponent = (params: GetUIComponentType): params is DisplayValueComponentParams => {
  return (
    !!params.isScripted &&
    (params.inputParameter?.uiComponent.type === UIComponentType.SINGLE_SELECT ||
      params.inputParameter?.uiComponent.type === UIComponentType.MULTI_SELECT ||
      params.inputParameter?.uiComponent.type === UIComponentType.CHECK_BOX_GROUP ||
      params.inputParameter?.uiComponent.type === UIComponentType.RADIO_BUTTON_GROUP) &&
    params.inputParameter?.uiComponent.dataSource.type === 'dynamic'
  );
};
