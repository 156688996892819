import { NvAxios, useQuery } from '@novaera/core';

export type WorkspaceDeletionRequestResponse = {
  reason: string;
  status: 'initiated' | 'started' | 'deleted';
  initiationDate: number;
  createdAt: string;
};

const getWorkspaceDeletionRequest = async () => {
  const result = await NvAxios.get<WorkspaceDeletionRequestResponse>(`v1/workspaces/deletion`);
  return result?.data;
};

export const useGetWorkspaceDeletionRequest = () => {
  return useQuery(['workspace-deletion-request'], getWorkspaceDeletionRequest, {
    retry: 0,
    cacheTime: 0,
    onError: () => {},
  });
};
