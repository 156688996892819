import { useUpdateConnectionMappingService } from '@novaera/actioner-service';
import { NvSelect, NvSelectMenuItem, NvSkeleton, NvTypography } from '@novaera/core';
import { assert } from '@novaera/utils';
import { FC } from 'react';
import { useGetWorkflowQueryParams } from '../../../../workflow-designer/user-app-workflow-canvas/controllers/use-get-workflow-query-params';
import { MultipleConnectionSelectProps } from './types';

export const MultipleConnectionSelect: FC<MultipleConnectionSelectProps> = ({ connectionMapping }) => {
  const { userAppId } = useGetWorkflowQueryParams();
  const { mutate, isLoading } = useUpdateConnectionMappingService();
  return isLoading ? (
    <NvSkeleton height="16px" width="200px" variant="rectangular" />
  ) : (
    <NvSelect
      placeholder="Select connection"
      compact
      onChange={(value) => {
        if (!value.target.value || value.target.value === 'add-new-connection') return;

        const connection = connectionMapping.connections.find((connection) => connection.id === value.target.value);

        assert(Boolean(connection?.id), new Error('Connection not found'));
        if (!connection?.id) return;

        mutate({
          appId: userAppId,
          schemaId: connectionMapping.schemaId,
          payload: {
            connectionId: connection?.id,
          },
          connectionName: connection?.name,
        });
      }}
      value={connectionMapping.connected ? connectionMapping.connectionId : null}
      SelectDisplayProps={{ style: { maxWidth: '100%' } }}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      }}
      sx={{
        '.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input': {
          backgroundColor: 'transparent',
        },
        '.MuiSelect-select.MuiInputBase-input.MuiSelect-select.MuiInputBase-input': {
          paddingLeft: '2px',
        },
        width: '100%',
      }}
    >
      {!connectionMapping.slackConnection &&
        connectionMapping.connections.map((option) => (
          <NvSelectMenuItem key={`connection-mapping-${option.id}-${option.name}`} value={option.id}>
            <NvTypography variant="h6" noWrap textOverflow={'ellipsis'}>
              {option.name}
            </NvTypography>
          </NvSelectMenuItem>
        ))}
    </NvSelect>
  );
};
