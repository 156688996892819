import { SearchConnection } from '@novaera/actioner-service';
import { NvDialogModal } from '@novaera/core';
import { ValidationErrors } from 'final-form';
import { ConnectModal } from '../../connect-modal';
import { ContactYourAdmin } from '../contact-your-admin-modal';
import { useMissingConnectionController } from '../controllers/use-missing-connection';
import { MissingConnectionFooter } from '../footer';
import { MissingConnectionBody } from '../missing-connection-body';
import { useMissingConnectionUpdatesController } from '../missing-connection-body/controllers/use-missing-connection-updates';
import { MissingConnectionModalProps } from '../types';

export const MissingConnectionModal = ({
  isOpened,
  onConnectionsCompleted,
  onModalClosed,
  connectionValidationCheckResult,
  workflowName,
}: MissingConnectionModalProps) => {
  const { addConnectionMapping } = useMissingConnectionUpdatesController();
  const { connectionValidationMode, worksWithFormSubmit } = useMissingConnectionController({
    connectionValidationSchemas: connectionValidationCheckResult?.invalidNodeConnections,
  });

  return (
    <ContactYourAdmin
      modal
      connectionValidationCheckResult={connectionValidationCheckResult}
      workflowName={workflowName}
      orElse={
        connectionValidationMode === 'missing' && connectionValidationCheckResult?.invalidNodeConnections ? (
          <ConnectModal
            isOpened={isOpened}
            onModalCloseClicked={onModalClosed}
            onFormSubmitFinished={onConnectionsCompleted}
            mode={'Connect'}
            schemaId={connectionValidationCheckResult?.invalidNodeConnections?.[0].connectionSchemaId}
            integrationId={connectionValidationCheckResult?.invalidNodeConnections?.[0].integrationId}
          />
        ) : (connectionValidationMode === 'missing-slack' || connectionValidationMode === 'multiple') &&
          connectionValidationCheckResult?.invalidNodeConnections ? (
          <NvDialogModal<{
            connection: SearchConnection;
          }>
            maxWidth="md"
            fullWidth
            modalIcon="power"
            Header="Connect"
            {...(worksWithFormSubmit && {
              onFormSubmit: ({ connection }) => {
                addConnectionMapping({
                  connectionId: connection.id,
                  schemaId: connection.schemaId,
                  connectionName: connection.name,
                });
                onModalClosed();
                onConnectionsCompleted();
              },
            })}
            onCloseButtonClicked={onModalClosed}
            formProps={{
              validate: (values) => {
                if (!values.connection) {
                  const validationErrors: ValidationErrors = { connection: 'Connection is required' };
                  return validationErrors;
                } else {
                  return;
                }
              },
            }}
            open={isOpened}
            Body={
              <MissingConnectionBody
                connectionValidationCheckResult={connectionValidationCheckResult}
                workflowName={workflowName}
              />
            }
            Footer={<MissingConnectionFooter onModalClosed={onModalClosed} worksWithFormSubmit={worksWithFormSubmit} />}
          />
        ) : null
      }
    />
  );
};
