import {
  Logo,
  useFetchWorkspaceList,
  useGetCurrentWorkspaceInfo,
  useWorkspaceInfoUpdate,
} from '@novaera/actioner-service';
import { ApplicationConfig } from '@novaera/application-config';
import { ROUTE_DEFAULTS, TARGET_URI } from '@novaera/constants';
import { useCreateWorkspace } from '@novaera/identity-provider';
import { getPathForTargetURI } from '@novaera/service';
import { setCookieForSpecificDomain } from '@novaera/utils';
import { AppContextAction, useAppContext } from '../../../../app-context';
import { DisplayNameForm, SubdomainForm } from '../../types';

export const useWorkspaceInfo = () => {
  const { dispatch } = useAppContext();

  const { mutate: mutateDisplayName, isLoading: isDisplayNameUpdating } = useWorkspaceInfoUpdate();
  const { mutate: mutateSubDomain, isLoading: isSubdomainNameUpdating } = useWorkspaceInfoUpdate();
  const { mutate: mutateIcon } = useWorkspaceInfoUpdate();

  const { refetch } = useFetchWorkspaceList();

  const { workspaceInfo, isLoading: isGetWorkspaceInfoLoading } = useGetCurrentWorkspaceInfo();

  const { checkSubDomain, isError: hasWorkspaceSubDomainError } = useCreateWorkspace({
    reason: 'name-change',
  });

  const handleDisplayNameUpdate = (value: DisplayNameForm) => {
    mutateDisplayName(
      {
        displayName: value.displayName,
      },
      {
        onSuccess: async () => {
          const workspaceResult = await refetch();

          dispatch({
            type: AppContextAction.UpdateWorkspaces,
            payload: {
              workspaces: workspaceResult.data?.workspaces || [],
            },
          });
        },
      }
    );
  };

  const handleSubdomainUpdate = (value: SubdomainForm) => {
    mutateSubDomain(
      {
        subdomain: value.subdomain,
      },
      {
        onSuccess: () => {
          setCookieForSpecificDomain(TARGET_URI, getPathForTargetURI(), `${ApplicationConfig.Actioner.baseHost}`);
          window.location.href = `${ApplicationConfig.Actioner.origin}${ROUTE_DEFAULTS.LOG_OUT}`;
        },
      }
    );
  };

  const handleIconChange = ({ logo }: { logo: Logo }) => {
    mutateIcon(
      { logo },
      {
        onSuccess: async () => {
          const workspaceResult = await refetch();

          dispatch({
            type: AppContextAction.UpdateWorkspaces,
            payload: {
              workspaces: workspaceResult.data?.workspaces || [],
            },
          });
        },
      }
    );
  };

  return {
    workspaceInfo,
    isDisplayNameUpdating,
    isSubdomainNameUpdating,
    isGetWorkspaceInfoLoading,
    hasWorkspaceSubDomainError,
    onSubdomainUpdate: handleSubdomainUpdate,
    onDisplayNameUpdate: handleDisplayNameUpdate,
    checkSubDomain,
    onIconChanged: handleIconChange,
  };
};
