import { DropResult } from '@hello-pangea/dnd';
import { SlackBlock } from '@novaera/actioner-service';
import { cloneDeep } from 'lodash';
import { useCallback } from 'react';
import { BlockListComponentParams } from '../../../use-ui-component/types';

export const useSlackBlockComponent = ({
  value,
  onChange,
}: Pick<BlockListComponentParams['inputProps'], 'value' | 'onChange'>) => {
  const makeOnDragEndFunction = useCallback(
    (result: DropResult) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      const source = value[result.source.index];
      value.splice(result.source.index, 1);
      value.splice(result.destination.index, 0, source);

      onChange(cloneDeep(value));
    },
    [onChange, value]
  );

  const handleDeleteBlock = useCallback(
    ({ index }: { index: number }) => {
      onChange(value.filter((v, i) => i !== index));
    },
    [onChange, value]
  );

  const handleOnChangeBlock = useCallback(
    (newBlock: SlackBlock, index: number) => {
      const formValues: SlackBlock[] = value;

      onChange(
        formValues?.map<SlackBlock>((block, innerIndex: number) => (innerIndex === index ? newBlock : block)) ?? [
          newBlock,
        ]
      );
    },
    [onChange, value]
  );
  return { makeOnDragEndFunction, handleDeleteBlock, handleOnChangeBlock };
};
