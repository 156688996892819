import {
  FormTrigger,
  InputParameter,
  UIComponentType,
  useGetWorkflow,
  useSaveToDraftWorkflow,
  useUpdateWorkflowMetadata,
} from '@novaera/actioner-service';
import {
  FieldArray,
  NvAddBoxIcon,
  NvBox,
  NvButton,
  NvCloseIcon,
  NvConditionalRender,
  NvConditionalWrap,
  NvCustomEmptyIcon,
  NvCustomExecuteButtonIcon,
  NvFlex,
  NvForm,
  NvNestedDropdown,
  NvSearchIcon,
  NvSwitch,
  NvTextField,
  NvTypography,
  Portal,
} from '@novaera/core';
import { useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import { assert, noop } from '@novaera/utils';
import arrayMutators from 'final-form-arrays';

import { DragDropContext, Draggable, DropResult, Droppable } from '@hello-pangea/dnd';
import { cloneDeep, isUndefined } from 'lodash';
import { FC, useCallback, useMemo, useState } from 'react';
import { FieldArrayRenderProps } from 'react-final-form-arrays';
import { useDebouncedCallback } from 'use-debounce';
import {
  NodeType,
  PropertyPanelHeader,
  PropertyPanelListHeader,
  PropertyPanelSection,
  PropertyPanelSimpleSection,
} from '../../../../../../../../components';
import { ADD_NEW_INPUT_MENU_ITEMS } from '../../../../../../../../utils/helpers/add-new-input-menu-items';
import { InputMenuItemValue } from '../../../../../../../../utils/helpers/add-new-input-menu-items/types';
import { generateNewInputParameter } from '../../../../../../../../utils/helpers/generate-new-input-parameter';
import { useWorkflowPermission } from '../../../../../../../user-app-permission-boundary/use-workflow-permission';
import { useUserAppOptionsContext } from '../../../../components/options/provider';
import { userAppGraph } from '../../../../graph-utils/user-app-graph';
import { useNovaeraFlow } from '../../../../use-novaera-flow';
import { usePropertyPanelContext } from '../../../provider';
import { PropertiesLoading } from '../../common/properties-loading';
import { FormTriggerPropertyPanelFormProps } from '../types';
import { FormTriggerDefaultUserSection } from './form-trigger-default-user-section';
import { FormTriggerInputParameterItem } from './form-trigger-input-parameter-item';
import { FormTriggerPropertiesProps } from './types';

export const FormTriggerProperties: FC<React.PropsWithChildren<FormTriggerPropertiesProps>> = ({
  onCloseClicked,
  onSelectedInputParameterChanged,
}) => {
  const { mutate: saveMetadata } = useUpdateWorkflowMetadata();
  const { userAppId, workflowId } = useParams();
  const { workflow, savedWorkflow } = useGetWorkflow({ appId: userAppId, workflowId });
  const [buttonLabel, setButtonLabel] = useState<string | undefined>(() => {
    return workflow?.trigger?.type === 'form' ? workflow?.trigger?.runButtonLabel : '';
  });
  const { mutate: saveToDraftWorkflow, isLoading: isDraftSaving } = useSaveToDraftWorkflow();
  const { selectedNode } = usePropertyPanelContext();
  const { setInputParameterIdsShowingOptions } = useUserAppOptionsContext();
  const theme = useTheme();

  const { hasEditPermission } = useWorkflowPermission();

  const initialValues = useMemo(
    () => ({ trigger: workflow?.trigger?.type === 'form' ? workflow.trigger : undefined }),
    [workflow?.trigger]
  );
  const handleOnChanges = ({ values }: { values: FormTriggerPropertyPanelFormProps }) => {
    if (workflow && values.trigger) {
      saveToDraftWorkflow({ ...workflow, trigger: values.trigger });
    }
  };

  const handleDragEndFunction = useCallback(
    (result: DropResult, fields: FieldArrayRenderProps<InputParameter, HTMLElement>['fields']) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }
      const trigger = initialValues.trigger;
      const inputParameters = trigger?.inputParameters;

      assert(
        !!(trigger && inputParameters),
        new Error('[FormTriggerProperties/handleDragEndFunction] trigger and inputParameters should be defined.'),
        'ERROR'
      );

      let source = inputParameters[result.source.index];
      const destination = inputParameters[result.destination.index];
      const nextDestination = inputParameters[result.destination.index + 1];
      const prevDestination = inputParameters[result.destination.index - 1];

      assert(
        !isUndefined(destination.order),
        new Error('[FormTriggerProperties/handleDragEndFunction] destination.order should be defined.'),
        'ERROR'
      );
      if (result.destination.index === 0) {
        //drop to start
        source = { ...source, order: destination.order - 1 };
      } else if (result.destination.index === inputParameters.length - 1) {
        //drop to end
        source = { ...source, order: destination.order + 1 };
      } else {
        //drop to middle
        if (result.source.index > result.destination.index) {
          // direction up
          assert(
            !isUndefined(prevDestination.order),
            new Error('[FormTriggerProperties/handleDragEndFunction] prevDestination.order should be defined.'),
            'ERROR'
          );

          source = { ...source, order: (destination.order + prevDestination.order) / 2 };
        } else {
          // direction down
          assert(
            !isUndefined(nextDestination.order),
            new Error('[FormTriggerProperties/handleDragEndFunction] nextDestination.order should be defined.'),
            'ERROR'
          );

          source = { ...source, order: (destination.order + nextDestination.order) / 2 };
        }
      }

      fields.update(result.source.index, source);
      fields.move(result.source.index, result.destination.index);
    },
    [initialValues.trigger]
  );

  const { updateNode } = useNovaeraFlow(userAppGraph);

  const handleLabelChange = useCallback(
    (labelValue: string) => {
      if (!hasEditPermission) {
        return;
      }

      assert(
        workflow?.trigger?.type === 'form',
        new Error('[FormTriggerProperties] - Workflow trigger type should be form.'),
        'ERROR'
      );

      const trigger: FormTrigger = {
        ...workflow.trigger,
        type: 'form',
        runButtonLabel: labelValue,
      };

      saveToDraftWorkflow({ ...workflow, trigger });
    },
    [hasEditPermission, saveToDraftWorkflow, workflow]
  );

  const debouncedHandleLabelChanged = useDebouncedCallback(handleLabelChange, 500);

  return (
    <NvFlex width="100%">
      <NvForm<FormTriggerPropertyPanelFormProps>
        onSubmit={noop}
        initialValues={initialValues}
        mutators={{ ...arrayMutators }}
        onChange={handleOnChanges}
        autoSaveProps={{ autoSaveType: 'debounce', debounceDelay: 500 }}
      >
        {workflow && workflow.trigger ? (
          <>
            <NvConditionalRender when={hasEditPermission}>
              <PropertyPanelHeader
                title={workflow.trigger.name}
                type={NodeType.FORM_TRIGGER}
                onTitleChange={async (value) => {
                  if (value && selectedNode) {
                    return new Promise<void>((resolve) => {
                      assert(
                        workflow.trigger?.type === 'form',
                        new Error('[FormTriggerProperties] - Workflow trigger type should be form.'),
                        'ERROR'
                      );

                      const trigger: FormTrigger = {
                        ...workflow.trigger,
                        type: 'form',
                        name: value,
                      };

                      const graphNode = userAppGraph.node(selectedNode.alias);
                      const newGraphNode = cloneDeep(graphNode);
                      newGraphNode.name = value;

                      saveToDraftWorkflow(
                        { ...workflow, trigger },
                        {
                          onSuccess: () => {
                            updateNode({ newNode: newGraphNode });
                          },
                          onSettled: () => {
                            resolve();
                          },
                        }
                      );
                    });
                  }
                }}
                validateTitle={(title) => (title && title.length > 0 ? undefined : 'This field is required')}
                actions={
                  <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
                    <NvCloseIcon />
                  </NvButton>
                }
              />
            </NvConditionalRender>
            <NvConditionalRender when={!hasEditPermission}>
              <PropertyPanelHeader
                title={workflow.trigger.name}
                type={NodeType.FORM_TRIGGER}
                validateTitle={(title) => (title && title.length > 0 ? undefined : 'This field is required')}
                actions={
                  <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
                    <NvCloseIcon />
                  </NvButton>
                }
              />
            </NvConditionalRender>

            <FieldArray<InputParameter> name={`trigger.inputParameters`} defaultValue={[]}>
              {({ fields }) => {
                const inputParameters = fields.value;

                return (
                  <>
                    <NvConditionalRender when={hasEditPermission}>
                      <PropertyPanelListHeader
                        title="inputs"
                        tooltip="Add input fields to retrieve data from users when they attempt to run the workflow."
                        actions={
                          <NvNestedDropdown<InputMenuItemValue>
                            menuItems={ADD_NEW_INPUT_MENU_ITEMS}
                            onClickItem={(props) => {
                              if (props.value) {
                                const newInputParameter = generateNewInputParameter(props.value, inputParameters);
                                if (newInputParameter.uiComponent.type === UIComponentType.DYNAMIC_INPUT) {
                                  const { uiComponent } = newInputParameter;
                                  fields.push({
                                    ...newInputParameter,
                                    uiComponent: { ...uiComponent, dataSource: { type: 'workflow' } },
                                  });
                                } else {
                                  fields.push(newInputParameter);
                                }

                                onSelectedInputParameterChanged({ selectedInputParameterId: newInputParameter.id });
                                if (
                                  newInputParameter.uiComponent.type === UIComponentType.CHECK_BOX_GROUP ||
                                  newInputParameter.uiComponent.type === UIComponentType.RADIO_BUTTON_GROUP
                                ) {
                                  setInputParameterIdsShowingOptions((prevValue) => {
                                    return [...prevValue, newInputParameter.id];
                                  });
                                }
                              }
                              assert(!!props.value, new Error('The menu item is expected to have value'));
                            }}
                            trigger={
                              <NvButton size="small" color="ghost" onlyIcon>
                                <NvAddBoxIcon />
                              </NvButton>
                            }
                          />
                        }
                      />
                    </NvConditionalRender>
                    <NvConditionalRender when={!hasEditPermission}>
                      <PropertyPanelListHeader
                        title="inputs"
                        tooltip="Add input fields to retrieve data from users when they attempt to run the workflow."
                      />
                    </NvConditionalRender>
                    {selectedNode?.type === 'form' && (
                      <PropertyPanelSimpleSection>
                        {inputParameters.length === 0 ? (
                          <NvFlex direction="row" alignItems="center" gap="8px" height="32px">
                            <NvCustomEmptyIcon
                              sx={{ width: '16px', height: '16px' }}
                              htmlColor={theme.palette.nv_neutral[60]}
                            />
                            <NvTypography variant="body2" textColor="ghost">
                              This form doesn't have any inputs yet.
                            </NvTypography>
                          </NvFlex>
                        ) : (
                          <DragDropContext onDragEnd={(result) => handleDragEndFunction(result, fields)}>
                            <Droppable droppableId={'form-trigger-input-parameters'}>
                              {(provided) => (
                                <NvFlex gap="12px" paddingBottom={'8px'} ref={provided.innerRef}>
                                  {inputParameters.map((inputParameter, index) => (
                                    <Draggable
                                      key={`draggable_${index}`}
                                      draggableId={`${index}`}
                                      index={index}
                                      isDragDisabled={isDraftSaving}
                                    >
                                      {(provided, snapshot) => (
                                        <NvConditionalWrap
                                          condition={snapshot.isDragging}
                                          wrap={(children) => <Portal>{children}</Portal>}
                                        >
                                          <NvBox
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            position="relative"
                                          >
                                            <FormTriggerInputParameterItem
                                              key={inputParameter.id}
                                              inputParameter={inputParameter}
                                              handleDelete={() => {
                                                fields.remove(index);
                                              }}
                                              onSelectedInputParameterChanged={onSelectedInputParameterChanged}
                                              dragHandleProps={provided.dragHandleProps}
                                            />
                                          </NvBox>
                                        </NvConditionalWrap>
                                      )}
                                    </Draggable>
                                  ))}

                                  {provided.placeholder}
                                </NvFlex>
                              )}
                            </Droppable>
                          </DragDropContext>
                        )}
                      </PropertyPanelSimpleSection>
                    )}
                  </>
                );
              }}
            </FieldArray>

            <PropertyPanelSection
              title="Button label"
              icon={<NvCustomExecuteButtonIcon />}
              tooltip="Enter a label that will be shown on the form view, allowing users to initiate the run process."
            >
              <NvFlex>
                <NvTextField
                  placeholder="Label"
                  fullWidth
                  onChange={(event) => {
                    setButtonLabel(event.target.value);
                    debouncedHandleLabelChanged(event.target.value);
                  }}
                  value={buttonLabel}
                />
              </NvFlex>
            </PropertyPanelSection>
            <PropertyPanelSection
              title="Searchable"
              tooltip="Use the searchable flag if you want to hide this workflow from the Slack."
              icon={<NvSearchIcon />}
              actions={
                <NvSwitch
                  checked={!savedWorkflow?.searchInvisibleByDefault}
                  onChange={(...args) => {
                    assert(!!savedWorkflow?.id, new Error('Workflow id is not defined'));
                    assert(!!savedWorkflow?.name, new Error('Workflow name can not be empty'));
                    const [, checked] = args;

                    saveMetadata({
                      appId: userAppId,
                      workflowId: workflow.id,
                      payload: {
                        name: savedWorkflow.name,
                        description: savedWorkflow.description,
                        tags: savedWorkflow.tags,
                        searchInvisibleByDefault: !checked,
                      },
                    });
                  }}
                  variant="compact"
                />
              }
            />
            {workflow.trigger.type === 'form' && (
              <FormTriggerDefaultUserSection
                defaultStarterUserId={workflow.trigger.defaultStarterUserId}
                useDefaultUserAsPrimeUser={workflow.trigger.useDefaultUserAsPrimeUser}
              />
            )}
          </>
        ) : (
          <PropertiesLoading />
        )}
      </NvForm>
    </NvFlex>
  );
};
