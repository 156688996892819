import { useGetWorkflowExecutionOutputResult } from '@novaera/actioner-service';
import { NvSkeleton, SectionMessage } from '@novaera/core';
import { FC, PropsWithChildren } from 'react';
import { BlocksViewer } from './blocks-viewer';
import { MarkdownOutputProps } from './types';

export const MarkdownOutput: FC<PropsWithChildren<MarkdownOutputProps>> = ({
  alias,
  executionIdentifier,
  userAppId,
  workflowId,
}) => {
  const { data, isLoading } = useGetWorkflowExecutionOutputResult({
    userAppId,
    workflowId,
    executionIdentifier,
    outputAlias: alias,
  });

  return isLoading ? (
    <NvSkeleton variant="rectangular" width="100%" height="305px" />
  ) : data?.type === 'failed-output' ? (
    <SectionMessage variant="error" message={data.error} />
  ) : data?.type === 'markdown' && data.blocks ? (
    <BlocksViewer blocks={data.blocks} />
  ) : (
    <SectionMessage variant="error" message={'Cannot draw markdown output'} />
  );
};
