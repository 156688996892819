import {
  KeyValueTableOutputComponent,
  KeyValueTableRow,
  OutputNode,
  useUpdateNode,
  WorkflowState,
} from '@novaera/actioner-service';
import { CodeInput, NvField, NvFlex, NvForm } from '@novaera/core';
import arrayMutators from 'final-form-arrays';
import { noop } from 'lodash';
import { FC, useCallback } from 'react';
import { PropertyPanelSection } from '../../../../../../../../../components';
import { useGetWorkflowContexts } from '../../../../../controllers/use-get-workflow-contexts';
import { KeyValueTableRowsAdapter } from './key-value-table-rows-adapter';
import { KeyValueTablePanelSectionProps } from './types';

export const KeyValueTablePanelSection: FC<React.PropsWithChildren<KeyValueTablePanelSectionProps>> = ({
  keyValueTableOutputComponent,
  alias,
  name,
  userAppId,
  workflowId,
}) => {
  const { mutate: updateNodeDetail } = useUpdateNode();
  const { workflowCodeInputContext } = useGetWorkflowContexts();

  const handleSave = useCallback(
    (newKeyValueTableOutputComponent: KeyValueTableOutputComponent) => {
      const constructedKeyValueTableOutputComponent: KeyValueTableOutputComponent = {
        type: 'key-value-table',
        rows: newKeyValueTableOutputComponent.rows?.map((row) => {
          const dataColumn: KeyValueTableRow = {
            dataSource: row?.dataSource,
            name: row?.name,
          };
          return dataColumn;
        }),
        dataSource: { type: 'javascript', value: newKeyValueTableOutputComponent?.dataSource?.value },
      };
      const newNode: OutputNode = {
        alias,
        name,
        outputComponent: constructedKeyValueTableOutputComponent,
        type: 'output',
        state: WorkflowState.DRAFT,
      };

      updateNodeDetail({
        appId: userAppId,
        workflowId,
        nodeAlias: alias,
        payload: newNode,
      });
    },
    [alias, name, updateNodeDetail, userAppId, workflowId]
  );

  return (
    <NvForm<KeyValueTableOutputComponent>
      onSubmit={noop}
      onChange={({ values }) => {
        handleSave(values);
      }}
      initialValues={keyValueTableOutputComponent}
      mutators={{ ...arrayMutators }}
      keepDirtyOnReinitialize
      autoSaveProps={{ autoSaveType: 'debounce', debounceDelay: 500 }}
    >
      <PropertyPanelSection title="Key value table source">
        <NvFlex gap="8px" direction="column">
          <NvField
            name="dataSource.value"
            component={<CodeInput context={workflowCodeInputContext} />}
            defaultValue="{{ }}"
          />
        </NvFlex>
      </PropertyPanelSection>
      <NvFlex>
        <NvField
          name="rows"
          component={<KeyValueTableRowsAdapter />}
          allowNull
          format={(value) => value}
          parse={(value) => value}
        />
      </NvFlex>
    </NvForm>
  );
};
