import { NvTypography } from '@novaera/core';
import { UpdateLogoInfoProps } from './types';

export const UpdateLogoInfo: React.FC<React.PropsWithChildren<UpdateLogoInfoProps>> = ({ slackAppId }) => (
  <NvTypography variant="body2" textColor="secondary">
    If you want to update your Slack app's logo, go to{' '}
    <a href={`https://api.slack.com/apps/${slackAppId}`} target="_blank" rel="noreferrer">
      App configuration page
    </a>{' '}
    in Slack.Other changes related to your app can be done in <strong>Slack app</strong> tab.
  </NvTypography>
);
