import {
  getUserApp,
  GetUserAppResponse,
  useGetAppSchemaDetail,
  useGetUserApp,
  useUpdateManagedApp,
  useUserAppServiceUtil,
} from '@novaera/actioner-service';
import { useToast } from '@novaera/core';
import { useMemo } from 'react';
import semver from 'semver';
import { RealTimeTaskEngine } from '../../../../../../real-time/real-time-task-engine';
import { useGetWorkflowQueryParams } from '../../../../workflow-designer/user-app-workflow-canvas/controllers/use-get-workflow-query-params';

export const useUpdateAppButtonController = () => {
  const { userAppId } = useGetWorkflowQueryParams();
  const { data: userApp, isLoading: isUserAppLoading } = useGetUserApp(userAppId);
  const { data: appSchemaDetail, isLoading: isAppSchemaDetailLoading } = useGetAppSchemaDetail({
    schemaId: userApp?.metadata.schemaId,
    isAnonymous: false,
  });
  const { updateCache } = useUserAppServiceUtil();
  const { mutate: updateManagedApp, isLoading: isUpdateStarted } = useUpdateManagedApp();

  const hasNewVersion = useMemo(() => {
    return (
      userApp?.managed &&
      userApp &&
      appSchemaDetail &&
      semver.compare(userApp?.metadata?.schemaVersion, appSchemaDetail?.version) === -1
    );
  }, [appSchemaDetail, userApp]);

  const { addToast } = useToast();

  const handleUpdateClick = (callback: () => void) => {
    updateManagedApp(
      {
        appId: userAppId,
      },
      {
        onSuccess: () => {
          const newTask = new RealTimeTaskEngine<
            GetUserAppResponse,
            {
              id: string;
              properties: {
                status: 'completed';
                appId: string;
              };
              type: 'managed-app-update';
              userId: string;
              workflowId: string;
            }
          >({
            id: userAppId,
            pollingCallback: async () => {
              const result = await getUserApp(userAppId);

              if (result.updateInProgress) {
                throw new Error('in_progress');
              }

              return result;
            },
            resultAction: ({ resultContext, source }) => {
              const message =
                source === 'socket'
                  ? `${userApp?.name} successfully updated to the latest version.`
                  : `${resultContext.name} successfully updated to the latest version.`;

              if (
                (source === 'socket' && resultContext.properties.status === 'completed') ||
                (source === 'polling' && !resultContext.updateInProgress)
              ) {
                addToast(message, { variant: 'success' });
              }

              updateCache({
                appId: userAppId,
                userApp: {
                  updateInProgress: false,
                  ...(userApp?.metadata &&
                    appSchemaDetail?.version && {
                      metadata: {
                        ...userApp.metadata,
                        schemaVersion: appSchemaDetail?.version,
                      },
                    }),
                },
              });
            },
          });
          newTask.executePolling();

          callback();
        },
      }
    );
  };

  return {
    hasNewVersion,
    onUpdateClick: handleUpdateClick,
    isUpdateInProgress: userApp?.updateInProgress,
    appSchemaDetail,
    isUpdateStarted,
    isCheckAvailableUpdateLoading: isUserAppLoading || isAppSchemaDetailLoading,
  };
};
