import { SearchConnection } from '@novaera/actioner-service';
import {
  NvButton,
  NvConditionalRender,
  NvConditionalWrap,
  NvField,
  NvFlex,
  NvSelect,
  NvSkeleton,
  NvTypography,
} from '@novaera/core';
import classNames from 'classnames';
import { ConnectionSchemaLogo } from '../../../../../components/connection-schema-logo';
import { ConnectForm } from '../../connect-form';
import { useMissingConnectionUpdatesController } from '../controllers/use-missing-connection-updates';
import { ConnectSlackButton } from './connect-slack-button';
import { useMissingConnectionItemController } from './controllers/use-missing-connection-item';
import { MissingConnectionConnectBody, StyledMissingConnectionItem, StyledMissingConnectionItemHeader } from './styled';
import { MissingConnectionItemProps } from './types';

export const MissingConnectionItem = ({ schema, mode, onFormSubmitCompleted }: MissingConnectionItemProps) => {
  const {
    connectionSchema,
    isLoading,
    connectionOptions,
    onConnectButtonClicked,
    isConnectPanelOpened,
    onConnectCancelClicked,
  } = useMissingConnectionItemController({ schema });

  const { addConnectionMapping } = useMissingConnectionUpdatesController();

  return (
    <StyledMissingConnectionItem>
      <StyledMissingConnectionItemHeader>
        <NvFlex flex={'1 1 0'} minWidth={'0'} flexDirection={'row'} alignItems={'center'} gap={'10px'}>
          <ConnectionSchemaLogo connectionSchema={connectionSchema} size="medium" />
          <NvTypography variant="h4">{connectionSchema?.name}</NvTypography>
        </NvFlex>
        <NvFlex flex={'0 0 auto'}>
          <NvConditionalRender when={isLoading}>
            <NvSkeleton variant="rectangular" width={'100px'} height={'24px'} />
          </NvConditionalRender>
          <NvConditionalRender when={!isLoading && schema.status === 'multiple'}>
            <NvConditionalWrap
              condition={mode === 'form-save'}
              wrap={(children) => <NvField name="connection" component={children} />}
            >
              <NvSelect
                {...(mode === 'in-save' && {
                  onChange: (event) => {
                    const value = event.target.value as SearchConnection;
                    addConnectionMapping({
                      connectionId: value.id,
                      schemaId: value.schemaId,
                      connectionName: value.name,
                    });
                  },
                })}
                size="small"
                placeholder="Choose connection"
                options={connectionOptions}
              />
            </NvConditionalWrap>
          </NvConditionalRender>
          <NvConditionalRender when={!isLoading && schema.status === 'missing' && !isConnectPanelOpened}>
            <NvButton size="small" onClick={onConnectButtonClicked}>
              Connect
            </NvButton>
          </NvConditionalRender>
          <NvConditionalRender when={!isLoading && schema.status === 'missing-slack' && !isConnectPanelOpened}>
            <ConnectSlackButton
              ButtonBase={({ onClick }) => (
                <NvButton size="small" onClick={onClick}>
                  Connect
                </NvButton>
              )}
            />
          </NvConditionalRender>
        </NvFlex>
      </StyledMissingConnectionItemHeader>
      <MissingConnectionConnectBody
        className={classNames({
          opened: isConnectPanelOpened,
          closed: !isConnectPanelOpened,
        })}
      >
        <ConnectForm
          onFormSubmitCompleted={onFormSubmitCompleted}
          onConnectCancelClicked={onConnectCancelClicked}
          schema={schema}
        />
      </MissingConnectionConnectBody>
    </StyledMissingConnectionItem>
  );
};
