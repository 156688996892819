import React, { useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import { useAnalytics } from '..';
import { PageViewTrackerProps } from './types';

export const PageViewTracker: React.FC<React.PropsWithChildren<PageViewTrackerProps>> = ({
  eventName,
  pageDetails,
}) => {
  const location = useLocation();
  const analytics = useAnalytics();

  useEffect(() => {
    analytics.page(eventName, { ...(pageDetails ?? {}) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return null;
};
