import {
  CodeInput,
  NvAddButtonWithLabel,
  NvButton,
  NvDivider,
  NvFlex,
  NvRefreshIcon,
  NvSearchIcon,
} from '@novaera/core';
import { useParams } from '@novaera/route';
import { FreeAppPermissionBoundary } from '../../user-app/user-app-permission-boundary/free-app-permission-boundary';
import { QuerySearchHelpTooltip } from '../query-search-help-tooltip';
import { ActionBox, RecordsSearchContainer } from './styled';
import { RecordsSearchProps } from './types';

export const RecordsSearch = ({
  search,
  onChange,
  onEnter,
  handleRefresh,
  onClickCreateButton,
  minHeight = '56px',
}: RecordsSearchProps) => {
  const { userAppId } = useParams();

  return (
    <RecordsSearchContainer>
      <CodeInput
        mode="sql"
        context={{}}
        value={search}
        startAdornment={<NvSearchIcon sx={{ width: '32px', height: '32px' }} />}
        startAdornmentOffset={32}
        placeholder="Search records..."
        onChange={onChange}
        onEnter={onEnter}
      />
      <NvFlex direction="row" alignItems="center" gap="8px" flex="0 0 auto" minHeight={minHeight}>
        <QuerySearchHelpTooltip />

        <ActionBox>
          {onClickCreateButton && (
            <>
              <FreeAppPermissionBoundary appId={userAppId}>
                <NvAddButtonWithLabel label="Add new record" onClick={onClickCreateButton} variant="small" />
              </FreeAppPermissionBoundary>
              <NvDivider orientation="vertical" sx={{ height: '12px' }} />
            </>
          )}
          <NvButton
            size="small"
            color="secondary"
            startIcon={<NvRefreshIcon sx={{ width: '16px', height: '16px' }} />}
            onClick={handleRefresh}
          >
            Refresh
          </NvButton>
        </ActionBox>
      </NvFlex>
    </RecordsSearchContainer>
  );
};
