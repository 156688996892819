import _ from 'lodash';
import { SearchAsYouTypeValuesProvider } from '../../../../../../../action-designer/providers/search-as-you-type-values';
import { FormParameterProvider } from '../../../providers/form-parameter-provider';
import { EventTriggers } from './event-triggers';
import { RunWorkflowFormTriggerBody } from './form-trigger';
import { WorkflowRunPanelResult } from './result';
import { WorkflowRunModalBodyProps } from './types';

export const WorkflowRunModalBody: React.FC<React.PropsWithChildren<WorkflowRunModalBodyProps>> = ({
  isLoading,
  result,
  executionIdentifier,
  workflow,
}) => {
  return isLoading || result ? (
    <WorkflowRunPanelResult
      workflow={workflow}
      isLoading={isLoading}
      result={!_.isError(result) && !isLoading ? result : { status: 'failed' }}
      executionIdentifier={executionIdentifier}
    />
  ) : workflow?.trigger?.type === 'form' ? (
    <SearchAsYouTypeValuesProvider>
      <FormParameterProvider workflow={workflow}>
        {({ inputParameters }) => (
          <RunWorkflowFormTriggerBody
            workflowId={workflow.id}
            workflowInputParameters={inputParameters ?? []}
            showHintText={true}
          />
        )}
      </FormParameterProvider>
    </SearchAsYouTypeValuesProvider>
  ) : (
    <EventTriggers />
  );
};
