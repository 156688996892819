import { isRequired, NvBox, NvField, NvFlex, NvTextField, NvTypography, StepField } from '@novaera/core';

export const SlackAccessToken: React.FC<React.PropsWithChildren<unknown>> = () => (
  <StepField
    help={{
      title: 'Why do you need to generate app configuration tokens?',
      description:
        'App configuration tokens are a special type of access tokens of Slack. We will use them to create your Slack app on your selected workspace.',
    }}
  >
    <NvFlex gap="24px">
      <NvFlex gap="8px">
        <NvTypography variant="h2">How to generate an app configuration token? </NvTypography>
        <NvFlex direction="row" gap="8px" alignItems="flex-start">
          <NvTypography variant="h7" textColor="subtle" padding="4px 0" flex="0 0 auto">
            Step 1
          </NvTypography>

          <NvTypography variant="body1" flex="1 1 auto" minWidth={0}>
            Go to{' '}
            <a href="https://api.slack.com/authentication/config-tokens" target="_blank" rel="noreferrer">
              App configuration tokens
            </a>{' '}
            page.
          </NvTypography>
        </NvFlex>
        <NvFlex direction="row" gap="8px" alignItems="flex-start">
          <NvTypography variant="h7" textColor="subtle" padding="4px 0" flex="0 0 auto">
            Step 2
          </NvTypography>

          <NvTypography variant="body1" flex="1 1 auto" minWidth={0}>
            Click <strong>Generate token</strong> and select your workspace where you want to install your Slack app.
            Then, click <strong>Generate</strong> button.
          </NvTypography>
        </NvFlex>
        <NvFlex direction="row" gap="8px" alignItems="flex-start">
          <NvTypography variant="h7" textColor="subtle" padding="4px 0" flex="0 0 auto">
            Step 3
          </NvTypography>

          <NvTypography variant="body1" flex="1 1 auto" minWidth={0}>
            Click <strong>Copy</strong> button under the <strong>Access Token</strong> column for your workspace. Then,
            paste it to the section below and click <strong>Next</strong>.
          </NvTypography>
        </NvFlex>
      </NvFlex>
      <NvBox maxWidth="350px">
        <NvField
          name="slackConfigurationToken"
          labelText="Your app configuration token"
          direction="label-on-top"
          hasRequiredIndicator
          validators={[isRequired()]}
          showErrorMessageOnlyWhenBlur
          component={<NvTextField placeholder="Paste the access token" type="password" />}
        />
      </NvBox>
    </NvFlex>
  </StepField>
);
