import { GridColDef, GridRowModel } from '@mui/x-data-grid';
import { ColumnResult } from '@novaera/actioner-service';
import { CellTypography, CellWrapper, Markdown, MemoNvDataGrid, NvFlex, RenderCellParams } from '@novaera/core';
import { isUndefined } from 'lodash';
import { isValidElement } from 'react';
import { ErrorBoundary } from '../../../../../../../../../../../error-boundary';
import { WorkflowReferenceButton } from '../../workflow-reference-button';

const tableViewRenderCell = ({ formattedValue }: RenderCellParams) => (
  <CellWrapper>
    {isValidElement(formattedValue) ? (
      formattedValue
    ) : (
      <CellTypography>
        {!isUndefined(formattedValue) && (
          <Markdown>{typeof formattedValue !== 'string' ? JSON.stringify(formattedValue) : formattedValue}</Markdown>
        )}
      </CellTypography>
    )}
  </CellWrapper>
);

export const TableView: React.FC<React.PropsWithChildren<{ columnResults: ColumnResult[] }>> = (props) => {
  const columns = props.columnResults.map((column) => {
    if (column.type === 'data') {
      const newColumn: GridColDef = {
        field: column.name,
        headerName: column.name,
        flex: 1,
      };
      return newColumn;
    } else {
      const referenceColumn: GridColDef = {
        field: 'actions',
        headerName: 'Actions',
        sortable: false,
        width: 128,
      };
      return referenceColumn;
    }
  });

  const rowCount = props.columnResults.length > 0 ? props.columnResults[0].numberOfRows : 0;

  const rows = Array.from({ length: rowCount }, (_, i) => {
    const row: GridRowModel = { muiDataGridRowId: i };
    props.columnResults.forEach((column) => {
      if (column.type === 'data') {
        row[column.name] = column.cells[i];
      } else {
        row.actions = (
          <NvFlex direction="column" gap="8px" padding="8px" alignItems={'center'}>
            {column.workflowReferences?.[i]?.map((workflowReference) => (
              <WorkflowReferenceButton
                key={`block_key_${workflowReference.id}`}
                workflowReference={workflowReference}
              />
            ))}
          </NvFlex>
        );
      }
    });

    return row;
  });

  return (
    <ErrorBoundary>
      <MemoNvDataGrid columns={columns} rows={rows} customRenderCell={tableViewRenderCell} />
    </ErrorBoundary>
  );
};
