import { ConnectionMapping, SetupWorkflow } from '@novaera/actioner-service';
import { Step } from '@novaera/core';
import { CONNECTION_STEP_LABEL, SETUP_WORKFLOW_STEP_LABEL } from './constants';

export const filterInstallSteps = ({
  steps,
  connectionMappings,
  setupWorkflows,
}: {
  steps: Step[];
  connectionMappings: ConnectionMapping[];
  setupWorkflows: SetupWorkflow[];
}) => {
  if (connectionMappings?.length === 0 && setupWorkflows?.length === 0) {
    return [steps[2]];
  } else {
    if (connectionMappings?.length === 0) {
      return steps.filter((s) => s.label !== CONNECTION_STEP_LABEL);
    } else if (setupWorkflows?.length === 0) {
      return steps.filter((s) => s.label !== SETUP_WORKFLOW_STEP_LABEL);
    } else {
      return steps;
    }
  }
};
