import { useQueries, UseQueryResult } from '@tanstack/react-query';
import { useMemo } from 'react';
import { QUERY_KEYS_WORKFLOWS } from '../keys';
import { getWorkflow } from '../use-get-workflow';
import { GetWorkflowResponse } from '../use-get-workflow/types';

export const useGetWorkflowsDetail = ({ appId, workflowIds }: { appId: string; workflowIds: string[] }) => {
  const results = useQueries({
    queries:
      workflowIds?.map((workflowId) => {
        return {
          queryFn: () => getWorkflow({ appId, workflowId }),
          queryKey: QUERY_KEYS_WORKFLOWS.detail({ appId, workflowId }),
          enabled: !!appId && !!workflowId,
          meta: { workflowId },
        };
      }) ?? [],
  });

  const idRequestPairs: Record<string, UseQueryResult<GetWorkflowResponse>> = useMemo(() => {
    return results?.reduce((acc, curr, currIndex) => {
      return { ...acc, [workflowIds[currIndex]]: curr };
    }, {});
  }, [results]);

  return idRequestPairs;
};
