import {
  Integration,
  useGetConnectionSchemaService,
  useSearchConnectionsService,
  WorkflowWithState,
} from '@novaera/actioner-service';
import { useMemo } from 'react';
import { useGetWorkflowQueryParams } from '../../../../../controllers/use-get-workflow-query-params';
import { ConnectionOption } from '../../types';

export const useWorkflowConnectionSectionController = ({
  integration,
  workflow,
}: {
  integration?: Integration;
  workflow: WorkflowWithState;
}) => {
  const { userAppId } = useGetWorkflowQueryParams();

  const {
    data: connectionsResult,
    isLoading: isConnectionsLoading,
    isFetching: isConnectionsFetching,
  } = useSearchConnectionsService({
    payload: {
      appId: userAppId,
      schemaId: integration?.connectionSchemaId,
    },
  });

  const { data: connectionSchemaData } = useGetConnectionSchemaService({
    integrationId: integration?.id,
    schemaId: integration?.connectionSchemaId,
  });

  const connectionOptions = useMemo(() => {
    const result: ConnectionOption[] = [];
    if (!connectionSchemaData?.slack && connectionSchemaData) {
      result.push({
        label:
          workflow.trigger?.type === 'form'
            ? workflow.trigger.defaultStarterUserId
              ? 'Workflow default user'
              : 'User-based connection'
            : 'Workflow default user',
        type: 'triggered-user-connection',
        value: {
          connectionId: undefined,
        },
      });
    }

    connectionsResult?.connections.forEach((connection) => {
      result.push({
        label: connection.name,
        type: 'connection',
        value: {
          connectionId: connection.id,
          user: connection.user,
        },
      });
    });

    return result.sort((a, b) => {
      if (a.type === 'triggered-user-connection') {
        return -1;
      } else if (b.type === 'triggered-user-connection') {
        return 1;
      } else {
        return 0;
      }
    });
  }, [
    connectionSchemaData,
    connectionsResult?.connections,
    workflow.trigger?.defaultStarterUserId,
    workflow.trigger?.type,
  ]);

  return {
    connectionOptions,
    isConnectionsLoading: isConnectionsLoading || isConnectionsFetching,
    isSlackConnection: connectionSchemaData?.slack,
  };
};
