import { NvFocusState, NvSlackIcon, StepTracker } from '@novaera/core';
import React from 'react';
import { ExportSlackAppFocusStateOption } from '../types';
import { ExportSlackAppFormBodyProps } from './types';
import { useExportSlackAppBody } from './use-export-slack-app-body';

export const ExportSlackAppFormBody: React.FC<React.PropsWithChildren<ExportSlackAppFormBodyProps>> = ({
  isOpen,
  onClose,
  type,
}) => {
  const { exportSlackAppSteps } = useExportSlackAppBody({ type });
  return (
    <NvFocusState
      open={isOpen}
      onClose={onClose}
      headerLogo={<NvSlackIcon fontSize="large" />}
      title={
        type === ExportSlackAppFocusStateOption.CREATE
          ? 'Create a standalone Slack app'
          : 'Update your standalone Slack app'
      }
    >
      <StepTracker steps={exportSlackAppSteps} onCancelClick={onClose} />
    </NvFocusState>
  );
};
