import {
  GetOptionsParamsForMultipleInputParametersIntegrationAction,
  GetOptionsParamsForMultipleInputParametersIntegrationActionInIntegration,
  GetOptionsParamsForMultipleInputParametersIntegrationActionInWorkflow,
  useGetOptions,
  useResetOptions,
} from '@novaera/actioner-service';
import { assert } from '@novaera/utils';
import { FC, createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useFormIdentifierContext } from '../../../providers/form-identifier-provider';
import { useDynamicInputContext } from '../../dynamic-input';
import { useCheckActionDynamicInputDependencyValue } from '../../dynamic-input/providers/controller/use-check-action-dynamic-input-dependency-value';
import { DependencyInputComponentStateFN } from '../../dynamic-input/providers/controller/use-check-dependency-value/types';
import {
  GetOptionsResponseFunction,
  IntegrationActionOptionsContextType,
  IntegrationActionOptionsProviderProps,
} from './types';

const IntegrationActionOptionsContext = createContext<IntegrationActionOptionsContextType | undefined>(undefined);

export const IntegrationActionOptionsProvider: FC<React.PropsWithChildren<IntegrationActionOptionsProviderProps>> = ({
  children,
  actionId,
  integrationId,
  draft,
  versionNumber,
  inputParameterIds,
  initialInputParameterIdsShowingOptions,
  inputParameterValues,
  searchAsYouTypeValues,
  allInputParametersWithOrder,
  ...rest
}) => {
  const { removeCacheForInputParameter } = useResetOptions();
  const { formId } = useFormIdentifierContext();
  const { getDynamicInputParameterParent } = useDynamicInputContext();
  const { getDependencyInputComponentState, filterSuccessDynamicInputs, dependenciesOfParameters } =
    useCheckActionDynamicInputDependencyValue({
      actionId,
      draft: Boolean(draft),
      integrationId,
      version: versionNumber,
    });

  const [inputParameterIdsShowingOptions, setInputParameterIdsShowingOptions] = useState<string[]>(
    initialInputParameterIdsShowingOptions
  );
  const inputOptionsContext: GetOptionsParamsForMultipleInputParametersIntegrationAction = useMemo(() => {
    if (rest.type === 'in-workflow') {
      const retVal: GetOptionsParamsForMultipleInputParametersIntegrationActionInWorkflow = {
        type: rest.type,
        appId: rest.appId,
        workflowId: rest.workflowId,
        requestParams: {
          actionId,
          integrationId,
          payload: {
            inputParameterIds: inputParameterIds,
            context: {},
            draft,
            versionNumber: versionNumber,
            inputParameters: inputParameterValues,
            searchAsYouTypeValues,
            formId,
          },
        },
        options: { keepPreviousData: true, staleTime: Infinity },
        enabledInputParameters: inputParameterIdsShowingOptions.filter((inputParameterId) =>
          filterSuccessDynamicInputs(inputParameterId, inputParameterValues)
        ),
        allInputParametersWithOrder,
        dependenciesOfParameters,
        getDynamicInputParameterParent,
      };
      return retVal;
    } else {
      const retVal: GetOptionsParamsForMultipleInputParametersIntegrationActionInIntegration = {
        type: rest.type,
        connectionId: rest.connectionId,
        requestParams: {
          actionId,
          integrationId,
          payload: {
            inputParameterIds: inputParameterIds,
            context: {},
            draft,
            versionNumber: versionNumber,
            inputParameters: inputParameterValues,
            searchAsYouTypeValues,
            formId,
          },
        },
        options: { keepPreviousData: true, staleTime: Infinity },
        enabledInputParameters: inputParameterIdsShowingOptions.filter((inputParameterId) =>
          filterSuccessDynamicInputs(inputParameterId, inputParameterValues)
        ),
        allInputParametersWithOrder,
      };
      return retVal;
    }
  }, [
    rest,
    actionId,
    integrationId,
    inputParameterIds,
    draft,
    versionNumber,
    inputParameterValues,
    searchAsYouTypeValues,
    formId,
    inputParameterIdsShowingOptions,
    allInputParametersWithOrder,
    filterSuccessDynamicInputs,
    dependenciesOfParameters,
    getDynamicInputParameterParent,
  ]);

  const results = useGetOptions(inputOptionsContext);

  const optionsMap = useMemo(() => {
    return inputParameterIds.map((inputParameter, index) => {
      return { inputParameterId: inputParameter, ...results[index] };
    });
  }, [inputParameterIds, results]);

  const invalidate = useCallback(
    (inputParameterId: string) => {
      removeCacheForInputParameter({
        ...rest,
        actionId,
        draft,
        inputParameterId,
        versionNumber,
        integrationId,
      });
    },
    [actionId, draft, integrationId, removeCacheForInputParameter, rest, versionNumber]
  );

  const getOptionsResponse = useCallback<GetOptionsResponseFunction>(
    (inputParameterId) => {
      const result = optionsMap.find((o) => o.inputParameterId === inputParameterId);
      const { dataSource, ...data } = result?.data ?? { options: [] };

      return {
        ...data,
        isLoading: result?.isInitialLoading || result?.isRefetching,
      };
    },
    [optionsMap]
  );

  const handleGetDynamicInputComponentState = useCallback<DependencyInputComponentStateFN>(
    ({ inputParameterId, inputParameterValues }) => {
      return getDependencyInputComponentState({
        inputParameterId,
        inputParameterValues,
      });
    },
    [getDependencyInputComponentState]
  );

  const value: IntegrationActionOptionsContextType = {
    setInputParameterIdsShowingOptions,
    getOptionsResponse,
    invalidate,
    getDynamicInputComponentState: (inputParameterId: string) => {
      return handleGetDynamicInputComponentState({
        inputParameterId,
        inputParameterValues,
      });
    },
  };
  return <IntegrationActionOptionsContext.Provider value={value}>{children}</IntegrationActionOptionsContext.Provider>;
};

export const useIntegrationActionOptionsContext = () => {
  const context = useContext(IntegrationActionOptionsContext);
  assert(
    !!context,
    new Error(`useIntegrationActionOptionsContext should be used within IntegrationActionOptionsProvider`),
    'ERROR'
  );

  return context;
};
