import { NvTypography } from '@novaera/core';
import { FooterWrapper } from './styled';

export const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <FooterWrapper>
      <NvTypography textColor="ghost" variant="h6">
        Copyright © Actioner | {currentYear} Actioner, All rights reserved
      </NvTypography>
    </FooterWrapper>
  );
};
