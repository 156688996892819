import { LocalizationProvider } from '@mui/x-date-pickers';
import {
  ActionerPrivateRoute,
  AddConnection,
  AppConfigFlaggerProvider,
  AppConfigFlaggerSubscriber,
  AppContextProvider,
  AppDirectory,
  AppLayout,
  AppLoading,
  AppPostMessage,
  Catalog,
  ErrorBoundary,
  ErrorPage as ErrorPage404,
  InstallSlackApp,
  IntegrationDetail,
  LayoutTopMenu,
  OAuthCodePage,
  ProfileSettings,
  ROUTES,
  RealTimeSubscription,
  Redirection,
  SlackAccessVerification,
  SlackManualUserMapping,
  StartMarketplaceSlackAppInstallation,
  UserAppDetail,
  UserApps,
  UserPermissionProvider,
  Workspace,
  handleMetadataFailed,
  isAnalyticsEnabled,
  segmentWriteKey,
} from '@novaera/actioner-ui';
import { AdminPanel, Impersonate } from '@novaera/admin-panel';
import { ApplicationConfig } from '@novaera/application-config';
import { LAST_ACTIVE_WORKSPACE, ROUTE_DEFAULTS } from '@novaera/constants';
import {
  AdapterDateFns,
  AnalyticsProvider,
  ConfirmDialogContextProvider,
  NvApolloClientProviderV1,
  NvAxios,
  NvAxiosWithoutInterceptor,
  NvReactQueryDevTools,
  ToastCloseAction,
  ToastProvider,
} from '@novaera/core';
import { ErrorPage, IdentityProvider } from '@novaera/identity-provider';
import { Navigate, PrivateRoute, PrivateRouteChildProps, PublicRoute, Route, Router } from '@novaera/route';
import {
  ServiceConfiguration,
  refreshAccessToken,
  requestFulfilled,
  requestRejected,
  responseFulfilled,
  responseRejected,
} from '@novaera/service';
import { ThemeMode, ThemeProvider, Themes } from '@novaera/theme-provider';
import { getSubdomain } from '@novaera/utils';
import { withProfiler } from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import { HelmetProvider } from 'react-helmet-async';
import { SEGMENT_CDN_URL } from './constants';
import { queryClient } from './query-client';

const { NX_API_URL } = process.env;
NvAxios.defaults.baseURL = NX_API_URL;
NvAxiosWithoutInterceptor.defaults.baseURL = NX_API_URL;
NvAxios.interceptors.request.use(requestFulfilled, requestRejected);
NvAxios.interceptors.response.use(responseFulfilled, responseRejected);

export function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider initialMode={ThemeMode.Light} initialTheme={Themes.Default}>
        <AnalyticsProvider writeKey={segmentWriteKey} enabled={isAnalyticsEnabled} cdnURL={SEGMENT_CDN_URL}>
          <ToastProvider action={ToastCloseAction}>
            <QueryClientProvider client={queryClient}>
              <HelmetProvider>
                <AppContextProvider>
                  <ConfirmDialogContextProvider>
                    <AppConfigFlaggerProvider>
                      <Router>
                        <ServiceConfiguration />
                        <IdentityProvider appName={'Actioner'}>
                          <Route
                            element={
                              <>
                                <NvApolloClientProviderV1
                                  loadingComponent={<AppLoading />}
                                  metadataPath="/v1/appsync/metadata"
                                  refreshTokenStrategy={refreshAccessToken}
                                  SubscriptionComponent={RealTimeSubscription}
                                  onMetadataFailed={handleMetadataFailed}
                                />
                                <AppConfigFlaggerSubscriber />
                              </>
                            }
                          >
                            <Route element={<AppLayout hasNoMenu={true} />}>
                              <Route
                                path={`${ROUTES.UserApps}/:userAppId/slack/apps/access`}
                                element={<InstallSlackApp />}
                              />
                            </Route>

                            <Route path={ROUTES.UserApps} element={<AppLayout />}>
                              <Route
                                index
                                element={
                                  <ActionerPrivateRoute>
                                    <UserApps />
                                  </ActionerPrivateRoute>
                                }
                              />
                              <Route
                                path=":userAppId/*"
                                element={
                                  <ActionerPrivateRoute>
                                    <UserAppDetail />
                                  </ActionerPrivateRoute>
                                }
                              />
                            </Route>

                            <Route element={<AppLayout />}>
                              <Route
                                path={`${ROUTES.Integrations}/:integrationId`}
                                element={
                                  <ActionerPrivateRoute>
                                    <IntegrationDetail />
                                  </ActionerPrivateRoute>
                                }
                              />
                              <Route
                                path={`${ROUTES.Integrations}/:integrationId/*`}
                                element={
                                  <ActionerPrivateRoute>
                                    <IntegrationDetail />
                                  </ActionerPrivateRoute>
                                }
                              />
                            </Route>

                            <Route element={<AppLayout />}>
                              <Route
                                path={`${ROUTES.ProfileSettings}/*`}
                                element={
                                  <ActionerPrivateRoute>
                                    <ProfileSettings />
                                  </ActionerPrivateRoute>
                                }
                              />
                            </Route>

                            <Route element={<AppLayout />}>
                              <Route
                                path={ROUTES.Catalog}
                                element={
                                  <ActionerPrivateRoute>
                                    <Catalog />
                                  </ActionerPrivateRoute>
                                }
                              />
                              <Route
                                path={`${ROUTES.Catalog}/:catalogId/*`}
                                element={
                                  <ActionerPrivateRoute>
                                    <Catalog />
                                  </ActionerPrivateRoute>
                                }
                              />
                            </Route>

                            <Route element={<LayoutTopMenu />}>
                              <Route
                                path={ROUTES.MissingConnectionPage}
                                element={
                                  <ActionerPrivateRoute hasNoMenu>
                                    <AddConnection />
                                  </ActionerPrivateRoute>
                                }
                              />
                            </Route>

                            <Route path="/" element={<AppLayout />}>
                              <Route index element={<Navigate to={`${ROUTES.UserApps}`} />} />

                              <Route
                                path={`${ROUTES.Workspace}/*`}
                                element={
                                  <ActionerPrivateRoute>
                                    <ErrorBoundary>
                                      <Workspace />
                                    </ErrorBoundary>
                                  </ActionerPrivateRoute>
                                }
                              />

                              <Route
                                path={ROUTES.SlackUsers}
                                element={
                                  <ActionerPrivateRoute>
                                    <ErrorBoundary>
                                      <SlackManualUserMapping />
                                    </ErrorBoundary>
                                  </ActionerPrivateRoute>
                                }
                              />
                            </Route>
                          </Route>
                          <Route element={<AppLayout hasNoMenu={true} />}>
                            <Route
                              path={`/slack/marketplace/apps/access`}
                              element={
                                <PublicRoute
                                  LoadingComponent={<AppLoading />}
                                  subdomain={getSubdomain() || Cookies.get(LAST_ACTIVE_WORKSPACE)}
                                >
                                  {({ isAuthenticated }: { isAuthenticated?: boolean }) => (
                                    <ErrorBoundary>
                                      <StartMarketplaceSlackAppInstallation isAnonymous={!isAuthenticated} />
                                    </ErrorBoundary>
                                  )}
                                </PublicRoute>
                              }
                            />
                          </Route>
                          <Route element={<AppLayout hasNoMenu={false} />}>
                            <Route
                              path={`${ROUTES.AppDirectory}/*`}
                              element={
                                <PublicRoute LoadingComponent={<AppLoading />}>
                                  {({ isAuthenticated }: { isAuthenticated?: boolean }) => (
                                    <ErrorBoundary>
                                      {isAuthenticated ? (
                                        <UserPermissionProvider>
                                          <AppDirectory isAuthenticated={Boolean(isAuthenticated)} />
                                        </UserPermissionProvider>
                                      ) : (
                                        <AppDirectory isAuthenticated={Boolean(isAuthenticated)} />
                                      )}
                                    </ErrorBoundary>
                                  )}
                                </PublicRoute>
                              }
                            />
                          </Route>

                          <Route element={<AppLayout hasNoMenu={true} />}>
                            <Route path={ROUTES.SlackVerification} element={<SlackAccessVerification />} />

                            <Route
                              path={`${ROUTES.Redirection}`}
                              element={
                                <PublicRoute LoadingComponent={<AppLoading />}>
                                  <ErrorBoundary>
                                    <Redirection />
                                  </ErrorBoundary>
                                </PublicRoute>
                              }
                            />
                            <Route
                              path={ROUTES.OAuthRedirection}
                              element={
                                <ErrorBoundary>
                                  <OAuthCodePage />
                                </ErrorBoundary>
                              }
                            />
                            <Route
                              path={ROUTES.PostMessage}
                              element={
                                <ErrorBoundary>
                                  <AppPostMessage />
                                </ErrorBoundary>
                              }
                            />
                            <Route
                              path={ROUTES.InvalidWorkspace}
                              element={
                                <ErrorPage
                                  onClick={() => {
                                    window.location.href = `${ApplicationConfig.Actioner.origin}${ROUTE_DEFAULTS.LOG_OUT}`;
                                  }}
                                  title="You are not a member"
                                  description={`You are not a member of ${
                                    getSubdomain() && getSubdomain().length > 0 ? getSubdomain() : 'this workspace'
                                  }. Please ask the workspace admin to invite you to this workspace.`}
                                  buttonText="Login"
                                />
                              }
                            />
                            <Route
                              path={ROUTES.SuspendedWorkspace}
                              element={
                                <ErrorPage
                                  onClick={() => {
                                    window.location.href = `${ApplicationConfig.Actioner.origin}${ROUTE_DEFAULTS.SIGN_IN}`;
                                  }}
                                  title={`${
                                    getSubdomain() && getSubdomain().length > 0 ? getSubdomain() : 'this workspace'
                                  } is suspended`}
                                  description={`Your workspace is currently suspended, so you won't be able to access it. Please get in touch with your workspace admin or contact us at support@actioner.com.`}
                                  buttonText="Login to another workspace"
                                />
                              }
                            />
                            <Route path={ROUTES.NotFound404} element={<ErrorPage404 />}></Route>
                            <Route path={'/app-loading'} element={<AppLoading />}></Route>

                            <Route path={ROUTES.Impersonate} element={<Impersonate productId="actioner" />} />

                            <Route
                              path={`${ROUTES.Admin}/*`}
                              element={
                                <PrivateRoute LoadingComponent={<AppLoading />}>
                                  {({ user }: PrivateRouteChildProps) => {
                                    return user?.isSuperAdmin ? (
                                      <AdminPanel productId="actioner" />
                                    ) : (
                                      <Navigate to={ROUTES.NotFound404} />
                                    );
                                  }}
                                </PrivateRoute>
                              }
                            />
                          </Route>
                          <Route path="*" element={<Navigate to={ROUTES.NotFound404} />}></Route>
                        </IdentityProvider>
                      </Router>
                    </AppConfigFlaggerProvider>
                  </ConfirmDialogContextProvider>
                </AppContextProvider>
                <NvReactQueryDevTools />
              </HelmetProvider>
            </QueryClientProvider>
          </ToastProvider>
        </AnalyticsProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default withProfiler(App);
