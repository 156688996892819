import { JSONPath } from 'jsonpath-plus';
import {
  head,
  isArray,
  isBoolean,
  isNumber,
  isObject,
  isUndefined,
  last,
  maxBy,
  meanBy,
  minBy,
  sumBy,
  template,
  templateSettings,
  uniq,
} from 'lodash';
import {
  AggregatorMethods,
  ChartDistribution,
  ChartValue,
  ChartValueJavascriptFormat,
  ChartValuePropertyFormat,
  FinalContext,
  ValueFormat,
} from '../types';

import {
  ERROR_CODE_TEMPLATE,
  NOT_ALLOWED_WINDOW_OBJECT_METHODS,
  NOT_ALLOWED_WINDOW_OBJECT_PROPERTIES,
} from '../constants';

import { SeverityLevel, captureMessage } from '@sentry/react';
import { MultiValueRule, SingleValueRule } from '../../lambda-handlers/rule-evaluations/types';
import { helperFunctions } from './helpers/constants';
import { ContextEnrichedExpressionTransformer, ExpressionTransformer, LodashTransformer } from './types';

export type TAssert = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  (condition: boolean, err: Error, severity?: TSeverity, params?: {}): asserts condition;
};

export enum Severity {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}

export type TSeverity = keyof typeof Severity;

/**
 * Throws in test and development, send a notice on production
 * @param condition Condition to assert - can use type assertions thanks to ts's "asserts condition"
 * @param err A js error object
 * @param severity
 */
export const assert: TAssert = function (condition: boolean, err: Error, severity = 'ERROR') {
  if (!condition) {
    const sev = Severity[severity];
    if (process.env.NODE_ENV !== 'production') {
      console.log('Assertion Error: ' + err.message, severity);
      if (sev === Severity.ERROR) {
        throw err;
      }
    } else {
      if (sev === Severity.ERROR) {
        captureMessage(err.message, sev as unknown as SeverityLevel);
      }
    }
  }
};

const convertStringKeyArrayToObjectContainingUndefinedValues = (items: string[]) =>
  items.reduce((acc, cur) => ({ ...acc, [cur]: undefined }), {});

const notAllowedWindowObjectProperties = convertStringKeyArrayToObjectContainingUndefinedValues(
  NOT_ALLOWED_WINDOW_OBJECT_PROPERTIES
);

const notAllowedWindowObjectMethods = convertStringKeyArrayToObjectContainingUndefinedValues(
  NOT_ALLOWED_WINDOW_OBJECT_METHODS
);

templateSettings.evaluate = /\{\{((?:.|\s)*?)\}\}/g;
templateSettings.interpolate = /\{\{((?:.|\s)*?)\}\}/g;
templateSettings.imports = {
  window: undefined,
  ...notAllowedWindowObjectProperties,
  ...notAllowedWindowObjectMethods,
  ...helperFunctions,
};

export class TemplateError extends Error {
  readonly code: string;

  constructor({ message, code }: { message: string; code: string }) {
    super(message);
    this.code = code;
  }
}

const enum EvalType {
  FUNCTION_TEMPLATE = 'function-template',
  LODASH_TEMPLATE = 'lodash-template',
}

export const expressionTransformer: ExpressionTransformer = (key, context, failable = false) => {
  assert(
    typeof window === 'undefined' || window !== window.parent,
    new Error('expressionTransformer is called from the main window. You should be calling it in the iframe'),
    'ERROR'
  );

  if (failable && isUndefined(key)) {
    throw new TemplateError({ message: 'template should not be empty', code: ERROR_CODE_TEMPLATE });
  }

  // "!failable && !context" means it is frontend use and there is no context.
  if (!failable && !context) {
    return key;
  }

  const { evalType, code } = decideEvalType(key);
  if (evalType === EvalType.FUNCTION_TEMPLATE) {
    return functionTransformer(code, context, failable);
  } else {
    return lodashTransformer(code, context, failable);
  }
};

export const lodashTransformer: LodashTransformer = (key, context, failable = false) => {
  assert(
    typeof window === 'undefined' || window !== window.parent,
    new Error('lodashTransformer is called from the main window. You should be calling it in the iframe'),
    'ERROR'
  );

  try {
    const templateTransformer = template(key);
    return templateTransformer(context);
  } catch (error) {
    if (failable) {
      if (error instanceof Error) {
        throw new TemplateError({ message: error.message, code: ERROR_CODE_TEMPLATE });
      } else {
        throw new TemplateError({ message: JSON.stringify(error), code: ERROR_CODE_TEMPLATE });
      }
    } else {
      if (error instanceof Error) {
        assert(false, new Error(`${key} could not be transformed by lodashTransformer. (${error.message})`), 'WARNING');
      } else {
        assert(false, new Error(`${key} could not be transformed by lodashTransformer. (${error})`), 'WARNING');
      }
    }
  }
};

const decideEvalType = (code?: string) => {
  const trimmedCode = code?.trim();
  const matchObject = trimmedCode?.match(/{{([\s\S]+?)}}/);
  if (matchObject?.[0].length === trimmedCode?.length) {
    return { evalType: EvalType.FUNCTION_TEMPLATE, code: matchObject?.[1] };
  } else {
    return { evalType: EvalType.LODASH_TEMPLATE, code: code };
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const enrichContext = (context?: any) => {
  const { context: actionContext = {}, ...rest } = context || {};

  return {
    ...rest,
    ...actionContext,
    context: { get: (key: string) => actionContext[key] },
    ...helperFunctions,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const enrichContextForJSEvaluation = (context?: any) => {
  const { context: actionContext = {}, ...rest } = context || {};

  return {
    ...rest,
    ...actionContext,
    context: { get: (key: string) => actionContext[key], put: '' },
    ...helperFunctions,
  };
};

export const contextEnrichedExpressionTransformer: ContextEnrichedExpressionTransformer = (
  key,
  context,
  failable = false
) => {
  assert(
    typeof window === 'undefined' || window !== window.parent,
    new Error(
      'contextEnrichedExpressionTransformer is called from the main window. You should be calling it in the iframe'
    ),
    'ERROR'
  );
  const newContext = enrichContext(context);
  return expressionTransformer(key, newContext, failable);
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const functionTransformer = (key?: string, context?: any, failable = false) => {
  try {
    const functionTemplateCompiler = new Function('scope', `with(scope||{}){ return (${key}); }`);
    if (context) {
      context = {
        ...context,
        window: undefined,
        ...notAllowedWindowObjectProperties,
        ...notAllowedWindowObjectMethods,
        ...helperFunctions,
      };
    }

    return functionTemplateCompiler(context);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (failable) {
      if (error instanceof Error) {
        throw new TemplateError({ message: error.message, code: ERROR_CODE_TEMPLATE });
      } else {
        throw new TemplateError({ message: JSON.stringify(error), code: ERROR_CODE_TEMPLATE });
      }
    } else {
      if (error instanceof Error) {
        assert(false, new Error(`${key} could not be transformed by lodashTransformer. (${error.message})`), 'WARNING');
      } else {
        assert(false, new Error(`${key} could not be transformed by lodashTransformer. (${error})`), 'WARNING');
      }
    }
  }
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const jsonPathTransformer = (key: string, context?: any) => {
  if (!context) return;

  try {
    const result = JSONPath({ path: key, json: context });
    return result;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    assert(false, new Error(`${key} could not be transformed by jsonPathTransformer. (${error.message})`), 'WARNING');
  }
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const aggregator = (context: any, method: AggregatorMethods, key: string) => {
  switch (method) {
    case AggregatorMethods.SUM:
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return sumBy(context, (r: any) => r[key]);
    case AggregatorMethods.COUNT:
      return context.length;
    case AggregatorMethods.AVERAGE:
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return meanBy(context, (r: any) => r[key]);
    case AggregatorMethods.MINIMUM_VALUE:
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return minBy(context, (r: any) => r[key])[key];
    case AggregatorMethods.MAXIMUM_VALUE:
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return maxBy(context, (r: any) => r[key])[key];
    case AggregatorMethods.FIRST_VALUE:
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return isObject(head(context)) ? head(context as any[])[key] : head(context);
    case AggregatorMethods.LAST_VALUE:
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return isObject(head(context)) ? last(context as any[])[key] : last(context);
    default:
      return;
  }
};

export const calculateChartValues = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
  dataMapper: ChartValue,
  aggregatorObj: ChartDistribution,
  finalContext: FinalContext,
  transformer?: LodashTransformer
) => {
  assert(
    typeof window === 'undefined' || window !== window.parent,
    new Error('calculateChartValues is called from the main window. You should be calling it in the iframe'),
    'ERROR'
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let xValues: any[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const yValues: any[] = [];

  const chartTransformerWrappedExceptionHandler = ({
    template,
    context,
  }: {
    template: ChartValueJavascriptFormat['value'];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    context: any;
  }) => {
    const chartTransformer = transformer ?? ((key, context) => lodashTransformer(key, context, true));
    const defaultValue = dataMapper.title ? `No ${dataMapper.title}` : 'No value';
    try {
      const transformedValue = chartTransformer(template, context);
      return transformedValue || defaultValue;
    } catch (error) {
      return defaultValue;
    }
  };

  if (isArray(data)) {
    if (dataMapper.type === ValueFormat.PROPERTY_BINDING) {
      xValues = uniq(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data.map((row: any) => jsonPathTransformer((dataMapper as ChartValuePropertyFormat).propertyPath, row)?.[0])
      );
      xValues.forEach((xValue) => {
        const result = data.filter(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (d: any) => jsonPathTransformer((dataMapper as ChartValuePropertyFormat).propertyPath, d)?.[0] === xValue
        );
        yValues.push(aggregator(result, aggregatorObj.method, aggregatorObj.value));
      });
    } else if (dataMapper.type === ValueFormat.JAVASCRIPT) {
      xValues = uniq(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data.map((row: any) =>
          chartTransformerWrappedExceptionHandler({
            template: (dataMapper as ChartValueJavascriptFormat).value,
            context: {
              ...finalContext,
              currentItem: row,
            },
          })
        )
      );

      xValues.forEach((xValue) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const result = data.filter((d: any) => {
          const value = chartTransformerWrappedExceptionHandler({
            template: (dataMapper as ChartValueJavascriptFormat).value,
            context: {
              ...finalContext,
              currentItem: d,
            },
          });
          return value === xValue;
        });

        yValues.push(aggregator(result, aggregatorObj.method, aggregatorObj.value));
      });
    }
  }

  return { xValues, yValues };
};

export const valueToString = (value: unknown) =>
  isArray(value) || isObject(value) || isBoolean(value) || isNumber(value) ? JSON.stringify(value) : value;

export const isMultiValueRule = (ruleValue?: MultiValueRule | SingleValueRule): ruleValue is MultiValueRule => {
  return (ruleValue as MultiValueRule)?.type === 'list';
};
