import { AnalyticsBrowser } from '@segment/analytics-next';
import React from 'react';
import { AnalyticsSwitchPlugin } from '../plugins';
import { AnalyticsProps } from './types';

const AnalyticsContext = React.createContext<AnalyticsBrowser>(undefined!);

export const AnalyticsProvider = ({ children, writeKey, enabled, cdnURL }: AnalyticsProps) => {
  const analytics = React.useMemo(
    () => AnalyticsBrowser.load({ writeKey, cdnURL, plugins: [AnalyticsSwitchPlugin({ isClosed: !enabled })] }),
    [cdnURL, enabled, writeKey]
  );

  return <AnalyticsContext.Provider value={analytics}>{children}</AnalyticsContext.Provider>;
};

export const useAnalytics = () => {
  const result = React.useContext(AnalyticsContext);
  if (!result) {
    throw new Error('Context used outside of AnalyticsProvider!');
  }
  return result;
};
