import {
  MarkDownBlock,
  SlackBlockTypes,
  StaticWorkflowReferenceElement,
  ValueTypes,
  WorkflowReferencesBlock,
} from '@novaera/actioner-service';

export const MARKDOWN_BLOCK_DEFAULT_VALUE: MarkDownBlock = {
  type: SlackBlockTypes.MARKDOWN,
  value: {
    type: ValueTypes.STRING,
    value: '',
  },
};

export const WORKFLOW_REFERENCES_BLOCK_NEW_ELEMENT_DEFAULT_VALUE: Omit<StaticWorkflowReferenceElement, 'id'> = {
  displayConfiguration: {
    componentType: 'button',
    text: 'Run',
  },
  workflowId: '',
  type: 'static',
};

export const WORKFLOW_REFERENCES_BLOCK_DEFAULT_VALUE: WorkflowReferencesBlock = {
  type: SlackBlockTypes.WORKFLOW_REFERENCES,
  elements: [],
  label: 'Click me!',
};
