import { UIComponentType } from '@novaera/actioner-service';
import { NvField } from '@novaera/core';
import classNames from 'classnames';
import { useCallback } from 'react';
import { useSearchAsYouTypeValuesContext } from '../../../../../../../../../../action-designer/providers/search-as-you-type-values';
import { GetOptionsResponseFunction } from '../../../../../../../../../../components/options/provider/types';
import { useUIComponents } from '../../../../../../../../../../components/ui-components/use-ui-component';
import { useFormParameterProvider } from '../../../../../../providers/form-parameter-provider';
import { InputParameterFieldProps } from './types';

export const InputParameterField = ({ inputParameter, result }: InputParameterFieldProps) => {
  const { onInputValueChanged, setEnabledInputParameterIdToFetchOptions, isInputParameterNewlyAdded } =
    useFormParameterProvider();
  const { setSearchAsYouTypeValues } = useSearchAsYouTypeValuesContext();
  const handleSearchAsYouTypeValueUpdate = useCallback(
    ({ value, inputParameterId }: { value: string; inputParameterId: string }) => {
      setSearchAsYouTypeValues((prev) => ({ ...prev, [inputParameterId]: value }));
    },
    [setSearchAsYouTypeValues]
  );

  const getOptionsResponse = useCallback<GetOptionsResponseFunction>(() => {
    const { dataSource, ...data } = result?.data ?? { options: [] };

    return {
      ...data,
      isLoading: result?.isInitialLoading || result?.isRefetching,
    };
  }, [result]);

  const { componentFieldProps, getComponentForField } = useUIComponents({
    getOptionsResponse,
    setInputParameterIdsShowingOptions: setEnabledInputParameterIdToFetchOptions,
    onSearchAsYouTypeValueUpdate: handleSearchAsYouTypeValueUpdate,
    inputParameter,
    onInputValueChange: (param) => {
      onInputValueChanged(param);
    },
  });

  return (
    <NvField
      wrapperClassName={classNames({
        'add-new-item': isInputParameterNewlyAdded(inputParameter.id),
      })}
      {...componentFieldProps}
      direction="label-on-top"
      hideLabel={inputParameter.uiComponent.type === UIComponentType.DYNAMIC_INPUT}
      name={inputParameter.id}
      component={getComponentForField({ inputParameter })}
    />
  );
};
