import { useDeleteIntegration, useGetIntegration } from '@novaera/actioner-service';
import { NvDeleteOutlineIcon, NvMenuWithItems, NvMoreHorizIcon, useConfirmDialog } from '@novaera/core';
import { useNavigate, useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import { INTEGRATION_LIST } from '../../constants';
import { IntegrationDescription } from './integration-description';
import { IntegrationHeaderAndLogo } from './integration-header-and-logo';
import { SummaryLayout } from './layout';
import { SummaryLoading } from './loading';

export const Summary = () => {
  const { integrationId } = useParams();
  const { data: integration, isInitialLoading } = useGetIntegration({ id: integrationId });
  const { mutate } = useDeleteIntegration();
  const { openConfirm } = useConfirmDialog();
  const theme = useTheme();

  const navigate = useNavigate();

  const handleDelete = () => {
    openConfirm({
      message: 'Are you sure?',
      onConfirm: () => {
        mutate(
          { id: integrationId },
          {
            onSuccess: () => {
              navigate(INTEGRATION_LIST);
            },
          }
        );
      },
    });
  };
  return !integration || isInitialLoading ? (
    <SummaryLoading />
  ) : (
    <SummaryLayout
      headerLeft={<IntegrationHeaderAndLogo integration={integration} />}
      headerRight={
        <NvMenuWithItems
          triggerButton={{
            content: <NvMoreHorizIcon />,
            props: { onlyIcon: true, size: 'small', color: 'secondary' },
          }}
          menuItems={[
            {
              name: 'Delete',
              onClick: () => handleDelete(),
              icon: (
                <NvDeleteOutlineIcon htmlColor={theme.palette.nv_error[40]} sx={{ width: '16px', height: '16px' }} />
              ),
            },
          ]}
        />
      }
      description={<IntegrationDescription integration={integration} />}
      infoBox={<></>}
    />
  );
};
