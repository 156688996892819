import {
  Trigger,
  WorkflowWithState,
  useGetActiveUser,
  useGetWorkflow,
  useSaveToDraftWorkflow,
} from '@novaera/actioner-service';
import { useCallback } from 'react';
import { useGetWorkflowQueryParams } from '../../../../../../../controllers/use-get-workflow-query-params';

export const useSwitchToMe = () => {
  const { user: activeUser } = useGetActiveUser();
  const { userAppId, workflowId } = useGetWorkflowQueryParams();
  const { workflow } = useGetWorkflow({ appId: userAppId, workflowId });
  const { mutate: saveToDraft, isLoading: isSaveLoading } = useSaveToDraftWorkflow();
  const handleSwitchToMe = useCallback(() => {
    const trigger = workflow?.trigger;
    if (trigger) {
      const newTrigger: Trigger = {
        ...trigger,
        defaultStarterUserId: activeUser?.userId,
      };
      const newWorkflow: WorkflowWithState = {
        ...workflow,
        trigger: newTrigger,
      };
      saveToDraft(newWorkflow);
    }
  }, [activeUser?.userId, saveToDraft, workflow]);

  return { handleSwitchToMe, isSaveLoading };
};
