import {
  CodeInput,
  Context,
  NvField,
  NvFlex,
  NvMenuWithItems,
  NvSettingsIcon,
  NvSideGroupedInputLayout,
} from '@novaera/core';
import { DynamicStaticMenuItem } from '../../../../../../components/parameter-mapper/parameter-mapper-form/parameter-mapper-item/settings-menu/dynamic-static-menu-item';
import { WorkflowSelectComponent } from '../../common/workflow-select-component';
import { SelectWorkflowWrapper } from '../../properties-panel/property-panels/workflow-dispatcher-property-panel-drawer/workflow-dispatcher-properties/styled';

type WorkflowSelectProps = {
  isDynamic: boolean;
  context: Context;
  typeFieldName: string;
  dynamicFieldAppIdFieldName: string;
  dynamicFieldWorkflowIdFieldName: string;
  staticFieldWorkflowIdFieldName: string;
};

export const WorkflowSelect = ({
  context,
  isDynamic,
  typeFieldName,
  dynamicFieldAppIdFieldName,
  dynamicFieldWorkflowIdFieldName,
  staticFieldWorkflowIdFieldName,
}: WorkflowSelectProps) => {
  return (
    <NvSideGroupedInputLayout
      variant="default"
      title={{ labelText: 'Select workflow', labelVariant: 'h5' }}
      {...((isDynamic && { CustomGroupWrapper: SelectWorkflowWrapper }) || undefined)}
      rightAction={
        <NvField
          name={typeFieldName}
          component={({ value, onChange }) => (
            <NvMenuWithItems
              triggerButton={{
                content: <NvSettingsIcon />,
                props: { sx: { marginTop: '0px' }, size: 'small', color: 'ghost', onlyIcon: true },
              }}
              menuItems={
                <DynamicStaticMenuItem
                  items={[
                    {
                      title: 'Static selection',
                      value: 'static',
                      description: 'Select workflow from the list.',
                    },
                    {
                      title: 'Dynamic selection',
                      value: 'dynamic',
                      description: `Dynamically retrieve the workflow name.`,
                    },
                  ]}
                  selectedIndex={value === 'static' ? 0 : 1}
                  onItemClicked={(value) => {
                    onChange(value);
                  }}
                />
              }
            />
          )}
        />
      }
      formItem={
        isDynamic ? (
          <NvFlex gap="16px" width={'100%'}>
            <NvField
              labelText="App ID"
              labelVariant="h6"
              direction="label-on-side"
              name={dynamicFieldAppIdFieldName}
              component={<CodeInput context={context} placeholder="{{...}}" />}
              defaultValue="{{app.id}}"
            />
            <NvField
              labelText="Workflow ID"
              direction="label-on-side"
              labelVariant="h6"
              name={dynamicFieldWorkflowIdFieldName}
              component={<CodeInput context={context} placeholder="{{...}}" />}
            />
          </NvFlex>
        ) : (
          <NvField<string | undefined>
            name={staticFieldWorkflowIdFieldName}
            component={<WorkflowSelectComponent searchParamsTriggerTypes={['form', 'genericWebhook']} size="small" />}
          />
        )
      }
    />
  );
};
