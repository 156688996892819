import { GetConversationNode, StringValue, ValueTypes, useUpdateNode } from '@novaera/actioner-service';
import {
  CodeInput,
  NvButton,
  NvCloseIcon,
  NvDeleteOutlineIcon,
  NvField,
  NvFingerPrintIcon,
  NvFlex,
  NvForm,
  NvMenuWithItems,
  NvMoreHorizIcon,
  NvTypography,
} from '@novaera/core';
import { useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import arrayMutators from 'final-form-arrays';
import { cloneDeep, noop } from 'lodash';
import { FC, useCallback, useMemo } from 'react';
import {
  NodeType,
  PropertyPanelHeader,
  PropertyPanelListHeader,
  PropertyPanelSection,
  PropertyPanelSimpleSection,
} from '../../../../../../../../components';
import { OutputItem } from '../../../../../../../../components/output-item';
import { useWorkflowPermission } from '../../../../../../../user-app-permission-boundary/use-workflow-permission';
import { GET_CONVERSATION_SAMPLE_RESPONSE } from '../../../../common/constants';
import { useGetWorkflowContexts } from '../../../../controllers/use-get-workflow-contexts';
import { userAppGraph } from '../../../../graph-utils/user-app-graph';
import { useNovaeraFlow } from '../../../../use-novaera-flow';
import { usePropertyPanelContext } from '../../../provider';
import { GetConversationPropertiesProps } from './types';

export const GetConversationProperties: FC<GetConversationPropertiesProps> = ({
  getConversationNode,
  onCloseClicked,
}) => {
  const theme = useTheme();
  const { userAppId, workflowId } = useParams();
  const { deleteNodeAndUpdateGraph } = usePropertyPanelContext();
  const { hasEditPermission } = useWorkflowPermission();
  const { mutate: updateNodeDetail } = useUpdateNode();
  const { updateNode } = useNovaeraFlow(userAppGraph);
  const { workflowCodeInputContext } = useGetWorkflowContexts();
  const initialGeConversationNode = useMemo<GetConversationNode>(
    () => ({
      ...getConversationNode,
    }),
    [getConversationNode]
  );

  const handleSaveName = useCallback(
    (newName: string) => {
      updateNodeDetail(
        {
          appId: userAppId,
          workflowId,
          nodeAlias: getConversationNode.alias,
          payload: { ...(getConversationNode as GetConversationNode), name: newName },
        },
        {
          onSuccess: () => {
            const graphNode = userAppGraph.node(getConversationNode.alias);
            const newGraphNode = cloneDeep(graphNode);
            newGraphNode.name = newName;
            updateNode({ newNode: newGraphNode });
          },
        }
      );
    },
    [getConversationNode, updateNode, updateNodeDetail, userAppId, workflowId]
  );

  const handleOnChange = useCallback(
    (values: GetConversationNode) => {
      updateNodeDetail({
        appId: userAppId,
        workflowId,
        nodeAlias: getConversationNode.alias,
        payload: { ...values },
      });
    },
    [updateNodeDetail, userAppId, getConversationNode, workflowId]
  );

  return (
    <NvFlex width="100%">
      <PropertyPanelHeader
        hasEditRight={hasEditPermission}
        title={getConversationNode.name}
        type={NodeType.GET_CONVERSATION}
        onTitleChange={async (title) => {
          if (title) {
            handleSaveName(title);
          }
        }}
        validateTitle={(title) => (title && title.length > 0 ? undefined : 'This field is required')}
        actions={
          <>
            <NvMenuWithItems
              triggerButton={{
                content: <NvMoreHorizIcon />,
                props: { onlyIcon: true, size: 'small', color: 'secondary' },
              }}
              menuItems={[
                {
                  name: 'Delete',
                  icon: (
                    <NvDeleteOutlineIcon
                      htmlColor={theme.palette.nv_error[40]}
                      sx={{ width: '16px', height: '16px' }}
                    />
                  ),
                  onClick: () => {
                    deleteNodeAndUpdateGraph({ nodeId: getConversationNode.alias });
                  },
                },
              ]}
            />

            <NvButton onlyIcon size="small" color="secondary" onClick={onCloseClicked}>
              <NvCloseIcon />
            </NvButton>
          </>
        }
      />
      <NvForm<GetConversationNode>
        onSubmit={noop}
        onChange={({ values }) => {
          handleOnChange(values);
        }}
        initialValues={initialGeConversationNode}
        keepDirtyOnReinitialize
        autoSaveProps={{ autoSaveType: 'debounce', debounceDelay: 500 }}
        mutators={{
          ...arrayMutators,
        }}
      >
        <PropertyPanelSimpleSection>
          <NvFlex gap="16px">
            <NvTypography variant="body2">Returns a relevant conversation from a given identifier.</NvTypography>
          </NvFlex>
        </PropertyPanelSimpleSection>
        <PropertyPanelSection title={'Conversation ID'} icon={<NvFingerPrintIcon />}>
          <NvField
            name={'conversationIdentifier'}
            format={(value: StringValue | undefined) => value?.value}
            parse={(value: string) => (value ? { type: ValueTypes.STRING, value: value } : undefined)}
            component={<CodeInput context={workflowCodeInputContext} placeholder="{{...}}" />}
            formControlStyle={{ width: '100%' }}
          />
        </PropertyPanelSection>

        <PropertyPanelListHeader title="Response" tooltip="The response displayed below is solely a sample." />
        <PropertyPanelSimpleSection>
          <OutputItem content={GET_CONVERSATION_SAMPLE_RESPONSE} isTestResultFailed={false} />
        </PropertyPanelSimpleSection>
      </NvForm>
    </NvFlex>
  );
};
