import { isAxiosError, NvAxios, useMutation, useToast } from '@novaera/core';
import { isString } from 'lodash';
import { SLACK_AUTH_ACCESS_PATH } from '../contants';
import { SlackCreateWorkspaceCompleteWorkspaceSelection, SlackJoinCompleteWorkspaceSelection } from './types';

const slackWorkspaceSelection = async (
  params: SlackJoinCompleteWorkspaceSelection | SlackCreateWorkspaceCompleteWorkspaceSelection
) => {
  const result = await NvAxios.post<{
    jwt: string;
    slackTeamId: string;
    workspaceId: string;
    subdomain: string;
  }>(`${SLACK_AUTH_ACCESS_PATH}/connections/anonymous/access/complete-workspace-selection`, params);
  return result.data;
};

export const useSlackCompleteWorkspaceSelection = () => {
  const { addToast } = useToast();
  return useMutation(slackWorkspaceSelection, {
    onError: (error) => {
      if (isAxiosError(error)) {
        addToast(
          isString(error.response?.data) ? error.response?.data : 'An error ocurred while getting Slack workspaces',
          {
            variant: 'error',
          }
        );
      }
    },
  });
};
