import { FetchProfileResponse, GetUserPermissionsResponse } from '@novaera/actioner-service';
import { SlackWorkspace } from '@novaera/ah-common';
import { getSubdomain } from '@novaera/utils';
import { useEffect, useRef } from 'react';

export const useUserGuidingIdentifier = ({
  enabled,
  user,
  permissions,
  slackWorkspace,
}: {
  enabled: boolean;
  user?: FetchProfileResponse;
  permissions?: GetUserPermissionsResponse['permissions'];
  slackWorkspace?: SlackWorkspace;
}) => {
  const isSent = useRef<boolean>(false);
  useEffect(() => {
    if (enabled && !isSent.current && permissions && user) {
      isSent.current = true;

      const permissionObject: Record<string, boolean> = {};
      for (const [key, value] of Object.entries(permissions)) {
        permissionObject[key.replace(':', '_')] = value;
      }

      window.userGuiding.identify(user.userId, {
        email: user.email,
        name: user.name,
        workspaceId: user.workspaceId,
        signupSource: user.signupSource,
        workspaceName: getSubdomain(),
        workspaceSlackConnectionCount: slackWorkspace ? 1 : 0,
        ...permissionObject,
      });
    }
  }, [enabled, isSent, permissions, slackWorkspace, user]);
  return {};
};
