import { matchPath, useNavigate } from '@novaera/route';

export const useCatalogUtil = () => {
  const navigate = useNavigate();

  const isPageInCatalogRecords = () => {
    const isCatalogEntityRecords = matchPath(`/catalog/:catalogId/records`, window.location.pathname);
    return Boolean(isCatalogEntityRecords);
  };

  const navigateToCatalogEntityType = (catalogId: string) => {
    if (isPageInCatalogRecords()) {
      navigateToCatalogEntityRecords(catalogId);
    } else {
      navigateToCatalogEntitySchema(catalogId);
    }
  };

  const navigateToCatalogEntityRecords = (catalogId: string) => {
    navigate(`/catalog/${catalogId}/records`);
  };

  const navigateToCatalogEntitySchema = (catalogId: string) => {
    navigate(`/catalog/${catalogId}/schema`);
  };

  return {
    navigateToCatalogEntityType,
    navigateToCatalogEntityRecords,
    navigateToCatalogEntitySchema,
    isPageInCatalogRecords,
  };
};
