import { NodeUnionTypeEnumLike, SendEmailNode, ValueTypes, useGetNode, useUpdateNode } from '@novaera/actioner-service';
import { NvFlex, NvForm } from '@novaera/core';
import { useParams } from '@novaera/route';
import { assert, noop } from '@novaera/utils';
import { FC, useCallback, useMemo, useState } from 'react';
import { PPDrawerItem, PropertyPaneWrapper } from '../../../../../../../components';
import { usePropertyPanelContext } from '../../provider';
import { PropertiesLoading } from '../common/properties-loading';
import { usePPDrawerItem, usePPDrawerWrapper } from '../controllers/use-pp-drawer-item';
import { MoreSettings } from './more-settings';
import { SendEmailProperties } from './send-email-properties';
import { EMAIL_CONTENT_TYPES } from './send-email-properties/constants';
import { SendEmailPropertyPanelDrawerProps } from './types';

export const SendEmailPropertyPanelDrawer: FC<SendEmailPropertyPanelDrawerProps> = ({ handleClose }) => {
  const { selectedNode, isPanelOpen: isFirstPanelOpen, emptySelectedNode } = usePropertyPanelContext();
  const [secondPanelOpen, setSecondPanelOpen] = useState(false);
  assert(!!selectedNode, new Error('[SendEmailPropertyPanelDrawer] - selectedNode should not be undefined!'), 'ERROR');
  const { userAppId: appId, workflowId } = useParams();
  const { mutate: updateNodeDetail } = useUpdateNode();
  const { node: sendEmailNode, isLoading } = useGetNode<typeof NodeUnionTypeEnumLike.sendEmail>({
    workflowId,
    appId,
    nodeAlias: selectedNode.id,
  });

  const { wrapperProps } = usePPDrawerWrapper({
    wrapper: { open: isFirstPanelOpen },
  });

  const {
    panelProps: [first, second],
    panelCloseFunctions: [, onSecondClose],
  } = usePPDrawerItem({
    subPanels: [
      {
        open: isFirstPanelOpen,
        closeFn: () => {
          emptySelectedNode();
          handleClose();
        },
      },
      {
        open: secondPanelOpen,
        closeFn: () => {
          setSecondPanelOpen(false);
        },
      },
    ],
  });

  const handleMoreSettingsClicked = () => {
    setSecondPanelOpen(true);
  };

  const initialSendEmailNode = useMemo<SendEmailNode | undefined>(
    () =>
      sendEmailNode
        ? {
            appId: { type: ValueTypes.STRING, value: '{{app.id}}' },
            fromName: { type: ValueTypes.STRING },
            cc: { type: ValueTypes.LIST, value: [] },
            bcc: { type: ValueTypes.LIST, value: [] },
            categories: { type: ValueTypes.LIST, value: [] },
            replyTo: { type: ValueTypes.STRING },
            contentType: { type: ValueTypes.STRING, value: EMAIL_CONTENT_TYPES[0].value },
            errorHandlingStrategy: { type: 'simple', continueOnError: true },
            to: { type: ValueTypes.LIST, value: [] },
            fromLocalPartSuffix: { type: ValueTypes.STRING, value: '' },
            subject: { type: ValueTypes.STRING, value: '' },
            content: { type: ValueTypes.STRING, value: '' },
            ...sendEmailNode,
          }
        : undefined,
    [sendEmailNode]
  );

  const handleOnChange = useCallback(
    (values: SendEmailNode) => {
      assert(!!sendEmailNode, new Error(''), 'ERROR');
      updateNodeDetail({
        appId,
        workflowId,
        nodeAlias: sendEmailNode.alias,
        payload: { ...values },
      });
    },
    [sendEmailNode, updateNodeDetail, appId, workflowId]
  );

  return (
    <PropertyPaneWrapper {...wrapperProps}>
      {isLoading ? (
        <PPDrawerItem {...first}>
          <PropertiesLoading />
        </PPDrawerItem>
      ) : (
        sendEmailNode && (
          <NvForm<SendEmailNode>
            onSubmit={noop}
            onChange={({ values }) => {
              handleOnChange(values);
            }}
            initialValues={initialSendEmailNode}
            keepDirtyOnReinitialize
            autoSaveProps={{ autoSaveType: 'debounce', debounceDelay: 500 }}
          >
            <NvFlex direction="row" height={'100%'}>
              <PPDrawerItem {...first}>
                <SendEmailProperties
                  onCloseClicked={handleClose}
                  sendEmailNode={sendEmailNode}
                  secondPanelOpen={secondPanelOpen}
                  onMoreSettingsClicked={handleMoreSettingsClicked}
                />
              </PPDrawerItem>
              <PPDrawerItem {...second}>
                <MoreSettings onCloseClicked={onSecondClose} />
              </PPDrawerItem>
            </NvFlex>
          </NvForm>
        )
      )}
    </PropertyPaneWrapper>
  );
};
