import { useGetIntegration, useGetIntegrationAction } from '@novaera/actioner-service';
import { useParams } from '@novaera/route';
import { assert } from '@novaera/utils';
import { isUndefined } from 'lodash';
import { useMemo } from 'react';
import { useFieldArray } from 'react-final-form-arrays';
import { useFunctionsContext } from '../../../../../../providers/functions-provider';
import { setNextItemAfterDeletion } from '../../../../../request-tab/requests/utils';
import { useFunctions } from '../../../controller/use-functions';

export const useFunctionList = () => {
  const { actionId, integrationId } = useParams();
  const { data: integration } = useGetIntegration({ id: integrationId });
  const { data } = useGetIntegrationAction({ actionId, integrationId, version: integration?.latestVersion.number });
  const {
    fields: { remove: removeFunction },
  } = useFieldArray('functions');

  const { selectedFunctionId, setSelectedFunctionId } = useFunctionsContext();

  const functions = useMemo(() => data?.functions, [data?.functions]);

  const { onAddNewFunction } = useFunctions();

  const onHandleItemClick = (id: string) => {
    setSelectedFunctionId(id);
  };
  const onHandleDeleteClick = (id: string) => {
    const version = integration?.latestVersion?.number;
    assert(!isUndefined(version), new Error('Integration is expected to be defined'), 'ERROR');
    const removedIndex = functions?.findIndex((f) => f.id === id);
    if (!isUndefined(removedIndex) && removedIndex > -1) {
      removeFunction(removedIndex);

      if (selectedFunctionId === id) {
        setNextItemAfterDeletion(id, removedIndex, setSelectedFunctionId, functions);
      }
    }
  };

  return { functions, selectedFunctionId, onHandleItemClick, onHandleDeleteClick, onAddNewFunction };
};
