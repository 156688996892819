import { ROUTES } from '../../common/routes';

export const PORTAL_ID_FOR_NAVIGATION_POPOVER = 'navigation-popover';
export const COMMAND_TOOLTIP_TEXT = 'Action bar';
export const APPS_TOOLTIP_TEXT = 'My apps';
export const APPS_ROUTE = ROUTES.UserApps;
export const COMMAND_ROUTE = '/';
export const GO_TO_COMMAND_SHORTCUT_KEY = 'command+k';
export const GO_TO_MY_APPS_SHORTCUT_KEY = 'command+p';
export const STORE_TOOLTIP_TEXT = 'App directory';
