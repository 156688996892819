import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { SlackBlockTypes, WorkflowReferencesBlock } from '@novaera/actioner-service';
import {
  NvAddBoxIcon,
  NvBox,
  NvConditionalWrap,
  NvCustomTextField,
  NvMenuWithItems,
  NvSmartButton,
  NvTypography,
  Portal,
} from '@novaera/core';
import { useParams } from '@novaera/route';
import { generateUniqueId } from '@novaera/utils';
import { FC } from 'react';
import { PropertyPanelListHeader, PropertyPanelSimpleSection } from '../../property-panel';
import { BlockListComponentParams } from '../use-ui-component/types';
import {
  MARKDOWN_BLOCK_DEFAULT_VALUE,
  WORKFLOW_REFERENCES_BLOCK_DEFAULT_VALUE,
  WORKFLOW_REFERENCES_BLOCK_NEW_ELEMENT_DEFAULT_VALUE,
} from './constants';
import { useSlackBlockComponent } from './controllers/use-slack-block-component';
import { MarkdownBlock } from './markdown-block';
import { WorkflowReferenceBlock } from './workflow-reference-block';

export const SlackBlocksComponent: FC<React.PropsWithChildren<BlockListComponentParams>> = ({
  inputProps: { value, onChange },
  context = {},
}) => {
  const { handleOnChangeBlock, makeOnDragEndFunction, handleDeleteBlock } = useSlackBlockComponent({
    value,
    onChange,
  });
  const { userAppId } = useParams();

  return (
    <>
      <PropertyPanelListHeader
        title="BLOCKS"
        actions={
          <NvMenuWithItems
            triggerButton={{
              props: {
                size: 'small',
                color: 'ghost',
                onlyIcon: true,
                variant: 'contained',
                sx: { flex: '0 0 auto' },
              },
              content: <NvAddBoxIcon sx={{ width: '16px', height: '16px' }} />,
            }}
            menuItems={[
              {
                icon: <NvCustomTextField sx={{ width: '16px', height: '16px' }} />,
                name: 'Markdown block',
                onClick: () => {
                  value ? onChange([...value, MARKDOWN_BLOCK_DEFAULT_VALUE]) : onChange([MARKDOWN_BLOCK_DEFAULT_VALUE]);
                },
              },
              {
                icon: <NvSmartButton sx={{ width: '16px', height: '16px' }} />,
                name: 'Button block',
                onClick: () => {
                  const id = generateUniqueId();
                  const newButtonElement = {
                    ...WORKFLOW_REFERENCES_BLOCK_NEW_ELEMENT_DEFAULT_VALUE,
                    id,
                    appId: userAppId,
                  };
                  const newWorkflowReferencesBlock: WorkflowReferencesBlock = {
                    ...WORKFLOW_REFERENCES_BLOCK_DEFAULT_VALUE,
                    elements: [newButtonElement],
                  };
                  value ? onChange([...value, newWorkflowReferencesBlock]) : onChange([newWorkflowReferencesBlock]);
                },
              },
            ]}
          />
        }
      />
      {(!value || value.length === 0) && (
        <PropertyPanelSimpleSection>
          <NvTypography variant="body2" textColor="ghost">
            No blocks have been added yet.
          </NvTypography>
        </PropertyPanelSimpleSection>
      )}
      <DragDropContext onDragEnd={makeOnDragEndFunction}>
        <Droppable droppableId={`slack_blocks`}>
          {(provided) => (
            <NvBox ref={provided.innerRef}>
              {value?.map((block, index) => {
                return (
                  <Draggable key={`draggable_${index}`} draggableId={`${index}`} index={index}>
                    {(provided, snapshot) => (
                      <NvConditionalWrap
                        condition={snapshot.isDragging}
                        wrap={(children) => <Portal>{children}</Portal>}
                      >
                        <NvBox ref={provided.innerRef} {...provided.draggableProps} position="relative">
                          {block.type === SlackBlockTypes.MARKDOWN ? (
                            <MarkdownBlock
                              onDeleteClick={() => handleDeleteBlock({ index })}
                              dragHandleProps={provided.dragHandleProps}
                              block={block}
                              index={index}
                              onChange={handleOnChangeBlock}
                              context={context}
                            />
                          ) : (
                            <WorkflowReferenceBlock
                              onDeleteClick={() => handleDeleteBlock({ index })}
                              dragHandleProps={provided.dragHandleProps}
                              block={block}
                              index={index}
                              context={context}
                              onChange={handleOnChangeBlock}
                              title="Button block"
                            />
                          )}
                        </NvBox>
                      </NvConditionalWrap>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </NvBox>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};
