import { QUERY_KEYS_NODE, useGetWorkflow, useUpdateWorkflowGraph } from '@novaera/actioner-service';
import { useParams } from '@novaera/route';
import { useQueryClient } from '@tanstack/react-query';
import { userAppGraph } from '../../graph-utils/user-app-graph';
import { useNovaeraFlow } from '../../use-novaera-flow';
import { deleteNodeFromWorkflow } from './delete-node-from-workflow';

export const useNodeDeletion = ({ emptySelectedNode }: { emptySelectedNode: () => void }) => {
  const { deleteNode } = useNovaeraFlow(userAppGraph);
  const { userAppId, workflowId } = useParams();
  const { mutate: updateWorkflowGraph } = useUpdateWorkflowGraph();
  const { workflow } = useGetWorkflow({ appId: userAppId, workflowId });
  const cache = useQueryClient();
  const deleteNodeAndUpdateGraph = ({ nodeId }: { nodeId: string }) => {
    return new Promise<void>((resolve) => {
      const newWorkflow = deleteNodeFromWorkflow({ workflow, deletedNodeId: nodeId });
      emptySelectedNode();
      updateWorkflowGraph(
        {
          appId: userAppId,
          workflowId,
          payload: {
            graph: { vertices: newWorkflow.graph?.vertices || [] },
            updates: {
              nodes: Object.values(newWorkflow.nodeSummaries || {}),
            },
          },
        },
        {
          onSuccess: (response) => {
            response.draft && deleteNode({ newWorkflow: response.draft });
            cache.removeQueries({
              queryKey: QUERY_KEYS_NODE.detail({ appId: userAppId, workflowId, nodeAlias: nodeId }),
            });
          },
          onSettled: () => {
            resolve();
          },
        }
      );
    });
  };
  return { deleteNodeAndUpdateGraph };
};
