import { ROUTES } from '../../common/routes';
import { Integrations } from '../../integrations/list';
import { Billing } from '../billing';
import { WorkspaceInfo } from '../info';
import { SlackWorkspace } from '../slack-workspace/ui';

const WORKSPACE_SETTINGS_LINK = ROUTES.Workspace;

export const WORKSPACE_SETTINGS_TAB_PATHS = {
  INFO: '/info',
  BILLING: '/billing-and-plans',
  SLACK_WORKSPACE: '/slack',
  INTEGRATIONS: '/integrations',
};

export const WORKSPACE_SETTINGS_TABS = [
  {
    label: 'Workspace info',
    content: <WorkspaceInfo />,
    path: `${WORKSPACE_SETTINGS_LINK}${WORKSPACE_SETTINGS_TAB_PATHS.INFO}`,
  },
  {
    label: 'Slack workspace',
    content: <SlackWorkspace />,
    path: `${WORKSPACE_SETTINGS_LINK}${WORKSPACE_SETTINGS_TAB_PATHS.SLACK_WORKSPACE}`,
  },
  {
    label: 'Billing and plans',
    content: <Billing />,
    path: `${WORKSPACE_SETTINGS_LINK}${WORKSPACE_SETTINGS_TAB_PATHS.BILLING}`,
  },
  {
    label: 'Custom integrations',
    content: <Integrations />,
    path: `${WORKSPACE_SETTINGS_LINK}${WORKSPACE_SETTINGS_TAB_PATHS.INTEGRATIONS}`,
  },
];

export const DEFAULT_ROOT_PATH = WORKSPACE_SETTINGS_TABS[0].path;
