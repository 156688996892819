import { FetchProfileResponse } from '@novaera/actioner-service';
import { useAnalytics } from '@novaera/core';
import { useEffect, useRef } from 'react';

export const useAnalyticsIdentifier = ({ enabled, user }: { enabled: boolean; user?: FetchProfileResponse }) => {
  const isSent = useRef<boolean>(false);
  const analytics = useAnalytics();
  useEffect(() => {
    if (enabled && !isSent.current && user && analytics) {
      isSent.current = true;
      analytics.identify(user.userId, {
        name: user.name,
        email: user.email,
      });
    }
  }, [analytics, enabled, isSent, user]);
};
