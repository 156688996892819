import { AuthenticationField } from '@novaera/actioner-service';
import {
  BasicTableCell,
  BasicTableRow,
  NvBox,
  NvButton,
  NvCloseIcon,
  NvCustomCheckIcon,
  NvEditIcon,
  NvTypography,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { useFieldItemController } from './controllers/use-field-item-controller';

export const FieldItem = ({
  authenticationField,
  rowIndex,
}: {
  authenticationField: AuthenticationField;
  rowIndex: number;
}) => {
  const theme = useTheme();
  const { onEditClicked, onRemoveClicked } = useFieldItemController();
  const { inputType, key, label, required } = authenticationField;

  return (
    <BasicTableRow key={key}>
      <BasicTableCell align="left">{label}</BasicTableCell>
      <BasicTableCell align="left">
        <NvBox component="span" color={theme.palette.nv_neutral_alpha[600]}>
          auth.
        </NvBox>
        <NvTypography variant="c2">{key}</NvTypography>
      </BasicTableCell>
      <BasicTableCell align="left">{inputType}</BasicTableCell>
      <BasicTableCell align="left">
        {required ? <NvCustomCheckIcon htmlColor={theme.palette.nv_success[60]} /> : ''}
      </BasicTableCell>
      <BasicTableCell align="left" size="small">
        <NvBox display="flex" flexDirection="row" gap="8px">
          <NvButton
            size="small"
            onlyIcon
            color="secondary"
            onClick={() => {
              onEditClicked(authenticationField, rowIndex);
            }}
          >
            <NvEditIcon fontSize="small" />
          </NvButton>
          <NvButton
            size="small"
            onlyIcon
            color="secondary"
            onClick={() => {
              onRemoveClicked(rowIndex);
            }}
          >
            <NvCloseIcon fontSize="small" />
          </NvButton>
        </NvBox>
      </BasicTableCell>
    </BasicTableRow>
  );
};
