export enum Themes {
  Default = 'Default',
  Alternative = 'Alternative',
  Dracula = 'Dracula',
  Winter = 'Winter',
}

export enum ThemeMode {
  Light = 'light',
  Dark = 'dark',
}

enum NEUTRAL_COLORS {
  N0 = '0',
  N5 = '5',
  N10 = '10',
  N20 = '20',
  N30 = '30',
  N40 = '40',
  N50 = '50',
  N60 = '60',
  N70 = '70',
  N80 = '80',
  N90 = '90',
  N100 = '100',
  N200 = '200',
  N300 = '300',
  N400 = '400',
  N500 = '500',
  N600 = '600',
  N700 = '700',
  N800 = '800',
  N900 = '900',
  N1000 = '1000',
}

enum NEUTRAL_ALPHA_COLORS {
  N10A = '10',
  N20A = '20',
  N30A = '30',
  N40A = '40',
  N50A = '50',
  N60A = '60',
  N70A = '70',
  N80A = '80',
  N90A = '90',
  N100A = '100',
  N200A = '200',
  N300A = '300',
  N400A = '400',
  N500A = '500',
  N600A = '600',
  N700A = '700',
  N800A = '800',
  N900A = '900',
}
enum MAIN_COLORS {
  M10 = '10',
  M20 = '20',
  M30 = '30',
  M40 = '40',
  M50 = '50',
  M60 = '60',
}
enum ACCENT_COLORS {
  A10 = '10',
  A20 = '20',
  A30 = '30',
  A40 = '40',
  A50 = '50',
  A60 = '60',
}
enum ERROR_COLORS {
  E10 = '10',
  E20 = '20',
  E30 = '30',
  E40 = '40',
  E50 = '50',
  E60 = '60',
}
enum WARNING_COLORS {
  W10 = '10',
  W20 = '20',
  W30 = '30',
  W40 = '40',
  W50 = '50',
  W60 = '60',
}
enum SUCCESS_COLORS {
  S10 = '10',
  S20 = '20',
  S30 = '30',
  S40 = '40',
  S50 = '50',
  S60 = '60',
}
enum NEW_COLORS {
  N10 = '10',
  N20 = '20',
  N30 = '30',
  N40 = '40',
  N50 = '50',
  N60 = '60',
}
enum CODE_COLORS {
  BROWN = 'brown',
  PURPLE = 'purple',
  GREEN = 'green',
  BLUE = 'blue',
  ORANGE = 'orange',
}
enum NODE_COLORS {
  OUTPUT = 'output',
  FUNCTION = 'function',
  TRIGGER = 'trigger',
  OPERATOR = 'operator',
  ACTION = 'action',
  WORKFLOW = 'workflow',
  EMITTER = 'emitter',
  RESPONSE = 'response',
  AI = 'ai',
  SEND_EMAIL = 'sendEmail',
  ACTIONER_EVENT = 'actionerEvent',
  JOB = 'job',
  FILE = 'file',
  LINK_GENERATOR = 'linkGenerator',
}

export type Colors<T> = {
  nv_neutral: Record<NEUTRAL_COLORS, T>;
  nv_neutral_alpha: Record<NEUTRAL_ALPHA_COLORS, T>;
  nv_main: Record<MAIN_COLORS, T>;
  nv_accent: Record<ACCENT_COLORS, T>;
  nv_error: Record<ERROR_COLORS, T>;
  nv_warning: Record<WARNING_COLORS, T>;
  nv_success: Record<SUCCESS_COLORS, T>;
  nv_new: Record<NEW_COLORS, T>;
  nv_code: Record<CODE_COLORS, T>;
  nv_node: Record<NODE_COLORS, T>;
};

enum Elevation {
  E100 = 'e100',
  E200 = 'e200',
  E300 = 'e300',
  E400 = 'e400',
  E500 = 'e500',
}

export type Elevations = Record<Elevation, string>;
declare module '@mui/material/styles' {
  interface Theme {
    elevations: Elevations;
  }

  interface TypographyVariants {
    h7?: React.CSSProperties;
    h8?: React.CSSProperties;
    body3?: React.CSSProperties;
    c1?: React.CSSProperties;
    c2?: React.CSSProperties;
    c3?: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    h7?: React.CSSProperties;
    h8?: React.CSSProperties;
    body3?: React.CSSProperties;
    c1?: React.CSSProperties;
    c2?: React.CSSProperties;
    c3?: React.CSSProperties;
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface PaletteOptions extends Colors<string> {}
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Palette extends Colors<string> {}
}
type ShadowMixin = {
  boxShadow: string;
};
declare module '@mui/material/styles/createMixins' {
  interface Mixins {
    focus: ShadowMixin;
    error: ShadowMixin;
    success: ShadowMixin;
    itemSelected: ShadowMixin;
  }
}

declare module '@mui/material/styles/zIndex' {
  interface ZIndex {
    navigation: number;
  }
}
