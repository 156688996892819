import { NvAxios, useQuery } from '@novaera/core';
import { USER_APP_ROOT_PATH } from '../constants';
import { QUERY_KEYS_USER_APP } from '../keys';
import { GetUserAppParams, GetUserAppResponse } from '../types';

export const getUserApp = async (userAppId: GetUserAppParams) => {
  const result = await NvAxios.get<GetUserAppResponse>(`${USER_APP_ROOT_PATH}/${userAppId}`);
  return result?.data;
};

export const useGetUserApp = (userAppId: GetUserAppParams) => {
  return useQuery<GetUserAppResponse>(QUERY_KEYS_USER_APP.detail(userAppId), () => getUserApp(userAppId), {
    enabled: !!userAppId,
  });
};
