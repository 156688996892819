import { useGetSlackApp } from '@novaera/actioner-service';
import { NvCompletionState, NvErrorIcon, NvFlex } from '@novaera/core';
import { useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';

export const RemoveStep: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { palette } = useTheme();
  const { packageId } = useParams();
  const { data: slackApp } = useGetSlackApp({ appId: packageId });

  return (
    <NvFlex alignItems="center">
      <NvCompletionState
        primaryText={`You are about to remove ${slackApp?.slackAppManifest.displayInfo.name} Slack app`}
        secondaryText={
          <>
            If you remove <strong>{slackApp?.slackAppManifest.displayInfo.name}</strong> app your incident app will
            still work in Actioner on Slack. However, Workflows may need to listen to certain Slack channels, and you
            need to <strong>invite Actioner</strong> on those channels for them to work properly
          </>
        }
        icon={<NvErrorIcon htmlColor={palette.nv_error[40]} sx={{ width: '40px', height: '40px' }} />}
      />
    </NvFlex>
  );
};
