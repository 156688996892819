import { NvButton, NvCustomGiftIcon, NvImage, NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../common/routes';
import { POLL_APP_ID, POLL_APP_NAME } from './constants';
import { FreeAppBody, FreeAppBox, FreeAppHeader, FreeAppWrapper, StyledImage } from './styled';

export const FreeAppBanner: FC = () => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  return (
    <FreeAppWrapper>
      <FreeAppBox>
        <FreeAppHeader>
          <NvCustomGiftIcon
            data-testid="trial-menu-button"
            sx={{ width: '12px', height: '12px' }}
            htmlColor={palette.nv_new[60]}
          />
          <NvTypography variant="h7" color={palette.nv_neutral[0]}>
            FREE APP
          </NvTypography>
        </FreeAppHeader>
        <FreeAppBody>
          <StyledImage width="108px" height="108px" src="assets/poll-app-logo.png"></StyledImage>
          <NvImage size={'xlarge'} src="assets/poll-app-logo.png" imageShape="square" hasNoElevation FallBack={''} />
          <NvTypography variant="h2">Poll</NvTypography>
          <NvButton
            color="secondary"
            size="small"
            onClick={() => {
              navigate(`${ROUTES.AppDirectory}/${POLL_APP_NAME}/${POLL_APP_ID}`);
            }}
            sx={{ marginTop: '12px' }}
          >
            Install app
          </NvButton>
        </FreeAppBody>
      </FreeAppBox>
    </FreeAppWrapper>
  );
};
