import {
  QueryFunction,
  QueryKey,
  useQuery as originalUseQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { useMemo } from 'react';
import { useHandleCommonError } from '../use-handle-error';

export function useQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>
): UseQueryResult<TData, TError> {
  const { handleCommonError } = useHandleCommonError();

  const newOptions = useMemo(() => {
    if (options && options.onError) {
      const onErrorFn = options.onError;
      return {
        ...options,
        onError: (err: TError) => {
          try {
            onErrorFn?.(err);
          } catch (error) {
            handleCommonError(err);
          }
        },
      };
    } else {
      return {
        ...(options ?? {}),
        onError: (err: unknown) => {
          handleCommonError(err);
        },
      };
    }
  }, [handleCommonError, options]);

  return originalUseQuery(queryKey, queryFn, newOptions);
}
