import {
  CloneResponse,
  useDeleteWorkflow,
  useGetIntegrationUpdates,
  useGetWorkflow,
  usePersistWorkflow,
  useRollbackWorkflow,
  useUpdateWorkflowEnableState,
  useUpdateWorkflowMetadata,
  WorkflowState,
} from '@novaera/actioner-service';
import {
  isRequired,
  MenuItems,
  NvButton,
  NvConditionalRender,
  NvConditionalWrap,
  NvContentCopyIcon,
  NvDeleteOutlineIcon,
  NvDialogModal,
  NvField,
  NvFlex,
  NvSkeleton,
  NvTagsAutoComplete,
  NvTextArea,
  NvTextField,
  NvToggleOffIcon,
  NvToggleOnIcon,
  NvTooltip,
  NvTypography,
  NvUpgradeIcon,
  NvWorkflowIcon,
  useConfirmDialog,
  useNvDialogModalState,
} from '@novaera/core';
import { Navigate, Route, Routes, useNavigate, useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import { assert, usePathCondition } from '@novaera/utils';
import { useMemo, useState } from 'react';
import { Header } from '../../../components';
import { Clone } from '../../../components/clone';
import { CloneFlagger } from '../../../components/clone/flagger';
import { useIsAppFree } from '../../user-app-permission-boundary/free-app-permission-boundary/use-free-app-permission-boundary';
import { useWorkflowPermission } from '../../user-app-permission-boundary/use-workflow-permission';
import { USER_APP_WORKFLOWS } from '../constants';
import { WorkflowDesigner } from '../workflow-designer';
import { userAppGraph } from '../workflow-designer/user-app-workflow-canvas/graph-utils/user-app-graph';
import { PropertyPanelProvider } from '../workflow-designer/user-app-workflow-canvas/properties-panel/provider';
import {
  useWorkflowErrorStateProvider,
  WorkflowErrorStateProvider,
} from '../workflow-designer/user-app-workflow-canvas/providers/workflow-error-state-provider';
import { convertBackendToUi } from '../workflow-designer/user-app-workflow-canvas/service/get-nodes';
import { useRefreshCanvas } from '../workflow-designer/user-app-workflow-canvas/use-novaera-flow/use-refresh-canvas';
import { IntegrationUpdateModal } from '../workflow-designer/user-app-workflow-canvas/workflow-sticky-panel/integration-update/modal';
import { WorkflowHistory } from '../workflow-history';
import { WORKFLOW_DESIGNER, WORKFLOW_DETAIL_MODES, WORKFLOW_HISTORY } from './constants';

export const WorkflowDetailComponent: React.FC<React.PropsWithChildren<unknown>> = () => {
  const theme = useTheme();
  const { userAppId, workflowId } = useParams();
  const navigate = useNavigate();
  const { openConfirm } = useConfirmDialog();
  const [mode, setMode] = useState<WORKFLOW_DETAIL_MODES | undefined>();
  const { workflow, savedWorkflow, isLoading } = useGetWorkflow({ appId: userAppId, workflowId });
  const { mutate: persistWorkflow, isLoading: isSaveProgress } = usePersistWorkflow();
  const { mutate: deleteWorkflow, isLoading: isDeleteWorkflowLoading } = useDeleteWorkflow();
  const { mutate: rollbackWorkflow, isLoading: isDiscardProgress } = useRollbackWorkflow();
  const { mutate: updateWorkflowEnableState } = useUpdateWorkflowEnableState();
  const { refreshCanvas } = useRefreshCanvas(userAppGraph);
  const { mutate, isLoading: isMetadataUpdateLoading } = useUpdateWorkflowMetadata();
  const { isOpened, onModalCloseClicked, onModalOpenClicked } = useNvDialogModalState();
  const { data: integrationUpdates, isLoading: isGetIntegrationUpdatesLoading } = useGetIntegrationUpdates({
    appId: userAppId,
    workflowId,
  });

  const { isAppFree } = useIsAppFree({ userAppId });

  const {
    isOpened: isClonedModalOpened,
    onModalCloseClicked: onCloneModalCloseClicked,
    onModalOpenClicked: onCloneModalOpenClicked,
    passedParameters,
  } = useNvDialogModalState<{ id: string; name: string }>();

  const {
    isOpened: isUpdateIntegrationVersionModalOpened,
    onModalCloseClicked: onUpdateIntegrationVersionModalCloseClicked,
    onModalOpenClicked: onUpdateIntegrationVersionModalOpenClicked,
  } = useNvDialogModalState();

  const [clonedItem, setClonedItem] = useState<CloneResponse | undefined>();

  const { hasEditPermission } = useWorkflowPermission();

  const { hasErrorInGraph: isErrorExistInGraph } = useWorkflowErrorStateProvider();

  const isSaveDisabled = useMemo(() => {
    if (isSaveProgress || isDiscardProgress) {
      return true;
    }

    if (isErrorExistInGraph) {
      return true;
    }

    return false;
  }, [isDiscardProgress, isErrorExistInGraph, isSaveProgress]);

  usePathCondition([
    {
      do: () => {
        setMode(WORKFLOW_DETAIL_MODES.DESIGNER);
      },
      when: (location) => WORKFLOW_DESIGNER({ appId: userAppId, workflowId }) === location.pathname,
    },
    {
      do: () => {
        setMode(WORKFLOW_DETAIL_MODES.HISTORY);
      },
      when: (location) => location.pathname.includes(WORKFLOW_HISTORY({ appId: userAppId, workflowId })),
    },
  ]);

  const workflowDetailHeaderActions: MenuItems[] = useMemo(() => {
    const menuActions: (MenuItems & { right: boolean })[] = [
      {
        name: 'Clone',
        onClick: () => {
          assert(!!workflow, new Error('To clone a workflow, it must be loaded first'));

          onCloneModalOpenClicked({ id: workflow?.id, name: workflow?.name });
        },
        icon: <NvContentCopyIcon sx={{ width: '16px', height: '16px' }} />,
        right: !isAppFree,
      },
      {
        name: 'divider',
        isDivider: true,
        dividerProps: { sx: { marginTop: '4px !important', marginBottom: '4px !important' } },
        right: !isAppFree,
      },
      {
        name: 'Delete',
        isLoading: isDeleteWorkflowLoading,
        onClick: () => {
          openConfirm({
            title: `Delete ${savedWorkflow?.name ?? 'This workflow'}`,
            message: (
              <NvTypography variant="body1">
                <b>{savedWorkflow?.name ?? 'This workflow'}</b> will no longer be available for this app. Deleted
                workflows cannot be recovered.
              </NvTypography>
            ),
            onConfirm: () => {
              deleteWorkflow(
                { appId: userAppId, workflowId },
                {
                  onSuccess: () => {
                    navigate(USER_APP_WORKFLOWS(userAppId));
                  },
                }
              );
            },
          });
        },
        icon: <NvDeleteOutlineIcon color="error" fontSize="small" />,
        right: !isAppFree,
      },
    ].filter(({ right }) => right);

    if (isGetIntegrationUpdatesLoading) {
      return (
        [
          {
            name: (
              <NvFlex
                flexDirection={'row'}
                gap={'8px'}
                alignItems={'center'}
                justifyContent={'center'}
                alignContent={'center'}
                width={'100%'}
              >
                <NvSkeleton variant="rectangular" width={'24px'} />
                <NvSkeleton variant="rectangular" width={'100%'} />
              </NvFlex>
            ),
            right: true,
          },
          {
            name: 'divider',
            isDivider: true,
            dividerProps: { sx: { marginTop: '4px !important', marginBottom: '4px !important' } },
            right: true,
          },
          ...menuActions,
        ] satisfies (MenuItems & { right: boolean })[]
      ).filter(({ right }) => right);
    } else if (!isGetIntegrationUpdatesLoading && !integrationUpdates?.isAllIntegrationsUpdated) {
      const items = [
        {
          name: (
            <NvFlex
              flexDirection={'row'}
              gap={'8px'}
              alignItems={'center'}
              justifyContent={'center'}
              alignContent={'center'}
              width={'100%'}
            >
              <NvUpgradeIcon
                sx={{
                  width: '16px',
                  height: '16px',
                }}
              />
              <NvTypography variant="h5">Update available</NvTypography>
            </NvFlex>
          ),
          onClick: () => {
            onUpdateIntegrationVersionModalOpenClicked();
          },
          right: true,
        },
        {
          name: 'divider',
          isDivider: true,
          dividerProps: { sx: { marginTop: '4px !important', marginBottom: '4px !important' } },
          right: true,
        },
        ...menuActions,
      ];
      return items.filter(({ right }) => right);
    } else {
      return menuActions;
    }
  }, [
    isAppFree,
    isDeleteWorkflowLoading,
    isGetIntegrationUpdatesLoading,
    integrationUpdates?.isAllIntegrationsUpdated,
    workflow,
    onCloneModalOpenClicked,
    openConfirm,
    savedWorkflow?.name,
    deleteWorkflow,
    userAppId,
    workflowId,
    navigate,
    onUpdateIntegrationVersionModalOpenClicked,
  ]);

  return (
    <NvFlex position="relative" width="100%" height="100%">
      <PropertyPanelProvider>
        {({ setIsPanelOpen, setSelectedNode }) => (
          <>
            <NvConditionalRender when={hasEditPermission}>
              <Header
                key={`workflow-detail-header-${mode}`}
                name={savedWorkflow?.name ?? ''}
                icon={<NvWorkflowIcon />}
                actions={workflowDetailHeaderActions}
                onBack={USER_APP_WORKFLOWS(userAppId)}
                isDraft={workflow?.state === WorkflowState.DRAFT}
                buttonActions={
                  <>
                    <NvButton
                      size="small"
                      color="secondary"
                      loading={isDiscardProgress}
                      disabled={isSaveProgress || isDiscardProgress}
                      onClick={() => {
                        setSelectedNode(null);
                        setIsPanelOpen(false);
                        return new Promise<void>((resolve) => {
                          rollbackWorkflow(
                            { appId: userAppId, id: workflowId },
                            {
                              onSettled: (rolledBackWorkflow) => {
                                convertBackendToUi({ workflow: rolledBackWorkflow });
                                refreshCanvas({ keepSelected: false });
                                resolve();
                              },
                            }
                          );
                        });
                      }}
                    >
                      Discard changes
                    </NvButton>
                    <NvConditionalWrap
                      condition={isErrorExistInGraph && !isSaveProgress && !isDiscardProgress}
                      wrap={(children) => (
                        <NvTooltip
                          title={
                            <NvFlex direction="column">
                              <NvTypography variant="body1">
                                <b>Cannot apply workflow.</b>
                              </NvTypography>
                              <NvTypography variant="body1">
                                Please fix the configuration errors before saving.
                              </NvTypography>
                            </NvFlex>
                          }
                          variant="large"
                        >
                          {/** span is the hacky way to show tooltips for disabled buttons */}
                          <span>{children}</span>
                        </NvTooltip>
                      )}
                    >
                      <NvButton
                        size="small"
                        disabled={isSaveDisabled}
                        loading={isSaveProgress}
                        onClick={() => {
                          return new Promise<void>((resolve) => {
                            persistWorkflow(
                              { appId: userAppId, id: workflowId, forceInvalidateCache: true },
                              {
                                onSuccess: () => {
                                  setSelectedNode(null);
                                  setIsPanelOpen(false);
                                  refreshCanvas({ keepSelected: false });
                                },
                                onSettled: () => {
                                  resolve();
                                },
                              }
                            );
                          });
                        }}
                      >
                        Apply
                      </NvButton>
                    </NvConditionalWrap>
                  </>
                }
                mode={mode}
                modes={[
                  { label: 'Designer', value: WORKFLOW_DETAIL_MODES.DESIGNER },
                  { label: 'Run history', value: WORKFLOW_DETAIL_MODES.HISTORY },
                ]}
                onModeSwitch={(mode: string) => {
                  if (mode === WORKFLOW_DETAIL_MODES.DESIGNER) {
                    navigate(WORKFLOW_DESIGNER({ appId: userAppId, workflowId }));
                  } else if (mode === WORKFLOW_DETAIL_MODES.HISTORY) {
                    setSelectedNode(null);
                    setIsPanelOpen(false);
                    navigate(WORKFLOW_HISTORY({ appId: userAppId, workflowId }));
                  }
                }}
                isLoading={isLoading}
                {...(!isAppFree ? { onEditNameClick: onModalOpenClicked } : {})}
                {...(savedWorkflow && hasEditPermission && !isAppFree
                  ? {
                      enableStateSlot: (
                        <NvButton
                          size="small"
                          color="secondary"
                          startIcon={
                            savedWorkflow.disabled ? (
                              <NvToggleOffIcon htmlColor={theme.palette.nv_error[40]} />
                            ) : (
                              <NvToggleOnIcon htmlColor={theme.palette.nv_success[60]} />
                            )
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                            updateWorkflowEnableState({
                              appId: savedWorkflow.appId,
                              id: savedWorkflow.id,
                              enabled: savedWorkflow.disabled,
                            });
                          }}
                        >
                          {savedWorkflow.disabled ? 'Disabled' : 'Enabled'}
                        </NvButton>
                      ),
                    }
                  : {})}
              />
            </NvConditionalRender>
            <NvConditionalRender when={!hasEditPermission}>
              <Header
                showInformationLabel={false}
                key={`workflow-detail-header-${mode}`}
                name={savedWorkflow?.name ?? ''}
                icon={<NvWorkflowIcon />}
                actions={[
                  {
                    name: 'Clone',
                    onClick: () => {
                      assert(!!workflow, new Error('To clone a workflow, it must be loaded first'));

                      onCloneModalOpenClicked({ id: workflow?.id, name: workflow?.name });
                    },
                    icon: <NvContentCopyIcon sx={{ width: '16px', height: '16px' }} />,
                  },
                ]}
                onBack={() => {
                  navigate(USER_APP_WORKFLOWS(userAppId));
                }}
                isDraft={workflow?.state === WorkflowState.DRAFT}
                mode={WORKFLOW_DETAIL_MODES.DESIGNER}
                isLoading={isLoading}
                {...(savedWorkflow && hasEditPermission
                  ? {
                      enableStateSlot: (
                        <NvButton
                          size="small"
                          color="secondary"
                          startIcon={
                            savedWorkflow.disabled ? (
                              <NvToggleOffIcon htmlColor={theme.palette.nv_error[40]} />
                            ) : (
                              <NvToggleOnIcon htmlColor={theme.palette.nv_success[60]} />
                            )
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                            updateWorkflowEnableState({
                              appId: savedWorkflow.appId,
                              id: savedWorkflow.id,
                              enabled: savedWorkflow.disabled,
                            });
                          }}
                        >
                          {savedWorkflow.disabled ? 'Disabled' : 'Enabled'}
                        </NvButton>
                      ),
                    }
                  : {})}
              />
            </NvConditionalRender>
            <NvDialogModal<{
              name?: string;
              description?: string;
              tags?: string[];
              searchable?: boolean;
            }>
              onCloseButtonClicked={onModalCloseClicked}
              open={isOpened}
              Header="Edit workflow"
              formProps={{
                initialValues: {
                  name: savedWorkflow?.name,
                  description: savedWorkflow?.description,
                  tags: savedWorkflow?.tags,
                  searchable: !savedWorkflow?.searchInvisibleByDefault,
                },
              }}
              maxWidth="sm"
              fullWidth
              primaryButtonText="Update"
              onFormSubmit={(values) => {
                assert(!!workflow?.id, new Error('Workflow id is not defined'));
                assert(!!values?.name, new Error('Workflow name can not be empty'));

                mutate(
                  {
                    appId: userAppId,
                    workflowId: workflow.id,
                    payload: {
                      name: values.name,
                      description: values.description,
                      tags: values.tags,
                      searchInvisibleByDefault: !values.searchable,
                    },
                  },
                  {
                    onSuccess: () => {
                      onModalCloseClicked();
                    },
                  }
                );
              }}
              isLoading={isMetadataUpdateLoading}
              Body={
                <>
                  <NvField
                    name="name"
                    labelText="Name"
                    labelVariant="h5"
                    direction="label-on-top"
                    showErrorMessageOnlyWhenBlur
                    component={<NvTextField size="medium" />}
                    validators={[isRequired()]}
                    hasRequiredIndicator
                  />

                  <NvField
                    direction="label-on-top"
                    name="description"
                    labelText="Description"
                    labelVariant="h5"
                    showErrorMessageOnlyWhenBlur
                    component={<NvTextArea rows={4} />}
                  />
                  <NvField
                    direction="label-on-top"
                    name="tags"
                    labelText="Tags"
                    labelVariant="h5"
                    showErrorMessageOnlyWhenBlur
                    isAutoComplete
                    component={<NvTagsAutoComplete placeholder={'Add tags'} />}
                  />
                </>
              }
            />
            {isUpdateIntegrationVersionModalOpened && (
              <IntegrationUpdateModal
                isModalOpened={isUpdateIntegrationVersionModalOpened}
                onModalCloseClicked={onUpdateIntegrationVersionModalCloseClicked}
              />
            )}
            <Routes>
              <Route
                path="designer"
                element={<WorkflowDesigner isSaveProgress={isSaveProgress || isDiscardProgress} />}
              />
              <Route path="history/*" element={<WorkflowHistory />}>
                <Route path=":workflowExecutionId" element={<WorkflowHistory />} />
              </Route>
              <Route path="*" element={<Navigate to="designer" />} />
            </Routes>
            {passedParameters?.id && passedParameters.name && (
              <Clone
                isOpened={isClonedModalOpened}
                appId={userAppId}
                workflowId={passedParameters.id}
                name={passedParameters.name}
                onModalCloseClicked={onCloneModalCloseClicked}
                type="workflows"
                onCloneSuccess={(param) => {
                  setClonedItem(param);
                }}
              />
            )}
            {clonedItem && (
              <CloneFlagger
                opened={!!clonedItem}
                clonedItem={clonedItem}
                onFlaggerClosed={() => {
                  setClonedItem(undefined);
                }}
                onOpenClicked={() => {
                  setSelectedNode(null);
                  setIsPanelOpen(false);
                }}
                type={'workflows'}
              />
            )}
          </>
        )}
      </PropertyPanelProvider>
    </NvFlex>
  );
};

export const WorkflowDetail = () => (
  <WorkflowErrorStateProvider>
    <WorkflowDetailComponent />
  </WorkflowErrorStateProvider>
);
