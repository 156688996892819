import { usePathCondition, UsePathConditionParams } from '@novaera/utils';
import React, { useMemo, useState } from 'react';
import { Location, useNavigate } from 'react-router-dom';
import { NvFlex } from '../../flex';

import { NvIconLabel } from '../../icon-label';
import { NvTabsWrapper, Title } from './styled';
import { HeaderWithTabLayoutProps } from './types';

export const HeaderWithTabLayout: React.FC<HeaderWithTabLayoutProps> = ({ title, tabs, variant }) => {
  const [selectedTab, setSelectedTab] = useState<number>();
  const navigate = useNavigate();

  const conditions: UsePathConditionParams = useMemo(() => {
    const availablePathConditions = tabs.map((tab, index) => ({
      when: tab.path,
      do: () => setSelectedTab(index),
    }));

    const notAvailablePathCondition = {
      when: ({ pathname }: Location) => !tabs.find((tab) => tab.path === pathname),
      do: () => navigate('/error/404'),
    };

    return [...availablePathConditions, notAvailablePathCondition];
  }, [navigate, tabs]);

  usePathCondition(conditions);

  const handleActiveTabChanged = (activeIndex: number) => {
    const path = tabs[activeIndex].path;
    navigate(path);
  };

  return (
    <NvFlex alignItems="center" padding="0 40px 60px 40px" overflow="auto" height="100%">
      <NvFlex maxWidth={variant === 'center-aligned' ? '768px' : '100%'} width="100%">
        <NvFlex>
          {'label' in title ? (
            <Title>
              <NvIconLabel icon={title.icon} label={title.label} />
            </Title>
          ) : (
            title
          )}
        </NvFlex>
        <NvTabsWrapper onActiveTabChanged={handleActiveTabChanged} focusIndex={selectedTab} tabs={tabs} />
      </NvFlex>
    </NvFlex>
  );
};
