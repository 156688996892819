import { useSearchWorkflows } from '@novaera/actioner-service';
import {
  NvAutocomplete,
  NvCloseIcon,
  NvExpandMoreIcon,
  NvField,
  NvFlex,
  NvSkeleton,
  NvTextField,
  NvTypography,
  useDebounce,
} from '@novaera/core';
import { useMemo, useState } from 'react';
import { ActionSelectMenu, ActionSelectMenuItem } from '../action-select-menu';
import { WorkflowSearchFieldProps, WorkflowSearchOption } from './types';

export const WorkflowSearchField: React.FC<React.PropsWithChildren<WorkflowSearchFieldProps>> = ({
  appId,
  fieldName,
  fieldProps,
  searchWorkflowsParams,
}) => {
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const debouncedSearchKeyword = useDebounce(searchKeyword, 500);
  const { data: workflowSearchData, isInitialLoading: isSearchingWorkflows } = useSearchWorkflows({
    appIds: [appId],
    keyword: debouncedSearchKeyword,
    ...(searchWorkflowsParams ?? {}),
    includeSearchInvisible: true,
  });
  const workflows = useMemo(
    () => workflowSearchData?.workflows.map(({ id, name }) => ({ id, name })) ?? [],
    [workflowSearchData?.workflows]
  );

  return (
    <NvField<WorkflowSearchOption>
      name={fieldName}
      isAutoComplete
      parse={(value) => value ?? ''}
      format={(value) => value ?? null}
      showErrorMessageOnlyWhenBlur
      component={
        <NvAutocomplete
          sx={{ width: '100%' }}
          options={workflows}
          getOptionLabel={(option) => option.name ?? ''}
          renderInput={(props) => <NvTextField {...props} placeholder="Select workflow" />}
          PaperComponent={({ children, ...props }) => (
            <ActionSelectMenu {...props}>
              {isSearchingWorkflows ? (
                <NvFlex margin="8px" gap="8px">
                  <NvFlex direction="row" gap="4px">
                    <NvSkeleton variant="rectangular" width="20px" height="20px" />
                    <NvSkeleton variant="rectangular" width="40%" height="20px" />
                  </NvFlex>
                  <NvFlex direction="row" gap="4px">
                    <NvSkeleton variant="rectangular" width="20px" height="20px" />
                    <NvSkeleton variant="rectangular" width="60%" height="20px" />
                  </NvFlex>
                  <NvFlex direction="row" gap="4px">
                    <NvSkeleton variant="rectangular" width="20px" height="20px" />
                    <NvSkeleton variant="rectangular" width="30%" height="20px" />
                  </NvFlex>
                </NvFlex>
              ) : (
                children
              )}
            </ActionSelectMenu>
          )}
          renderOption={(props, option) => (
            <ActionSelectMenuItem {...props} key={option.id}>
              <NvFlex direction="row" alignItems="center" gap="8px" width="100%">
                <NvTypography variant="body1" noWrap>
                  {option.name}
                </NvTypography>
              </NvFlex>
            </ActionSelectMenuItem>
          )}
          isOptionEqualToValue={(option, value) => value && option.id === value.id}
          clearIcon={<NvCloseIcon sx={{ height: '12px', width: '12px' }} />}
          popupIcon={<NvExpandMoreIcon />}
          onInputChange={(event, value) => {
            setSearchKeyword(value);
          }}
          filterOptions={(o) => o}
        />
      }
      {...fieldProps}
    />
  );
};
