import { DataSource, DynamicInputSourceActionInIntegration, DynamicInputSourceActionInWorkflow } from './data-source';
import { SelectComponent } from './select-component';

export enum UIComponentType {
  CHECK_BOX = 'checkbox',
  CHECK_BOX_GROUP = 'checkboxGroup',
  DATE_PICKER = 'datePicker',
  HEADER = 'header',
  LINK = 'link',
  PLAIN_TEXT = 'plainText',
  RADIO_BUTTON_GROUP = 'radioButtonGroup',
  MULTI_SELECT = 'select',
  SINGLE_SELECT = 'singleSelect',
  TEXT_FIELD = 'text',
  TIME_PICKER = 'timePicker',
  DATE_TIME_PICKER = 'date-time-picker',
  BLOCK_LIST = 'blockList',
  RECORD_ATTRIBUTES = 'record-attributes',
  RECORD_UPDATE_ATTRIBUTES = 'record-update-attributes',
  RECORD_PARTIAL_UPDATE = 'record-partial-update',
  JOB_TARGET = 'job-target',
  DYNAMIC_INPUT = 'dynamic-input',
}

export interface UIComponent {
  type: UIComponentType;
}

export interface InputUIComponent extends UIComponent {
  label: string;
  hint?: string;
}

export interface CheckboxComponent extends InputUIComponent {
  type: UIComponentType.CHECK_BOX;
}

export interface CheckboxGroupComponent extends InputUIComponent {
  type: UIComponentType.CHECK_BOX_GROUP;
  dataSource: DataSource;
}

export interface DatePickerComponent extends InputUIComponent {
  type: UIComponentType.DATE_PICKER;
}

export interface HeaderComponent extends UIComponent {
  type: UIComponentType.HEADER;
  text: string;
  level: 'H1' | 'H2' | 'H3' | 'H4' | 'H5' | 'H6';
}

export interface LinkComponent extends InputUIComponent {
  type: UIComponentType.LINK;
  url: string;
}

export interface PlainTextComponent extends UIComponent {
  type: UIComponentType.PLAIN_TEXT;
  text: string;
  level: 'P1' | 'P2' | 'P3';
}

export interface RadioButtonGroupComponent extends InputUIComponent {
  type: UIComponentType.RADIO_BUTTON_GROUP;
  dataSource: DataSource;
}

export interface MultiSelectComponent extends SelectComponent {
  type: UIComponentType.MULTI_SELECT;
}
export interface SingleSelectComponent extends SelectComponent {
  type: UIComponentType.SINGLE_SELECT;
}

export interface TextInputComponent extends InputUIComponent {
  type: UIComponentType.TEXT_FIELD;
  placeHolder?: string;
  multiLine?: boolean;
}

export interface TimePickerComponent extends InputUIComponent {
  type: UIComponentType.TIME_PICKER;
}

export interface DateTimePickerComponent extends InputUIComponent {
  type: UIComponentType.DATE_TIME_PICKER;
}

export interface BlockListUIComponent extends InputUIComponent {
  type: UIComponentType.BLOCK_LIST;
}

export interface JobTargetUIComponent extends InputUIComponent {
  type: UIComponentType.JOB_TARGET;
}

export interface RecordAttributesUIComponent extends InputUIComponent {
  type: UIComponentType.RECORD_ATTRIBUTES;
}

export interface RecordUpdateAttributesUIComponent extends InputUIComponent {
  type: UIComponentType.RECORD_UPDATE_ATTRIBUTES;
}

export interface RecordPartialUpdateUIComponent extends InputUIComponent {
  type: UIComponentType.RECORD_PARTIAL_UPDATE;
}

export interface DynamicInputComponent extends InputUIComponent {
  type: UIComponentType.DYNAMIC_INPUT;
  dataSource: DynamicInputSourceActionInIntegration | DynamicInputSourceActionInWorkflow;
}

export type UIComponentTypes =
  | CheckboxComponent
  | CheckboxGroupComponent
  | DatePickerComponent
  | HeaderComponent
  | LinkComponent
  | PlainTextComponent
  | RadioButtonGroupComponent
  | MultiSelectComponent
  | SingleSelectComponent
  | TextInputComponent
  | TimePickerComponent
  | DateTimePickerComponent
  | BlockListUIComponent
  | RecordAttributesUIComponent
  | RecordUpdateAttributesUIComponent
  | RecordPartialUpdateUIComponent
  | JobTargetUIComponent
  | DynamicInputComponent;

export const isCustomComponent = (componentType: UIComponentType) => {
  return [UIComponentType.BLOCK_LIST].includes(componentType);
};

export const isInputComponent = (componentType: UIComponentTypes | UIComponent): componentType is InputUIComponent => {
  return [
    UIComponentType.CHECK_BOX,
    UIComponentType.CHECK_BOX_GROUP,
    UIComponentType.DATE_PICKER,
    UIComponentType.LINK,
    UIComponentType.PLAIN_TEXT,
    UIComponentType.RADIO_BUTTON_GROUP,
    UIComponentType.MULTI_SELECT,
    UIComponentType.SINGLE_SELECT,
    UIComponentType.TEXT_FIELD,
    UIComponentType.TIME_PICKER,
    UIComponentType.DATE_TIME_PICKER,
    UIComponentType.DYNAMIC_INPUT,
  ].includes(componentType.type);
};
