import { AvailableConnection, ConnectionMapping } from '@novaera/actioner-service';
import { assert } from '@novaera/utils';
import { useMemo } from 'react';
import { ConnectionState, OnlyOneSlack } from '../types';

export const useConnectionState = ({ connectionMapping }: { connectionMapping: ConnectionMapping }) => {
  const connectionState: ConnectionState = useMemo(() => {
    if (connectionMapping.connections) {
      if (connectionMapping.connections.length > 1) {
        assert(!connectionMapping.slackConnection, new Error('Slack cannot have multiple connection'), 'ERROR');
        if (connectionMapping.connected) {
          const connectedConnection = connectionMapping.connections.find(
            (connection) => connection.id === connectionMapping.connectionId
          );
          assert(
            !!connectedConnection,
            new Error(
              'When connected is true there should be a connectionId which has a corresponding connection instance in connections'
            ),
            'ERROR'
          );
          return {
            type: 'multiple',
            connected: true,
            connections: connectionMapping.connections,
            connectedConnection,
          };
        } else {
          return {
            type: 'multiple',
            connected: false,
            connections: connectionMapping.connections,
          };
        }
      } else if (connectionMapping.connections.length === 1) {
        assert(
          !!connectionMapping.connected,
          new Error('When there is only one connection the connection mapping should be connected'),
          'ERROR'
        );

        if (connectionMapping.slackConnection) {
          const connectedConnection = { name: connectionMapping.connections[0].name };
          const a: OnlyOneSlack = {
            type: 'only-one-slack',
            connected: true,
            connectedConnection,
          };

          return a;
        } else {
          const connectedConnection: AvailableConnection = {
            id: connectionMapping.connections[0].id,
            name: connectionMapping.connections[0].name,
          };
          return {
            type: 'only-one',
            connected: true,
            connectedConnection: connectedConnection,
          };
        }
      } else {
        return {
          connected: false,
          type: 'no-connection',
        };
      }
    } else {
      assert(
        !connectionMapping.connected,
        new Error('The connection mapping is connected even there is no connection in the connection list'),
        'ERROR'
      );

      return {
        connected: false,
        type: 'no-connection',
      };
    }
  }, [connectionMapping]);

  return { connectionState };
};
