import { GetSwitchTypeParams } from './use-get-switch-type/types';

export const QUERY_KEYS_SUBSCRIPTION = {
  all: ['subscription'] as const,
};

export const QUERY_KEYS_BILLING = {
  all: ['billing'] as const,
};

export const QUERY_KEYS_BILLING_PREFERENCES = {
  all: ['billing-preferences'] as const,
};

export const QUERY_KEYS_SWITCH_TYPE = {
  all: ['switch-type'] as const,
  detail: (params: GetSwitchTypeParams) =>
    [
      ...QUERY_KEYS_SWITCH_TYPE.all,
      'detail',
      params.fromPlanId,
      params.fromBillingCycle,
      params.toPlanId,
      params.toBillingCycle,
    ] as const,
};
