import { AssistantDocument, AssistantWorkflow } from '@novaera/actioner-service';
import {
  FieldArray,
  NvAddBoxIcon,
  NvArrowForwardIcon,
  NvButton,
  NvFlex,
  NvPopover,
  NvSearchEmptyState,
} from '@novaera/core';
import { useParams } from '@novaera/route';
import { noop } from 'lodash';
import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import React, { useState } from 'react';
import { SimpleSearchInput } from '../../../../components';
import { FreeAppPermissionBoundary } from '../../../user-app-permission-boundary/free-app-permission-boundary';
import { useIsAppFree } from '../../../user-app-permission-boundary/free-app-permission-boundary/use-free-app-permission-boundary';
import { NodeListItemsWrapper } from '../styled';
import { AssistantListItemRightComponent } from './assistant-list-item/right-component';
import { AssistantListItem } from './assistant-list-item/styled';

export const AssistantList = <T extends AssistantWorkflow | AssistantDocument>({
  name,
  type,
  SelectComponent,
  isAdditionalActionsOpen,
  additionalActions,
  onGoTo,
  onDelete,
}: {
  name: string;
  type: 'workflow' | 'document';
  SelectComponent: (params: { items: T[]; successCallback: (newItem: T) => void }) => React.ReactNode;
  isAdditionalActionsOpen?: Record<string, boolean>;
  additionalActions?: (item: T) => React.ReactNode;
  onGoTo: (id: string) => void;
  onDelete: (params: { id: string; successCallback: () => void }) => void;
}) => {
  const { userAppId } = useParams();
  const { isAppFree } = useIsAppFree({ userAppId });
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const popupState = usePopupState({
    variant: 'popover',
    popupId: `add-assistant-${type}-popup`,
  });

  return (
    <FieldArray<T> name={name}>
      {({ fields }) => {
        const items = fields.value ?? [];
        const filteredItems = items.filter(({ name }) => name.includes(searchKeyword));

        return (
          <>
            <NvPopover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              sx={{ marginTop: '8px' }}
            >
              {SelectComponent({
                items,
                successCallback: (newItem) => {
                  fields.push(newItem);
                  popupState.close();
                },
              })}
            </NvPopover>

            {items.length === 0 ? (
              <FreeAppPermissionBoundary appId={userAppId}>
                <NvButton
                  startIcon={<NvAddBoxIcon />}
                  size="small"
                  color="secondary"
                  {...bindTrigger(popupState)}
                  isSelected={popupState.isOpen}
                >
                  Add {type}
                </NvButton>
              </FreeAppPermissionBoundary>
            ) : (
              <NvFlex gap="10px" width="100%">
                <NvFlex direction="row" alignItems="center" justifyContent="space-between">
                  <SimpleSearchInput
                    onKeywordChanged={(keyword) => {
                      setSearchKeyword(keyword ?? '');
                    }}
                    sx={{ minWidth: 220 }}
                  />
                  <FreeAppPermissionBoundary appId={userAppId}>
                    <NvButton
                      startIcon={<NvAddBoxIcon />}
                      size="small"
                      color="ghost"
                      {...bindTrigger(popupState)}
                      isSelected={popupState.isOpen}
                    >
                      Add {type}
                    </NvButton>
                  </FreeAppPermissionBoundary>
                </NvFlex>
                <NodeListItemsWrapper>
                  {filteredItems.length === 0 && (
                    <NvSearchEmptyState text={`No ${type}s found. Try different words or clear search bar.`} />
                  )}
                  {filteredItems.map((item, index) => (
                    <AssistantListItem
                      key={`ai-assistant-${type}-${item.id}`}
                      name={item.name}
                      onItemClick={noop}
                      rightComponent={
                        <AssistantListItemRightComponent
                          isOpen={isAdditionalActionsOpen?.[item.id]}
                          actions={
                            <>
                              <NvButton
                                color="secondary"
                                size="small"
                                endIcon={<NvArrowForwardIcon />}
                                onClick={() => onGoTo(item.id)}
                              >
                                Go to {type}
                              </NvButton>
                              <FreeAppPermissionBoundary appId={userAppId}>
                                {additionalActions?.(item)}
                              </FreeAppPermissionBoundary>
                            </>
                          }
                          {...(!isAppFree
                            ? {
                                onDelete: () => {
                                  onDelete({
                                    id: item.id,
                                    successCallback: () => {
                                      fields.remove(index);
                                    },
                                  });
                                },
                              }
                            : {})}
                        />
                      }
                      extraData={{ isCompact: true }}
                    />
                  ))}
                </NodeListItemsWrapper>
              </NvFlex>
            )}
          </>
        );
      }}
    </FieldArray>
  );
};
