import { useGetUserApp, useGetUserAppSetupStatus, useUpdateUserAppUserPreferences } from '@novaera/actioner-service';
import { NvBox, NvButton, NvCloudUploadIcon, NvFlex, NvFocusState, NvTooltip, SectionMessage } from '@novaera/core';
import { useMatch, useNavigate } from '@novaera/route';
import { usePathCondition } from '@novaera/utils';
import { FC, PropsWithChildren, useRef, useState } from 'react';
import { ROUTES } from '../../../common/routes';
import { USER_APP_DESCRIPTION, USER_APP_SETUP } from '../constants';
import { InstallSteps } from './install-steps';
import { CompleteAppSetupWrapper } from './styled';

export const InstallUserAppFlow: FC<PropsWithChildren<{ appId: string }>> = ({ appId }) => {
  const navigate = useNavigate();
  const dismissButtonRef = useRef<HTMLDivElement | null>(null);
  const [isFocusStateOpen, setFocusStateOpen] = useState<boolean>(false);
  const [openTriggeredFromButton, setOpenTriggeredFromButton] = useState<boolean>();
  const { data: userApp } = useGetUserApp(appId);
  const { mutate: updateUserAppUserPreferences, isLoading: isUpdateUserAppUserPreferencesLoading } =
    useUpdateUserAppUserPreferences();

  const isAppSetupRoute = useMatch(ROUTES.UserAppSetup);

  usePathCondition([
    {
      do: () => {
        setFocusStateOpen(true);
      },
      when: () => {
        return !!isAppSetupRoute;
      },
    },
    {
      do: () => {
        setFocusStateOpen(false);
        setOpenTriggeredFromButton(false);
      },
      when: () => !openTriggeredFromButton && !isAppSetupRoute,
    },
  ]);

  const { data, isLoading, refetch } = useGetUserAppSetupStatus({
    appId,
  });

  const handleClose = () => {
    setFocusStateOpen(false);
    setOpenTriggeredFromButton(false);
    navigate(USER_APP_DESCRIPTION(appId));
    refetch();
  };

  return (
    <>
      {!isLoading &&
        !(data?.dismissed || data?.setupWorkflowsCompleted) &&
        userApp?.metadata.schemaId &&
        userApp.metadata.creationSource !== 'scratch' && (
          <NvFlex alignItems="center" justifyContent="center" width="100%" padding="24px 40px 0 40px">
            <CompleteAppSetupWrapper>
              <NvBox ref={dismissButtonRef}>
                <SectionMessage
                  message="There are missing configurations. You need to complete the setup process before starting to use this app."
                  variant="info"
                  actionButton={
                    <NvFlex direction="row" gap="8px" alignItems="center">
                      <NvButton
                        size="small"
                        onClick={() => {
                          navigate(USER_APP_SETUP(appId));
                        }}
                      >
                        Complete app setup
                      </NvButton>
                      <NvTooltip title={'You can access the setup guide later if you close this banner.'}>
                        <NvButton
                          color="secondary"
                          size="small"
                          onClick={() => {
                            updateUserAppUserPreferences({ appId, setupDismissed: true });
                          }}
                          disabled={isUpdateUserAppUserPreferencesLoading}
                          loading={isUpdateUserAppUserPreferencesLoading}
                        >
                          Close
                        </NvButton>
                      </NvTooltip>
                    </NvFlex>
                  }
                  subtle
                />
              </NvBox>
            </CompleteAppSetupWrapper>
          </NvFlex>
        )}

      <NvFocusState
        open={isFocusStateOpen}
        headerLogo={<NvCloudUploadIcon fontSize="large" />}
        title={userApp?.name ? `Setup ${userApp?.name} app` : ''}
        onClose={handleClose}
      >
        <InstallSteps appId={appId} onCancel={handleClose} />
      </NvFocusState>
    </>
  );
};
