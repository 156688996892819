import { useGetWorkflow, WorkflowState } from '@novaera/actioner-service';
import { useGetWorkflowQueryParams } from '../../../../../../controllers/use-get-workflow-query-params';

export const useRunWorkflowFormBody = ({ workflowId }: { workflowId: string }) => {
  const { userAppId } = useGetWorkflowQueryParams();
  const { workflow } = useGetWorkflow({ appId: userAppId, workflowId });

  return {
    isDraft: workflow?.state === WorkflowState.DRAFT,
    userAppId,
  };
};
