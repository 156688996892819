import {
  MarkdownOutputComponent,
  OutputNode,
  SlackBlocks,
  useUpdateNode,
  WorkflowState,
} from '@novaera/actioner-service';
import { NvForm } from '@novaera/core';
import { noop } from '@novaera/utils';
import { FC, useCallback } from 'react';
import { SlackBlocksComponent } from '../../../../../../../../../components/ui-components/slack-blocks-component';
import { FieldInputType } from '../../../../../../../../../components/ui-components/use-ui-component/types';
import { useGetWorkflowContexts } from '../../../../../controllers/use-get-workflow-contexts';
import { StyledNvField } from './styled';
import { MarkdownPanelSectionProps } from './types';

export const MarkdownPanelSection: FC<React.PropsWithChildren<MarkdownPanelSectionProps>> = ({
  markdownOutputComponent,
  alias,
  name,
  userAppId,
  workflowId,
}) => {
  const { mutate: updateNodeDetail } = useUpdateNode();
  const { workflowCodeInputContext } = useGetWorkflowContexts();

  const handleSave = useCallback(
    (newMarkdownOutputComponent: MarkdownOutputComponent) => {
      const newNode: OutputNode = {
        alias,
        name,
        outputComponent: newMarkdownOutputComponent,
        type: 'output',
        state: WorkflowState.DRAFT,
      };

      updateNodeDetail({
        appId: userAppId,
        workflowId,
        nodeAlias: alias,
        payload: newNode,
      });
    },
    [alias, name, updateNodeDetail, userAppId, workflowId]
  );

  return (
    <NvForm<MarkdownOutputComponent>
      onSubmit={noop}
      onChange={({ values }) => {
        handleSave(values);
      }}
      initialValues={markdownOutputComponent}
      keepDirtyOnReinitialize
      autoSaveProps={{ autoSaveType: 'debounce', debounceDelay: 500 }}
    >
      <StyledNvField
        component={(props) => {
          const actualPropsType = props as unknown as FieldInputType<SlackBlocks>;
          return <SlackBlocksComponent context={workflowCodeInputContext} inputProps={actualPropsType} />;
        }}
        name={'blocks'}
        defaultValue={[]}
      />
    </NvForm>
  );
};
