import {
  AssistantDocument,
  DocumentItem,
  useDeleteAssistantDocument,
  useUpdateAssistantDocuments,
} from '@novaera/actioner-service';
import { NvArticleIcon, NvFlex, NvForm } from '@novaera/core';
import { useParams } from '@novaera/route';
import arrayMutators from 'final-form-arrays';
import { noop } from 'lodash';
import React from 'react';
import { USER_APP_DOCUMENT_DETAIL } from '../../constants';
import { AssistantList } from '../assistant-list';
import { AIAssistantSection } from '../section';
import { DocumentSelect } from './document-select';
import { AIAssistantDocumentsForm, DocumentsProps } from './types';

export const Documents: React.FC<DocumentsProps> = ({ assistantDocuments }) => {
  const { userAppId } = useParams();
  const { mutate: deleteAssistantDocument } = useDeleteAssistantDocument();
  const { mutate: updateAssistantDocuments } = useUpdateAssistantDocuments();

  return (
    <NvFlex>
      <NvForm<AIAssistantDocumentsForm>
        onSubmit={noop}
        keepDirtyOnReinitialize
        initialValues={{
          documents: assistantDocuments,
        }}
        mutators={{
          ...arrayMutators,
        }}
      >
        <AIAssistantSection
          title="Documents"
          icon={<NvArticleIcon sx={{ width: '20px', height: '20px' }} />}
          infoText="You can add documents here. Your AI assistant can access and utilize the information in your documents."
        >
          <AssistantList<AssistantDocument>
            name={'documents'}
            type={'document'}
            SelectComponent={({ items, successCallback }) => (
              <DocumentSelect
                existingDocumentIds={items.map((d) => d.id)}
                onSelectDocument={({ id, name, tags }: DocumentItem) => {
                  const newDocument = { id, name, tags };

                  updateAssistantDocuments(
                    { appId: userAppId, document: newDocument },
                    {
                      onSuccess: () => {
                        successCallback(newDocument);
                      },
                    }
                  );
                }}
              />
            )}
            onGoTo={(id) => {
              window.open(USER_APP_DOCUMENT_DETAIL(userAppId, id), '_blank');
            }}
            onDelete={({ id, successCallback }) => {
              deleteAssistantDocument(
                { appId: userAppId, docId: id },
                {
                  onSuccess: () => {
                    successCallback();
                  },
                }
              );
            }}
          />
        </AIAssistantSection>
      </NvForm>
    </NvFlex>
  );
};
