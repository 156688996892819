import {
  NvAddBoxIcon,
  NvButton,
  NvConditionalRender,
  NvFlex,
  NvSearchEmptyState,
  NvTrackChangesIcon,
  NvTypography,
} from '@novaera/core';
import { BaseItem, SimpleSearchInput } from '../../../components';
import { EmptyState } from '../../../components/empty-state';
import { EmptyStates } from '../../../components/empty-state/types';
import { INTEGRATION_EVENT_DETAIL } from '../../constants';
import { useEventsController } from './controllers/use-events-controller';
import { EventsLoading } from './events-loading';
import { EventsProps } from './types';

export const Events: React.FC<React.PropsWithChildren<EventsProps>> = ({ onCreateEventRuleClick }) => {
  const {
    isLoading,
    eventRules,
    filteredEvents,
    searchKeyword,
    setSearchKeyword,
    handleAddEvent,
    integrationId,
    eventRuleMenuItems,
  } = useEventsController({ onCreateEventRuleClick });

  return (
    <>
      <NvConditionalRender when={() => isLoading}>
        <EventsLoading />
      </NvConditionalRender>

      <NvConditionalRender when={() => !isLoading}>
        <NvFlex gap="16px">
          <NvFlex direction="row" gap="8px" alignItems="center">
            <NvTrackChangesIcon sx={{ width: '32px', height: '32px', flex: '0 0 auto' }} />
            <NvTypography variant="h1" flex="1 1 auto" minWidth={0}>
              Triggers
            </NvTypography>
          </NvFlex>
          {eventRules.length === 0 ? (
            <EmptyState
              variant={EmptyStates.EVENTS}
              CustomButton={
                <NvButton
                  startIcon={<NvAddBoxIcon />}
                  color="secondary"
                  onClick={handleAddEvent}
                  size="small"
                  sx={{ flex: '0 0 auto' }}
                >
                  Add trigger
                </NvButton>
              }
            />
          ) : (
            <>
              <NvFlex direction="row" alignItems="center" justifyContent="space-between">
                <SimpleSearchInput
                  placeholder="Search triggers"
                  onKeywordChanged={(keyword) => setSearchKeyword(keyword ?? '')}
                />

                <NvButton startIcon={<NvAddBoxIcon />} size="small" color="ghost" onClick={handleAddEvent}>
                  Add trigger
                </NvButton>
              </NvFlex>
              {filteredEvents.length === 0 && searchKeyword.length > 0 ? (
                <NvSearchEmptyState text="No triggers found. Try different words or clear search bar." />
              ) : (
                <NvFlex gap="8px">
                  {filteredEvents.map(({ name, description, id }) => (
                    <BaseItem
                      key={`event-rule-item-${id}`}
                      name={name}
                      description={description}
                      to={INTEGRATION_EVENT_DETAIL(integrationId, id)}
                      menuItems={eventRuleMenuItems({ id, name })}
                    />
                  ))}
                </NvFlex>
              )}
            </>
          )}
        </NvFlex>
      </NvConditionalRender>
    </>
  );
};
