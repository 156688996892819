import { SearchWorkflowsParams, useGetWorkflow, useSearchWorkflows } from '@novaera/actioner-service';
import {
  NvAutocomplete,
  NvCloseIcon,
  NvExpandMoreIcon,
  NvFlex,
  NvSkeleton,
  NvTextField,
  NvTypography,
  useDebounce,
} from '@novaera/core';
import { useParams } from '@novaera/route';
import { FC, useMemo, useState } from 'react';
import { ActionSelectMenu, ActionSelectMenuItem } from '../../../../../../components/action-select-menu';

export const WorkflowSelectComponent: FC<{
  value?: string;
  onChange?: (value?: string) => void;
  searchParamsTriggerTypes?: SearchWorkflowsParams['triggerTypes'];
  size?: 'small' | undefined;
}> = ({ value, onChange, searchParamsTriggerTypes = ['form'], size }) => {
  const { userAppId } = useParams();

  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const debouncedSearchKeyword = useDebounce(searchKeyword, 500);
  const { data, isLoading } = useSearchWorkflows({
    appIds: [userAppId],
    keyword: debouncedSearchKeyword,
    sort: { order: 'asc', field: 'name' },
    triggerTypes: searchParamsTriggerTypes,
    includeSearchInvisible: true,
  });

  const searchedWorkflows = useMemo(() => data?.workflows ?? [], [data?.workflows]);
  const { savedWorkflow, isLoading: isSelectedLoading } = useGetWorkflow({ appId: userAppId, workflowId: value });
  return isSelectedLoading ? (
    <NvSkeleton variant="rectangular" height="32px" />
  ) : (
    <NvAutocomplete
      sx={{ width: '100%' }}
      options={searchedWorkflows}
      getOptionLabel={(option) => option?.name ?? ''}
      size={size}
      renderInput={(props) => <NvTextField {...props} placeholder="Select a workflow" />}
      PaperComponent={({ children, ...props }) => (
        <ActionSelectMenu {...props}>
          {isLoading ? (
            <NvFlex margin="8px" gap="8px">
              <NvFlex direction="row" gap="4px">
                <NvSkeleton variant="rectangular" width="20px" height="20px" />
                <NvSkeleton variant="rectangular" width="40%" height="20px" />
              </NvFlex>
              <NvFlex direction="row" gap="4px">
                <NvSkeleton variant="rectangular" width="20px" height="20px" />
                <NvSkeleton variant="rectangular" width="60%" height="20px" />
              </NvFlex>
              <NvFlex direction="row" gap="4px">
                <NvSkeleton variant="rectangular" width="20px" height="20px" />
                <NvSkeleton variant="rectangular" width="30%" height="20px" />
              </NvFlex>
            </NvFlex>
          ) : (
            children
          )}
        </ActionSelectMenu>
      )}
      renderOption={(props, option) => (
        <ActionSelectMenuItem {...props} key={option.id}>
          <NvFlex direction="row" alignItems="center" gap="8px" width="100%">
            <NvTypography variant="body1" noWrap>
              {option.name}
            </NvTypography>
          </NvFlex>
        </ActionSelectMenuItem>
      )}
      isOptionEqualToValue={(option, value) => value && option.id === value.id}
      clearIcon={<NvCloseIcon sx={{ height: '12px', width: '12px' }} />}
      popupIcon={<NvExpandMoreIcon />}
      onInputChange={(event, value) => setSearchKeyword(value)}
      filterOptions={(o) => o}
      onChange={(_, value) => {
        onChange?.(value?.id);
      }}
      value={value ? savedWorkflow ?? null : null}
    />
  );
};
