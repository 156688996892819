import { ConnectionSchema } from '@novaera/actioner-service';
import { NvFlex } from '@novaera/core';
import { useState } from 'react';
import { ConnectionSchemaLogo } from '../../../../components/connection-schema-logo';
import { DetailLayout } from '../../../../components/detail-layout';
import { ConnectionSchemaModelBody } from '../../../components/connection-schema-modal';
import { useIntegrationConnectionController } from '../controllers/use-integration-connection';
import { IntegrationDetailBody } from './body';
import { IntegrationConnectionBodyLoadingSkeleton } from './loading-skeleton';
import { IntegrationConnectionDetailProps } from './types';

export const IntegrationConnectionDetail = (props: IntegrationConnectionDetailProps) => {
  const [hasChange, setHasChange] = useState<boolean>(false);

  const { onSaveClicked, onModalSubmit, isUpdateConnectionLoading, onDeleteClicked } =
    useIntegrationConnectionController();

  return (
    <DetailLayout<ConnectionSchema, ConnectionSchema>
      useArrayMutators
      headerProps={{
        name: props?.connection?.name || '',
        icon: <ConnectionSchemaLogo connectionSchema={props.connection} size="small" />,
        hasChange,
        enableEdit: true,
        isLoading: props.isConnectionLoading,
      }}
      editModalProps={{
        title: 'Update',
        isLoading: isUpdateConnectionLoading,
        modalBody: <ConnectionSchemaModelBody isEdit={true} />,
        onSubmit: onModalSubmit,
        primaryButtonText: 'Save',
        formProps: {
          initialValues: props.connection,
        },
      }}
      onFormSubmit={onSaveClicked}
      formProps={{
        initialValues: props.connection,
      }}
      onActionButtonClicked={(type, value) => {
        if (type === 'delete') {
          onDeleteClicked(value);
        }
      }}
      onFormStateChanged={(params) => {
        setHasChange(params.isDirty);
      }}
      isLoading={props.isConnectionLoading}
      LoadingSkeleton={<IntegrationConnectionBodyLoadingSkeleton />}
    >
      <NvFlex marginTop={'16px'} gap="16px">
        <IntegrationDetailBody />
      </NvFlex>
    </DetailLayout>
  );
};
