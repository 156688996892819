import { useListConnectionMappingService } from '@novaera/actioner-service';
import { NvSkeleton, NvTypography } from '@novaera/core';
import { useParams } from '@novaera/route';
import { useMemo } from 'react';

export const SlackWorkspace: React.FC = () => {
  const { userAppId } = useParams();
  const { data: connectionSchemas, isInitialLoading } = useListConnectionMappingService({
    appId: userAppId,
  });

  const slackConnection = useMemo(() => {
    const slackConnectionSchema = connectionSchemas?.find((c) => c.slackConnection);
    return slackConnectionSchema && slackConnectionSchema.connections.length > 0
      ? { ...slackConnectionSchema.connections[0] }
      : undefined;
  }, [connectionSchemas]);

  return isInitialLoading ? (
    <NvSkeleton variant="rectangular" height="24px" width="120px" />
  ) : slackConnection ? (
    <NvTypography>{slackConnection.name}</NvTypography>
  ) : null;
};
