import { NvGraph } from '@novaera/core';
import { AppWorkflowAllNodeType, AppWorkflowRealNodeType } from '../../components/user-app-workflow-node-types/types';
import { ActionComponentFactory } from './create-action';
import { ActionerEventComponentFactory } from './create-actioner-event';
import { AssistantComponentFactory } from './create-assistant';
import { BranchJunctionComponentFactory } from './create-branch-junction';
import { WorkflowConditionComponentFactory } from './create-condition';
import { DelayComponentFactory } from './create-delay';
import { FilesComponentFactory } from './create-files';
import { FunctionComponentFactory } from './create-function';
import { GetConversationComponentFactory } from './create-get-conversation';
import { JobComponentFactory } from './create-job';
import { LinkGeneratorComponentFactory } from './create-link-generator';
import { LoopComponentFactory } from './create-loop';
import { OutputComponentFactory } from './create-output';
import { ResponseComponentFactory } from './create-response';
import { SendEmailComponentFactory } from './create-send-email';
import { WorkflowDispatcherComponentFactory } from './create-workflow-dispatcher';
import { QAComponentFactory } from './qa';
import { WorkflowResolverComponentFactory } from './workflow-resolver';

export const createComponentMethodMap: Record<
  AppWorkflowRealNodeType,
  ({
    graph,
  }: {
    graph: NvGraph<AppWorkflowAllNodeType>;
  }) =>
    | ActionComponentFactory
    | BranchJunctionComponentFactory
    | WorkflowConditionComponentFactory
    | LoopComponentFactory
    | FunctionComponentFactory
    | ResponseComponentFactory
    | OutputComponentFactory
    | DelayComponentFactory
    | SendEmailComponentFactory
    | WorkflowDispatcherComponentFactory
    | WorkflowResolverComponentFactory
    | QAComponentFactory
    | AssistantComponentFactory
    | GetConversationComponentFactory
    | ActionerEventComponentFactory
    | JobComponentFactory
    | FilesComponentFactory
    | LinkGeneratorComponentFactory
> = {
  action: function ({ graph }: { graph: NvGraph<AppWorkflowAllNodeType> }) {
    return new ActionComponentFactory({ graph });
  },
  branchJunction: ({ graph }: { graph: NvGraph<AppWorkflowAllNodeType> }) => {
    return new BranchJunctionComponentFactory({ graph });
  },
  workflowCondition: function ({ graph }: { graph: NvGraph<AppWorkflowAllNodeType> }) {
    return new WorkflowConditionComponentFactory({ graph });
  },
  loop: function ({ graph }: { graph: NvGraph<AppWorkflowAllNodeType> }) {
    return new LoopComponentFactory({ graph });
  },
  function: function ({ graph }: { graph: NvGraph<AppWorkflowAllNodeType> }) {
    return new FunctionComponentFactory({ graph });
  },
  delay: function ({ graph }: { graph: NvGraph<AppWorkflowAllNodeType> }) {
    return new DelayComponentFactory({ graph });
  },
  response: function ({ graph }: { graph: NvGraph<AppWorkflowAllNodeType> }) {
    return new ResponseComponentFactory({ graph });
  },
  output: function ({ graph }: { graph: NvGraph<AppWorkflowAllNodeType> }) {
    return new OutputComponentFactory({ graph });
  },
  sendEmail: function ({ graph }: { graph: NvGraph<AppWorkflowAllNodeType> }) {
    return new SendEmailComponentFactory({ graph });
  },
  workflowDispatcher: function ({ graph }: { graph: NvGraph<AppWorkflowAllNodeType> }) {
    return new WorkflowDispatcherComponentFactory({ graph });
  },
  workflowResolver: function ({ graph }: { graph: NvGraph<AppWorkflowAllNodeType> }) {
    return new WorkflowResolverComponentFactory({ graph });
  },
  aiKnowledge: function ({ graph }: { graph: NvGraph<AppWorkflowAllNodeType> }) {
    return new QAComponentFactory({ graph });
  },
  assistant: function ({ graph }: { graph: NvGraph<AppWorkflowAllNodeType> }) {
    return new AssistantComponentFactory({ graph });
  },
  getConversation: function ({ graph }: { graph: NvGraph<AppWorkflowAllNodeType> }) {
    return new GetConversationComponentFactory({ graph });
  },
  actionerEventPublisher: function ({ graph }: { graph: NvGraph<AppWorkflowAllNodeType> }) {
    return new ActionerEventComponentFactory({ graph });
  },
  job: function ({ graph }: { graph: NvGraph<AppWorkflowAllNodeType> }) {
    return new JobComponentFactory({ graph });
  },
  file: function ({ graph }: { graph: NvGraph<AppWorkflowAllNodeType> }) {
    return new FilesComponentFactory({ graph });
  },
  linkGenerator: function ({ graph }: { graph: NvGraph<AppWorkflowAllNodeType> }) {
    return new LinkGeneratorComponentFactory({ graph });
  },
};
