import { SlackAuthAnonymousAccessResponse, SlackAuthAnonymousAccessWorkspaceSelectionResponse } from './types';

export const isSlackAuthAccessAnonymous = (
  response: SlackAuthAnonymousAccessResponse | SlackAuthAnonymousAccessWorkspaceSelectionResponse
): response is SlackAuthAnonymousAccessResponse => {
  return (
    (response as SlackAuthAnonymousAccessResponse).slackAppMessageTabUrl !== undefined &&
    (response as SlackAuthAnonymousAccessResponse).status === 'all-set'
  );
};

export const isSlackAuthWorkspaceSelectionResponse = (
  response: SlackAuthAnonymousAccessResponse | SlackAuthAnonymousAccessWorkspaceSelectionResponse
): response is SlackAuthAnonymousAccessWorkspaceSelectionResponse => {
  return (response as SlackAuthAnonymousAccessWorkspaceSelectionResponse).status === 'workspace-selection-pending';
};
