import { CatalogEntity, CatalogEntityParameters, useSearchCatalogEntities } from '@novaera/actioner-service';
import { SchemaType } from '@novaera/ah-common';
import { GridSortModel } from '@novaera/core';
import { assert } from '@novaera/utils';
import { useMemo, useRef, useState } from 'react';
import { getRecordColumns } from '../../../../../components/records-table/utils';
import { useSelectedCatalogEntity } from '../../../../controllers/use-selected-catalog-entity';
import { RECORD_LIMIT } from '../../constants';

export const useCatalogEntitySearch = () => {
  const { selectedEntityType: entityType } = useSelectedCatalogEntity();

  assert(!!entityType, new Error('To use entity search, entity type must be selected'), 'ERROR');

  const [searchKeyword, setSearchKeyword] = useState('');
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [page, setPage] = useState<number>(0);
  const [query, setQuery] = useState<string>('');
  const searchQueryKeywordRef = useRef('');

  const sortParams = useMemo(() => {
    if (sortModel.length > 0) {
      const sortParameter = sortModel[0];
      const order = sortParameter.sort as 'asc' | 'desc';
      const fieldNames = [sortParameter.field] as string[];
      return { order, fieldNames };
    } else {
      return null;
    }
  }, [sortModel]);

  const nextPageOffset = useMemo(() => (page > 0 ? page * RECORD_LIMIT : null), [page]);

  const queryParams = useMemo(
    () => ({
      query,
      limit: RECORD_LIMIT,
      ...(nextPageOffset ? { nextPageOffset } : {}),
      ...(sortParams ? { sortParams } : {}),
    }),
    [nextPageOffset, query, sortParams]
  );

  const {
    data: catalogEntities,
    isLoading,
    isRefetching,
    refetch,
  } = useSearchCatalogEntities({
    entityTypeId: entityType.id,
    ...queryParams,
  });

  const columns = useMemo(() => {
    // custom properties is not a field which can be defined as a parameter in the entity type
    // it is a field which can be added in catalog action in workflow
    const customPropertiesField: CatalogEntityParameters = {
      id: `${entityType.id}.customProperties`,
      label: 'Custom Properties',
      name: 'customProperties',
      managed: false,
      schema: {
        type: SchemaType.map,
        constraints: [],
        mandatory: false,
        readOnly: false,
      },
    };

    return getRecordColumns<CatalogEntity, CatalogEntityParameters>({
      name: entityType.name,
      fields: [...(entityType.parameters ?? []), customPropertiesField],
    });
  }, [entityType]);

  const rows = useMemo(
    () => catalogEntities?.entities?.reduce<CatalogEntity[]>((acc, page) => [...acc, page], []) ?? [],
    [catalogEntities]
  );

  const totalHits = useMemo(() => catalogEntities?.totalHits, [catalogEntities?.totalHits]);

  const handleSearchChange = (keyword: string) => {
    searchQueryKeywordRef.current = keyword;
    setSearchKeyword(keyword);
  };

  // it has type any on codemirror too.
  // @ref /records/records-detail/controller/use-records-detail/index.tsx
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSearchEnter = (e: any) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      setQuery(searchQueryKeywordRef.current);
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const handleSearchRefresh = () => {
    refetch();
  };

  const handleSortModelChange = (newSortModel: GridSortModel) => {
    if (isLoading || isRefetching) {
      return;
    }
    setSortModel(newSortModel);
  };

  const handlePageChange = (page: number) => {
    if (isLoading || isRefetching) {
      return;
    }
    setPage(page);
  };

  const getCatalogEntity = (id: string) => {
    return catalogEntities?.entities?.find((entity) => entity.id === id);
  };

  return {
    searchKeyword,
    totalHits,
    isLoading,
    isRefetching,
    page,
    rows,
    columns,
    onSearchChange: handleSearchChange,
    onSearchEnter: handleSearchEnter,
    onSearchRefresh: handleSearchRefresh,
    onSortModelChange: handleSortModelChange,
    onPageChange: handlePageChange,
    getCatalogEntity,
  };
};
