import { useDeleteIntegrationEventRule, useGetIntegrationEventRules } from '@novaera/actioner-service';
import { NvDeleteOutlineIcon, useConfirmDialog } from '@novaera/core';
import { useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import { matchSorter } from 'match-sorter';
import { useCallback, useMemo, useState } from 'react';

export const useEventsController = ({ onCreateEventRuleClick }: { onCreateEventRuleClick: () => void }) => {
  const { integrationId } = useParams();
  const theme = useTheme();

  const { openConfirm } = useConfirmDialog();
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const { data, isLoading } = useGetIntegrationEventRules({ integrationId });
  const { mutate: deleteEventRule } = useDeleteIntegrationEventRule();
  const filteredEvents = useMemo(
    () => (data?.eventRules ? matchSorter(data.eventRules, searchKeyword, { keys: ['name'] }) : []),
    [data?.eventRules, searchKeyword]
  );

  const handleAddEvent = () => {
    onCreateEventRuleClick();
  };

  const eventRuleMenuItems = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ({ id, name }: any) => [
      {
        name: 'Delete',
        onClick: () => {
          openConfirm({
            title: `Delete ${name} ?`,
            message:
              'Deleting the trigger is a permanent operation that can affect any associated workflows. Are you sure you want to proceed with the deletion?',
            onConfirm: () => {
              deleteEventRule({ integrationId, id });
            },
          });
        },
        icon: <NvDeleteOutlineIcon htmlColor={theme.palette.nv_error[40]} sx={{ width: '16px', height: '16px' }} />,
      },
    ],
    [theme.palette.nv_error, openConfirm, deleteEventRule, integrationId]
  );

  return {
    isLoading,
    eventRules: data?.eventRules ?? [],
    filteredEvents,
    searchKeyword,
    setSearchKeyword,
    handleAddEvent,
    integrationId,
    eventRuleMenuItems,
  };
};
