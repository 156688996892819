import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { NvBox, NvConditionalWrap, NvDivider, NvDragIndicatorIcon, NvFlex, NvTypography, Portal } from '@novaera/core';
import { isUndefined } from 'lodash';
import { FC } from 'react';
import { FormIdentifierProvider } from '../../providers/form-identifier-provider';
import { RowItemCard } from '../../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/properties-panel/property-panels/common/row-item-card';
import { PPDrawerItem } from '../property-panel';
import { WorkflowReferenceItemsProps } from './types';
import { useWorkflowReferenceItems } from './use-workflow-reference-items';
import { WorkflowReferenceItem } from './workflow-reference-item';
import { WorkflowReferenceRowDetail } from './workflow-reference-row-detail';
import {
  DynamicWorkflowReferenceRowDetailProps,
  StaticWorkflowReferenceRowDetailProps,
  WorkflowReferenceRowDetailProps,
} from './workflow-reference-row-detail/types';

export const WorkflowReferenceItems: FC<React.PropsWithChildren<WorkflowReferenceItemsProps>> = ({
  block,
  onChange,
  index,
  context = {},
}) => {
  const {
    element,
    handleDeleteClick,
    handleElementChange,
    makeOnDragEndFunction,
    workflowsDetailsMap,
    setSelectedElementId,
    setPanelOpen,
    selectedElementId,
    wrapperElementRef,
    panelCloseFn,
    panelProps,
  } = useWorkflowReferenceItems({ block, onChange, index });

  return (
    <>
      <DragDropContext onDragEnd={makeOnDragEndFunction}>
        <Droppable droppableId={`slack_button_blocks`}>
          {(provided) => (
            <NvFlex gap="12px" ref={provided.innerRef}>
              {block.elements.map((element, elementIndex) => (
                <Draggable
                  key={`draggable_element_${elementIndex}`}
                  draggableId={`${elementIndex}`}
                  index={elementIndex}
                >
                  {(provided, snapshot) => {
                    let workflowReferenceRowDetailProps: WorkflowReferenceRowDetailProps;

                    if (element.type === 'static') {
                      const staticProps: StaticWorkflowReferenceRowDetailProps = {
                        type: 'static',
                        workflowDetail: workflowsDetailsMap[element.workflowId],
                      };
                      workflowReferenceRowDetailProps = staticProps;
                    } else {
                      const dynamicProps: DynamicWorkflowReferenceRowDetailProps = { type: 'dynamic' };
                      workflowReferenceRowDetailProps = dynamicProps;
                    }

                    return (
                      <NvConditionalWrap
                        condition={snapshot.isDragging}
                        wrap={(children) => <Portal>{children}</Portal>}
                      >
                        <NvBox ref={provided.innerRef} {...provided.draggableProps} position="relative">
                          <RowItemCard
                            isSelected={selectedElementId === element.id}
                            rowItemLeftContentProps={{
                              draggable: false,
                              icon: (
                                <NvBox {...provided.dragHandleProps}>
                                  <NvDragIndicatorIcon sx={{ flex: '0 0 auto', width: '16px', height: '16px' }} />
                                </NvBox>
                              ),
                            }}
                            key={`${element.id}`}
                            rowItemContent={
                              <NvFlex direction="row" gap="8px" alignItems="center">
                                <NvTypography variant="h5">{`Button ${elementIndex + 1}`}</NvTypography>
                                <NvDivider orientation="vertical" sx={{ height: '12px' }} />
                                <WorkflowReferenceRowDetail {...workflowReferenceRowDetailProps} />
                              </NvFlex>
                            }
                            onClick={() => {
                              setSelectedElementId(element.id);
                              setTimeout(() => setPanelOpen(true));
                            }}
                          ></RowItemCard>
                        </NvBox>
                      </NvConditionalWrap>
                    );
                  }}
                </Draggable>
              ))}
              {provided.placeholder}
            </NvFlex>
          )}
        </Droppable>
      </DragDropContext>
      {element && (
        <Portal node={wrapperElementRef.current}>
          <PPDrawerItem {...panelProps}>
            {element && !isUndefined(index) && (
              <FormIdentifierProvider initialFormId={element.type === 'static' ? element.formId : undefined}>
                <WorkflowReferenceItem
                  index={block.elements.findIndex((e) => e.id === selectedElementId)}
                  key={selectedElementId}
                  workflowReference={element}
                  onClose={() => {
                    panelCloseFn();
                    setTimeout(() => setSelectedElementId(undefined), 300);
                  }}
                  context={context}
                  onChange={handleElementChange}
                  onDeleteClick={handleDeleteClick}
                />
              </FormIdentifierProvider>
            )}
          </PPDrawerItem>
        </Portal>
      )}
    </>
  );
};
