import { JobTargetType, WorkflowJobTarget, WorkflowState, useGetWorkflow } from '@novaera/actioner-service';
import {
  NvArrowForwardIcon,
  NvButton,
  NvDivider,
  NvField,
  NvFlex,
  NvTypography,
  NvWorkflowIcon,
  isRequired,
  useField,
} from '@novaera/core';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import OnChange from '../../../../../action-designer/components/on-change';
import { InputValuesProvider } from '../../../../../action-designer/providers/input-values';
import { WorkflowParameterMapper } from '../../../../../components';
import { FormIdentifierProvider } from '../../../../../providers/form-identifier-provider';
import { USER_APP_WORKFLOW } from '../../../constants';
import { WorkflowSelectComponent } from '../../../workflow-designer/user-app-workflow-canvas/common/workflow-select-component';
import { JobTargetProps } from './types';

export const JobTarget: React.FC<React.PropsWithChildren<JobTargetProps>> = ({ appId, parentFieldName }) => {
  const baseFieldName = parentFieldName ? `${parentFieldName}.target` : 'target';
  const {
    input: { value: target, onChange: onChangedTarget },
  } = useField<WorkflowJobTarget>(baseFieldName);
  const navigate = useNavigate();

  const { savedWorkflow } = useGetWorkflow({ appId, workflowId: target.id });

  const initialParameters = useMemo(
    () =>
      target?.parameters?.reduce((prev, current) => {
        return {
          ...prev,
          [current.parameterId]: current,
        };
      }, {}),
    [target?.parameters]
  );

  return (
    <>
      <NvFlex direction="row" alignItems="center" gap="4px">
        <NvWorkflowIcon sx={{ width: '16px', height: '16px' }} />
        <NvTypography variant="h5">Workflow</NvTypography>
      </NvFlex>
      <OnChange name={`${baseFieldName}.id`}>
        {() => {
          onChangedTarget({
            ...target,
            type: JobTargetType.WORKFLOW,
            parameters: [],
            formId: undefined,
          });
        }}
      </OnChange>

      <NvFlex direction="row" alignItems="center" gap="16px" maxWidth="600px">
        <NvField
          name={`${baseFieldName}.id`}
          validators={[isRequired()]}
          component={<WorkflowSelectComponent searchParamsTriggerTypes={['form']} />}
        />
        {target.id && (
          <>
            <NvDivider orientation="vertical" sx={{ height: '12px' }} />
            <NvButton
              endIcon={<NvArrowForwardIcon />}
              color="ghost"
              size="small"
              onClick={() => {
                navigate(`${USER_APP_WORKFLOW({ userAppId: appId, workflowId: target.id })}/designer`);
              }}
            >
              Go to workflow
            </NvButton>
          </>
        )}
      </NvFlex>
      {target.id && savedWorkflow?.trigger?.type === 'form' && (
        <NvFlex>
          <NvDivider sx={{ margin: '12px 0' }} />
          <NvFlex gap="16px">
            <NvFlex direction="row" gap="4px" alignItems="center">
              <NvTypography variant="h5" flex="1 1 auto">
                Workflow inputs
              </NvTypography>
            </NvFlex>
            <FormIdentifierProvider initialFormId={target.formId}>
              <InputValuesProvider initialInputFormValues={initialParameters}>
                <WorkflowParameterMapper
                  appId={appId}
                  onParameterMappingsChanged={({ formId, parameterMappings }) => {
                    onChangedTarget({ ...target, parameters: parameterMappings, formId });
                  }}
                  initialParameterMappings={target.parameters}
                  workflow={{ ...savedWorkflow, state: WorkflowState.SAVED }}
                  workflowId={savedWorkflow.id}
                  isForceHideSwitch
                />
              </InputValuesProvider>
            </FormIdentifierProvider>
          </NvFlex>
        </NvFlex>
      )}
    </>
  );
};
