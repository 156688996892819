import { UIComponentType } from '@novaera/actioner-service';
import { FilteredComponent } from '../../../types';
export const inputParameterProperties: Record<UIComponentType, FilteredComponent> = {
  [UIComponentType.TEXT_FIELD]: {
    selectedComponent: UIComponentType.TEXT_FIELD,
    propertyIds: [
      {
        id: 'basic.name',
      },
      {
        id: 'basic.label',
      },
      {
        id: 'basic.placeHolder',
      },
      {
        id: 'basic.hint',
      },
      {
        id: 'basic.required',
      },
      {
        id: 'basic.hidden',
      },
      {
        id: 'basic.defaultValue',
      },
      {
        id: 'validations',
      },
    ],
  },
  [UIComponentType.MULTI_SELECT]: {
    selectedComponent: UIComponentType.MULTI_SELECT,
    propertyIds: [
      {
        id: 'basic.name',
      },
      {
        id: 'basic.label',
      },
      {
        id: 'basic.placeHolder',
      },
      {
        id: 'basic.hint',
      },
      {
        id: 'basic.required',
      },
      {
        id: 'basic.hidden',
      },
      {
        id: 'basic.defaultValue',
      },
      {
        id: 'basic.searchAsTypeConfiguration',
      },
      {
        id: 'basic.freeSolo',
      },
      {
        id: 'source.requests',
      },
      {
        id: 'validations',
      },
      {
        id: 'schema.sourceType',
      },
    ],
  },
  [UIComponentType.SINGLE_SELECT]: {
    selectedComponent: UIComponentType.SINGLE_SELECT,
    propertyIds: [
      {
        id: 'basic.name',
      },
      {
        id: 'basic.label',
      },
      {
        id: 'basic.placeHolder',
      },
      {
        id: 'basic.hint',
      },
      {
        id: 'basic.required',
      },
      {
        id: 'basic.hidden',
      },
      {
        id: 'basic.defaultValue',
      },
      {
        id: 'basic.searchAsTypeConfiguration',
      },
      {
        id: 'basic.freeSolo',
      },
      {
        id: 'source.requests',
      },
      {
        id: 'validations',
      },
      {
        id: 'schema.sourceType',
      },
    ],
  },
  [UIComponentType.DATE_PICKER]: {
    selectedComponent: UIComponentType.DATE_PICKER,
    propertyIds: [
      {
        id: 'basic.name',
      },
      {
        id: 'basic.label',
      },
      {
        id: 'basic.placeHolder',
      },
      {
        id: 'basic.hint',
      },
      {
        id: 'basic.required',
      },
      {
        id: 'basic.hidden',
      },
      {
        id: 'basic.defaultValue',
      },
      {
        id: 'validations',
      },
    ],
  },
  [UIComponentType.DATE_TIME_PICKER]: {
    selectedComponent: UIComponentType.DATE_TIME_PICKER,
    propertyIds: [
      {
        id: 'basic.name',
      },
      {
        id: 'basic.label',
      },
      {
        id: 'basic.placeHolder',
      },
      {
        id: 'basic.hint',
      },
      {
        id: 'basic.required',
      },
      {
        id: 'basic.hidden',
      },
      {
        id: 'basic.defaultValue',
      },
      {
        id: 'validations',
      },
    ],
  },
  [UIComponentType.TIME_PICKER]: {
    selectedComponent: UIComponentType.TIME_PICKER,
    propertyIds: [
      {
        id: 'basic.name',
      },
      {
        id: 'basic.label',
      },
      {
        id: 'basic.hint',
      },
      {
        id: 'basic.required',
      },
      {
        id: 'basic.hidden',
      },
      {
        id: 'basic.defaultValue',
      },
      {
        id: 'basic.placeHolder',
      },
      {
        id: 'validations',
      },
    ],
  },
  [UIComponentType.CHECK_BOX]: {
    selectedComponent: UIComponentType.CHECK_BOX,
    propertyIds: [
      {
        id: 'basic.name',
      },
      {
        id: 'basic.label',
      },
      {
        id: 'basic.hint',
      },
      {
        id: 'basic.hidden',
      },
      {
        id: 'basic.defaultValue',
      },
      {
        id: 'validations',
      },
    ],
  },
  [UIComponentType.CHECK_BOX_GROUP]: {
    selectedComponent: UIComponentType.CHECK_BOX_GROUP,
    propertyIds: [
      {
        id: 'basic.name',
      },
      {
        id: 'basic.label',
      },
      {
        id: 'basic.hint',
      },
      {
        id: 'basic.hidden',
      },
      {
        id: 'basic.required',
      },
      {
        id: 'source.requests',
      },
      {
        id: 'basic.defaultValue',
      },
      {
        id: 'validations',
      },
      {
        id: 'schema.sourceType',
      },
    ],
  },
  [UIComponentType.RADIO_BUTTON_GROUP]: {
    selectedComponent: UIComponentType.RADIO_BUTTON_GROUP,
    propertyIds: [
      {
        id: 'basic.name',
      },
      {
        id: 'basic.label',
      },
      {
        id: 'basic.hint',
      },
      {
        id: 'basic.required',
      },
      {
        id: 'basic.hidden',
      },
      {
        id: 'basic.defaultValue',
      },
      {
        id: 'source.requests',
      },
      {
        id: 'validations',
      },
    ],
  },
  [UIComponentType.HEADER]: {
    selectedComponent: UIComponentType.HEADER,
    propertyIds: [
      {
        id: 'basic.name',
      },
      {
        id: 'basic.header.text',
      },
      {
        id: 'basic.variant',
      },
    ],
  },
  [UIComponentType.BLOCK_LIST]: {
    selectedComponent: UIComponentType.BLOCK_LIST,
    propertyIds: [
      {
        id: 'slack.basic.name',
      },
      {
        id: 'basic.required',
      },
    ],
  },
  [UIComponentType.DYNAMIC_INPUT]: {
    selectedComponent: UIComponentType.DYNAMIC_INPUT,
    propertyIds: [
      {
        id: 'basic.name',
      },
      {
        id: 'basic.label',
      },
      {
        id: 'basic.hint',
      },
      {
        id: 'dynamic.input.data.source',
      },
    ],
  },
  [UIComponentType.PLAIN_TEXT]: {
    selectedComponent: UIComponentType.PLAIN_TEXT,
    propertyIds: [
      {
        id: 'basic.name',
      },
      {
        id: 'basic.plainText.text',
      },
    ],
  },
  [UIComponentType.LINK]: {
    selectedComponent: UIComponentType.LINK,
    propertyIds: [
      {
        id: 'basic.name',
      },
      {
        id: 'basic.plainText.text',
      },
    ],
  },
  [UIComponentType.JOB_TARGET]: {
    selectedComponent: UIComponentType.JOB_TARGET,
    propertyIds: [
      {
        id: 'jobTarget.basic.name',
      },
      {
        id: 'basic.required',
      },
    ],
  },
  [UIComponentType.RECORD_ATTRIBUTES]: {
    selectedComponent: UIComponentType.RECORD_ATTRIBUTES,
    propertyIds: [
      {
        id: 'recordAttributes.basic.name',
      },
    ],
  },
  [UIComponentType.RECORD_UPDATE_ATTRIBUTES]: {
    selectedComponent: UIComponentType.RECORD_UPDATE_ATTRIBUTES,
    propertyIds: [
      {
        id: 'recordUpdateAttributes.basic.name',
      },
    ],
  },
  [UIComponentType.RECORD_PARTIAL_UPDATE]: {
    selectedComponent: UIComponentType.RECORD_PARTIAL_UPDATE,
    propertyIds: [
      {
        id: 'recordPartialUpdate.basic.name',
      },
    ],
  },
};
