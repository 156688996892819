import { useRemoveSlackConnection, useUpdateSlackWorkspaceSettings } from '@novaera/actioner-service';
import { SlackWorkspace } from '@novaera/ah-common';
import {
  Initial,
  NvArrowForwardIcon,
  NvButton,
  NvDeleteOutlineIcon,
  NvDivider,
  NvFlex,
  NvImage,
  NvMenuWithItems,
  NvPersonAddIcon,
  NvPowerRoundedIcon,
  NvSwitch,
  NvTypography,
  useConfirmDialog,
} from '@novaera/core';
import { useGetSlackAuth } from '@novaera/identity-provider';
import { useTheme } from '@novaera/theme-provider';
import { FC } from 'react';
import { SlackIntegrationItem, SlackWorkspaceSwitchCard } from './styled';

export const SlackWorkspaceCard: FC<{
  slackWorkspace: SlackWorkspace;
  handleInviteSlackUsers: () => void;
}> = ({ slackWorkspace, handleInviteSlackUsers }) => {
  const theme = useTheme();
  const { mutate: updateSlackWorkspaceSettings, isLoading: isUpdateSlackWorkspaceSettingsLoading } =
    useUpdateSlackWorkspaceSettings();
  const { mutate: removeSlackWorkspace, isLoading: isRemoveSlackWorkspaceLoading } = useRemoveSlackConnection();
  const { openConfirm } = useConfirmDialog();
  const { slackAuthUrl } = useGetSlackAuth();

  const onRemoveConnectionClicked = (slackWorkspace: SlackWorkspace) => {
    const { teamId } = slackWorkspace;
    openConfirm({
      title: `Remove ${slackWorkspace.teamName} ?`,
      confirmButtonLabel: 'Remove',
      message:
        'You are about to remove your Slack connection. You will not be able to run your actions in your Slack workspace.',
      onConfirm: () => {
        removeSlackWorkspace({ teamId });
      },
    });
  };
  return (
    <SlackIntegrationItem>
      <NvFlex direction="row" gap="8px" alignItems="center">
        <NvImage
          imageShape="square"
          src={slackWorkspace.iconUrl}
          FallBack={<Initial size="medium" value={slackWorkspace.teamName} color={''} />}
          size="medium"
          hasNoElevation
        />

        <NvTypography variant="h3" flex="1 1 auto" minWidth={0} noWrap>
          {slackWorkspace.teamName}
        </NvTypography>

        <NvFlex direction="row" gap="12px" alignItems="center" flex="0 0 auto">
          <NvButton
            size="small"
            color="ghost"
            endIcon={<NvArrowForwardIcon />}
            onClick={() => {
              window.location.href = slackWorkspace.slackTeamLink;
            }}
          >
            Go to Slack workspace
          </NvButton>
          <NvDivider orientation="vertical" borderColor={theme.palette.nv_neutral_alpha[30]} sx={{ height: '12px' }} />
          {isRemoveSlackWorkspaceLoading ? (
            <NvButton disabled onlyIcon loading color="secondary" size="small" />
          ) : (
            <NvMenuWithItems
              menuItems={[
                {
                  name: 'Reconnect',
                  onClick: () => {
                    if (slackAuthUrl) {
                      window.location.href = slackAuthUrl;
                    }
                  },
                  icon: <NvPowerRoundedIcon sx={{ width: '16px', height: '16px' }} />,
                },
                {
                  name: 'divider',
                  isDivider: true,
                },
                {
                  name: 'Remove workspace',
                  icon: (
                    <NvDeleteOutlineIcon
                      htmlColor={theme.palette.nv_error[40]}
                      sx={{ width: '16px', height: '16px' }}
                    />
                  ),
                  onClick: () => {
                    onRemoveConnectionClicked(slackWorkspace);
                  },
                },
              ]}
            />
          )}
        </NvFlex>
      </NvFlex>

      <NvFlex gap="24px" margin="12px 0 24px 0">
        <NvFlex gap="8px">
          <NvTypography variant="h4">User settings</NvTypography>
          <NvFlex gap="8px">
            <SlackWorkspaceSwitchCard>
              <NvSwitch
                disabled={isUpdateSlackWorkspaceSettingsLoading}
                checked={slackWorkspace.settings.syncSlackUsersToActioner}
                variant="compact"
                onChange={(e, checked) => {
                  updateSlackWorkspaceSettings({
                    teamId: slackWorkspace.teamId,
                    syncSlackUsersToActioner: checked,
                    slackUsersCanJoinWithoutConfirmation: checked
                      ? true
                      : slackWorkspace.settings.slackUsersCanJoinWithoutConfirmation,
                  });
                }}
              />
              <NvFlex>
                <NvTypography variant="body1">Keep Slack users synchronized with Actioner.</NvTypography>
                <NvTypography variant="body2" textColor="secondary">
                  If this option is enabled, any new user joining your Slack workspace will also be automatically
                  <br />
                  added as a user in Actioner.
                </NvTypography>
              </NvFlex>
            </SlackWorkspaceSwitchCard>
            {!slackWorkspace.settings.syncSlackUsersToActioner && (
              <SlackWorkspaceSwitchCard>
                <NvSwitch
                  disabled={isUpdateSlackWorkspaceSettingsLoading}
                  checked={slackWorkspace.settings.slackUsersCanJoinWithoutConfirmation}
                  variant="compact"
                  onChange={(e, checked) => {
                    updateSlackWorkspaceSettings({
                      teamId: slackWorkspace.teamId,
                      syncSlackUsersToActioner: slackWorkspace.settings.syncSlackUsersToActioner,
                      slackUsersCanJoinWithoutConfirmation: checked,
                    });
                  }}
                />
                <NvFlex>
                  <NvTypography variant="body1">Slack users can join to Actioner without confirmation.</NvTypography>
                  <NvTypography variant="body2" textColor="secondary">
                    If this option is enabled, users in your Slack workspace will be able to join Actioner without
                    needing confirmation.
                  </NvTypography>
                </NvFlex>
              </SlackWorkspaceSwitchCard>
            )}
          </NvFlex>
        </NvFlex>
        {!slackWorkspace.settings.syncSlackUsersToActioner && (
          <NvFlex gap="8px" alignItems="flex-start">
            <NvTypography variant="h4">Invite your Slack users to start using Actioner together</NvTypography>
            <NvButton
              size="medium"
              color="secondary"
              startIcon={<NvPersonAddIcon />}
              onClick={() => {
                handleInviteSlackUsers();
              }}
            >
              Invite Slack users
            </NvButton>
          </NvFlex>
        )}
      </NvFlex>
    </SlackIntegrationItem>
  );
};
