import {
  Initial,
  NvConditionalWrap,
  NvCustomCardGiftIcon,
  NvDivider,
  NvFlex,
  NvImage,
  NvTypography,
} from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import classNames from 'classnames';
import { isUndefined } from 'lodash';
import { useMediaQuery } from 'react-responsive';
import { ROUTES } from '../../../common/routes';
import { ManagedAppIcon } from '../../../components/managed-apps/icon';
import { Contributors } from './contributors';
import { Stats } from './stats';
import {
  AppDirectoryCardContainer,
  AppDirectoryCardDescription,
  AppDirectoryCardHeader,
  NvAppDirectoryCardLink,
} from './styled';
import { Tags } from './tags';
import { AppDirectoryCardProps } from './types';

export const AppDirectoryCard: React.FC<AppDirectoryCardProps> = ({
  title,
  description,
  tags,
  star,
  contributors,
  logoUrl,
  isPreviewCard,
  id,
  isManagedApp,
  free,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const showTags = tags.length > 0 && !isMobile;
  const showStars = !isUndefined(star) && Number(star) > 0;
  return (
    <NvConditionalWrap
      condition={!isPreviewCard}
      wrap={(children) => (
        <NvAppDirectoryCardLink
          to={`${ROUTES.AppDirectory}/${title
            .trim()
            .toLowerCase()
            .replace(/[^a-z0-9]+/gi, '-')}/${id}`}
        >
          {children}
        </NvAppDirectoryCardLink>
      )}
    >
      <AppDirectoryCardContainer
        className={classNames({
          'not-clickable': isPreviewCard,
        })}
      >
        <AppDirectoryCardHeader>
          <NvFlex flex="1 1 auto" alignItems="flex-start">
            <NvImage
              imageShape="square"
              src={logoUrl}
              FallBack={<Initial size="medium" value={title} color={theme.palette.nv_neutral[500]} />}
              size="medium"
            />
          </NvFlex>
          <NvFlex direction="row" flex="0 0 auto" gap="6px" alignItems="center">
            {free && (
              <NvFlex gap="2px" direction="row">
                <NvCustomCardGiftIcon sx={{ width: '12px', height: '12px' }} htmlColor={theme.palette.nv_new[60]} />
                <NvTypography variant="h7">
                  <b>FREE APP</b>
                </NvTypography>
              </NvFlex>
            )}
            {free && (showStars || showTags) && <NvDivider orientation="vertical" sx={{ height: '8px' }} />}
            {showTags && <Tags title={title} tags={tags} />}
            {showTags && showStars && <NvDivider orientation="vertical" sx={{ height: '8px' }} />}
            {showStars && <Stats star={star} />}
          </NvFlex>
        </AppDirectoryCardHeader>
        <AppDirectoryCardDescription>
          <NvFlex flex="1 1 auto" minHeight={0} gap="8px">
            <NvTypography variant="h4" noWrap textOverflow="ellipsis" flex="0 0 auto">
              {title}
            </NvTypography>
            <NvTypography variant="body3">{description}</NvTypography>
          </NvFlex>
          {isManagedApp && (
            <NvFlex
              flexDirection={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              alignContent={'center'}
            >
              <Contributors contributors={contributors} />
              <ManagedAppIcon iconTheme="light" />
            </NvFlex>
          )}
        </AppDirectoryCardDescription>
      </AppDirectoryCardContainer>
    </NvConditionalWrap>
  );
};
