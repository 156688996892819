import {
  AssistantWorkflow,
  SearchWorkflow,
  useDeleteAssistantWorkflow,
  useUpdateAssistantWorkflows,
} from '@novaera/actioner-service';
import {
  NvDialogModal,
  NvField,
  NvFlex,
  NvForm,
  NvMenuWithItems,
  NvTextArea,
  NvTypography,
  NvWorkflowIcon,
} from '@novaera/core';
import { useParams } from '@novaera/route';
import arrayMutators from 'final-form-arrays';
import { noop } from 'lodash';
import React, { useState } from 'react';
import { USER_APP_WORKFLOW } from '../../constants';
import { AssistantList } from '../assistant-list';
import { AIAssistantSection } from '../section';
import { AIAssistantWorkflowsForm, WorkflowsProps } from './types';
import { WorkflowSelect } from './workflows-select';

export const Workflows: React.FC<WorkflowsProps> = ({ assistantWorkflows }) => {
  const { userAppId } = useParams();
  const [selectedWorkflowToUpdate, setSelectedWorkflowToUpdate] = useState<AssistantWorkflow | undefined>();
  const [isMoreActionsOpen, setIsMoreActionsOpen] = useState<Record<string, boolean>>({});
  const { mutate: deleteAssistantWorkflow } = useDeleteAssistantWorkflow();
  const { mutate: updateAssistantWorkflows, isLoading: isUpdateAssistantWorkflowsLoading } =
    useUpdateAssistantWorkflows();

  return (
    <NvFlex>
      <NvForm<AIAssistantWorkflowsForm>
        onSubmit={noop}
        keepDirtyOnReinitialize
        initialValues={{
          workflows: assistantWorkflows,
        }}
        mutators={{
          ...arrayMutators,
        }}
      >
        <AIAssistantSection
          title="Workflows"
          icon={<NvWorkflowIcon sx={{ width: '20px', height: '20px' }} />}
          infoText="Only workflows specifically added to the provided list can be implemented by your AI assistant. Workflows not listed will not be used by the assistant."
        >
          <AssistantList<AssistantWorkflow>
            name={'workflows'}
            type={'workflow'}
            SelectComponent={({ items, successCallback }) => (
              <WorkflowSelect
                existingWorkflowIds={items.map((w) => w.id)}
                onSelectWorkflow={({ id, name, tags }: SearchWorkflow) => {
                  const newWorkflow = { id, name, tags: tags ?? [], instructions: '' };
                  updateAssistantWorkflows(
                    { appId: userAppId, workflow: newWorkflow },
                    {
                      onSuccess: () => {
                        successCallback(newWorkflow);
                      },
                    }
                  );
                }}
              />
            )}
            isAdditionalActionsOpen={isMoreActionsOpen}
            additionalActions={(item) => (
              <NvMenuWithItems
                onOpened={() => {
                  setIsMoreActionsOpen({ [item.id]: true });
                }}
                onClosed={() => {
                  setIsMoreActionsOpen({ [item.id]: false });
                }}
                menuItems={[
                  {
                    name: 'Change workflow instructions',
                    onClick: () => {
                      setSelectedWorkflowToUpdate(item);
                    },
                  },
                ]}
              />
            )}
            onGoTo={(id) => {
              window.open(USER_APP_WORKFLOW({ userAppId, workflowId: id }), '_blank');
            }}
            onDelete={({ id, successCallback }) => {
              deleteAssistantWorkflow(
                { appId: userAppId, workflowId: id },
                {
                  onSuccess: () => {
                    successCallback();
                  },
                }
              );
            }}
          />
        </AIAssistantSection>
        {!!selectedWorkflowToUpdate && (
          <NvDialogModal
            formProps={{ initialValues: { instructions: selectedWorkflowToUpdate.instructions } }}
            onCloseButtonClicked={() => {
              setSelectedWorkflowToUpdate(undefined);
            }}
            open={Boolean(selectedWorkflowToUpdate)}
            Header={`${selectedWorkflowToUpdate.name} instructions`}
            maxWidth="sm"
            modalIcon="shortText"
            primaryButtonText="Save"
            isLoading={isUpdateAssistantWorkflowsLoading}
            onFormSubmit={({ instructions }) => {
              updateAssistantWorkflows(
                { appId: userAppId, workflow: { ...selectedWorkflowToUpdate, instructions } },
                {
                  onSuccess: () => {
                    setSelectedWorkflowToUpdate(undefined);
                  },
                }
              );
            }}
            Body={
              <>
                <NvTypography>
                  Use workflow instructions to guide the assistant on utilizing this workflow. A well-defined set of
                  instructions ensures a more effective AI assistant.
                </NvTypography>
                <NvField name={'instructions'} component={<NvTextArea rows={3} />} />
              </>
            }
          />
        )}
      </NvForm>
    </NvFlex>
  );
};
