import {
  Connection,
  useConnectService,
  useGetActiveUser,
  useGetConnectionSchemaService,
  useInvalidateSearchConnectionsService,
  useInvalidateUserConnectionsService,
  useReconnectService,
  useTestConnectionSchema,
  useTestConnectionService,
} from '@novaera/actioner-service';
import { isAxiosError, useToast } from '@novaera/core';
import { generatePath } from '@novaera/route';
import { assert } from '@novaera/utils';
import { useState } from 'react';
import { ROUTES } from '../../../../../common/routes';
import { isOAuth2Authentication } from '../../../../../integrations/utils';
import { useOAuth2ConnectionController } from '../../oauth2-connection/controllers/use-oauth2-connection';
import { OAuth2PromiseResultType } from '../../oauth2-connection/controllers/use-oauth2-connection/types';
import { ConnectModalProps, ConnectionModalForm } from '../../types';
import { ConnectionServiceFuncArgs, TestResponseResult } from './types';
import { isTestConnectionResultError } from './utils';

export const useConnectModalController = ({
  connected,
  mode,
  connectionId,
  onModalCloseClicked,
  test,
  integrationId,
  initialSchemaId,
}: {
  mode: ConnectModalProps['mode'];
  connected?: boolean;
  onModalCloseClicked?: ConnectModalProps['onModalCloseClicked'];
  connectionId?: string;
  test?: ConnectModalProps['test'];
  integrationId?: string;
  initialSchemaId?: string;
}) => {
  const [schemaId, setSchemaId] = useState(initialSchemaId);
  const [response, setResponse] = useState<TestResponseResult>();
  const [oauthPromiseResult, setOAuthPromiseResult] = useState<OAuth2PromiseResultType>();

  const { startOAuthFlow, isLoading: isOAuth2AuthenticationLoading, resetStates } = useOAuth2ConnectionController();

  const { mutate: connectService, isLoading: isConnectServiceLoading } = useConnectService();
  const { mutate: reConnectService, isLoading: isReconnectServiceLoading } = useReconnectService();
  const { mutate: testIntegrationConnectionSchemaService, isLoading: isTestIntegrationConnectionSchemaLoading } =
    useTestConnectionSchema();
  const { mutate: testConnectionService, isLoading: isTestConnectionServiceLoading } = useTestConnectionService();

  const { data: connectionSchema, isInitialLoading: isConnectionSchemaLoading } = useGetConnectionSchemaService({
    integrationId,
    schemaId,
  });
  const { user } = useGetActiveUser({ enabled: connectionSchema?.slack });
  const hideConnectButton = user?.roleId === 'user' && connectionSchema?.slack;
  const { invalidate } = useInvalidateSearchConnectionsService();
  const { invalidate: invalidateUserConnections } = useInvalidateUserConnectionsService();

  const { addToast } = useToast();

  const isLoading =
    isTestIntegrationConnectionSchemaLoading ||
    isConnectServiceLoading ||
    isReconnectServiceLoading ||
    isTestConnectionServiceLoading ||
    isConnectionSchemaLoading;

  const onSuccess = (resolve: (connection?: Connection) => void, connection?: Connection) => {
    resolve(connection);
    onModalCloseClicked?.({ connection });
  };

  const onError = (resolve: () => void, error: unknown) => {
    if (isAxiosError(error)) {
      setResponse({
        message: JSON.stringify(error?.response?.data, undefined, 2),
        statusCode: error?.response?.status.toString(),
      });
    } else {
      setResponse({
        message: 'Unknown error',
        statusCode: 'Unknown status code',
      });
    }

    resolve();
  };

  const testIntegrationConnectionSchema = async ({ value, resolve }: ConnectionServiceFuncArgs) => {
    assert(
      connectionSchema !== undefined,
      new Error('Connection schema can not be null for testing connection schema service')
    );

    assert(!!integrationId, new Error('Integration id can not be null for testing connection schema service'));

    const { context } = getCleanContext(value);

    testIntegrationConnectionSchemaService(
      {
        connectionId: connectionSchema.id,
        integrationId,
        payload: {
          context,
        },
      },
      {
        onSuccess: (result) => {
          if (isTestConnectionResultError(result)) {
            setResponse({
              message: result.errorMessage,
              statusCode: result.statusCode.toString(),
            });
          } else {
            setResponse(undefined);
            onSuccess(resolve);
            addToast('Connection test passed', { variant: 'success' });
          }
        },
        onError: (error) => {
          onError(resolve, error);
        },
      }
    );
  };

  const testConnection = async ({ value, resolve }: ConnectionServiceFuncArgs) => {
    assert(!!connectionId, new Error('Connection id can not be null for testing connection service'));

    const { context } = getCleanContext(value);

    testConnectionService(
      {
        connectionId,
        payload: {
          context,
        },
      },
      {
        onSuccess: (result) => {
          if (isTestConnectionResultError(result)) {
            setResponse({
              message: result.errorMessage,
              statusCode: result.statusCode.toString(),
            });
          } else {
            setResponse(undefined);
            onSuccess(resolve);
            addToast('Connection test passed', { variant: 'success' });
          }
        },
        onError: (error) => {
          onError(resolve, error);
        },
      }
    );
  };

  const connect = async ({ value, resolve }: ConnectionServiceFuncArgs) => {
    assert(connectionSchema !== undefined, new Error('Connection schema can not be null for connect service'));

    if (connectionSchema.slack) {
      window.open(`${generatePath(ROUTES.Workspace)}/slack`, '_blank');
      onSuccess(resolve);
      return;
    }

    const { context, connectionName } = getCleanContext(value);

    assert(!!connectionName, new Error('Connection name can not be null for connect service'));

    connectService(
      {
        schemaId: connectionSchema.id,
        payload: {
          integrationId,
          name: connectionName,
          context,
        },
      },
      {
        onSuccess: (connectionService) => {
          onSuccess(resolve, connectionService);
        },
        onError: (error) => {
          onError(resolve, error);
        },
      }
    );
  };

  const reconnect = async ({ value, resolve }: ConnectionServiceFuncArgs) => {
    assert(!!connectionId, new Error('Connection id can not be null for reconnect service'));
    assert(connectionSchema !== undefined, new Error('Connection schema can not be null for reconnect service'));

    const { context, connectionName } = getCleanContext(value);

    assert(!!connectionName, new Error('Connection name can not be null for reconnect service'));

    reConnectService(
      {
        connectionId,
        payload: {
          name: connectionName,
          context: context,
        },
      },
      {
        onSuccess: () => {
          onSuccess(resolve);
        },
        onError: (error) => {
          onError(resolve, error);
        },
      }
    );
  };

  const basicConnectionFormSubmit = (value: ConnectionModalForm) => {
    return new Promise<Connection | void>((resolve) => {
      if (mode === 'Test') {
        if (test === 'connection-schema') {
          return testIntegrationConnectionSchema({ value, resolve });
        } else {
          return testConnection({ value, resolve });
        }
      } else if (mode === 'Connect' && !connected) {
        return connect({ value, resolve });
      } else if (mode === 'Connect' && connected) {
        return reconnect({ value, resolve });
      }
      return;
    });
  };

  const oauth2FormSubmit = async (value: ConnectionModalForm) => {
    assert(connectionSchema !== undefined, new Error('Connection schema can not be null'));

    const { context, connectionName } = getCleanContext(value);

    assert(!!connectionName, new Error('Connection name can not be null'));

    return await startOAuthFlow({
      integrationId,
      connectionSchemaId: connectionSchema.id,
      connectionId,
      connectionName,
      context: context,
    });
  };

  const handleFormSubmit = async (value: ConnectionModalForm) => {
    if (isOAuth2Authentication(connectionSchema?.authentication)) {
      const result = await oauth2FormSubmit(value);
      setOAuthPromiseResult(result);
      if (result.type === 'success') {
        invalidate({
          payload: {
            schemaId: connectionSchema?.id,
          },
        });
        invalidateUserConnections();
        onModalCloseClicked?.();
      }
      return { ...result, schemaId: connectionSchema?.id, name: value.name };
    } else {
      return basicConnectionFormSubmit(value);
    }
  };

  const handleClearResponse = () => {
    setResponse(undefined);
    setOAuthPromiseResult(undefined);
    resetStates();
    setSchemaId(initialSchemaId);
    onModalCloseClicked?.();
  };

  return {
    onFormSubmit: handleFormSubmit,
    response,
    onClose: handleClearResponse,
    isLoading: isLoading || isOAuth2AuthenticationLoading,
    oauthPromiseResult,
    connectionSchema,
    isConnectionSchemaLoading,
    hideConnectButton,
    schemaId,
    setSchemaId,
  };
};

function getCleanContext(value: ConnectionModalForm) {
  const { name, selectedSchema, ...context } = value;
  return {
    context,
    connectionName: name,
    selectedSchema,
  };
}
