import { isAxiosError, NvAxios, useAxiosErrorHandler, useMutation, useToast } from '@novaera/core';
import { SLACK_APP_ROOT_PATH } from '../constants';
import { StartSlackAppInstallationParams, StartSlackAppInstallationResponse } from './types';

const startSlackAppInstallation = async ({ appId }: StartSlackAppInstallationParams) => {
  const result = await NvAxios.post<StartSlackAppInstallationResponse>(
    `${SLACK_APP_ROOT_PATH(appId)}/start-installation`
  );
  return result?.data;
};

export const useStartSlackAppInstallation = () => {
  const { axiosErrorHandler } = useAxiosErrorHandler();
  const { addToast } = useToast();

  return useMutation(startSlackAppInstallation, {
    onError: (error) => {
      if (isAxiosError(error)) {
        axiosErrorHandler(error);
      } else if (error instanceof Error) {
        addToast(`The Slack app installation can not be started, reason: ${error.message}`, { variant: 'error' });
      } else {
        addToast(`The Slack app installation can not be started, reason: ${JSON.stringify(error)}`, {
          variant: 'error',
        });
      }
    },
  });
};
