import { useFetchWorkspaceList } from '@novaera/actioner-service';
import { useCallback, useEffect } from 'react';
import { checkWorkspaceLength } from '../../../../common/utils';
import { redirectToWorkspace } from '../../../sign-in/workspace-panel/list/utils';

export const useWorkspaceRedirection = () => {
  const { data } = useFetchWorkspaceList(false);

  const shouldRedirectToWorkspace = useCallback(
    () => checkWorkspaceLength(data?.workspaces.length ?? 0),
    [data?.workspaces.length]
  );

  useEffect(() => {
    if (shouldRedirectToWorkspace() && data) {
      redirectToWorkspace(data?.workspaces[0].subdomain);
    }
  }, [data, data?.workspaces, shouldRedirectToWorkspace]);
};
