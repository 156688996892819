import { ParameterMapping, ParameterTypes } from '@novaera/actioner-service';
import { NvFlex, NvForm } from '@novaera/core';
import { assert, noop } from '@novaera/utils';
import { isEqual, isNil, uniqBy } from 'lodash';
import { FC, useMemo } from 'react';
import { InputFormValues, useInputValuesContext } from '../../../action-designer/providers/input-values';
import { formatAndFilterInputFormValuesAsParameterMappings } from '../../../action-designer/providers/input-values/utils';
import { ParameterMapperItem } from './parameter-mapper-item';
import { PropertyMapperFormProps } from './types';

export const ParameterMapperForm: FC<React.PropsWithChildren<PropertyMapperFormProps>> = ({
  inputParameters,
  onParameterMappingsChanged,
  initialParameterMappings,
  getOptionsResponse,
  getDynamicInputParameters,
  setInputParameterIdsShowingOptions,
  hideInputParameterLabel,
  context,
  dynamicInputParameters,
  showHiddenConfig,
  isDisplayValueConfigurable,
  onScriptedChange,
  getDynamicInputComponentState,
  isForceHideSwitch,
}) => {
  const { setFormValues, inputValues } = useInputValuesContext();

  const hasValue = (parameterMapping: ParameterMapping) => {
    if (parameterMapping.type === ParameterTypes.SIMPLE) {
      const { value } = parameterMapping;
      return !isNil(value);
    } else if (parameterMapping.type === ParameterTypes.SLACK_BLOCK) {
      return parameterMapping.blocks;
    } else if (parameterMapping.type === ParameterTypes.RECORD_ATTRIBUTES) {
      return parameterMapping.fields;
    } else if (parameterMapping.type === ParameterTypes.RECORD_PARTIAL_UPDATE) {
      return parameterMapping.updates;
    } else if (parameterMapping.type === ParameterTypes.JOB_TARGET) {
      return parameterMapping.parameterMappings;
    } else {
      assert(false, new Error('unknown parameter mapping types'), 'ERROR');
    }
  };

  const handleOnChange = (values: InputFormValues) => {
    const currentPropertyMappings = formatAndFilterInputFormValuesAsParameterMappings(
      values,
      [...inputParameters, ...dynamicInputParameters].map((i) => i.id)
    );
    const previousPropertyMappings = formatAndFilterInputFormValuesAsParameterMappings(
      inputValues,
      [...inputParameters, ...dynamicInputParameters].map((i) => i.id)
    );

    if (!isEqual(previousPropertyMappings, currentPropertyMappings)) {
      onParameterMappingsChanged(
        currentPropertyMappings?.filter((inputValue) => {
          const isHiddenInput = showHiddenConfig && inputValue.type === ParameterTypes.SIMPLE && inputValue.hidden;
          if (isHiddenInput) {
            return true;
          } else {
            return hasValue(inputValue);
          }
        })
      );
    }

    setFormValues((prev) => {
      const activeInputParameterIds = [
        ...inputParameters.map(({ id }) => id),
        ...dynamicInputParameters.map(({ id }) => id),
      ];
      const otherParameterMappings = Object.values(prev ?? {}).filter(
        ({ parameterId }) => !activeInputParameterIds.includes(parameterId)
      );
      const result = uniqBy([...Object.values(values), ...(otherParameterMappings ?? [])], 'parameterId');

      return result.reduce((acc, cur) => {
        return { ...acc, [cur.parameterId]: cur };
      }, {});
    });
  };

  const initialValues = useMemo(() => {
    const retVal = initialParameterMappings?.reduce((prev, current) => {
      const parameterId = current.parameterId;
      return { ...prev, [parameterId]: current };
    }, {});
    return retVal;
  }, [initialParameterMappings]);

  return (
    <NvForm<InputFormValues>
      onSubmit={noop}
      onChange={({ values }) => {
        handleOnChange(values);
      }}
      keepDirtyOnReinitialize
      initialValues={initialValues}
      autoSaveProps={{ autoSaveType: 'debounce', debounceDelay: 500 }}
    >
      <NvFlex gap="16px">
        {inputParameters.map((inputParameter) => (
          <ParameterMapperItem
            showHiddenConfig={showHiddenConfig}
            isDisplayValueConfigurable={isDisplayValueConfigurable}
            getDynamicInputParameters={getDynamicInputParameters}
            getOptionsResponse={getOptionsResponse}
            setInputParameterIdsShowingOptions={setInputParameterIdsShowingOptions}
            inputParameter={inputParameter}
            key={inputParameter.id}
            hideLabel={hideInputParameterLabel?.(inputParameter)}
            context={context}
            onScriptedChange={onScriptedChange}
            getDynamicInputComponentState={getDynamicInputComponentState}
            isForceHideSwitch={isForceHideSwitch}
          />
        ))}
      </NvFlex>
    </NvForm>
  );
};
