import { useGetWorkflowExecutionOutputResult } from '@novaera/actioner-service';
import { NvSkeleton, SectionMessage } from '@novaera/core';
import { FC, PropsWithChildren } from 'react';
import { TableView } from './table-viewer';
import { TableOutputProps } from './types';

export const TableOutput: FC<PropsWithChildren<TableOutputProps>> = ({
  alias,
  executionIdentifier,
  userAppId,
  workflowId,
}) => {
  const { data, isLoading } = useGetWorkflowExecutionOutputResult({
    userAppId,
    workflowId,
    executionIdentifier,
    outputAlias: alias,
  });

  return isLoading ? (
    <NvSkeleton variant="rectangular" width="100%" height="305px" />
  ) : data?.type === 'failed-output' ? (
    <SectionMessage variant="error" message={data.error} />
  ) : data?.type === 'table' && data.columns ? (
    <TableView columnResults={data.columns} />
  ) : (
    <SectionMessage variant="error" message={'Cannot draw table'} />
  );
};
