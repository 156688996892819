import {
  NvBarChartIcon,
  NvBorderAllIcon,
  NvKeyValueTableIcon,
  NvPieChartOutlineIcon,
  NvShortTextIcon,
} from '@novaera/core';

export const TAB_ICON = {
  table: <NvBorderAllIcon fontSize="small" />,
  'key-value-table': <NvKeyValueTableIcon fontSize="small" />,
  markdown: <NvShortTextIcon fontSize="small" />,
  chart: {
    bar: <NvBarChartIcon fontSize="small" />,
    pie: <NvPieChartOutlineIcon fontSize="small" />,
  },
};
