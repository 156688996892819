import { DocumentItem, SearchWorkflow } from '@novaera/actioner-service';
import { NodeListItem, NvButton, NvChip, NvDivider, NvFlex } from '@novaera/core';

export const convertAssistantItemToNodeListItem = <T extends DocumentItem | SearchWorkflow>({
  items,
  existingItemIds,
}: {
  items: T[];
  existingItemIds: string[];
}) =>
  items
    .filter((item) => !existingItemIds.includes(item.id))
    .map(
      (item) =>
        ({
          name: item.name,
          type: 'assistant-item',
          extraData: { ...item, isCompact: true },
          rightComponent: (
            <NvFlex direction="row" alignItems="center" justifyContent="space-between" flex="1 1 auto" minWidth={0}>
              <NvFlex direction="row" alignItems="center">
                {item.tags && item.tags.length > 0 && (
                  <>
                    <NvDivider orientation="vertical" sx={{ height: '12px', margin: '0 12px 0 4px' }} />
                    <NvFlex direction="row" alignItems="center" gap="4px">
                      {item.tags.map((tag, index) => (
                        <NvChip key={`assistant-item-tag-${tag}-${index}`} label={tag} />
                      ))}
                    </NvFlex>
                  </>
                )}
              </NvFlex>

              <NvButton color="secondary" size="small">
                Add
              </NvButton>
            </NvFlex>
          ),
        } satisfies NodeListItem<T>)
    );
