import { isRequired, isWordChar, NvFlex, NvMenuItem, NvSelect, NvTextField } from '@novaera/core';
import { DatasourceMapper } from '../../../../action-designer/components/input-tab/components/data-source-mapper';
import { DynamicFieldsDataSource } from '../../../../action-designer/components/input-tab/components/dynamic-fields-data-source';
import { InputParametersSwitch } from '../../../../action-designer/components/input-tab/styled';
import { CommandRequestList } from '../../../../action-designer/components/input-tab/test-action/command-request-list';

import { FormTriggerDatasourceMapper } from '../../../../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/properties-panel/property-panels/form-trigger-property-panel-drawer/form-trigger-properties/components/data-source-mapper';
import { FormTriggerDynamicInput } from '../../../../user-app/user-app-detail/workflow-designer/user-app-workflow-canvas/properties-panel/property-panels/form-trigger-property-panel-drawer/form-trigger-properties/components/dynamic-input';
import { ConstraintMap } from '../../../data-model-layout/right-content/field/validation-properties/constraint-map';
import { FormTriggerDefaultValue } from '../../custom-property-components/form-trigger-default-value';
import { IntegrationActionDefaultValue } from '../../custom-property-components/integration-action-default-value';
import { PropertySection } from '../../types';
import { GetPropertySectionsProps } from './types';

export const getPropertySections = ({
  containerRef,
  configuredEntity,
  idsToFilter,
  ...rest
}: GetPropertySectionsProps): PropertySection[] => {
  const propertyPropsMap: PropertySection[] = [
    {
      propertyPropsList: [
        {
          id: 'basic.name',
          name: 'name',
          labelText: 'ID',
          isRequired: true,
          hasRequiredIndicator: true,
          validators: [isRequired(), isWordChar()],
          component: <NvTextField startLabelAdornment="input." size="small" />,
          labelWidth: '101px',
        },
        {
          id: 'basic.plainText.text',
          name: 'uiComponent.text',
          labelText: 'Paragraph',
          component: <NvTextField multiline rows={3} size="small" />,
          labelWidth: '101px',
        },
        {
          id: 'basic.header.text',
          name: 'uiComponent.text',
          labelText: 'Title',
          component: <NvTextField size="small" />,
          labelWidth: '101px',
        },
        {
          id: 'basic.variant',
          name: 'uiComponent.level',
          labelText: 'Hierarchy',
          component: (
            <NvSelect id="variant" size="small">
              <NvMenuItem value="H1">H1</NvMenuItem>
              <NvMenuItem value="H2">H2</NvMenuItem>
              <NvMenuItem value="H3">H3</NvMenuItem>
              <NvMenuItem value="H4">H4</NvMenuItem>
              <NvMenuItem value="H5">H5</NvMenuItem>
              <NvMenuItem value="H6">H6</NvMenuItem>
            </NvSelect>
          ),
          labelWidth: '101px',
        },
        {
          id: 'basic.label',
          name: 'uiComponent.label',
          labelText: 'Label',
          isRequired: true,
          validators: [isRequired()],
          hasRequiredIndicator: true,
          component: <NvTextField placeholder="Enter label" size="small" />,
          labelWidth: '101px',
        },
        {
          id: 'basic.placeHolder',
          name: 'uiComponent.placeHolder',
          labelText: 'Placeholder',
          component: <NvTextField placeholder="Enter placeholder" size="small" />,
          labelWidth: '101px',
        },
        {
          id: 'basic.hint',
          name: 'uiComponent.hint',
          labelText: 'Helper text',
          component: <NvTextField multiline rows={3} placeholder="Add helper text" size="small" />,
          labelWidth: '101px',
        },
        {
          id: 'jobTarget.basic.name',
          name: 'name',
          labelText: 'Name',
          component: <NvTextField placeholder="Enter a name for slack blocks" size="small" />,
          labelWidth: '101px',
        },
        {
          id: 'basic.required',
          name: 'schema.mandatory',
          labelText: 'Mandatory',
          component: (
            <InputParametersSwitch
              label={`Required fields must be provided when running this ${
                configuredEntity.type === 'integration-action-input-parameter' ? 'action' : 'workflow'
              }.`}
              variant="compact"
            />
          ),
          type: 'checkbox',
          labelWidth: '101px',
        },
        {
          id: 'basic.searchAsTypeConfiguration',
          name: 'uiComponent.searchAsTypeConfiguration.enabled',
          labelText: 'Search',
          component: (
            <InputParametersSwitch
              label="When enabled, this field will support the search-as-you-type capability."
              variant="compact"
            />
          ),
          infoText:
            'Search-as-you-type option supports search queries for requests linked to this field. When you start typing, it will return the partially matched values.',
          type: 'checkbox',
          labelWidth: '101px',
        },
        {
          id: 'basic.freeSolo',
          name: 'uiComponent.freeSolo',
          labelText: 'Custom values',
          component: (
            <InputParametersSwitch label="When enabled, this field accepts custom values." variant="compact" />
          ),
          infoText:
            'Custom values allow users to input their own choices, even if not in the predefined dropdown options.',
          type: 'checkbox',
          labelWidth: '101px',
        },
        {
          id: 'command.basic.label',
          name: 'label',
          labelText: 'Label',
          isRequired: true,
          validators: [isRequired()],
          hasRequiredIndicator: true,
          component: <NvTextField placeholder="Enter a label for your button" size="small" />,
          labelWidth: '101px',
        },
        {
          id: 'slack.basic.name',
          name: 'name',
          labelText: 'Name',
          component: <NvTextField placeholder="Enter a name for slack blocks" size="small" />,
          labelWidth: '101px',
        },
        {
          id: 'recordAttributes.basic.name',
          name: 'name',
          labelText: 'Name',
          component: <NvTextField placeholder="Enter a name for record attributes" size="small" />,
          labelWidth: '101px',
        },
        {
          id: 'recordUpdateAttributes.basic.name',
          name: 'name',
          labelText: 'Name',
          component: <NvTextField placeholder="Enter a name for record update attributes" size="small" />,
          labelWidth: '101px',
        },
        {
          id: 'recordPartialUpdate.basic.name',
          name: 'name',
          labelText: 'Name',
          component: <NvTextField placeholder="Enter a name for record model" size="small" />,
          labelWidth: '101px',
        },
      ],
    },
    {
      title: 'Run steps',
      propertyPropsList: [
        {
          id: 'execute.requests',
          name: 'runSteps',
          hasOwnFieldImplementation: true,
          component: <CommandRequestList />,
        },
      ],
    },
    ...(configuredEntity.type === 'integration-action-input-parameter'
      ? [
          {
            propertyPropsList: [
              {
                id: 'source.requests',
                name: 'uiComponent.dataSource',
                hasOwnFieldImplementation: true,
                component: (
                  <DatasourceMapper containerRef={containerRef} schema={configuredEntity.inputParameter.schema} />
                ),
              },
            ],
          },
        ]
      : []),
    ...(configuredEntity.type === 'integration-action-input-parameter' && rest.type === 'integration-action'
      ? [
          {
            propertyPropsList: [
              {
                id: 'dynamic.input.data.source',
                name: 'uiComponent.dataSource',
                hasOwnFieldImplementation: true,
                component: <DynamicFieldsDataSource integrationVersion={rest.integrationVersion} />,
              },
            ],
            disableDefaultSectionWrapper: true,
          },
        ]
      : []),
    ...(configuredEntity.type === 'form-trigger-input-parameter' && rest.type === 'form-trigger'
      ? [
          {
            propertyPropsList: [
              {
                id: 'source.requests',
                name: 'uiComponent.dataSource',
                hasOwnFieldImplementation: true,
                component: (
                  <FormTriggerDatasourceMapper
                    schema={configuredEntity.inputParameter.schema}
                    onDataSourceIndexChanged={rest.onDataSourceIndexChanged}
                    dataSourceIndex={rest.dataSourceIndex}
                  />
                ),
              },
              {
                id: 'dynamic.input.data.source',
                name: 'uiComponent.dataSource',
                hasOwnFieldImplementation: true,
                component: <FormTriggerDynamicInput />,
              },
            ],
            disableDefaultSectionWrapper: true,
          },
        ]
      : []),

    ...(configuredEntity.type === 'integration-action-input-parameter' &&
    configuredEntity.inputParameter &&
    configuredEntity.inputParameter.schema
      ? [
          {
            title: 'Default value',
            isCollapsible: true,
            propertyPropsList: [
              {
                id: 'basic.defaultValue',
                name: `schema.defaultValue`,
                hasOwnFieldImplementation: true,
                component: (
                  <IntegrationActionDefaultValue name="schema" inputParameter={configuredEntity.inputParameter} />
                ),
              },
            ],
          },
        ]
      : []),
    ...(configuredEntity.type === 'form-trigger-input-parameter' &&
    configuredEntity.inputParameter &&
    configuredEntity.inputParameter.schema
      ? [
          {
            title: 'Default value',
            isCollapsible: true,
            propertyPropsList: [
              {
                id: 'basic.defaultValue',
                name: `schema.defaultValue`,
                hasOwnFieldImplementation: true,
                component: <FormTriggerDefaultValue name="schema" inputParameter={configuredEntity.inputParameter} />,
              },
            ],
          },
        ]
      : []),
    ...((configuredEntity.type === 'integration-action-input-parameter' ||
      configuredEntity.type === 'form-trigger-input-parameter') &&
    configuredEntity.inputParameter &&
    configuredEntity.inputParameter.schema &&
    configuredEntity.inputParameter.schema.constraints &&
    configuredEntity.inputParameter.schema.constraints.length !== 0
      ? [
          {
            id: 'validation',
            title: 'Validations',
            isCollapsible: true,
            propertyPropsList: [
              {
                id: 'validations',
                name: `schema`,
                hasOwnFieldImplementation: true,
                component: (
                  <NvFlex gap="8px">
                    <ConstraintMap
                      constraints={configuredEntity.inputParameter.schema.constraints}
                      fieldSchemaName={`schema`}
                      schemaType={configuredEntity.inputParameter.schema.type}
                    />
                  </NvFlex>
                ),
              },
            ],
          },
        ]
      : []),
  ];

  const filteredPropertyPropsMap: PropertySection[] = [];
  propertyPropsMap.forEach((propertyGroup) => {
    const filteredPropertyPropsList = propertyGroup.propertyPropsList.filter((propertyProp) =>
      idsToFilter.find((i) => i.id === propertyProp.id)
    );
    if (filteredPropertyPropsList && filteredPropertyPropsList.length > 0) {
      filteredPropertyPropsMap.push({
        ...propertyGroup,
        propertyPropsList: filteredPropertyPropsList,
      });
    }
  });

  return filteredPropertyPropsMap;
};
