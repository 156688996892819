import { NodeExecutionSortOrder, useGetWorkflowNodeExecutions } from '@novaera/actioner-service';
import {
  NvButton,
  NvCustomSortDownIcon,
  NvCustomSortUpIcon,
  NvFlex,
  NvFullscreenRounded,
  NvTooltip,
} from '@novaera/core';
import { useParams } from '@novaera/route';
import { useState } from 'react';
import { WorkflowHistoryListHeader } from '../../styled';
import { WorkflowHistoryNodeExecutionsWrapper } from './styled';
import { WorkflowHistoryNodeExecution } from './workflow-history-node-execution';
import { WorkflowHistoryNodeExecutionLoadingItem } from './workflow-history-node-execution/loading-item';

export const WorkflowHistoryNodeExecutions = () => {
  const { userAppId, workflowId, workflowExecutionId } = useParams();
  const [fullScreen, setFullScreen] = useState<boolean>(true);
  const [sortOrder, setSortOrder] = useState<NodeExecutionSortOrder>(NodeExecutionSortOrder.EARLIEST_FIRST);
  const [selectedNodeExecutionObjectKeys, setSelectedNodeExecutionObjectKeys] = useState<string[]>([]);

  const {
    data: workflowNodeExecutionsQueries,
    isInitialLoading: isWorkflowExecutionLoading,
    fetchNextPage: fetchNextWorkflowNodeExecutionsPage,
    hasNextPage: hasNextWorkflowNodeExecutionsPage,
    isFetchingNextPage: isFetchingNextWorkflowNodeExecutionsPage,
  } = useGetWorkflowNodeExecutions({
    appId: userAppId,
    workflowId,
    workflowExecutionId,
    'sort-order': sortOrder,
  });

  const workflowNodeExecutionsPages = workflowNodeExecutionsQueries?.pages.filter((p) => p);

  return (
    <WorkflowHistoryNodeExecutionsWrapper fullScreen={fullScreen}>
      <WorkflowHistoryListHeader
        title="Run order"
        actions={
          <>
            <NvTooltip title={sortOrder === NodeExecutionSortOrder.EARLIEST_FIRST ? 'Earliest first' : 'Latest first'}>
              <NvButton
                onlyIcon
                size="small"
                color="ghost"
                onClick={() => {
                  setSortOrder((prev) =>
                    prev === NodeExecutionSortOrder.EARLIEST_FIRST
                      ? NodeExecutionSortOrder.LATEST_FIRST
                      : NodeExecutionSortOrder.EARLIEST_FIRST
                  );
                }}
              >
                {sortOrder === NodeExecutionSortOrder.EARLIEST_FIRST ? (
                  <NvCustomSortUpIcon />
                ) : (
                  <NvCustomSortDownIcon />
                )}
              </NvButton>
            </NvTooltip>
            <NvButton onlyIcon size="small" color="ghost" onClick={() => setFullScreen((prev) => !prev)}>
              <NvFullscreenRounded />
            </NvButton>
          </>
        }
      />
      {isWorkflowExecutionLoading ? (
        <NvFlex>
          <WorkflowHistoryNodeExecutionLoadingItem />
          <WorkflowHistoryNodeExecutionLoadingItem />
          <WorkflowHistoryNodeExecutionLoadingItem />
        </NvFlex>
      ) : (
        workflowNodeExecutionsPages?.map(({ logs }) =>
          logs.map((log) => (
            <WorkflowHistoryNodeExecution
              key={log.objectKey}
              nodeExecution={log}
              selectedNodeExecutionObjectKeys={selectedNodeExecutionObjectKeys}
              handleItemClick={() => {
                setSelectedNodeExecutionObjectKeys((prev) =>
                  prev.includes(log.objectKey) ? prev.filter((p) => p !== log.objectKey) : [...prev, log.objectKey]
                );
              }}
            />
          ))
        )
      )}
      {hasNextWorkflowNodeExecutionsPage && (
        <NvFlex alignItems="flex-start" padding="16px" flex="0 0 auto">
          <NvButton
            size="small"
            color="secondary"
            onClick={() => {
              fetchNextWorkflowNodeExecutionsPage();
            }}
            loading={isFetchingNextWorkflowNodeExecutionsPage}
            disabled={isFetchingNextWorkflowNodeExecutionsPage}
          >
            Show more
          </NvButton>
        </NvFlex>
      )}
    </WorkflowHistoryNodeExecutionsWrapper>
  );
};
