import {
  AccessType,
  Group,
  UseCreateGroupParams,
  useCreateGroup,
  useDeleteGroup,
  useGetGroups,
} from '@novaera/actioner-service';
import {
  NvAddBoxIcon,
  NvButton,
  NvDeleteOutlineIcon,
  NvDialogModal,
  NvDivider,
  NvFlex,
  NvSearchEmptyState,
  NvSkeleton,
  NvTypography,
  useConfirmDialog,
} from '@novaera/core';
import { lowerCase } from 'lodash';
import { useMemo, useState } from 'react';
import { EmptyState } from '../../../components/empty-state';
import { EmptyStates } from '../../../components/empty-state/types';
import { UserAndGroupImage } from '../../../components/user-and-group-image';
import { SearchAndButtonBar } from '../components/search-and-add-button';
import { DEFAULT_GROUP_HELPER_TEXT } from './constants';
import { GroupsCreateUpdateModalBody } from './groups-create-update-modal-body';
import { GroupBaseItem } from './styled';
import { isDefaultGroup } from './utils';

export const Groups = () => {
  const { data, isInitialLoading: isLoadingUsers } = useGetGroups();
  const { mutate, isLoading: isCreatingGroup } = useCreateGroup();
  const [isOpened, setIsOpened] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const { openConfirm } = useConfirmDialog();
  const { mutate: deleteGroup } = useDeleteGroup();

  const handleRemoveGroup = (group: Group) => {
    openConfirm({
      message: 'Are you sure?',
      onConfirm: () => {
        deleteGroup({ id: group.id });
      },
    });
  };

  const handleOnSubmit = (values: UseCreateGroupParams) => {
    return mutate(values, {
      onSuccess: () => {
        setIsOpened(false);
      },
    });
  };

  const groups = useMemo(
    () =>
      (data?.groups
        ? data.groups.filter((group) => group.name.toLocaleLowerCase().includes(lowerCase(searchKeyword)))
        : []
      ).sort((g) => (isDefaultGroup(g.id) ? -1 : 1)),
    [data?.groups, searchKeyword]
  );

  return isLoadingUsers ? (
    <NvFlex gap="16px">
      <NvFlex flexDirection="row" justifyContent="space-between">
        <NvSkeleton variant="rectangular" width={'100px'} height={'16px'} />
        <NvSkeleton variant="rectangular" width={'100px'} height={'16px'} />
      </NvFlex>
      <NvSkeleton variant="rectangular" height={'16px'} />
      <NvSkeleton variant="rectangular" height={'16px'} />
      <NvSkeleton variant="rectangular" height={'16px'} />
    </NvFlex>
  ) : (
    <>
      <NvFlex gap="16px">
        {data?.groups && data.groups.length > 0 && (
          <SearchAndButtonBar
            buttonLabel="Add group"
            onButtonClick={() => {
              setIsOpened(true);
            }}
            setSearchUserValue={(keyword) => {
              setSearchKeyword(keyword);
            }}
          />
        )}

        {groups.length > 0 ? (
          groups.map((group) => (
            <GroupBaseItem
              key={`group_item_${group.id}`}
              tags={!isDefaultGroup(group.id) ? group.tags : []}
              name={
                <NvFlex direction="row" gap="16px" alignItems="center" width="100%">
                  <NvFlex
                    direction="row"
                    alignItems="center"
                    gap="12px"
                    sx={!isDefaultGroup(group.id) ? { flex: '1 1 auto', minWidth: 0 } : {}}
                  >
                    <UserAndGroupImage src={group.logoUrl} id={group.id} type={AccessType.GROUP} />
                    <NvTypography variant="h4" noWrap>
                      {group.name}
                    </NvTypography>
                  </NvFlex>

                  {isDefaultGroup(group.id) && (
                    <NvFlex
                      direction="row"
                      alignItems="center"
                      gap="8px"
                      marginLeft="-8px"
                      flex="1 1 auto"
                      minWidth={0}
                    >
                      <NvDivider orientation="vertical" sx={{ height: '12px' }} />
                      <NvTypography variant="body2" textColor="subtle" noWrap>
                        {DEFAULT_GROUP_HELPER_TEXT[group.id]}
                      </NvTypography>
                    </NvFlex>
                  )}
                </NvFlex>
              }
              to={group.id}
              actions={
                !isDefaultGroup(group.id) && (
                  <NvFlex alignItems="center" flexDirection="row" gap="8px" flex="0 0 auto">
                    <NvTypography variant="h7" textColor="subtle">{`${group.totalUsers} USERS`}</NvTypography>
                    <NvDivider orientation="vertical" sx={{ height: '12px' }} />
                    <NvButton
                      onlyIcon
                      variant="contained"
                      color="ghost"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        handleRemoveGroup(group);
                      }}
                    >
                      <NvDeleteOutlineIcon />
                    </NvButton>
                  </NvFlex>
                )
              }
              disabled={isDefaultGroup(group.id)}
            />
          ))
        ) : data?.groups && data.groups.length > 0 ? (
          <NvSearchEmptyState text="No group found. Try different words or clear search bar." />
        ) : (
          <EmptyState
            variant={EmptyStates.GROUPS}
            CustomButton={
              <NvButton color="secondary" onClick={() => setIsOpened(true)} startIcon={<NvAddBoxIcon />} size="small">
                Add group
              </NvButton>
            }
          />
        )}
      </NvFlex>
      <NvDialogModal<UseCreateGroupParams>
        onFormSubmit={handleOnSubmit}
        maxWidth="sm"
        fullWidth
        open={isOpened}
        onCloseButtonClicked={() => setIsOpened(false)}
        Header="Add group"
        modalIcon="add"
        Body={<GroupsCreateUpdateModalBody />}
        isLoading={isCreatingGroup}
      />
    </>
  );
};
