import { Context, ContextCancelation, Plugin } from '@segment/analytics-next';

export const AnalyticsSwitchPlugin = ({ isClosed }: { isClosed: boolean }): Plugin => {
  const gateKeep = (ctx: Context) => {
    if (!isClosed) {
      return ctx;
    }
    ctx.cancel(
      new ContextCancelation({
        reason: 'Analytics closed for dev environments.',
        retry: false,
      })
    );
  };

  return {
    name: 'Analytics Switch Event Delivery Plugin',
    type: 'before', // Only `before` plugins can prevent event delivery
    version: '1.0.0',
    load: () => Promise.resolve(),
    isLoaded: () => true,
    alias: (ctx: Context) => gateKeep(ctx),
    identify: (ctx: Context) => gateKeep(ctx),
    group: (ctx: Context) => gateKeep(ctx),
    page: (ctx: Context) => gateKeep(ctx),
    track: (ctx: Context) => gateKeep(ctx),
  };
};
