import {
  InputParameter,
  InputParameterValues,
  SetupWorkflow,
  SimpleParameterMapping,
  WorkflowState,
  WorkflowWithState,
  isSetupWorkflowType,
  useGetFormParameters,
} from '@novaera/actioner-service';
import { useFormState } from '@novaera/core';
import { assert } from '@novaera/utils';
import { Dispatch, SetStateAction, createContext, useCallback, useContext, useState } from 'react';
import { useFormIdentifierContext } from '../../../../../../providers/form-identifier-provider';

type OnInputValueChangedType = {
  inputParameterId: string;
  value: unknown;
};

type FormParameterContextValueType = {
  onInputValueChanged: (param: OnInputValueChangedType) => void;
  inputParameters?: InputParameter[];
  setEnabledInputParameterIdToFetchOptions: Dispatch<SetStateAction<string[]>>;
  enabledInputParameterIdToFetchOptions?: string[];
  isInitialLoading?: boolean;
  isLoading?: boolean;
  formId: string;
  isInputParameterNewlyAdded: (inputParameterId: string) => boolean;
};

const FormParameterContext = createContext<FormParameterContextValueType | undefined>(undefined);

export const FormParameterProvider = ({
  children,
  workflow,
}: {
  workflow: WorkflowWithState | SetupWorkflow;
  children: React.ReactNode | ((props: FormParameterContextValueType) => React.ReactNode);
}) => {
  const [changedParameterId, setChangedParameterId] = useState<string | undefined>();

  const [enabledInputParameterIdToFetchOptions, setEnabledInputParameterIdToFetchOptions] = useState<string[]>([]);

  const [parameterValues, setParameterValues] = useState<SimpleParameterMapping<InputParameterValues>[]>([]);

  const [newAddedFormIds, setNewAddedFormIds] = useState<string[]>([]);

  const { values } = useFormState();

  const { getFormIdV2, setFormIdV2 } = useFormIdentifierContext();

  const {
    data: formParameters,
    isInitialLoading,
    isFetching: isLoading,
  } = useGetFormParameters({
    appId: workflow.appId,
    workflowId: workflow.id,
    payload: {
      isDraft: isSetupWorkflowType(workflow) ? false : workflow.state === WorkflowState.DRAFT,
      formId: getFormIdV2(),
      changedParameterId,
      parameterMappings: parameterValues,
    },
    onSuccess: (data) => {
      if (formParameters) {
        const diff = data.inputParameterWithValues
          .map((p) => p.inputParameter.id)
          .filter((x) => !formParameters.inputParameterWithValues.map((p) => p.inputParameter.id).includes(x));
        setNewAddedFormIds(diff);
      }
      setFormIdV2(data.formId);
    },
  });

  const handleIsInputParameterNewlyAdded = useCallback(
    (inputParameterId: string) => {
      return newAddedFormIds.includes(inputParameterId);
    },
    [newAddedFormIds]
  );

  const handleInputValueChanged = useCallback(
    ({ inputParameterId, value }: OnInputValueChangedType) => {
      const inputFormValues = { ...values };

      // if value added to form values, set it
      if (value) {
        inputFormValues[inputParameterId] = value;
      } else {
        // get parameter value from formParameters and remove values part to add inputFormValues
        const parameterMapping = formParameters?.inputParameterWithValues.find(
          (p) => p.inputParameter.id === inputParameterId
        )?.parameterMapping;

        if (parameterMapping) {
          inputFormValues[inputParameterId] = {
            ...parameterMapping,
            value: {
              type: parameterMapping.value?.type,
            },
          };
        }
      }

      const parameterValues: SimpleParameterMapping<InputParameterValues>[] = [];
      Object.keys(inputFormValues).forEach((key) => {
        parameterValues.push(inputFormValues[key] as SimpleParameterMapping<InputParameterValues>);
      });

      setParameterValues(parameterValues);
      setChangedParameterId(inputParameterId);
    },
    [formParameters?.inputParameterWithValues, values]
  );

  const inputParameters = formParameters?.inputParameterWithValues.map((p) => p.inputParameter);

  return (
    <FormParameterContext.Provider
      value={{
        onInputValueChanged: handleInputValueChanged,
        setEnabledInputParameterIdToFetchOptions: setEnabledInputParameterIdToFetchOptions,
        enabledInputParameterIdToFetchOptions,
        isInitialLoading,
        isLoading,
        inputParameters,
        formId: getFormIdV2(),
        isInputParameterNewlyAdded: handleIsInputParameterNewlyAdded,
      }}
    >
      {typeof children === 'function'
        ? children({
            onInputValueChanged: handleInputValueChanged,
            inputParameters,
            setEnabledInputParameterIdToFetchOptions: setEnabledInputParameterIdToFetchOptions,
            enabledInputParameterIdToFetchOptions,
            isInitialLoading,
            isLoading,
            formId: getFormIdV2(),
            isInputParameterNewlyAdded: handleIsInputParameterNewlyAdded,
          })
        : children}
    </FormParameterContext.Provider>
  );
};

export const useFormParameterProvider = () => {
  const context = useContext(FormParameterContext);
  assert(!!context, new Error(`useFormParameterProvider should be used within FormParameterProvider`), 'ERROR');

  return context;
};
