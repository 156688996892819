import { USER_PERMISSION } from '@novaera/actioner-service';
import { NvButton, NvDialogModal, NvSkeleton, NvTypography } from '@novaera/core';
import { useGetSlackAuth } from '@novaera/identity-provider';
import { getSubdomain } from '@novaera/utils';
import { useState } from 'react';
import { UserPermissionBoundary } from '../../../../../../user-permission-boundary';

export const ConnectSlackButton: React.FC<{ ButtonBase: React.FC<{ onClick: () => void }> }> = ({ ButtonBase }) => {
  const { slackAuthUrl, isLoading } = useGetSlackAuth();
  const [isContactWithAdminModalOpen, setIsContactWithAdminModalOpen] = useState<boolean>(false);
  return isLoading ? (
    <NvSkeleton variant="rectangular" height="24px" width="64px" />
  ) : (
    <>
      <UserPermissionBoundary
        permission={USER_PERMISSION.WORKSPACE_CONNECT_SLACK}
        Fallback={
          <ButtonBase
            onClick={() => {
              setIsContactWithAdminModalOpen(true);
            }}
          />
        }
      >
        <ButtonBase
          onClick={() => {
            if (slackAuthUrl) {
              window.open(slackAuthUrl, '_blank');
            }
          }}
        />
      </UserPermissionBoundary>
      {isContactWithAdminModalOpen && (
        <NvDialogModal
          onCloseButtonClicked={() => {
            setIsContactWithAdminModalOpen(false);
          }}
          open={isContactWithAdminModalOpen}
          modalIcon="power"
          Header="Connect"
          Body={
            <NvTypography>
              <b>{getSubdomain() && getSubdomain().length > 0 ? getSubdomain() : 'This workspace'}</b> has not connected
              to any Slack workspaces yet. Contact with your workspace admin to set up a Slack connection for this
              workspace.
            </NvTypography>
          }
          Footer={
            <NvButton
              color="secondary"
              onClick={() => {
                setIsContactWithAdminModalOpen(false);
              }}
            >
              Cancel
            </NvButton>
          }
        />
      )}
    </>
  );
};
