import { WorkflowState, WorkflowWithState } from '@novaera/actioner-service';
import { NvDialogModal, NvPlayArrowIcon, NvTooltip } from '@novaera/core';
import { assert } from '@novaera/utils';
import { PropsWithChildren, useEffect } from 'react';
import { InputFormValues } from '../../../../../../action-designer/providers/input-values';
import { MissingConnectionModal } from '../../../../../../integrations/components/missing-connection/modal';
import { useFormIdentifierContext } from '../../../../../../providers/form-identifier-provider';
import { useWorkflowReferenceOutputProvider } from '../../providers/workflow-reference-output-provider';
import { WorkflowOutputRefRunImmediatelyParams } from '../../providers/workflow-reference-output-provider/types';
import { WorkflowStickyPanelButton } from '../styled';
import { WorkflowRunModalBody } from './body';
import { useExecuteWorkflow } from './controllers/use-execute-workflow';
import { useWorkflowRun } from './controllers/use-workflow-run';
import { WorkflowRunPanelFooter } from './footer';

export const WorkflowRun = ({
  workflow,
  currentPage,
  workflowOutputRefParams,
  initialFormValues,
  isWorkflowOutputLoading,
  isSaveProgress,
}: PropsWithChildren<{
  workflow?: WorkflowWithState;
  initialFormValues?: InputFormValues;
  currentPage: number;
  workflowOutputRefParams?: WorkflowOutputRefRunImmediatelyParams;
  isWorkflowOutputLoading?: boolean;
  isSaveProgress: boolean;
}>) => {
  const { getFormIdV2 } = useFormIdentifierContext();

  const { resetWorkflowReferenceOutputs, onExecutionRunFinished, updateFormValues } =
    useWorkflowReferenceOutputProvider();

  assert(workflow !== undefined, new Error('For workflow run, workflow can not be undefined'));

  const { onExecuteWorkflow, isLoading, result, cleanResult, executionIdentifier } = useExecuteWorkflow({
    workflowId: workflow.id,
    workflowTriggerType: workflow?.trigger?.type,
    formId: getFormIdV2(),
    isDraft: workflow?.state === WorkflowState.DRAFT,
    onExecutionRunFinished,
    workflowOutputRefParams,
  });

  const {
    onRunClick,
    isRunModalOpened,
    onRunModalCloseClicked,
    isConnectionMappingModalOpened,
    onConnectionMappingModalClosed,
    connectionValidationCheckResult,
    onConnectionsCompleted,
  } = useWorkflowRun({ workflow });

  const handleModalClose = () => {
    onRunModalCloseClicked();
    // this is for not to see cleaned result before closing the modal
    setTimeout(cleanResult, 0);
    resetWorkflowReferenceOutputs();
  };

  useEffect(() => {
    if (workflowOutputRefParams?.runImmediately && workflow?.id === workflowOutputRefParams?.workflowId) {
      // trigger immediately workflow run if workflow reference is set to run immediately
      initialFormValues && onExecuteWorkflow(initialFormValues);
    }
  }, [
    initialFormValues,
    onExecuteWorkflow,
    workflow?.id,
    workflowOutputRefParams?.runImmediately,
    workflowOutputRefParams?.workflowId,
  ]);

  useEffect(() => {
    if (!workflowOutputRefParams?.runImmediately) {
      cleanResult();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflow?.id]);

  const modalHeader =
    !isWorkflowOutputLoading && !isLoading && result
      ? 'Workflow Result'
      : currentPage === 0
      ? 'Run Workflow'
      : `Run ${workflow?.name}`;

  return (
    <>
      <NvTooltip title="Test workflow" placement="top">
        <WorkflowStickyPanelButton
          id="run-workflow-button"
          startIcon={<NvPlayArrowIcon />}
          color="success"
          size="small"
          onClick={onRunClick}
          disabled={isSaveProgress}
          loading={isSaveProgress}
        >
          Run
        </WorkflowStickyPanelButton>
      </NvTooltip>
      <NvDialogModal<InputFormValues>
        formProps={{
          initialValues: initialFormValues,
        }}
        unmountOnExit
        modalIcon="playArrow"
        Header={modalHeader}
        fullWidth
        maxWidth="md"
        onCloseButtonClicked={handleModalClose}
        open={isRunModalOpened}
        primaryButtonText="Run"
        isLoading={isLoading}
        onFormSubmit={(values) => {
          updateFormValues({
            values,
          });
          onExecuteWorkflow(values);
        }}
        Body={
          <WorkflowRunModalBody
            key={`workflow-run-modal-body-${workflow.id}`}
            workflow={workflow}
            isLoading={isLoading}
            result={result}
            executionIdentifier={executionIdentifier}
          />
        }
        Footer={
          <WorkflowRunPanelFooter
            workflow={workflow}
            isLoading={Boolean(isLoading || isWorkflowOutputLoading)}
            isResult={!!result && result.status !== 'in_progress'}
            onCloseClicked={handleModalClose}
            currentPage={currentPage}
          />
        }
      />
      {workflow.trigger?.type === 'form' && (
        <MissingConnectionModal
          connectionValidationCheckResult={connectionValidationCheckResult}
          onConnectionsCompleted={onConnectionsCompleted}
          isOpened={isConnectionMappingModalOpened}
          onModalClosed={onConnectionMappingModalClosed}
          workflowName={workflow.name}
        />
      )}
    </>
  );
};
