import { WorkflowWithState, useGetWorkflow, useSaveToDraftWorkflow } from '@novaera/actioner-service';
import { useCallback } from 'react';
import { useGetWorkflowQueryParams } from '../../../../../../../controllers/use-get-workflow-query-params';

export const useEmptyDefaultPrinciple = () => {
  const { mutate: saveToDraft, isLoading: isSaveLoading } = useSaveToDraftWorkflow();
  const { userAppId, workflowId } = useGetWorkflowQueryParams();
  const { workflow } = useGetWorkflow({ appId: userAppId, workflowId });

  const emptyDefaultPrinciple = useCallback(() => {
    const trigger = workflow?.trigger;
    if (trigger) {
      const { defaultStarterUserId, ...restOfTrigger } = trigger;

      const newWorkflow: WorkflowWithState = {
        ...workflow,
        trigger: restOfTrigger.type === 'form' ? { ...restOfTrigger, useDefaultUserAsPrimeUser: false } : restOfTrigger,
      };
      saveToDraft(newWorkflow);
    }
  }, [saveToDraft, workflow]);

  return { emptyDefaultPrinciple, isSaveLoading };
};
