import { NvAxios, useQuery } from '@novaera/core';
import { ASSISTANTS_ROOT_PATH } from '../constants';

import { isAxiosError } from 'axios';
import { QUERY_KEYS_ASSISTANTS } from '../keys';
import { Assistant } from '../types';
import { GetAssistantParams } from './types';

const getAssistant = async ({ appId }: GetAssistantParams) => {
  const result = await NvAxios.get<Assistant>(`${ASSISTANTS_ROOT_PATH(appId)}`);
  return result?.data;
};

export const useGetAssistant = ({ appId }: GetAssistantParams) => {
  return useQuery(QUERY_KEYS_ASSISTANTS.detail({ appId }), () => getAssistant({ appId }), {
    enabled: !!appId,
    retry: (count, error) => {
      const isNotFound = isAxiosError(error) && error.response?.status === 404;
      return !isNotFound;
    },
    onError: (error) => {
      if (isAxiosError(error) && error.response?.status === 404) {
        // do nothing
      } else {
        throw error;
      }
    },
  });
};
