import { NvAxios, useQuery } from '@novaera/core';
import { CONNECTION_ROOT_PATH } from '../constants';
import { QUERY_KEYS_CONNECTION } from '../keys';

import { useQueryClient } from '@tanstack/react-query';
import { SearchConnectionResult, UseSearchConnectionsParams } from './types';

const searchConnection: (params: UseSearchConnectionsParams) => Promise<SearchConnectionResult> = async (params) => {
  const { payload } = params;
  const result = await NvAxios.post<SearchConnectionResult>(`${CONNECTION_ROOT_PATH}/search`, { ...payload });
  return result?.data;
};

export const useSearchConnectionsService = (params: UseSearchConnectionsParams) => {
  return useQuery<SearchConnectionResult>(
    QUERY_KEYS_CONNECTION.search(params.payload),
    () => searchConnection(params),
    {
      enabled: params.enabled === undefined ? !!params.payload.schemaId : params.enabled && !!params.payload.schemaId,
    }
  );
};

/**
 * When a connection is created using OAuthService, we need to invalidate the search query using the hook below
 */
export const useInvalidateSearchConnectionsService = () => {
  const cache = useQueryClient();

  return {
    invalidate: (params: UseSearchConnectionsParams) => {
      cache.invalidateQueries(QUERY_KEYS_CONNECTION.search(params.payload));
    },
  };
};
