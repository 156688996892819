import { NodeExecutionStatus } from '@novaera/actioner-service';
import { NvTypography } from '@novaera/core';
import { useTheme } from '@novaera/theme-provider';
import { upperFirst } from 'lodash';

export const WorkflowHistoryNodeExecutionStatusLabel: React.FC<
  React.PropsWithChildren<{ status: NodeExecutionStatus }>
> = ({ status }) => {
  const theme = useTheme();
  return status === NodeExecutionStatus.SUCCESSFUL ? (
    <NvTypography variant="c2" sx={{ color: theme.palette.nv_success[60] }}>
      <b>{upperFirst(status)}</b>
    </NvTypography>
  ) : status === NodeExecutionStatus.IN_PROGRESS ? (
    <NvTypography variant="c2" sx={{ color: theme.palette.nv_neutral[600] }}>
      <b>{upperFirst(status)}</b>
    </NvTypography>
  ) : status === NodeExecutionStatus.DISCARDED ? (
    <NvTypography variant="c2" sx={{ color: theme.palette.nv_main[40] }}>
      <b>{upperFirst(status)}</b>
    </NvTypography>
  ) : status === NodeExecutionStatus.NOT_EXECUTED ? (
    <NvTypography variant="c2">
      <b>Not executed</b>
    </NvTypography>
  ) : status === NodeExecutionStatus.FAILED ? (
    <NvTypography variant="c2" sx={{ color: theme.palette.nv_error[40] }}>
      <b>{upperFirst(status)}</b>
    </NvTypography>
  ) : null;
};
