import { CatalogEntityType } from '@novaera/actioner-service';
import { NvButton, NvCloseIcon, NvFlex } from '@novaera/core';
import { getStringifiedValue } from '@novaera/utils';
import { useMemo } from 'react';
import { PropertyPanelHeader } from '../../../../components';
import { CatalogDetailPanelItem } from './item';

export const CatalogDetailPanel = ({
  catalogEntity,
  entityType,
  onDetailPanelClose,
}: {
  catalogEntity?: Record<string, unknown>;
  entityType: CatalogEntityType;
  onDetailPanelClose: () => void;
}) => {
  const entityItems = useMemo(() => {
    const items: {
      label: string;
      value: string | undefined;
    }[] = [
      {
        label: 'Id',
        value: getStringifiedValue(catalogEntity?.id),
      },
    ];

    entityType.parameters?.forEach((parameter) => {
      items.push({
        label: parameter.name,
        value: getStringifiedValue(catalogEntity?.[parameter.name]),
      });
    });

    if (catalogEntity?.customProperties) {
      items.push({
        label: 'Custom properties',
        value: JSON.stringify(catalogEntity.customProperties),
      });
    }

    return items;
  }, [catalogEntity, entityType.parameters]);

  return (
    <NvFlex flexDirection={'column'} width={'100%'}>
      <PropertyPanelHeader
        title={entityType.name}
        actions={
          <NvButton
            onlyIcon
            size="small"
            color="secondary"
            onClick={() => {
              onDetailPanelClose();
            }}
          >
            <NvCloseIcon />
          </NvButton>
        }
      />
      <NvFlex flexDirection={'column'} gap={'8px'} padding="16px">
        {entityItems?.map(({ label, value }) => {
          return <CatalogDetailPanelItem key={label} label={label} value={value} />;
        })}
      </NvFlex>
    </NvFlex>
  );
};
