import {
  APP_PERMISSION,
  useCreateAssistant,
  useDeleteAssistant,
  useGetAssistant,
  useSyncAssistant,
} from '@novaera/actioner-service';
import { NvButton, NvDeleteOutlineIcon, NvFlex, NvSmartToyIcon, NvTypography, useConfirmDialog } from '@novaera/core';
import { useParams } from '@novaera/route';
import { useTheme } from '@novaera/theme-provider';
import React from 'react';
import { Header } from '../../../components';
import { EmptyState } from '../../../components/empty-state';
import { EmptyStates } from '../../../components/empty-state/types';
import { UserAppPageLayoutWrapper } from '../../../components/user-app-page-layout/styled';
import { UserAppPermissionBoundary } from '../../user-app-permission-boundary';
import { FreeAppPermissionBoundary } from '../../user-app-permission-boundary/free-app-permission-boundary';
import { useIsAppFree } from '../../user-app-permission-boundary/free-app-permission-boundary/use-free-app-permission-boundary';
import { Documents } from './documents';
import { Instructions } from './instructions';
import { AIAssistantsLoading } from './loading';
import { AssistantPageLayoutWrapper } from './styled';
import { Workflows } from './workflows';

export const AIAssistant: React.FC = () => {
  const theme = useTheme();
  const { userAppId } = useParams();
  const { openConfirm, closeConfirm } = useConfirmDialog();
  const { data: assistant, isInitialLoading: isAssistantLoading } = useGetAssistant({ appId: userAppId });
  const { mutate: createAssistant, isLoading: isCreateAssistantLoading } = useCreateAssistant();
  const { mutate: deleteAssistant, isLoading: isDeleteAssistantLoading } = useDeleteAssistant();
  const { mutate: syncAssistant, isLoading: isSyncAssistantLoading } = useSyncAssistant();
  const { isAppFree } = useIsAppFree({ userAppId });

  const handleCreateAIAssistant = () => {
    createAssistant({ appId: userAppId });
  };

  const handleDeleteAIAssistant = () => {
    openConfirm({
      title: 'Delete AI assistant?',
      message: (
        <NvTypography variant="body1">
          This assistant will no longer be available. If there are other workflows depend on this assistant, some nodes
          in such workflows may be also impacted.
        </NvTypography>
      ),
      confirmButton: (
        <NvButton
          variant="contained"
          size="medium"
          color="error"
          loading={isDeleteAssistantLoading}
          disabled={isDeleteAssistantLoading}
          onClick={() => {
            deleteAssistant(
              { appId: userAppId },
              {
                onSuccess: () => {
                  closeConfirm();
                },
              }
            );
          }}
        >
          Delete
        </NvButton>
      ),
    });
  };

  const handleSaveAIAssistant = () => {
    syncAssistant({ appId: userAppId });
  };

  return isAssistantLoading ? (
    <AIAssistantsLoading />
  ) : !assistant ? (
    <UserAppPageLayoutWrapper>
      <EmptyState
        variant={EmptyStates.AI_ASSISTANT}
        CustomButton={
          <FreeAppPermissionBoundary appId={userAppId}>
            <UserAppPermissionBoundary appId={userAppId} permission={APP_PERMISSION.APP_EDIT}>
              <NvButton
                startIcon={<NvSmartToyIcon />}
                color="primary"
                onClick={handleCreateAIAssistant}
                size="small"
                sx={{ flex: '0 0 auto' }}
                loading={isCreateAssistantLoading}
                disabled={isCreateAssistantLoading}
              >
                Create AI assistant
              </NvButton>
            </UserAppPermissionBoundary>
          </FreeAppPermissionBoundary>
        }
      />
    </UserAppPageLayoutWrapper>
  ) : (
    <AssistantPageLayoutWrapper>
      <NvFlex gap="24px" width="100%">
        <Header
          name={'AI assistant'}
          icon={<NvSmartToyIcon />}
          actions={
            !isAppFree
              ? [
                  {
                    name: 'Delete AI assistant',
                    onClick: handleDeleteAIAssistant,
                    icon: (
                      <NvDeleteOutlineIcon
                        htmlColor={theme.palette.nv_error[40]}
                        sx={{ width: '16px', height: '16px' }}
                      />
                    ),
                  },
                ]
              : []
          }
          hasChange
          buttonActions={
            <FreeAppPermissionBoundary appId={userAppId}>
              <NvButton
                size="small"
                onClick={handleSaveAIAssistant}
                loading={isSyncAssistantLoading}
                disabled={isSyncAssistantLoading}
              >
                Update ai assistant
              </NvButton>
            </FreeAppPermissionBoundary>
          }
        />
        <NvFlex width="100%" padding="0 24px" alignItems="center">
          <NvFlex width="100%" maxWidth="768px">
            <Instructions assistantInstructions={assistant.instructions} />
            <Documents assistantDocuments={assistant.documents} />
            <Workflows assistantWorkflows={assistant.workflows} />
          </NvFlex>
        </NvFlex>
      </NvFlex>
    </AssistantPageLayoutWrapper>
  );
};
