import { ApplicationConfig } from '@novaera/application-config';
import { LOCAL_STORAGE_ACCESS_KEY, ROUTE_DEFAULTS, TARGET_URI } from '@novaera/constants';
import { AxiosError, NvAxios, isAxiosError } from '@novaera/core';
import { assert, setCookieForSpecificDomain } from '@novaera/utils';
import Cookies from 'js-cookie';
import { TokenState } from '../../../../common/constants';
import { ErrorType } from '../../../../common/types';
import { setAccessToken } from '../../../../common/utils';
import { refreshAccessToken } from '../../../../services/endpoints';
import { getPathForTargetURI } from '../../utils';

const isGoToLogin = (error: AxiosError) => {
  return error.response?.headers['ErrorCode'] === '4011' || error.response?.headers['errorcode'] === '4011';
};

export const responseRejected = async function (error: ErrorType) {
  const status = isAxiosError(error) ? error.response?.status : null;

  switch (status) {
    case 401: {
      /**
       * header code status
       *  4011: Go to login
       *  4012: Token expired
       */
      const accessToken = Cookies.get(LOCAL_STORAGE_ACCESS_KEY) || '';

      const shouldRefreshAccessToken =
        Boolean(accessToken) &&
        isAxiosError(error) &&
        // eslint-disable-next-line eqeqeq
        (error.response?.headers['ErrorCode'] == '4012' ||
          // eslint-disable-next-line eqeqeq
          error.response?.headers['errorcode'] == '4012' ||
          error.response?.data.errorDescription === 'Token expired');

      if (shouldRefreshAccessToken) {
        if (!TokenState.expired) {
          TokenState.expired = true;
        }

        if (!TokenState.isGettingNewToken) {
          TokenState.isGettingNewToken = true;
          return refreshAccessToken(accessToken)
            .then((response) => {
              assert(
                !!response,
                new Error('[NvAxios.interceptors - Status 401] - response can not be undefined.'),
                'ERROR'
              );
              if (response) {
                setAccessToken(response.data.accessToken);
                TokenState.expired = false;
              }

              TokenState.isGettingNewToken = false;
            })
            .catch((err) => {
              if (isAxiosError(err) && err.response?.status === 422) {
                setCookieForSpecificDomain(TARGET_URI, getPathForTargetURI(), `${ApplicationConfig.Actioner.baseHost}`);
                window.location.href = `${ApplicationConfig.Actioner.origin}${ROUTE_DEFAULTS.LOG_OUT}`;
              } else {
                TokenState.isGettingNewToken = false;
              }
            })
            .finally(() => {
              return Promise.reject(error);
            });
        } else {
          return Promise.reject(error);
        }
      } else {
        if (isAxiosError(error)) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if ((error.config as any).isPublic) {
            // Has no access token or has access token but it cannot be refreshed by identity service.
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (error.config as any).publicCallback();
            return Promise.resolve({ data: null });
          } else {
            if (isGoToLogin(error)) {
              setCookieForSpecificDomain(TARGET_URI, getPathForTargetURI(), `${ApplicationConfig.Actioner.baseHost}`);
              window.location.href = `${ApplicationConfig.Actioner.origin}${ROUTE_DEFAULTS.LOG_OUT}`;
            } else {
              assert(
                false,
                new Error(
                  `Axios error and not is public, 401 resulted with error: ${JSON.stringify(
                    error
                  )}, token state:${JSON.stringify(TokenState)}`
                ),
                'ERROR'
              );
            }
          }
        } else {
          assert(
            false,
            new Error(
              `Not axios error, 401 error: ${JSON.stringify(error)}, token state: ${JSON.stringify(TokenState)}`
            ),
            'ERROR'
          );
        }
        // refer: https://github.com/axios/axios/issues/583#issuecomment-504317347
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return Promise.reject(error);
      }
    }
    case 403: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (isAxiosError(error) && !(NvAxios.defaults as any).isPublic) {
        // eslint-disable-next-line eqeqeq
        if (error.response?.headers['errorcode'] == '4031') {
          //  4031: No workspace access
          window.location.href = `${window.location.origin}/error/invalid-workspace`;
          // eslint-disable-next-line eqeqeq
        } else if (error.response?.headers['errorcode'] == '4033') {
          //  4033: suspended workspace
          window.location.href = `${window.location.origin}/error/suspended-workspace`;
        } else {
          assert(false, new Error(`Unhandled errorcode : ${error.response?.headers['errorcode']}`), 'ERROR');
        }
      }
      return Promise.reject(error);
    }

    default:
      return Promise.reject(error);
  }
};
