import { SlackAppInstallationTrigger, useInstallSlackApp } from '@novaera/actioner-service';
import { LoadingPage } from '@novaera/identity-provider';
import { useNavigate, useParams, useQueryParams } from '@novaera/route';
import { assert } from '@novaera/utils';
import { useEffect, useState } from 'react';
import { USER_APP_SLACK_APP } from '../../constants';

import { getSlackAppRedirectUri } from '../utils';
import { CompleteSlackAppInstallation } from './complete-slack-app-installation';

export const InstallSlackApp: React.FC<React.PropsWithChildren<unknown>> = () => {
  const navigate = useNavigate();
  const { userAppId } = useParams();
  const { getSearchParams, removeQueryParams } = useQueryParams();
  const params = getSearchParams<{ code: string; state: string }>();
  const { mutate: installSlackApp, isLoading } = useInstallSlackApp(userAppId);
  const [isCompleteStateOpen, setIsCompleteStateOpen] = useState<boolean>(false);
  const [installationTrigger, setInstallationTrigger] = useState<SlackAppInstallationTrigger | null>(null);

  useEffect(() => {
    if ((!params?.code || !params?.state) && !isCompleteStateOpen) {
      navigate(USER_APP_SLACK_APP(userAppId));
    }
  }, [isCompleteStateOpen, navigate, userAppId, params?.code, params?.state]);

  useEffect(() => {
    if (params?.code && params?.state) {
      const authorizationCode = params.code;
      const [triggerId, installTrigger] = params.state.split('_');

      assert(!!userAppId, new Error('Slack app can not be installed without appId'), 'ERROR');

      const redirectUri = getSlackAppRedirectUri(userAppId);

      installSlackApp(
        {
          appId: userAppId,
          installSlackAppRequestParams: {
            authorizationCode,
            triggerId,
            redirectUri,
          },
        },

        {
          onSuccess: () => {
            setIsCompleteStateOpen(true);
            setInstallationTrigger(installTrigger as SlackAppInstallationTrigger);
          },
          onSettled: () => {
            removeQueryParams(['code', 'state']);
          },
        }
      );
    }
  }, [installSlackApp, userAppId, params?.code, params?.state, removeQueryParams]);

  return isLoading ? (
    <LoadingPage />
  ) : (
    <CompleteSlackAppInstallation
      isOpen={isCompleteStateOpen}
      installationTrigger={installationTrigger}
      onClose={() => {
        setIsCompleteStateOpen(false);
        navigate(USER_APP_SLACK_APP(userAppId));
      }}
    />
  );
};
